import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { MedicalCard } from '@app/models/MedicalCard.model';

export interface MedicalCardsState extends EntityState<MedicalCard> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'medicalCards' })
export class MedicalCardsStore extends EntityStore<MedicalCardsState> {
  constructor() {
    super();
  }
}

