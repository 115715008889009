<mat-dialog-content>
  <h2 *ngIf="data?.warning">{{ 'warningDialog.safety_warning' | translate }} </h2>
  <div class="flex-row">
    <img
      class="warning"
      *ngIf="data?.warning"
      src="/assets/png/warning_iso.png"
    />
    <ng-container *ngIf="data?.translated">
      <div class="message">{{ data?.prompt | translate }}</div>
    </ng-container>
    <ng-container *ngIf="!data?.translated">
      <div class="message" [innerHtml]="data?.prompt"></div>
    </ng-container>
  </div>

  <div class="actions-container">
    <button class="outline-button" mat-ripple (click)="ok()">
      {{ "common.close" | translate }}
    </button>
  </div>
</mat-dialog-content>
