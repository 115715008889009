import { BATTERY_MAX, BATTERY_MIN, Cable, CableType, ConnectionQuality } from '@app/enums';
import { Battery, Device, Supply } from '@app/types';

export interface StimStat {
  latency: number;
  ena: boolean;
  flag: number;
  max: number;
  tim: number;
}

export class StellaState {

  public pingTime: number;
  public battery: Battery;
  public network: any;
  public channelsConnected: ChannelsInfo[] = [];
  public impedance: [];
  public debug = '';
  public log = '';
  public supply: Supply;
  public userAgent: any;
  public network2: any;
  public device: Device;
  public cable: CableType = CableType.NONE; // CableType.EMGEMS_6_2; //CableType.NONE;

  public accumulatedJson: any = {};

  accumulate(json) {
    this.accumulatedJson = {
      ...this.accumulatedJson,
      ...json,
      dbg: ''
    };
  }

  modify(changes) {
    if (changes.pingTime) {
      this.pingTime = changes.pingTime;
    }
    if (changes.bat) {
      const bb = {
        low: BATTERY_MIN,
        high: BATTERY_MAX,
        ...changes.bat
      };
      this.battery = {
        ...bb,
        notified: bb.low > bb.voltage,
        level: this.calculateLevel(bb),
      };
    }
    if (changes.dbg) {
      this.debug += changes.dbg;
    }
    if (changes.i) {
      this.impedance = changes.i;
      for (let i = 0; i < changes.i.length; i++) {
        this.channelsConnected[i] = {
          imp: changes.i[i],
          quality: this.checkImpedance(changes.i[i])
        };
      }
    }
    if (changes.sup) {
      this.supply = {
        ...this.supply,
        ...changes.sup
      };
    }
    if (changes.userAgent) {
      this.userAgent = {
        ...this.userAgent,
        ...changes.userAgent
      };
    }
    if (changes.network) {
      this.network2 = {
        ...this.network2,
        ...changes.network
      };
    }
    if (changes.device) {
      this.device = {
        ...this.device,
        ...changes.device
      };
    }
    if (changes.mdr) {
      const newCable = changes.mdr.id;
      this.cable = newCable;
    }
    if (changes.log) {
      this.log += changes.log + '\r\n';
    }
  }

  getConnectionQuality() {
    return this.pingTime;
  }

  getCable() {
    return Cable[this.cable];
  }

  private checkImpedance(imp: number): ConnectionQuality {
    const WELL_THRESHOLD = 6e3;
    const POOR_THRESHOLD = 12e3;
    if (imp <= -1e3) {
      return ConnectionQuality.NONE;
    }
    if (imp <= WELL_THRESHOLD) {
      return ConnectionQuality.WELL;
    } else if (imp > WELL_THRESHOLD && imp <= POOR_THRESHOLD) {
      return ConnectionQuality.POOR;
    }

    return ConnectionQuality.NONE;
  }

  private calculateLevel(battery): number {
    const A = 100 / (battery.high - battery.low);
    const B = 0 - A * battery.low;

    return Math.min(Math.max(A * battery.vol + B, 0), 100);
  }
}

export interface ChannelsInfo {
  imp: number;
  muscle?: string;
  quality?: ConnectionQuality;
}
