import { Component, Input, Output } from '@angular/core';
import { GamesService } from '@app/shared/services/games.service';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MarkdownMessageDialogComponent } from '@components/markdown-message-dialog/markdown-message-dialog.component';
import { AnalyticsCategory, GTagService, AnalyticsAction } from '@app/shared/gtag.service';

@Component({
  selector: 'sba-game-copyright-action',
  templateUrl: './game-copyright-action.component.html',
  styleUrls: ['./game-copyright-action.component.scss']
})
export class GameCopyrightActionComponent {

  @Input()
  game: string;

  constructor(
    private readonly gtag: GTagService,
    private readonly gamesService: GamesService,
    private readonly dialog: MatDialog
  ) {
  }

  showCopyright(e: MouseEvent) {
    e.preventDefault();
    e.stopImmediatePropagation();

    this.gtag.emit(AnalyticsCategory.CONCEPT, AnalyticsAction.CONCEPT_GAME_DETAILS_CLICKED, this.game);
    return this.gamesService.getGames()
      .pipe(
        map(gamesDescription => gamesDescription[this.game])
      ).subscribe(v => {
        const copyright = v.copyright;

        // TODO: In the future, add multilanguage title to the dialog saying "Copyright notice"
        this.dialog.open(MarkdownMessageDialogComponent, {
          data: {
            prompt: copyright
          }
        });
      });
  }
}
