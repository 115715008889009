import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface SessionState {
  session: boolean;
  exercise: boolean;
  logging: boolean;
  uploading: boolean;
  progress: number;
  exercises: (string | number)[];
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'session'
})
export class SessionStore extends Store<SessionState> {

  constructor() {
    super({});
  }
}
