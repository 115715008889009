<mat-dialog-content>
  <ng-container *ngIf="data?.translated">
    <markdown ngPreserveWhitespaces class="message">{{ data?.prompt | translate }}</markdown>
  </ng-container>
  <ng-container *ngIf="!data?.translated">
     <markdown ngPreserveWhitespaces class="message">{{ data?.prompt }}</markdown>
  </ng-container>

  <div class="actions-container">
    <button class="outline-button" mat-ripple (click)="ok()">
      {{ 'common.close' | translate }}
    </button>
  </div>
</mat-dialog-content>
