import { isProgramViewType } from "./utils/utils";

export interface BodyModel {
  name: string;
  part: string;
  emgIcons: string[];
  emsIcons: string[];
  emsTriggeredIcons?: string[];
  channels: number;
  supportSide: boolean;
  tensPurpose?: boolean;
  pelvicPurpose?: boolean;
}

export interface IconModel {
  name: string;
  part?: string;
  icons: string[];
  channels: number;
  supportSide: boolean;
}

export const ADDUCTORES = {
  name: 'adductores',
  part: 'lower',
  emgIcons: ['Adductores_EMG.png'],
  emsIcons: ['Adductores_EMS.png'],
  emsTriggeredIcons: ['Adductores_EMS_ref.png'],
  channels: 1,
  supportSide: true,
  pelvicPurpose: true
};

export const BICEPS_BRACHII = {
  name: 'biceps-brachii',
  part: 'upper',
  emgIcons: ['Biceps-brachii_EMG.png'],
  emsIcons: ['Biceps-brachii_EMS.png'],
  emsTriggeredIcons: ['Biceps-brachii_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const BICEPS_FEMORIS = {
  name: 'biceps-femoris',
  part: 'lower',
  emgIcons: ['Biceps-femoris_EMG.png'],
  emsIcons: ['Biceps-femoris_EMS.png', 'Biceps-femoris_EMS_02.png'],
  emsTriggeredIcons: ['Biceps-femoris_EMS_ref_02.png'],
  channels: 2,
  supportSide: true
};

export const BRACHIORADIALIS = {
  name: 'brachioradialis',
  part: 'upper',
  emgIcons: ['Brachioradialis_EMG.png'],
  emsIcons: ['Brachioradialis_EMS.png'],
  emsTriggeredIcons: ['Brachioradialis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const DELTOIDEUS_ANTERIOR = {
  name: 'deltoideus-anterior',
  part: 'upper',
  emgIcons: ['Deltoideus-anterior_EMG.png'],
  emsIcons: ['Deltoideus-anterior_EMS.png'],
  emsTriggeredIcons: ['Deltoideus-anterior_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const DELTOIDEUS_MEDIUM = {
  name: 'deltoideus-medium',
  part: 'upper',
  emgIcons: ['Deltoideus-medium_EMG.png'],
  emsIcons: ['Deltoideus-medium_EMS.png'],
  emsTriggeredIcons: ['Deltoideus-medium_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const DELTOIDEUS_POSTERIOR = {
  name: 'deltoideus-posterior',
  part: 'upper',
  emgIcons: ['Deltoideus-posterior_EMG.png'],
  emsIcons: ['Deltoideus-posterior_EMS.png'],
  emsTriggeredIcons: ['Deltoideus-posterior_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const ERECTOR_SPINAE_ILIOCOSTALIS = {
  name: 'erector-spinae-iliocostalis',
  part: 'back',
  emgIcons: ['Erector-spinae-iliocostalis_EMG.png'],
  emsIcons: ['Erector-spinae-iliocostalis_EMS.png'],
  emsTriggeredIcons: ['Erector-spinae-iliocostalis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const ERECTOR_SPINAE_LONGISSIMUS = {
  name: 'erector-spinae-longissimus',
  part: 'back',
  emgIcons: ['Erector-spinae-longissimus_EMG.png'],
  emsIcons: ['Erector-spinae-longissimus_EMS.png'],
  emsTriggeredIcons: ['Erector-spinae-longissimus_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const EXTENSOR_DIGITORUM = {
  name: 'extensor_digitorum',
  part: 'upper',
  emgIcons: ['Extensor_digitorum_EMG.png'],
  emsIcons: ['Extensor_digitorum_EMS.png'],
  emsTriggeredIcons: ['Extensor_digitorum_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const FLEXOR_DIGITORUM = {
  name: 'flexor_digitorum',
  part: 'upper',
  emgIcons: ['Flexor_digitorum_superficialis_EMG.png'],
  emsIcons: ['Flexor_digitorum_superficialis_EMS.png'],
  emsTriggeredIcons: ['Flexor_digitorum_superficialis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const FLEXOR_POLLICIS_LONGUS = {
  name: 'flexor_pollicis_longus',
  part: 'upper',
  emgIcons: ['Flexor_pollicis_longus_EMG.png'],
  emsIcons: ['Flexor_pollicis_longus_EMS.png'],
  emsTriggeredIcons: ['Flexor_pollicis_longus_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const FLEXOR_CARPI_RADIALIS = {
  name: 'flexor-carpi-radialis',
  part: 'upper',
  emgIcons: ['Flexor-carpi-radialis_EMG.png'],
  emsIcons: ['Flexor-carpi-radialis_EMS.png'],
  emsTriggeredIcons: ['Flexor-carpi-radialis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const FRONTALIS = {
  name: 'frontalis',
  part: 'head',
  emgIcons: ['Frontalis_EMG_2.png'],
  emsIcons: ['Frontalis_EMS.png'],
  emsTriggeredIcons: ['Frontalis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const STERNOCLEIDOMASTOID = {
  name: 'sternocleidomastoid',
  part: 'back',
  emgIcons: ['Sternocleidomastoid_EMG.png'],
  emsIcons: ['Sternocleidomastoid_EMG.png'], // i must leave EMSIcons because is required but we don't have icon for ems
  channels: 1,
  supportSide: true
};
export const TEMPORAL = {
  name: 'temporal',
  part: 'head',
  emgIcons: ['Temporal_EMG.png'],
  emsIcons: ['Temporal_EMG.png'], // i must leave EMSIcons because is required but we don't have icon for ems
  channels: 1,
  supportSide: true
};

export const GASTROCNEMIUS_MEDIALIS = {
  name: 'gastrocnemius-medialis',
  part: 'lower',
  emgIcons: ['Gastrocnemius-medialis_EMG.png'],
  emsIcons: ['Gastrocnemius-medialis_EMS_02.png'],
  emsTriggeredIcons: ['Gastrocnemius-medialis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const GASTROCNEMIUS_LATERALIS = {
  name: 'gastrocnemius-lateralis',
  part: 'lower',
  emgIcons: ['Gastrocnemius_lateralis_EMG.png'],
  emsIcons: ['Gastrocnemius_lateralis_EMS.png'],
  emsTriggeredIcons: ['Gastrocnemius_lateralis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const GASTROCNEMIUS_EXTERNAL = {
  name: 'gastrocnemius-external',
  part: 'lower',
  emgIcons: ['Gastrocnemius-medialis_EMG.png'],
  emsIcons: ['Gastrocnemius-medialis_EMS_02.png'],
  emsTriggeredIcons: ['Gastrocnemius-medialis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const GASTROCNEMIUS = {
  name: 'gastrocnemius',
  part: 'lower',
  emgIcons: [],
  emsIcons: ['Gastrocnemius-medialis_EMS_01.png'],
  emsTriggeredIcons: [],
  channels: 2,
  supportSide: true
};

export const GLUTEUS_MAXIMUS = {
  name: 'gluteus-maximus',
  part: 'lower',
  emgIcons: ['Gluteus-maximus_EMG.png'],
  emsIcons: ['Gluteus-maximus_EMS.png'],
  emsTriggeredIcons: ['Gluteus-maximus_EMS_ref.png'],
  channels: 2,
  supportSide: true,
  pelvicPurpose: true
};

export const GLUTEUS_MEDIUS = {
  name: 'gluteus-medius',
  part: 'lower',
  emgIcons: ['Gluteus-medius_EMG.png'],
  emsIcons: ['Gluteus-medius_EMS.png'],
  emsTriggeredIcons: ['Gluteus-medius_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const INFRASPINATUS = {
  name: 'infraspinatus',
  part: 'upper',
  emgIcons: ['Infraspinatus_EMG.png'],
  emsIcons: ['Infraspinatus_EMS.png'],
  emsTriggeredIcons: ['Infraspinatus_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const LATISSIMUS_DORSI = {
  name: 'latissimus-dorsi',
  part: 'back',
  emgIcons: ['Latissimus-dorsi_EMG.png'],
  emsIcons: ['Latissimus-dorsi_EMS.png'],
  emsTriggeredIcons: ['Latissimus-dorsi_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const MASSETER = {
  name: 'masseter',
  part: 'head',
  emgIcons: ['Masseter_EMG_2.png'],
  emsIcons: ['Masseter_EMS.png'],
  emsTriggeredIcons: ['Masseter_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const MULTIFIDUS = {
  name: 'multifidus',
  part: 'back',
  emgIcons: ['Multifidus_EMG.png'],
  emsIcons: ['Multifidus_EMS.png'],
  emsTriggeredIcons: ['Multifidus_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const NECK_EXTENSORS = {
  name: 'neck-extensors',
  part: 'back',
  emgIcons: ['Neck-extensors_EMG.png'],
  emsIcons: ['Neck-extensors_EMS.png'],
  emsTriggeredIcons: ['Neck-extensors_EMS_ref.png'],
  channels: 1,
  supportSide: true
};
export const OBLIQUUS_EXTERNUS_ABDOMINIS = {
  name: 'obliquus-externus-abdominis',
  part: 'abdominal',
  emgIcons: ['Obliquus-externus-abdominis_EMG2.png'],
  emsIcons: ['Obliquus-externus-abdominis_EMS2.png'],
  emsTriggeredIcons: ['Obliquus-externus-abdominis_EMS_ref_03.png'],
  channels: 1,
  supportSide: true
};

export const ILIOPSOAS = {
  name: 'iliopsoas',
  part: 'back',
  emgIcons: ['Transversus-abdominis_EMG.png'],
  emsIcons: ['Transversus-abdominis_EMS.png'],
  emsTriggeredIcons: ['Transversus-abdominis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};


export const PECTORALIS_MAJOR = {
  name: 'pectoralis-major',
  part: 'pectoral',
  emgIcons: ['Pectoralis-major_EMG.png'],
  emsIcons: ['Pectoralis-major_EMS.png'],
  emsTriggeredIcons: ['Pectoralis-major_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const PERONEUS = {
  name: 'peroneus-longus',
  part: 'lower',
  emgIcons: ['Peroneus_EMG.png'],
  emsIcons: ['Peroneus_EMS.png'],
  emsTriggeredIcons: ['Peroneus_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const RECTUS_ABDOMINIS = {
  name: 'rectus-abdominis',
  part: 'abdominal',
  emgIcons: ['Miesien_prosty_EMG.png'],
  emsIcons: ['Miesien_prosty_EMS.png'],
  emsTriggeredIcons: ['Miesien_prosty_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const RECTUS_FEMORIS = {
  name: 'rectus-femoris',
  part: 'lower',
  emgIcons: ['Rectus-femoris_EMG.png'],
  emsIcons: ['Rectus-femoris_EMS.png'],
  emsTriggeredIcons: ['Rectus-femoris_EMS_ref.png'],
  channels: 1,
  supportSide: true
};
export const SEMITENDINOSUS = {
  name: 'semitendinosus',
  part: 'lower',
  emgIcons: ['Semitendinosus_EMG.png'],
  emsIcons: ['Semitendinosus_EMS.png'],
  emsTriggeredIcons: ['Semitendinosus_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const SERRATUS_ANTERIOR = {
  name: 'serratus-anterior',
  part: 'pectoral',
  emgIcons: ['Serratus-anterior_EMG_01.png', 'Serratus-anterior_EMG_02.png'],
  emsIcons: ['Serratus-anterior_EMS_01.png', 'Serratus-anterior_EMS_02.png'],
  emsTriggeredIcons: ['Serratus-anterior_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const SOLEUS = {
  name: 'soleus',
  part: 'lower',
  emgIcons: ['Soleus_EMG.png'],
  emsIcons: ['Soleus_EMS.png'],
  emsTriggeredIcons: ['Soleus_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const SUPRASPINATUS = {
  name: 'supraspinatus',
  part: 'upper',
  emgIcons: ['Supraspinatus_EMG.png'],
  emsIcons: ['Supraspinatus_EMS.png'],
  emsTriggeredIcons: ['Supraspinatus_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const PRONATOR_TERES = {
  name: 'pronator_teres',
  part: 'upper',
  emgIcons: ['Pronator_teres_EMG.png'],
  emsIcons: ['Pronator_teres_EMS.png'],
  emsTriggeredIcons: ['Pronator_teres_EMS_ref.png'],
  channels: 1,
  supportSide: true
};


export const TENSOR_FASCIA_LATAE = {
  name: 'tensor-fascia-latae',
  part: 'lower',
  emgIcons: ['Tensor-fascia-latae_EMG.png'],
  emsIcons: ['Tensor-fascia-latae_EMS.png'],
  emsTriggeredIcons: ['Tensor-fascia-latae_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const TIBIALIS_ANTERIOR = {
  name: 'tibialis-anterior',
  part: 'lower',
  emgIcons: ['Tibialis-anterior_EMG.png'],
  emsIcons: ['Tibialis-anterior_EMS.png'],
  emsTriggeredIcons: ['Tibialis-anterior_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const TRANSVERSUS_ABDOMINIS = {
  name: 'transversus-abdominis',
  part: 'abdominal',
  emgIcons: ['Obliquus-externus-abdominis_EMG.png'],
  emsIcons: ['Obliquus-externus-abdominis_EMS.png'],
  emsTriggeredIcons: ['Obliquus-externus-abdominis_EMS_ref_01.png', 'Obliquus-externus-abdominis_EMS_ref_02.png'],
  channels: 1,
  supportSide: true,
  pelvicPurpose: true
};

export const TRAPEZIUS_ASCENDENS = {
  name: 'trapezius-ascendens',
  part: 'back',
  emgIcons: ['Trapezius-ascendens_EMG.png'],
  emsIcons: ['Trapezius-ascendens_EMS.png'],
  emsTriggeredIcons: ['Trapezius-ascendens_EMS_ref.png'],
  channels: 1,
  supportSide: true
};
export const TRAPEZIUS_DESCENDES = {
  name: 'trapezius-descendes',
  part: 'back',
  emgIcons: ['Trapezius-descendes_EMG.png'],
  emsIcons: ['Trapezius-descendes_EMS.png'],
  emsTriggeredIcons: ['Trapezius-descendes_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const TRAPEZIUS_TRANSVERSALIS = {
  name: 'trapezius-transversalis',
  part: 'back',
  emgIcons: ['Trapezius-transversalis_EMG.png'],
  emsIcons: ['Trapezius-transversalis_EMS.png'],
  emsTriggeredIcons: ['Trapezius-transversalis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const TRICEPS_BRACHII_C_LAT = {
  name: 'triceps-brachii-c-lat',
  part: 'upper',
  emgIcons: ['Triceps-brachii-c-lat_EMG.png'],
  emsIcons: ['Triceps-brachii-c-lat_EMS.png'],
  emsTriggeredIcons: ['Triceps-brachii-c-lat_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const TRICEPS_BRACHII_C_LONG = {
  name: 'triceps-brachii-c-long',
  part: 'upper',
  emgIcons: ['Triceps-brachii-c-long_EMG.png'],
  emsIcons: ['Triceps-brachii-c-long_EMS.png'],
  emsTriggeredIcons: ['Triceps-brachii-c-long_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const VASTUS_LATERIALIS = {
  name: 'vastus-lateralis',
  part: 'lower',
  emgIcons: ['Vastus-lateralis_EMG.png'],
  emsIcons: ['Vastus-lateralis_EMS.png'],
  emsTriggeredIcons: ['Vastus-lateralis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const VASTUS_MEDIALIS = {
  name: 'vastus-medialis',
  part: 'lower',
  emgIcons: ['Vastus-medialis_EMG.png'],
  emsIcons: ['Vastus-medialis_EMS.png'],
  emsTriggeredIcons: ['Vastus-medialis_EMS_ref.png'],
  channels: 1,
  supportSide: true
};

export const TENS_ANKLE = {
  name: 'ankle',
  part: 'lower',
  emgIcons: [],
  emsIcons: ['TENS_ankle_pain.png'],
  channels: 1,
  supportSide: true,
  tensPurpose: true
};

export const TENS_ELBOW = {
  name: 'elbow',
  part: 'upper',
  emgIcons: [],
  emsIcons: ['TENS_elbow_pain.png'],
  channels: 2,
  supportSide: true,
  tensPurpose: true
};

export const TENS_FOOT = {
  name: 'foot',
  part: 'lower',
  emgIcons: [],
  emsIcons: [
    'TENS_foot_pain_01a.png',
    'TENS_foot_pain_01b.png',
  ],
  channels: 2,
  supportSide: true,
  tensPurpose: true
};

export const TENS_HAND = {
  name: 'forearm',
  part: 'upper',
  emgIcons: [],
  emsIcons: [
    'TENS_hand_pain_01a.png',
    'TENS_hand_pain_01b.png'
  ],
  channels: 1,
  supportSide: true,
  tensPurpose: true
};

export const TENS_HIP = {
  name: 'hip',
  part: 'back',
  emgIcons: [],
  emsIcons: [
    'TENS_hip_pain.png',
  ],
  channels: 2,
  supportSide: true,
  tensPurpose: true
};

export const TENS_LOW_BACK = {
  name: 'low_back',
  part: 'back',
  emgIcons: [],
  emsIcons: [
    'TENS_low_back_pain.png',
  ],
  channels: 2,
  supportSide: true,
  tensPurpose: true
};

export const TENS_KNEE = {
  name: 'knee',
  part: 'lower',
  emgIcons: [],
  emsIcons: [
    'TENS_knee_pain.png',
  ],
  channels: 2,
  supportSide: true,
  tensPurpose: true
};

export const TENS_UPPER_BACK = {
  name: 'upper_back',
  part: 'back',
  emgIcons: [],
  emsIcons: [
    'TENS_upper_back_pain.png',
  ],
  channels: 2,
  supportSide: true,
  tensPurpose: true
};

export const TENS_NECK = {
  name: 'neck',
  part: 'back',
  emgIcons: [],
  emsIcons: [
    'TENS_neck_pain.png',
  ],
  channels: 2,
  supportSide: true,
  tensPurpose: true
};

export const TENS_SHOULDER = {
  name: 'shoulder',
  part: 'back',
  emgIcons: [],
  emsIcons: [
    'TENS_shoulder_pain.png',
  ],
  channels: 2,
  supportSide: true,
  tensPurpose: true
};

export const TENS_WRIST = {
  name: 'hand',
  part: 'upper',
  emgIcons: [],
  emsIcons: [
    'TENS_wrist_pain_01a.png',
    'TENS_wrist_pain_01b.png',
  ],
  channels: 1,
  supportSide: true,
  tensPurpose: true
};

const PELVIC_CHANNEL_LIMIT = 2;
export const PELVIC_VAGINAL = {
  name: 'vaginal',
  part: 'pelvic',
  emgIcons: ['Vaginal.png'],
  emsIcons: ['Vaginal.png'],
  emsTriggeredIcons: ['Vaginal.png'],
  channels: PELVIC_CHANNEL_LIMIT,
  supportSide: false,
  tensPurpose: false,
  pelvicPurpose: true
};
export const PELVIC_ANAL_F = {
  name: 'anal_f',
  part: 'pelvic',
  emgIcons: ['Anal_01.png'],
  emsIcons: ['Anal_01.png'],
  emsTriggeredIcons: ['Anal_01.png'],
  channels: PELVIC_CHANNEL_LIMIT,
  supportSide: false,
  tensPurpose: false,
  pelvicPurpose: true
};
export const PELVIC_ANAL_M = {
  name: 'anal_m',
  part: 'pelvic',
  emgIcons: ['Anal_02.png'],
  emsIcons: ['Anal_02.png'],
  emsTriggeredIcons: ['Anal_02.png'],
  channels: PELVIC_CHANNEL_LIMIT,
  supportSide: false,
  tensPurpose: false,
  pelvicPurpose: true
};

const FES_CHANNEL_LIMIT = 3;
const OPEN_CLOSE = {
  name: 'open_and_close',
  part: 'upper',
  channels: FES_CHANNEL_LIMIT,
  supportSide: true,
  icons: ['FES_grasp_release_kanal_01.png', 'FES_grasp_release_kanal_02.png', 'FES_grasp_release_kanal_03.png']
};
const HAND_TO_MOUTH = {
  name: 'hand_to_mouth',
  part: 'upper',
  channels: 4,
  supportSide: true,
  icons: ['FES_hand_to_mouth_kanal_01.png', 'FES_hand_to_mouth_kanal_020304.png']
};
const GRASP_RELEASE = {
  name: 'grasp_and_release',
  part: 'upper',
  channels: FES_CHANNEL_LIMIT,
  supportSide: true,
  icons: ['FES_grasp_release_kanal_01.png', 'FES_grasp_release_kanal_02.png', 'FES_grasp_release_kanal_03.png']
};
const ARM_SUPPORT = {
  name: 'arm_extension_support',
  part: 'upper',
  channels: FES_CHANNEL_LIMIT,
  supportSide: true,
  icons: ['FES_arm_support_kanal_010203.png']
};
const OPEN_CLOSE_TRIGGERED = {
  name: 'open_and_close_triggered',
  part: 'upper',
  channels: FES_CHANNEL_LIMIT,
  supportSide: true,
  icons: ['FES_grasp_release_kanal_01_ref.png', 'FES_grasp_release_kanal_02_ref.png', 'FES_grasp_release_kanal_03_ref.png']
};
const HAND_TO_MOUTH_TRIGGERED = {
  name: 'hand_to_mouth_triggered',
  part: 'upper',
  channels: 4,
  supportSide: true,
  icons: ['FES_hand_to_mouth_kanal_01_ref.png', 'FES_hand_to_mouth_kanal_020304_ref.png']
};
const GRASP_RELEASE_TRIGGERED = {
  name: 'grasp_and_release_triggered',
  part: 'upper',
  channels: FES_CHANNEL_LIMIT,
  supportSide: true,
  icons: ['FES_grasp_release_kanal_01_ref.png', 'FES_grasp_release_kanal_02_ref.png', 'FES_grasp_release_kanal_03_ref.png']
};
const ARM_SUPPORT_TRIGGERED = {
  name: 'arm_extension_support_triggered',
  part: 'upper',
  channels: FES_CHANNEL_LIMIT,
  supportSide: true,
  icons: ['FES_arm_support_kanal_010203_ref.png']
};

export const BODY_MODELS: BodyModel[] = [
  FRONTALIS,
  STERNOCLEIDOMASTOID,
  TEMPORAL,
  MASSETER,
  NECK_EXTENSORS,
  TRAPEZIUS_ASCENDENS,
  TRAPEZIUS_DESCENDES,
  TRAPEZIUS_TRANSVERSALIS,
  LATISSIMUS_DORSI,
  EXTENSOR_DIGITORUM,
  FLEXOR_DIGITORUM,
  FLEXOR_POLLICIS_LONGUS,
  PRONATOR_TERES,
  SUPRASPINATUS,
  ERECTOR_SPINAE_ILIOCOSTALIS,
  ERECTOR_SPINAE_LONGISSIMUS,
  MULTIFIDUS,
  DELTOIDEUS_ANTERIOR,
  DELTOIDEUS_MEDIUM,
  DELTOIDEUS_POSTERIOR,
  INFRASPINATUS,
  BICEPS_BRACHII,
  TRICEPS_BRACHII_C_LAT,
  TRICEPS_BRACHII_C_LONG,
  BRACHIORADIALIS,
  FLEXOR_CARPI_RADIALIS,
  PECTORALIS_MAJOR,
  SERRATUS_ANTERIOR,
  OBLIQUUS_EXTERNUS_ABDOMINIS,
  RECTUS_ABDOMINIS,
  TRANSVERSUS_ABDOMINIS,
  TENSOR_FASCIA_LATAE,
  GLUTEUS_MAXIMUS,
  GLUTEUS_MEDIUS,
  // ILIOPSOAS,
  ADDUCTORES,
  RECTUS_FEMORIS,
  VASTUS_LATERIALIS,
  VASTUS_MEDIALIS,
  BICEPS_FEMORIS,
  SEMITENDINOSUS,
  GASTROCNEMIUS_MEDIALIS,
  GASTROCNEMIUS_LATERALIS,
  SOLEUS,
  PERONEUS,
  TIBIALIS_ANTERIOR,
  TENS_ANKLE,
  TENS_ELBOW,
  TENS_FOOT,
  TENS_HAND,
  TENS_LOW_BACK,
  TENS_SHOULDER,
  TENS_WRIST,
  TENS_UPPER_BACK,
  TENS_KNEE,
  TENS_NECK,
  TENS_HIP
];

const EMS_PAIN: IconModel[] = [
  TENS_ANKLE,
  TENS_ELBOW,
  TENS_FOOT,
  TENS_HAND,
  TENS_LOW_BACK,
  TENS_SHOULDER,
  TENS_WRIST,
  TENS_UPPER_BACK,
  TENS_KNEE,
  TENS_NECK,
  TENS_HIP,
  PELVIC_VAGINAL,
  PELVIC_ANAL_F,
  PELVIC_ANAL_M
].map(bm => ({
  name: bm.name,
  icons: bm.emsIcons,
  supportSide: bm.supportSide,
  channels: bm.channels,
  part: bm.part || 'not_applicable'
}));

const EMS_FES_STANDARD: IconModel[] = [
  OPEN_CLOSE,
  HAND_TO_MOUTH,
  GRASP_RELEASE,
  ARM_SUPPORT

];
const EMS_FES_TRIGGERED: IconModel[] = [
  OPEN_CLOSE_TRIGGERED,
  HAND_TO_MOUTH_TRIGGERED,
  GRASP_RELEASE_TRIGGERED,
  ARM_SUPPORT_TRIGGERED
];

const EMS_STANDARD: IconModel[] = [
  FRONTALIS,
  MASSETER,
  NECK_EXTENSORS,
  TRAPEZIUS_ASCENDENS,
  TRAPEZIUS_DESCENDES,
  TRAPEZIUS_TRANSVERSALIS,
  LATISSIMUS_DORSI,
  ERECTOR_SPINAE_ILIOCOSTALIS,
  ERECTOR_SPINAE_LONGISSIMUS,
  EXTENSOR_DIGITORUM,
  FLEXOR_DIGITORUM,
  FLEXOR_POLLICIS_LONGUS,
  PRONATOR_TERES,
  SUPRASPINATUS,
  MULTIFIDUS,
  DELTOIDEUS_ANTERIOR,
  DELTOIDEUS_MEDIUM,
  DELTOIDEUS_POSTERIOR,
  // ILIOPSOAS,
  INFRASPINATUS,
  BICEPS_BRACHII,
  TRICEPS_BRACHII_C_LAT,
  TRICEPS_BRACHII_C_LONG,
  BRACHIORADIALIS,
  FLEXOR_CARPI_RADIALIS,
  PECTORALIS_MAJOR,
  SERRATUS_ANTERIOR,
  OBLIQUUS_EXTERNUS_ABDOMINIS,
  RECTUS_ABDOMINIS,
  TENSOR_FASCIA_LATAE,
  GLUTEUS_MAXIMUS,
  GLUTEUS_MEDIUS,
  ADDUCTORES,
  RECTUS_FEMORIS,
  VASTUS_LATERIALIS,
  VASTUS_MEDIALIS,
  BICEPS_FEMORIS,
  SEMITENDINOSUS,
  GASTROCNEMIUS_MEDIALIS,
  GASTROCNEMIUS_LATERALIS,
  SOLEUS,
  PERONEUS,
  TIBIALIS_ANTERIOR
].map(bm => ({
  name: bm.name,
  icons: bm.emsIcons,
  supportSide: bm.supportSide,
  channels: bm.channels,
  part: bm.part || 'not_applicable'
}));

const EMS_TRIGGERED: IconModel[] = [
  FRONTALIS,
  MASSETER,
  NECK_EXTENSORS,
  TRAPEZIUS_ASCENDENS,
  TRAPEZIUS_DESCENDES,
  TRAPEZIUS_TRANSVERSALIS,
  LATISSIMUS_DORSI,
  ERECTOR_SPINAE_ILIOCOSTALIS,
  ERECTOR_SPINAE_LONGISSIMUS,
  EXTENSOR_DIGITORUM,
  FLEXOR_DIGITORUM,
  FLEXOR_POLLICIS_LONGUS,
  PRONATOR_TERES,
  SUPRASPINATUS,
  // ILIOPSOAS,
  MULTIFIDUS,
  DELTOIDEUS_ANTERIOR,
  DELTOIDEUS_MEDIUM,
  DELTOIDEUS_POSTERIOR,
  INFRASPINATUS,
  BICEPS_BRACHII,
  TRICEPS_BRACHII_C_LAT,
  TRICEPS_BRACHII_C_LONG,
  BRACHIORADIALIS,
  FLEXOR_CARPI_RADIALIS,
  PECTORALIS_MAJOR,
  SERRATUS_ANTERIOR,
  OBLIQUUS_EXTERNUS_ABDOMINIS,
  RECTUS_ABDOMINIS,
  TRANSVERSUS_ABDOMINIS,
  TENSOR_FASCIA_LATAE,
  GLUTEUS_MAXIMUS,
  GLUTEUS_MEDIUS,
  ADDUCTORES,
  RECTUS_FEMORIS,
  VASTUS_LATERIALIS,
  VASTUS_MEDIALIS,
  BICEPS_FEMORIS,
  SEMITENDINOSUS,
  GASTROCNEMIUS_MEDIALIS,
  GASTROCNEMIUS_LATERALIS,
  SOLEUS,
  PERONEUS,
  TIBIALIS_ANTERIOR
].map((bm: BodyModel) => ({
  name: bm.name,
  icons: bm.emsTriggeredIcons || [],
  supportSide: bm.supportSide,
  channels: bm.channels,
  part: bm.part || 'not_applicable'
}));

const EMG_STANDARD = [
  FRONTALIS,
  STERNOCLEIDOMASTOID,
  TEMPORAL,
  MASSETER,
  NECK_EXTENSORS,
  TRAPEZIUS_ASCENDENS,
  TRAPEZIUS_DESCENDES,
  TRAPEZIUS_TRANSVERSALIS,
  LATISSIMUS_DORSI,
  ERECTOR_SPINAE_ILIOCOSTALIS,
  ERECTOR_SPINAE_LONGISSIMUS,
  EXTENSOR_DIGITORUM,
  FLEXOR_DIGITORUM,
  FLEXOR_POLLICIS_LONGUS,
  PRONATOR_TERES,
  SUPRASPINATUS,
  // ILIOPSOAS,
  MULTIFIDUS,
  DELTOIDEUS_ANTERIOR,
  DELTOIDEUS_MEDIUM,
  DELTOIDEUS_POSTERIOR,
  INFRASPINATUS,
  BICEPS_BRACHII,
  TRICEPS_BRACHII_C_LAT,
  TRICEPS_BRACHII_C_LONG,
  BRACHIORADIALIS,
  FLEXOR_CARPI_RADIALIS,
  PECTORALIS_MAJOR,
  SERRATUS_ANTERIOR,
  OBLIQUUS_EXTERNUS_ABDOMINIS,
  RECTUS_ABDOMINIS,
  TRANSVERSUS_ABDOMINIS,
  TENSOR_FASCIA_LATAE,
  GLUTEUS_MAXIMUS,
  GLUTEUS_MEDIUS,
  ADDUCTORES,
  RECTUS_FEMORIS,
  VASTUS_LATERIALIS,
  VASTUS_MEDIALIS,
  BICEPS_FEMORIS,
  SEMITENDINOSUS,
  GASTROCNEMIUS_MEDIALIS,
  GASTROCNEMIUS_LATERALIS,
  SOLEUS,
  PERONEUS,
  TIBIALIS_ANTERIOR
].map(bm => ({
  name: bm.name,
  icons: bm.emgIcons,
  supportSide: bm.supportSide,
  channels: bm.channels,
  part: bm.part || 'not_applicable'
}));

const PELVIC_EMG = [
  PELVIC_VAGINAL,
  PELVIC_ANAL_F,
  PELVIC_ANAL_M,
  ADDUCTORES,
  GLUTEUS_MAXIMUS,
  RECTUS_ABDOMINIS,
  TRANSVERSUS_ABDOMINIS
].map(bm => ({
  name: bm.name,
  icons: bm.emgIcons,
  supportSide: bm.supportSide,
  channels: bm.channels,
  part: bm.part || 'not_applicable'
}));

const PELVIC_STANDARD = [
  PELVIC_VAGINAL,
  PELVIC_ANAL_F,
  PELVIC_ANAL_M
].map(bm => ({
  name: bm.name,
  icons: bm.emsIcons,
  supportSide: bm.supportSide,
  channels: bm.channels,
  part: bm.part || 'not_applicable'
}));

const PELVIC_TRIGGERED = [
  PELVIC_VAGINAL,
  PELVIC_ANAL_F,
  PELVIC_ANAL_M
].map((bm: BodyModel) => ({
  name: bm.name,
  icons: bm.emsTriggeredIcons,
  supportSide: bm.supportSide,
  channels: bm.channels,
  part: bm.part || 'not_applicable'
}));

const ALL_MUSCLES = {
  ems: {
    fes: {
      triggered: [
        OPEN_CLOSE_TRIGGERED,
        ARM_SUPPORT_TRIGGERED,
        GRASP_RELEASE_TRIGGERED,
        HAND_TO_MOUTH_TRIGGERED
      ],
      standard: [
        OPEN_CLOSE,
        ARM_SUPPORT,
        GRASP_RELEASE,
        HAND_TO_MOUTH
      ]
    },
    standard: EMS_STANDARD,
    pelvic: {
      triggered: PELVIC_TRIGGERED,
      standard: PELVIC_STANDARD
    },
    pain: EMS_PAIN,
    triggered: EMS_TRIGGERED
  },
  emg: {
    pelvic: PELVIC_EMG,
    standard: EMG_STANDARD
  }
};

export function getMusclesForProgram(program, concept: string): IconModel[] {
  if (program.type === 'electrostim' && program.extras.functionalCategory === 'tens') {
    return ALL_MUSCLES.ems.pain;
  }
  if (program.type === 'electrostim' && program.steps.incontinence && program.steps.details.emgTriggered) {
    return ALL_MUSCLES.ems.pelvic.triggered;
  }
  if (program.type === 'electrostim' && program.steps.incontinence && !program.steps.details.emgTriggered) {
    return ALL_MUSCLES.ems.pelvic.standard;
  }
  if (program.type === 'electrostim' && !program.steps.incontinence && program.steps.details.emgTriggered) {
    return ALL_MUSCLES.ems.triggered;
  }
  if (program.type === 'electrostim' && !program.steps.details.emgTriggered) {
    return ALL_MUSCLES.ems.standard;
  }
  if (program.type === 'fes' && !program.steps.details.emgTriggered) {
    return ALL_MUSCLES.ems.fes.standard.filter(mm => mm.name === program.name);
  }
  if (program.type === 'fes' && program.steps.details.emgTriggered) {
    return ALL_MUSCLES.ems.fes.triggered.filter(mm => mm.name === program.name);
  }
  if (program.steps.calibration && (program.steps.incontinence || concept === 'pelvic')) {
    return ALL_MUSCLES.emg.pelvic;
  }
  if (program.steps.calibration && concept && concept !== 'pelvic') {
    return ALL_MUSCLES.emg.standard;
  }
  if (program.steps.calibration) {
    return [...ALL_MUSCLES.emg.standard, ...ALL_MUSCLES.emg.pelvic].filter((value, index, self) =>
      index === self.findIndex((t) => t.name === value.name));
  }
  if (isProgramViewType(program.name) && concept === 'pelvic') {
    return ALL_MUSCLES.emg.pelvic;
  }
  if (isProgramViewType(program.name) && concept && concept !== 'pelvic') {
    return ALL_MUSCLES.emg.standard;
  }
  if (isProgramViewType(program.name)) {
    return [...ALL_MUSCLES.emg.standard, ...ALL_MUSCLES.emg.pelvic].filter((value, index, self) =>
      index === self.findIndex((t) => t.name === value.name));
  }

  return;
}
