import { ActionDispatcher } from '@app/training/ActionDispatcher';

export class HubConnection {

  private onMessage = null;
  private worker = new Worker('./directhubWebsocket.worker', { name: 'direct', type: 'module' });

  static newInstance(url): HubConnection {
    return new HubConnection(url);
  }

  constructor(url: string) {
    this.worker.postMessage({
      type: 'create',
      data: {
        url
      }
    });
  }

  subscribe(handler: (data: any) => void): void {
    this.onMessage = handler;
  }

  isOpen = () => true;

  async open(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.worker.postMessage({
        type: 'open',
      });
      this.worker.onmessage = event => {
        const ev = event.data;
        switch (ev.type) {
          case 'opened':
            this.bindEvents();
            resolve();
            break;
          case 'close':
            reject();
            break;
        }
      };
    });
  }

  private bindEvents() {
    const dispatcher = new ActionDispatcher({
      message: ev => this.onMessage(ev.data.message),
      authed: () => this.onMessage({event: 'AUTHED'}),
      token: _ => {},
      close: () => this.onMessage({event: 'CLOSED'})
    });
    window.onbeforeunload = () => {
      this.worker.postMessage('close');
    };
    this.worker.onmessage = event => {
      const ev = event.data;
      dispatcher.dispatch({
        event: ev.type,
        ...ev
      });
    };
  }

  send(json, force: boolean): boolean {
    this.worker.postMessage({
      type: 'send',
      data: {
        message: json, force
      }
    });
    return true;
  }
}
