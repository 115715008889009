<sba-page-title [title]="'exercises.' + (program?.name || 'none')"></sba-page-title>
<sba-channel-selector-muscle-mapper
  class="switchable"
  [ngClass]="{ visible: !isValid }"
  [program]="program"
  [alreadyStarted]="alreadyStarted"
  (isValid)="changeIsValid($event)"
>
</sba-channel-selector-muscle-mapper>
<div class="switchable" [ngClass]="{ visible: isValid }">
  <ng-content></ng-content>
</div>
