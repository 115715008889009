import { ChannelSignal } from '@app/types';
import { MonoTypeOperatorFunction, OperatorFunction } from 'rxjs';
import { filter, map } from 'rxjs/operators';

function filterCorrectValues(): MonoTypeOperatorFunction<ChannelSignal> {
  return input$ => input$.pipe(filter(d => !(d.channel === undefined || d.channel === null)));
}

function signalAmplifier(amp: number): OperatorFunction<ChannelSignal, any> {
  return input$ => input$.pipe(map(({ channel, data }) => ({
    channel,
    average: (data[data.length - 1] || 0) * amp
  })));
}

function signalAmplifierZip(amp: number): OperatorFunction<ChannelSignal, any> {
  return input$ => input$.pipe(
    filter(zipped => {
      const maxLength = Math.max(...Object.values(zipped).map(z => z.length));
      return maxLength > 0;
    }),
    map(zipped => {
      return Object.entries<any>(zipped).map(([key, value]) => {
        return {
          channel: key,
          average: (value[value.length - 1] || 0) * amp
        };
      });
    }));
}

export const EgzoRX = {
  filterCorrectValues,
  signalAmplifier,
  signalAmplifierZip
};
