import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColorService } from '@app/shared/services/color.service';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import * as StellaCommands from '@app/stella/services/StellaCommands';
import { validChannels } from '@app/training/ChannelResolver';
import * as ElectrostimProgram from '@app/training/pages/electrostim/ElectrostimProgram';
import { SelectedMuscle } from '@app/types';

interface DialogData {
  program: any;
  muscles: SelectedMuscle[];
  range: {
    min: number;
    max: number;
  };
  electrostimLevels?: number[];
  noBackToMuscleSelection?: boolean;
}

@Component({
  selector: 'sba-stim-calibration',
  templateUrl: './stim-calibration.component.html',
  styleUrls: ['./stim-calibration.component.scss']
})
export class StimCalibrationComponent implements OnInit {

  electrostimLevel = [0, 0, 0, 0, 0, 0, 0, 0];
  currentChannelSelected = 0;
  channels: number[];
  program;
  stimConf;
  noBackToMuscleSelection?: boolean;

  constructor(
    public colorService: ColorService,
    public dialogRef: MatDialogRef<StimCalibrationComponent>,
    private readonly stellaDirect: StellaDirectService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    dialogRef.disableClose = true;
    this.program = data.program;
    this.electrostimLevel = data.electrostimLevels ?? [0, 0, 0, 0, 0, 0, 0, 0]
    this.noBackToMuscleSelection = data.noBackToMuscleSelection;
  }

  ngOnInit(): void {
    this.channels = validChannels(this.data.muscles).ids;
    this.currentChannelSelected = this.channels[0];
    const [{ prog: stimConf }, ...rest] = ElectrostimProgram.createStimCalibration(
      this.program,
      this.electrostimLevel,
      true,
      this.channels);
    // tslint:disable-next-line: forin
    for (const conf in stimConf.stim) {
      const configuration = stimConf.stim[conf];
      configuration.off = 0;
      configuration.bin = configuration.per * configuration.cnt || 10e6;
    }
    this.stimConf = stimConf;
    // this.startStimOnlyForActiveChannel();
  }

  startStimOnlyForActiveChannel() {
    this.stellaDirect.send(StellaCommands.STIM_PROGRAM_OFF);
    const confOfActiveChannel = this.stimConf[this.currentChannelSelected];

    this.stellaDirect.send({
      stim: {
        [this.currentChannelSelected]: {
          ...confOfActiveChannel,
          amp: this.electrostimLevel[this.currentChannelSelected] / 1e3
        }
      }
    });
  }
  changeOn(channel: number) {
    this.currentChannelSelected = channel;
    this.cdr.detectChanges();
    this.stellaDirect.send(StellaCommands.STIM_PROGRAM_OFF);
  }

  isValid(levels: number[], program): boolean {
    if (program.details.customProgram === 200) {
      console.log(levels);
      return levels.some(l => l !== 0) && levels.filter(l => l > 0).length < 3;
    }

    return levels.some(l => l !== 0);
  }

  modifyLevel(val: number, ch: number) {
    if (this.electrostimLevel[ch] + val > this.data.range.max
      || this.electrostimLevel[ch] + val < this.data.range.min) {
      console.warn('[Electrostim] Amperage value is out of range');
      return;
    }
    this.electrostimLevel = this.electrostimLevel.map((el, index) => {
      if (index !== ch) {
        return el;
      }
      return el + val;
    });
    this.cdr.detectChanges();
    if (this.electrostimLevel[ch] > 10) {
      this.stellaDirect.send(StellaCommands.SET_OVERVOLTAGE_TRIGGER(70));
    }
    // this.stellaDirect.send(StellaCommands.STIM_RESUME);
    // this.stellaDirect.send(StellaCommands.CHANGE_AMPLITUDE(ch, this.electrostimLevel[ch]));
    this.startStimOnlyForActiveChannel();
  }

  confirm() {
    this.stellaDirect.send(StellaCommands.STIM_PROGRAM_OFF);
    this.dialogRef.close(this.electrostimLevel);
  }

  deny() {
    this.stellaDirect.send(StellaCommands.STIM_PROGRAM_OFF);
    this.dialogRef.close(false);
  }
}
