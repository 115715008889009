import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pulses'
})
export class PulsesPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if (value.phases) {
      return  value.phases.map(ph => ph.pulseDuration || value.details.pulseDuration)
      .filter(Boolean)
      .join('/');
    }
    return value.details.pulseDuration;
  }

}
