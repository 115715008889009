import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

type SubjectResponse = 'offline' | 'offline-request-triggered' | 'online' | 'success' | 'unknown';
@Injectable({
    providedIn: 'root'
})
export class ConnectionService {
    private connectionMonitor$ = new BehaviorSubject<SubjectResponse>('online');

    constructor() {
        window.addEventListener('offline-request-triggered', (e) => {
            this.connectionMonitor$.next('offline-request-triggered');
        });
        window.addEventListener('online', (e) => {
            this.connectionMonitor$.next('online' );
        });
    }

    monitor(): Subject<SubjectResponse> {
        return this.connectionMonitor$;
    }
}
