import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Notification } from '@app/types';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private translate: TranslateService) { }
  notifications$ = new Subject<Notification>();

  showError(message: string = 'base.error', action?) {
    this.translate.get([
      message, 'snackbar.actions.dismiss'
    ]).subscribe(mes => {
      this.notifications$.next({
        type: 'error',
        message: mes[message],
        action: action || mes['snackbar.actions.dismiss']
      });
    });
  }
}

