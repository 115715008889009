import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Pages } from '@app/pages';
import { environment } from '@env/environment';

const packages = ['neuro', 'ortho', 'pain', 'pelvic', 'sport'];

@Component({
  selector: 'sba-waiting-room',
  templateUrl: './waiting-room.component.html',
  styleUrls: ['./waiting-room.component.scss']
})
export class WaitingRoomComponent implements OnInit {

  packages: PackageData[] = [];

  constructor(private readonly router: Router) { }

  ngOnInit(): void {
    this.packages = packages.map(pckg => ({
        name: pckg,
        allowed: true,
        icon: `${environment.assetsUrl}/stella_bio_cdn/package_${pckg}.png`,
        path: `${Pages.CONCEPTS}/${pckg}`
      }));
  }
  selectPackage(selectedPackage: PackageData) {
    this.router.navigate([selectedPackage.path]);
  }
}

interface PackageData {
  name: string;
  allowed: boolean;
  icon: string;
  path: string;
}
