import Color from 'color';

// 2874b2 - ab2524 - ffa421 - eec900 - 48a43f - 83639d - 7b5141 - 333333 - f4f4f4

export const colors = {
  0: '#AB2524',
  1: '#2874B2',
  2: '#48A43F',
  3: '#83639D',
  4: '#7B5141',
  5: '#282828',
  6: '#EEC900',
  7: '#EC7C25'
};

export const COLOR_ARRAY = Object.values(colors);

export function getColorForChannel(channel: number): Color {
  return new Color(colors[channel] || '#66cccc');
}
