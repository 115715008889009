import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ColorService } from '@app/shared/services/color.service';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import * as StellaCommands from '@app/stella/services/StellaCommands';
import { validChannels } from '@app/training/ChannelResolver';
import * as ElectrostimProgram from '@app/training/pages/electrostim/ElectrostimProgram';
import { SelectedMuscle } from '@app/types';
import { ProgramModule, StimStatus } from '@egzotech/exo-electrostim';
import { StellaChannelIndex } from '@egzotech/exo-electrostim/dist/stella-interfaces';

interface DialogData {
  program: any;
  muscles: SelectedMuscle[];
  module: ProgramModule;
  electrostimLevels?: number[];
  noBackToMuscleSelection?: boolean;
}

@Component({
  selector: 'sba-stim-calibration-program-module',
  templateUrl: './stim-calibration-program-module.component.html',
  styleUrls: ['./stim-calibration-program-module.component.scss']
})
export class StimCalibrationProgramModuleComponent implements OnInit {

  electrostimLevel = [0, 0, 0, 0, 0, 0, 0, 0];
  currentChannelSelected = 0;
  channels: number[];
  program;
  stimConf;
  programModule: ProgramModule;
  noBackToMuscleSelection?: boolean;
  currentChannelRange: {
    min: number;
    max: number;
  };

  constructor(
    public colorService: ColorService,
    public dialogRef: MatDialogRef<StimCalibrationProgramModuleComponent>,
    private readonly stellaDirect: StellaDirectService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    dialogRef.disableClose = true;
    this.program = data.program;
    this.programModule = data.module;
    this.electrostimLevel = data.electrostimLevels ?? [0, 0, 0, 0, 0, 0, 0, 0]
    this.noBackToMuscleSelection = data.noBackToMuscleSelection;
    this.currentChannelRange = { min: 0, max: 100 };
  }

  ngOnInit(): void {
    this.channels = validChannels(this.data.muscles).ids;
    this.currentChannelSelected = this.channels[0];

    this.currentChannelRange = {
      min: 0,

      // NOTE: Each channel can have different max intensity, but for this app this case is simplified
      //       because all implemented programs have the same intensity for each channel.
      max: (this.programModule.config.maxIntensity[0] ?? 0.1) * 1000
    };

    const [{ prog: stimConf }, ...rest] = ElectrostimProgram.createStimCalibration(
      this.program,
      this.electrostimLevel,
      true,
      this.channels);
    // tslint:disable-next-line: forin
    for (const conf in stimConf.stim) {
      const configuration = stimConf.stim[conf];
      configuration.off = 0;
      configuration.bin = configuration.per * configuration.cnt || 10e6;
    }
    this.stimConf = stimConf;
    this.programModule.setState(StimStatus.Calibration);
  }

  changeOn(channel: number) {
    this.currentChannelSelected = channel;
    this.programModule.stop();

    // Update channel current range
    this.currentChannelRange = {
      min: 0,

      // NOTE: Each channel can have different max intensity, but for this app this case is simplified
      //       because all implemented programs have the same intensity for each channel.
      max: (this.programModule.config.maxIntensity[0] ?? 0.1) * 1000
    };

    this.cdr.detectChanges();
  }

  isValid(levels: number[], program): boolean {
    if (program.details.customProgram === 200) {
      console.log(levels);
      return levels.some(l => l !== 0) && levels.filter(l => l > 0).length < 3;
    }

    return levels.some(l => l !== 0);
  }

  modifyLevel(val: number) {
    const ch = this.currentChannelSelected;

    if (this.electrostimLevel[ch] + val > this.currentChannelRange.max
      || this.electrostimLevel[ch] + val < this.currentChannelRange.min) {
      console.warn('[Electrostim] Amperage value is out of range');
      return;
    }

    this.electrostimLevel = this.electrostimLevel.map((el, index) => {
      if (index !== ch) {
        return el;
      }
      return el + val;
    });

    this.cdr.detectChanges();

    if (this.electrostimLevel[ch] > 10) {
      this.stellaDirect.send(StellaCommands.SET_OVERVOLTAGE_TRIGGER(70));
    }

    this.programModule.setIntensity(ch as StellaChannelIndex, this.electrostimLevel[ch]/1000);
  }

  confirm() {
    this.programModule.setState(StimStatus.Program);
    this.dialogRef.close(this.electrostimLevel);
  }

  deny() {
    this.programModule.setState(StimStatus.Program);
    this.dialogRef.close(false);
  }
}
