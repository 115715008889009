import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode } from '@datorama/akita';
import 'node_modules/ngx-toastr/toastr.css';
import 'material-design-icons/iconfont/material-icons.css';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableDebugTools } from '@angular/platform-browser';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);
bootstrap()
  .then((appRef: any) => enableDebugTools(appRef))
  .catch(err => console.error(err));
