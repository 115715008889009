import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'sba-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  constructor(private readonly router: Router) {
    setTimeout(() => {
      this.router.navigateByUrl("/");
    }, 5000)
  }
}
