<div
  class="single-stella single-stella--container"
  matRipple
  (click)="handleSelect()"
>
  <div class="row">
    <div
      class="single-stella--color"
      [ngStyle]="{ borderColor: stella.ledringColor || 'darkgold' }"
    ></div>
    <div class="address-row">
      <p class="single-stella--address" [ngClass]="{ local: isLocal }">
        {{ stella.customName }}
      </p>
      <!-- <button
        mat-icon-button
        class="edit-name-button"
        (click)="handleEdit($event)"
      > -->
        <!-- <mat-icon class="edit-name-button--icon">create</mat-icon> -->
      <!-- </button> -->
    </div>
    <div class="right" style="display: flex; align-items: center;">
      <mat-icon style="color: #e84a5f; height: 29px" *ngIf="!isValidVersion()">error_outline</mat-icon>
      <stella-wifi-icon
        [level]="stella.wifi?.level"
        *ngIf="false"
      ></stella-wifi-icon>
      <stella-battery-icon *ngIf="stella.bat" [level]="stella.bat | battery"></stella-battery-icon>
    </div>
  </div>
</div>
