<mat-dialog-content>
  <div class="title-row">
    <h2 class="setting-header">
      {{ "emg.cableWarning" | translate }}
    </h2>
  </div>

  <div class="row">
    <img class="warning" src="/assets/png/warning_iso.png" />
    <div class="advices">
      <p style="max-width: 350px">{{ "stimSetting.cable_training_aborted" | translate }}</p>
    </div>
  </div>

  <div class="dialog-actions">
    <div class="outline-button" (click)="confirm()">
      {{ "navigation.finishTraining" | translate }}
    </div>
  </div>
</mat-dialog-content>
