
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { catchError, retryWhen, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RetryRequestService } from "./retry-request.service";
import { MatDialog } from "@angular/material";
import { UnknownErrorDialogComponent } from "@app/components/unknown-error-dialog/unknown-error-dialog.component";
 
@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
 
  constructor(
    public router: Router,
    private retryRequestService: RetryRequestService,
    private readonly dialog: MatDialog
    ) {
  }
 
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(() => {
        window.dispatchEvent(new Event('success-http-request'));
      }),
      retryWhen((err) => this.retryRequestService.handleRetryRequest(err)),
      catchError((error) => {
        console.log(error)
        if (!error.status || error.status === 0) {
          window.dispatchEvent(new Event('unknown-http-request'));
        }
        if( error.status === 500) {
          this.dialog.open(UnknownErrorDialogComponent, {data: {errorCode: error.status, errorMessage: error.error?.message}})
        }
        return throwError(error);
      })
    )
  }
}