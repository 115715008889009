import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'sba-page-title',
  template: '<div></div>'
})
export class PageTitleComponent implements OnInit {

  @Input() set translate(val: boolean) {
    this._translate = val;
    this.setTitle(this._title);
  }

  @Input() set title(value: string) {
    this._title = value;
    this.setTitle(this._title);
  }
  private _title: string;
  private _translate = true;

  constructor(
    private titleService: Title,
    private translateService: TranslateService) { }

  ngOnInit(): void {
    this.translateService.onLangChange
      .pipe(
        tap(_ => {
          this.setTitle(this._title);
        })
      ).subscribe();
  }

  private setTitle(key: string) {
    if (!key) {
      this.titleService.setTitle('Stella BIO App');
      return;
    }
    if (!this._translate) {
      this.titleService.setTitle(`${key} | Stella BIO App`);
      return;
    }
    if (this._translate) {
      const translation = this.translateService.instant(key);
      this.titleService.setTitle(`${translation} | Stella BIO App`);
      return;
    }
  }
}
