import { DeepReadonly } from "@egzotech/exo-electrostim";
import { CableType } from "./CableType";
import { ChannelProgramBase } from "./ChannelProgramBase";
import { isChannelProgramParameterDefinition, ChannelProgramParameterDefinition, ChannelProgramParameterDefinitionUnit, ChannelProgramParameters, ChannelProgramParametersValue, ChannelProgramParametersValues } from "./ChannelProgramParameters";

export interface ChannelProgramControllerOptions {
    parameters?: DeepReadonly<ChannelProgramParametersValues> | null
}

function isReadonlyArray(arg: any): arg is readonly any[] {
    return Array.isArray(arg);
}

function isReadonlyChannelProgramParameterDefinition(obj: object):
    obj is DeepReadonly<ChannelProgramParameterDefinition<ChannelProgramParameterDefinitionUnit>> {
    return isChannelProgramParameterDefinition(obj);
}

export class ChannelProgramController<T extends ChannelProgramBase = ChannelProgramBase> {
    private program: DeepReadonly<T> | null;
    private connectedCable: DeepReadonly<CableType> | null;
    private _availableChannels: number[];
    private _availableChannelsFeatures: string[][];
    private _coercedProgram: DeepReadonly<T> | null;
    private _channelMapping: { [key: number]: number } | null;
    private _isChannelMappingValid: boolean;

    get minRequiredChannels() {
        if (!this.program) {
            throw new Error("Cannot read minRequiredChannels without setting a program");
        }

        return this.program.minRequiredChannels ?? 1;
    }

    get maxSupportedChannels() {
        if (!this.program) {
            throw new Error("Cannot read maxSupportedChannels without setting a program");
        }

        return this.program.maxSupportedChannels ?? this._availableChannels.length;
    }

    get availableChannels() {
        return this._availableChannels;
    }

    get isCableCorrect() {
        if (!this.program) {
            throw new Error("Cannot read isCableCorrect without setting a program");
        }

        return this._availableChannels.length > 0
            && this._availableChannels.length >= this.minRequiredChannels;
    }

    get isChannelMappingValid() {
        return this._isChannelMappingValid;
    }

    get parameters() {
        return this.program?.parameters ?? null;
    }

    get tags() {
        if (!this.program) {
            throw new Error("Cannot read tags without setting a program");
        }

        return this.program.tags ?? [];
    }

    get coercedProgram() {
        if (!this.program || !this._coercedProgram) {
            throw new Error("Cannot read coercedProgram without setting a program");
        }

        return this._coercedProgram;
    }

    get channelMapping() {
        if (!this.program) {
            throw new Error("Cannot read channelMapping without setting a program");
        }

        return this._channelMapping;
    }

    onCableChanged: ((cable: DeepReadonly<CableType>) => void) | null = null;
    onApplyParameters: ((program: DeepReadonly<T>, coercedProgram: T, parameters: DeepReadonly<ChannelProgramParametersValues>) => T) | null = null;

    constructor(
        readonly options?: {
            allowMappingSingleVirtualChannelToMultiplePhyscialChannels: boolean
        }
    ) {
        this.program = null;
        this.connectedCable = null;
        this._availableChannels = [];
        this._availableChannelsFeatures = [];
        this._coercedProgram = null;
        this._channelMapping = null;
        this._isChannelMappingValid = false;
    }

    setProgram(program: DeepReadonly<T>, options?: ChannelProgramControllerOptions) {
        let coercedProgram = program;

        if (options?.parameters) {
            coercedProgram = this.adaptParameters(program, options.parameters) as DeepReadonly<T>;
        }

        const channelMapping = this.adaptMapping(program, null);

        this.program = program;
        this._coercedProgram = coercedProgram;
        this._channelMapping = channelMapping;

        this.checkCable();
        this.checkMapping();
    }

    setChannelMapping(mapping: { [key: number]: number } | null) {
        if (!this.program) {
            throw new Error("Cannot set channel mapping without setting a program");
        }

        this._channelMapping = this.adaptMapping(this.program, mapping);
    }

    getChannelFeatures(channel: number) {
        const channelIndex = this._availableChannels.indexOf(channel);

        if (channelIndex < 0) {
            throw new Error("This channel is not supported");
        }

        return this._availableChannelsFeatures[channelIndex];
    }

    setCable(cable: DeepReadonly<CableType>) {
        if (cable === this.connectedCable) {
            return;
        }

        this.connectedCable = cable;
        this.checkCable();
        this.checkMapping();

        if (this.onCableChanged) {
            this.onCableChanged(cable);
        }
    }

    dispose() {
        this.program = null;
        this.connectedCable = null;
        this._availableChannels = null!;
        this._availableChannelsFeatures = null!;

        this.onCableChanged = null;
        this.onApplyParameters = null;
    }

    private checkCable() {
        if (!this.program) {
            return;
        }

        if (!this.connectedCable) {
            return;
        }

        const cable = this.connectedCable;

        let availableChannels = [];

        for (let i = 0; i < cable.channels.length; i++) {
            availableChannels.push({
                channel: cable.channels[i],
                features: cable.channelFeatures[i]
            });
        }

        if (this.program.allowedCableChannelFeatures) {
            availableChannels = availableChannels.filter(
                ch => this.program!.allowedCableChannelFeatures!.some(feature => ch.features.indexOf(feature) >= 0)
            );
        }

        this._availableChannels = availableChannels.map(v => v.channel);
        this._availableChannelsFeatures = availableChannels.map(v => v.features);
    }

    private checkMapping() {
        this._isChannelMappingValid = false;

        if (!this._channelMapping) {
            return;
        }

        const keys = Object.keys(this._channelMapping);

        if (keys.length > this.maxSupportedChannels) {
            return;
        }

        if (!keys.every(key => this._availableChannels.indexOf(+key) >= 0)) {
            return;
        }

        if (!this.options?.allowMappingSingleVirtualChannelToMultiplePhyscialChannels) {
            const valuesSet = new Set();

            for (const prop in this._channelMapping) {
                if (!valuesSet.add(this._channelMapping[prop])) {
                    return;
                }
            }
        }

        this._isChannelMappingValid = true;
    }

    private adaptMapping(program: DeepReadonly<T>, channelMapping: DeepReadonly<{ [key: number]: number }> | null) {
        let adaptedChannelMapping: { [key: number]: number };

        const minRequiredChannels = program.minRequiredChannels ?? 1;
        this._isChannelMappingValid = false;

        if (!channelMapping) {
            // Provide default identity mapping
            adaptedChannelMapping = {};

            for (let i = 0; i < minRequiredChannels; i++) {
                adaptedChannelMapping[i] = i;
            }
        }
        else {
            const values = Object.values(channelMapping);

            if (new Set(values).size < minRequiredChannels) {
                throw new Error("Not all channels are mapped. Please map all available channels.");
            }

            const notExistingMappedChannels = values.filter(k => k >= minRequiredChannels)

            if (notExistingMappedChannels.length > 0) {
                throw new Error(`Mapping is invalid, channels ${notExistingMappedChannels.join(", ")} are not available in this program."`);
            }

            adaptedChannelMapping = channelMapping;
        }

        const keys = Object.keys(adaptedChannelMapping);

        if (program.maxSupportedChannels && keys.length > program.maxSupportedChannels) {
            throw new Error(`Mapping is invalid, ${keys.length} channels were mapped, but program only supports up to ${program.maxSupportedChannels} channels.`);
        }

        if (!this.options?.allowMappingSingleVirtualChannelToMultiplePhyscialChannels) {
            const valuesSet = new Set();

            for (const prop in adaptedChannelMapping) {
                if (valuesSet.has(adaptedChannelMapping[prop])) {
                    throw new Error(`Mapping is invalid, controller does not allow single virtual channel to be mapped to multiple physical channels.`
                        + ` Channel that is mapped multiple times: ${adaptedChannelMapping[prop]}`);
                }

                valuesSet.add(adaptedChannelMapping[prop]);
            }
        }


        if (keys.every(key => this._availableChannels.indexOf(+key) >= 0)) {
            this._isChannelMappingValid = true;
        }

        return adaptedChannelMapping;
    }

    private adaptParameters(
        program: DeepReadonly<T>,
        parameters: DeepReadonly<ChannelProgramParametersValue<ChannelProgramParameters>>
    ) {
        if (!this.validateParameters(program, parameters)) {
            throw new Error("Paremeter validation failed");
        }

        return this.applyParameters(program, parameters);
    }

    private applyParameters(
        program: DeepReadonly<T>,
        parameters: DeepReadonly<ChannelProgramParametersValue<ChannelProgramParameters>>
    ) {
        if (!program.parameters) {
            throw new Error("Program does not define any parameters. Cannot apply parameter values to program.");
        }

        let coercedProgram: T = JSON.parse(JSON.stringify(program));

        this.applyParametersWalk(coercedProgram, parameters);

        if (this.onApplyParameters) {
            coercedProgram = this.onApplyParameters(program, coercedProgram, parameters);
        }

        return coercedProgram;
    }

    private applyParametersWalk(
        parent: { [key: string] : any },
        values: DeepReadonly<ChannelProgramParametersValues>
    ) {
        for (const prop in values) {
            const value = values[prop];

            if (typeof value === "number") {
                parent[prop] = value;
            }

            if (typeof value !== "object") {
                continue;
            }

            if (isReadonlyArray(value)) {
                if (!Array.isArray(parent[prop])) {
                    parent[prop] = [];
                }

                for (let i = 0; i < value.length; i++) {
                    const item = value[i];

                    if (item === null || item === undefined) {
                        continue;
                    }

                    if (typeof parent[prop][i] !== "object") {
                        parent[prop][i] = {};
                    }

                    this.applyParametersWalk(parent[prop][i], item);
                }

                continue;
            }

            this.applyParametersWalk(parent[prop], (values as any)[prop]);
        }
    }

    private validateParameters(
        program: DeepReadonly<T>,
        values: DeepReadonly<ChannelProgramParametersValues> | undefined
    ) {
        const definitions = program.parameters;

        if (!values) {
            // Empty parameters are always valid
            return true;
        }

        if (!definitions) {
            // Without definition parameters cannot be validated
            return false;
        }

        return this.validateParametersWalk(definitions, values);
    }

    private validateParametersWalk(
        definitions: DeepReadonly<ChannelProgramParameters>,
        values: DeepReadonly<ChannelProgramParametersValue<ChannelProgramParameters>>
    ) {
        for (const prop in values) {
            const definition = definitions[prop];
            const value = values[prop];

            if (!definition) {
                // Value without definition cannot be validated
                return false;
            }

            if (typeof definition !== "object") {
                // Definition should be an object or array, otherwise the definition is invalid
                return false;
            }

            if (isReadonlyChannelProgramParameterDefinition(definition)) {
                if (typeof value !== "number") {
                    // Value must be a number
                    return false;
                }

                if (definition.values.indexOf(value) < 0) {
                    // Value not found in definition values
                    return false;
                }

                continue;
            }

            if (typeof value !== "object") {
                // At this point we are expecting a nested object or an array for the value
                return false;
            }

            if (isReadonlyArray(definition) || isReadonlyArray(value)) {
                // Go deeper into an array

                if (!isReadonlyArray(value) || !isReadonlyArray(definition) ) {
                    // Value also must be an array
                    return false;
                }

                for (let i = 0; i < definition.length; i++) {
                    const item = value[i];
                    const itemDefinition = definition[i];

                    if (item === null || item === undefined) {
                        continue;
                    }

                    if (itemDefinition === null || itemDefinition === undefined) {
                        // No definition for this array item, cannot validate the value
                        return false;
                    }

                    if (!this.validateParametersWalk(itemDefinition, item)) {
                        return false;
                    }
                }

                continue;
            }

            if (!this.validateParametersWalk(definition, value)) {
                return false;
            }
        }

        return true;
    }
}
