import { Injectable } from '@angular/core';
import { Cable, CableType, DEFAULT_STELLA_HOSTNAME, DEFAULT_STELLA_WSS_PORT, SmartledProgram, StellaConnectionStatus, STELLA_CURRENT_STELLA, STELLA_EMG_LAST_MASK } from '@app/enums';
import { StellaDevice } from '@app/models/StellaDevice.model';
import { LoggerService } from '@app/shared/services/logger.service';
import { CableDetails, ChannelSignal } from '@app/types';
import { environment } from '@env/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { DirectConnectionSingleton } from './DirectConnection';
import { PacketCounter } from './PacketCounter';
import { SmartledFactoryService } from './smartled-factory.service';
import { StimStat, StellaState } from './StellaState';

export interface ConnectionOptions {
  online: boolean;
  sendFlag: boolean;
  reconnect: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class StellaDirectService {
  constructor(
    // private listStella: ListStellaService,
    private ledFactory: SmartledFactoryService,
    private logger: LoggerService,
  ) {
    const stored = localStorage.getItem(STELLA_CURRENT_STELLA);
    if (stored) {
      const parsed = JSON.parse(stored);
      if (parsed.stella.net) {
        this.setStella(parsed.stella);
      }
    }
    this.state = new StellaState();
    this.packetCounter = new PacketCounter();
  }

  public state: StellaState;

  source$ = new BehaviorSubject<ChannelSignal>({});
  raw$ = new BehaviorSubject<any>({});
  state$ = new BehaviorSubject<StellaState>(null);
  cable$ = new BehaviorSubject<CableDetails>(Cable[CableType.NONE]);
  timeout$ = new BehaviorSubject<number>(0);
  notifications$ = new Subject<any>();
  connected$ = new BehaviorSubject<{ connected: StellaConnectionStatus }>({
    connected: StellaConnectionStatus.DISCONNECTED
  });
  status$ = new BehaviorSubject<number>(0);
  stimEnabled$ = new BehaviorSubject<number>(0);
  allowReconnect = true;
  packetCounter: PacketCounter;
  // TODO: Check this after work done
  currentStella: StellaDevice;
  connection: DirectConnectionSingleton;
  implicit: boolean;
  timeoutCounter = 0;
  currentStella$ = new Subject<StellaDevice>();

  stimStat$ = new BehaviorSubject<StimStat>({
    latency: 0,
    ena: false,
    flag: 0,
    max: 0,
    tim: 0
  });

  get isStellaSelected() {
    if (this.connected$.value === { connected: StellaConnectionStatus.CONNECTED }) {
      return true;
    } else {
      return Boolean(this.currentStella);
    }
  }

  private getStellaAddress(options: ConnectionOptions): string {
    if (this.currentStella) {
      return this.currentStella.net.direct[options.online ? 'master' : 'local'];
    }
    return `wss://${DEFAULT_STELLA_HOSTNAME}:${DEFAULT_STELLA_WSS_PORT}`;
  }

  setSendingFlag(flag: boolean) {
    this.connection.setSendingFlag(flag);
  }

  setMask(mask: number) {
    localStorage.setItem(STELLA_EMG_LAST_MASK, String(mask));
    this.send({ ads: { mask } });
  }

  async connect(options: ConnectionOptions = { online: true, sendFlag: true, reconnect: false }) {
    try {
      this.connected$.next({ connected: StellaConnectionStatus.CONNECTING });
      const directAddress = this.getStellaAddress(options);
      this.connection = DirectConnectionSingleton.newInstance(directAddress, options);
      await this.connection.open();
      if (options.reconnect) {
        this.setMask(Number(localStorage.getItem(STELLA_EMG_LAST_MASK)));
      }
      this.logger.info(`Connected to Stella: ${new Date()}`);
      this.connection.on('packet', () => {
        this.packetCounter.increment();
      });
      this.connection.on('state', data => {
        if (data.status) {
          this.status$.next(data.status);
        }
        if (data.stimstat) {
          this.stimStat$.next(data.stimstat as StimStat);
          this.stimEnabled$.next(data.stimstat.flag);
        }
        this.state.modify(data);
        this.state.accumulate(data);
        this.state$.next(this.state);
        if (data.mdr) {
          const cable = this.state.getCable();

          if (this.cable$.value.code !== cable.code) {
            this.cable$.next(cable);
          }
        }
      });
      this.connection.on('close', event => {
        this.logger.error(`CLOSED WITH ${event.code} ${event.reason}: ${new Date()}`);
        if (event.code === 1000) {
          this.disconnect();
        }
      });
      this.connection.on('error', e => {
        this.logger.error(e);
      });
      this.connection.on('timeout', async () => {
        this.timeoutCounter += 1;
        this.timeout$.next(this.timeoutCounter);
        this.disconnect();
        if (this.allowReconnect) {
          await this.connect(options);
        }
        this.logger.error(`Timeout from Stella: ${new Date()}`);
      });
      this.connection.subscribe({
        pre: raws => this.raw$.next(raws),
        post: message => {
          this.source$.next(message);
        }
      });
      this.connected$.next({ connected: StellaConnectionStatus.CONNECTED });
      this.packetCounter.start();
    } catch (err) {
      if (this.allowReconnect) {
        this.logger.info(`Reconnect to Stella: ${new Date()}`);
        setTimeout(async () => {
          await this.connect({ ...options, reconnect: true });
        }, 1000);

      } else {
        this.connection = null;
        this.connected$.next({ connected: StellaConnectionStatus.DISCONNECTED });
        this.logger.error(`Disconnected from Stella: ${new Date()}`);
      }
    }
  }

  setSmartled(program: SmartledProgram) {
    this.connection.send({ smartled: this.ledFactory.getLed(program) });
  }

  disconnect(): void {
    this.logger.info(`Disconnected from Stella `);
    if (this.connection) {
      this.connection.close();
      this.connection = null;
    }
    this.connected$.next({ connected: StellaConnectionStatus.DISCONNECTED });
  }

  send(data): void {
    if (!environment.production) {
      console.log(JSON.stringify(data));
    }

    if (this.connection && this.validateCommand()) {
      this.connection.send(data);
    }
  }

  validateCommand(): boolean {
    return true;
  }

  clearStella(): void {
    localStorage.removeItem(STELLA_CURRENT_STELLA);
    this.currentStella = null;
    this.disconnect();
  }

  setStella(stella: StellaDevice, implicit = false) {
    localStorage.setItem(STELLA_CURRENT_STELLA, JSON.stringify({ stella }));
    if (!this.currentStella) {
      this.currentStella = stella;
      this.implicit = implicit;
    }
    if (stella) {
      this.currentStella = stella;
      this.implicit = implicit;
      this.disconnect();
    }

    this.currentStella$.next(stella);
  }

  setMuscle(channel: number, muscle: string, color: string) {
    this.state.modify({ id: channel, channel: { muscle, color } });
    this.state$.next(this.state);
  }
}
