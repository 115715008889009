import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BtConfigureComponent } from './pages/bt-configure/bt-configure.component';
import { ListStellasComponent } from './pages/list-stellas/list-stellas.component';
import { StellaComponent } from './stella.component';

const routes: Routes = [
  {
    path: '',
    component: StellaComponent,
    children: [
      {
        path: 'connect',
        component: ListStellasComponent
      },
      {
        path: 'connect/new',
        component: BtConfigureComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StellaRoutingModule {}
