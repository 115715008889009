<div mat-dialog-content class="end-training-dialog">
  <form class="signup--form" [formGroup]="newTitleGroup">
    <p *ngIf="buttons.length !== 1">
      {{ "components.trainingQuit.prompt" | translate }}
    </p>
    <p *ngIf="buttons.length === 1">
      {{ "components.trainingQuit.prompt_single" | translate }}
    </p>

    <mat-form-field class="full-width" *ngIf="hasNewTitleInput">
      <input
        matInput
        autocomplete="off"
        [placeholder]="'components.trainingQuit.changeTitleInfo' | translate"
        formControlName="newTitle"
      />
    </mat-form-field>

    <div class="spinner-actions" *ngIf="inProgress">
      <mat-progress-spinner diameter="64" mode="indeterminate">
      </mat-progress-spinner>
    </div>

    <div class="dialog-actions" *ngIf="!inProgress">
      <div
        class="outline-button"
        *ngFor="let button of buttons"
        (click)="button.action()"
      >
        {{ button.label | translate }}
      </div>
    </div>
  </form>
</div>
