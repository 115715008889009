import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { STELLA_CURRENT_CLINIC } from '@app/enums';
import { Clinic } from '@app/models/Clinic.model';

export interface ClinicsState extends EntityState<Clinic> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'clinics',
  cache: {
    ttl: 3600000
  },
  resettable: true
})
export class ClinicsStore extends EntityStore<ClinicsState> {
  constructor() {
    super();
    const clinicLs = localStorage.getItem(STELLA_CURRENT_CLINIC);
    if (clinicLs) {
      const parsed = JSON.parse(clinicLs);
      this.set([{ ...parsed, ls: true }]);
      this.setActive(parsed.id);
    }
  }

  incrementActiveClinic(patId: string) {
    this.update(active => {
      const pat = active.entities[patId];
      return {
        ...active,
        active: patId,
        entities: {
          ...active.entities,
          [patId]: {
            ...pat,
            selection: [
              {
                last: new Date().toISOString(),
                selected: 1
              }
            ]
          }
        }
      };
    });
  }
}
