<sba-exercise-container (start)="onStart($event)">
<div class="emg-page">
    <div class="title-row">
      <sba-training-title-row 
          (back)="goBack()" 
          [name]="'exercises.' + template.name | translate"
          [short]="'descriptions.' + (template.steps.short ? template.steps.short : template.steps.steps.short) | translate"
        ></sba-training-title-row>
      <div class="right">
        <ng-container *ngIf="!hideNotImplementedFeature">
          <div class="chart-type" matRipple>
            <mat-icon svgIcon="single_chart"></mat-icon>
            <p>line charts</p>
          </div>
        </ng-container>
          <div class="chart-type" matRipple (click)="onChartTypeChange('chart')">
            <mat-icon 
              svgIcon="single_chart"
              [ngClass]="{ selected: selectedType === 'chart', chart: true }"
            ></mat-icon>
            <p>{{ "emg.chart" | translate }}</p>
          </div>

        <div class="chart-type" matRipple (click)="onChartTypeChange('bar')">
          <mat-icon
            svgIcon="bar_chart"
            [ngClass]="{ selected: selectedType === 'bar', bar: true }"
          ></mat-icon>
          <p>{{ "emg.bar" | translate }}</p>
        </div>
        <div
          class="end-training"
          matRipple
          (click)="endTraining()"
        >
          <mat-icon>close</mat-icon>
          <p>{{ "diagnostic.endTraining" | translate }}</p>
        </div>
      </div>
    </div>
    <div class="emg-page--content">
      <div class="scale" *ngIf="initialSetupDone">
        <sba-channel-selector
          *ngIf="selectedType === 'chart'"
          [value]="displayedChannels"
          [channels]="muscles | localFunction: getOnlyValid"
          (change)="changeChannel($event)"
        ></sba-channel-selector>

        <sba-scale-selector [value]="max" [unit]="unit" (change)="changeScale($event)"></sba-scale-selector>
        <sba-unit-selector *ngIf="needsCalibration()" [value]="unit" (change)="changeUnit($event)"></sba-unit-selector>
      </div>
      <div class="chart-slider-container">
        <div class="chart-content">
          <div class="chart-container">
            <div class="chart">
              <div id="chart-line" #chart [style.z-index]="selectedType === 'chart' ? '1': '0'"></div>
              <div id="chart-bar" #chart [style.z-index]="selectedType === 'bar' ? '1': '0'"></div>
            </div>
            <div class="labels-container">
              <div
                channelBadge
                class="channel-info"
                *ngFor="let channel of muscles | localFunction:showOnlyConnected; trackById"
                [channel]="channel"
                [value]="values[channel]"
                [color]="channel | channelcolor"
                [muscle]="channel | muscle:muscles"
              ></div>
            </div>
          </div>
        </div>

        <div class="slider-container">
          <mat-slider
            vertical
            min="0"
            [max]="max"
            [step]="max / 50"
            [value]="threshold"
            (input)="handleThresholdChange($event)"
          ></mat-slider>
        </div>
      </div>
    </div>
    <div>
      <sba-emg-training-controls
      class="controls"
        *ngIf="trainingReady"
        [state]="state.current$ | async"
        (start)="start()"
        (stop)="stop()"
        (pause)="pause()"
      ></sba-emg-training-controls>
    </div>
</div>
</sba-exercise-container>