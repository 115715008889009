export interface ActionEvent {
  event: string;
  [key: string]: any;
}

export class ActionDispatcher {
  constructor(private readonly actions) {}

  dispatch(event: ActionEvent) {
    const action = this.actions[event.event];
    if (action) {
      action(event);
    }
  }
}
