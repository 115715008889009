import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, DoCheck, AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { ChannelBadgeMuscle } from '../types';

@Component({
  selector: '[channelBadge]',
  templateUrl: './channel-badge.component.html',
  styleUrls: ['./channel-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelBadgeComponent {
  @Input() channel: number;
  @Input() value: number;
  @Input() color: string;
  @Input() selectedColor: string;
  @Input() maintenance = false;
  @Input() max: number;
  @Input() muscle: ChannelBadgeMuscle;
  @Input() impedance: number;
  @Input() hideMuscle = false;
  @Input() showImpedance = false;
  @Input() showMax = false;
  @Output() reset = new EventEmitter<void>();
  @Input() selected: boolean;

  hideNotImplementedFeature = environment.hideNotImplemented;
}
