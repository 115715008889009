import { ChannelSignal, IChartController } from '@app/types';

const SIZE = 40;

export class BaseChartController implements IChartController {
  constructor(public chart) {
  }

  exerciseTime = 0;
  selectedChannel = 0;

  addData(signal: ChannelSignal): number {
    const size = (signal[this.selectedChannel] || []).length;
    this.exerciseTime += size;
    return this.exerciseTime;
  }

  updateChannel(channel: number): void {
    this.selectedChannel = channel;
  }

  pushSingleSample(): void {
    this.exerciseTime += SIZE / 1000;
  }
}
