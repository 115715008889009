import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationCancel, NavigationStart, Router } from '@angular/router';
import { MessageDialogComponent } from '@app/components/message-dialog/message-dialog.component';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { DashboardMode, StellaConnectionStatus } from '@app/enums';
import { Exercise } from '@app/models/Exercise.model';
import { Pages } from '@app/pages';
import { EnvironmentService } from '@app/shared/services/environment.service';
import { BatteryService } from '@app/shared/services/low-battery.service';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { ExercisesQuery } from '@app/store/exercises/exercise.query';
import { validChannels } from '@app/training/ChannelResolver';
import { EmgCalibrationComponent } from '@app/training/components/emg-calibration/emg-calibration.component';
import { ExerciseContainerComponent } from '@app/training/components/exercise-container/exercise-container.component';
import { CurrentExerciseService } from '@app/training/services/current-excercise.service';
import { CurrentSessionService } from '@app/training/services/current-session.service';
import { ChannelSignal, SelectedMuscle } from '@app/types';
import { EgzoGameHost, GameDescription, GameServiceLevelSettings } from '@egzotech/egzotech-game-service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { filter, flatMap, map, tap } from 'rxjs/operators';
import * as GameUtils from './GameUtilities';
import { BaseTrainingComponent } from '../baseTrainingComponent';

const DID = '123';
const CID = '234';

@Component({
  selector: 'sba-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GamesComponent extends BaseTrainingComponent implements OnDestroy {

  @ViewChild('game', { static: false }) gameRef: ElementRef;
  private host: EgzoGameHost;
  private game: string;

  muscles: SelectedMuscle[] = this.exerciseService.muscles;
  sub: Subscription = new Subscription();
  mvc: number[];
  connected$: BehaviorSubject<{ connected: StellaConnectionStatus }>;
  startedExerciseId: number;
  template: Exercise;
  selectedChannel = 0;
  selectedSecondaryChannel = 1;
  navigationState: { game: GameDescription, levels: { [key: string]: GameServiceLevelSettings } };
  calibrationDialogRef;
  @ViewChild(ExerciseContainerComponent) exerciseContainer: ExerciseContainerComponent;


  constructor(
    private readonly route: ActivatedRoute,
    private readonly env: EnvironmentService,
    private readonly exerciseService: CurrentExerciseService,
    private readonly router: Router,
    private readonly exercises: ExercisesQuery,
    private readonly dashboard: DashboardService,
    protected stellaDirect: StellaDirectService,
    protected sessionService: CurrentSessionService,
    private readonly dialog: MatDialog,
    private readonly ngZone: NgZone,
    private readonly batteryService: BatteryService,
    location: Location
  ) {
    super();
    this.mvc = sessionService.maxArray;
    const isPelvic = localStorage.getItem('CURRENT_CONCEPT') === 'pelvic';
    if (isPelvic) {
      this.selectedChannel = 6;
    }
    this.connected$ = stellaDirect.connected$;
    if (this.router.getCurrentNavigation()) {
      this.navigationState = this.router.getCurrentNavigation().extras.state as any;
    } else {
      this.navigationState = history.state;
    }

    const { levels } = this.navigationState;

    if (!levels || !Object.keys(levels).length) {
      location.back();
    }

    this.sub.add(
      router.events.pipe(
        filter(event => event instanceof NavigationStart),
        filter(_ => Boolean(this.host)),
        tap(ev => {
          this.ngZone.runOutsideAngular(() => {
            this.host.message('game', GameUtils.PAUSE_COMMAND);
          });
        })
      ).subscribe()
    );

    this.sub.add(
      router.events.pipe(
        filter(event => event instanceof NavigationCancel),
        filter(_ => Boolean(this.host)),
        tap(_ => {
          this.ngZone.runOutsideAngular(() => {
            this.host.message('game', GameUtils.PLAY_COMMAND);
          });
        })
      ).subscribe()
    );

    this.sub.add(
      this.route.paramMap
        .pipe(
          map(params => params.get('name')),
          tap(name => {
            this.template = this.exercises.getAll().find(ex => ex.name === name);
          })
        )
        .subscribe(async game => {
          this.game = game;
        })
    );

    this.batteryService.batteryStatus$.subscribe(val => {
      if (val === 'LOW') {
        this.ngZone.runOutsideAngular(() => {
          this.host.message('game', GameUtils.PAUSE_COMMAND);
        });
      }
    })
  }

  onStart(muscles: SelectedMuscle[]) {
    this.muscles = muscles.map(m => ({ ...m })).sort((a, b) => a.channel - b.channel);
    this.selectedChannel = muscles.find(q => q.quality !== 'none').channel;
    const nextMuscle = muscles.find(q => q.quality !== 'none' && q.channel !== this.selectedChannel);
    this.selectedSecondaryChannel = nextMuscle ? nextMuscle.channel : 0;

    this.start();
  }

  async initStella(): Promise<boolean> {
    try {
      this.sub.add(
        this.exerciseService.source$
          .pipe(tap(signal => this.handleData(signal)))
          .subscribe()
      );
      return true;
    } catch (err) {
      return false;
    }
  }

  protected performInstructionDialog(): Observable<any> {
    if (this.template.steps.instructions) {
      return this.dialog.open(MessageDialogComponent, {
        data: {
          translated: true,
          prompt: this.template.steps.instructions
        }
      }).afterClosed();
    }
    return of({});
  }

  protected performAdditionalInstructionDialog(): Observable<any> {
    if (this.dashboard.exercise?.template?.steps?.additionalInstructions) {
      return this.dialog.open(MessageDialogComponent, {
        data: {
          translated: false,
          prompt: this.dashboard.exercise.template.steps.additionalInstructions
        }
      }).afterClosed();
    }
    return of({});
  }

  async start() {
    if (this.template.steps.calibration) {
      if (this.calibrationDialogRef) {
        return;
      }
      this.createCalibrationObservable(false)
        .pipe(
          flatMap( async result => {
            await this.performInstructionDialog().toPromise();
            await this.performAdditionalInstructionDialog().toPromise();
            return result;
          })
        ).subscribe({
          error: _ => {
            console.log('[WARN] Go back to muscle selection');
          },
          next: (result: any) => {

            this.handleFinishedCalibration(result, () => {
              setTimeout(async () => {
                this.host = new EgzoGameHost(this.gameRef.nativeElement);
                await this.initStella();
                this.startedExerciseId = await this.exerciseService.initializeExercise(this.template.name, {
                  module: `games.${this.game}`,
                  primary: this.selectedChannel,
                  secondary: this.selectedSecondaryChannel,
                  emgCalibration: this.mvc,
                  template: this.template
                });
                await this.exerciseService.update({
                  muscles: this.muscles
                });
                await this.startGame();
                await this.exerciseService.start();
              }, 50);
            });
          }
        });
    }
    super.onPlay();
  }

  createCalibrationObservable(recalibration = false): Observable<any> {
    this.calibrationDialogRef = this.dialog.open(EmgCalibrationComponent, {
      width: '80%',
      data: {
        channel: this.selectedChannel,
        muscles: this.muscles,
        // TODO: Add flag if game can be two for two channels
        showSecondarySelection: this.template.name === 'abordage',
        selectedSecondaryChannel: this.selectedSecondaryChannel,
      }
    });
    return this.calibrationDialogRef
      .afterClosed()
      .pipe(
        map(result => this.checkRejection(result)),
        filter(Boolean)
      );
  }

  private checkRejection(result) {
    if (!result) {
      this.handleRejection();
    }
    return result;
  }

  private handleRejection() {
    this.exerciseContainer.restart();
    this.calibrationDialogRef = null;
    throw new Error('back_to_muscle_selection');
  }

  handleFinishedCalibration(result: any, closureFnc) {
    this.mvc = result.max;
    this.selectedChannel = result.channel;
    this.selectedSecondaryChannel = result.selectedSecondaryChannel;
    closureFnc();
    this.calibrationDialogRef = null;
  }

  private normalizeValue(signal: ChannelSignal): number {
    const indexOfSelected = this.muscles
      .filter(f => f.quality !== 'none')
      .findIndex(c => c.channel === this.selectedChannel);
    const max = this.mvc[indexOfSelected];
    const point0 = max / 2;
    if (!signal[this.selectedChannel]) {
      throw new Error('found_no_signal');
    }
    return (signal[this.selectedChannel][0] * 1e6 - point0) / (max / 2);
  }

  handleData(signal: ChannelSignal): void {
    let sig: {[key: string]: number | undefined} = {};
    try {
      sig = { n: this.normalizeValue(signal) };
      if (isNaN(sig.n)) {
        delete sig.n;
      }
    } catch (err) {
      // console.log(err);
    }
    Object.entries<Float32Array>(signal)
      .filter(([_, value]) => value.length)
      .forEach(([key, value]) => {
        sig[`ch${key}`] = value[0] * 1e6;
      });
    this.ngZone.runOutsideAngular(() => {
      this.host.message('game', {
        data: { sig }
      });
    });
  }

  async ngOnDestroy() {
    if (this.host) {
      this.ngZone.runOutsideAngular(() => {
        this.host.message('game', GameUtils.STOP_COMMAND);
      });
    }
    await this.exerciseService.stop();
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.stellaDirect.setMask(0);
  }

  private calculateRanges() {
    const usedChannels = validChannels(this.muscles).ids;

    const ranges = usedChannels.reduce((sum, next, index) => {
      sum[`ch${next}`] = {
        order: index,
        min: 0,
        max: this.mvc[index]
      };
      return sum;
    }, {});
    if (this.template.name === 'abordage' && usedChannels.length === 2) {
      return {
        [`ch${this.selectedChannel}`]: {
          order: 0,
          ...ranges[`ch${this.selectedChannel}`]
        },
        [`ch${usedChannels.find(v => this.selectedChannel !== v)}`]: {
          order: 1,
          ...ranges[`ch${usedChannels.find(v => this.selectedChannel !== v)}`]
        }
      };
    }

    return {
      [`ch${this.selectedChannel}`]: {
        order: 0,
        ...ranges[`ch${this.selectedChannel}`]
      }
    };
  }

  private async startGame() {
    const { game, levels } = this.navigationState;

    this.host.initialize({
      basePath: `${this.env.get('gamesUrl')}${game.path}/`,
      useGTag: false,
      // @ts-ignore
      environment: 'development',
      environmentIdentifier: DID && CID ? DID + '-' + CID : undefined
    });

    GameUtils.setupLevelsForStella(levels);
    // Set delayResults for all levels to disable delay when results are sent
    await this.host.load(game);

    this.ngZone.runOutsideAngular(() => {
      this.host.message('game', GameUtils.setLevels(levels));
    });

    GameUtils.setDarkBackground(this.host);

    this.host.onMessage = async (msg) => {
      if (!msg.data || 'clients' in msg.data) {
        return;
      }

      if (msg.data.game) {

        if (msg.data.game.results) {
          await this.exerciseService.update({ results: msg.data.game.results }, this.startedExerciseId);
        }

        if (msg.data.game.control === 'pause') {
          this.exerciseService.pause();
        }

        if (msg.data.game.control === 'stop') {
          if (this.dashboard.started) {
            this.dashboard.nextExercise();
          } else {
            if (this.dashboard.mode === DashboardMode.PATIENT) {
              this.router.navigate([Pages.PATIENT_CALENDAR], { state: { force: true } });
            } else {
              this.router.navigate([Pages.PATIENT_MEDICAL_CARD], { state: { force: true} });
            }
          }
        }

        if (msg.data.game.control === 'ready') {
          const channelsRanges = this.calculateRanges();
          this.ngZone.runOutsideAngular(() => {
            this.host.message('game', GameUtils.setRanges(channelsRanges));
            this.host.message('game', GameUtils.PLAY_COMMAND);
          });
        }

        if (msg.data.game.request) {
          if ((msg.data.game.request as any).modTime) {
            this.ngZone.runOutsideAngular(() => {
              this.host.message('game', GameUtils.MOD_TIME(msg.data.game.request.modTime.id));
            });
          }
          if ((msg.data.game.request as any).recalibrateEMG) {
            this.ngZone.runOutsideAngular(() => {
              this.host.message('game', GameUtils.PAUSE_COMMAND);
            });
            if (this.calibrationDialogRef) {
              return;
            }
            this.createCalibrationObservable(true)
              .subscribe((result: any) => {
                this.handleFinishedCalibration(result, () => {
                  const channelsRanges = this.calculateRanges();
                  this.exerciseService.start();
                  this.ngZone.runOutsideAngular(() => {
                    this.host.message('game', GameUtils.setRanges(channelsRanges));
                    this.host.message('game', GameUtils.PLAY_COMMAND);
                  });
                });
              });
          }
        }
      }
    };
  }

  pause() {
    this.exerciseService.pause();
  }
}
