import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'frequencies'
})
export class FrequenciesPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if (value.phases) {
      return value.phases.map(ph => ph.frequency || ph.sequence[0].frequency || 0).filter(Boolean).join('/');
    }
    return value.details.frequency || '---';
  }

}
