import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanActivateFeatureGuard } from '@app/training/guards/can-activate-feature.guard';
import { CanStartElectrostimGuardGuard } from '@app/training/guards/can-start-electrostim-guard.guard';
import { CanDeactivateGuard } from '@app/training/guards/CanDeactivate.guard';
import { BiofeedbackComponent } from '@app/training/pages/biofeedback/biofeedback.component';
import { ConceptComponent } from '@app/training/pages/concept/concept.component';
import { CustomDiagnosticTestComponent } from '@app/training/pages/custom-diagnostic-test/custom-diagnostic-test.component';
import { ElectrostimComponent } from '@app/training/pages/electrostim/electrostim.component';
import { EmgComponent } from '@app/training/pages/emg/emg.component';
import { GameLevelListComponent } from '@app/training/pages/game-level-list/game-level-list.component';
import { GamesComponent } from '@app/training/pages/games/games.component';
import { WaitingRoomComponent } from '@app/training/pages/waiting-room/waiting-room.component';
import { TrainingComponent } from '@app/training/training.component';

const routes: Routes = [
  {
    path: 'trainings',
    component: TrainingComponent,
    children: [
      {
        path: 'emg/view',
        component: EmgComponent,
        canDeactivate: [CanDeactivateGuard],

      },
      {
        path: 'emg/relaxation_analysis',
        component: EmgComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          module: 'diag.emg',
          moduleSource: 'direct',
          exerciseName: 'relaxation_analysis'
        }
      },
      {
        path: 'emg/view_with_mvc',
        component: EmgComponent,
        canDeactivate: [CanDeactivateGuard],
        data: {
          module: 'diag.emg',
          moduleSource: 'direct',
          exerciseName: 'view_with_mvc'
        }        
      },      
      {
        path: 'emg/custom',
        component: BiofeedbackComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [CanActivateFeatureGuard],
        data: {
          module: 'diag.biofeedback',
          moduleSource: 'direct',
          exerciseName: 'custom'
        }
      },
      {
        path: 'emg/low_back_pain',
        component: BiofeedbackComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [CanActivateFeatureGuard],
        data: {
          module: 'diag.low_back_pain',
          moduleSource: 'direct',
          exerciseName: 'low_back_pain'
        }
      },
      {
        path: 'emg/sorens',
        component: BiofeedbackComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [CanActivateFeatureGuard],
        data: {
          module: 'diag.sorens',
          moduleSource: 'direct',
          exerciseName: 'sorens'
        }
      },
      {
        path: 'emg/:name',
        component: CustomDiagnosticTestComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [CanActivateFeatureGuard],
        data: {
          module: 'diag',
          moduleSource: 'param',
          paramName: 'name'
        }
      },
      {
        path: 'games/:name',
        component: GameLevelListComponent,
        canActivate: [CanActivateFeatureGuard],
        data: {
          module: 'games',
          moduleSource: 'param',
          paramName: 'name'
        }
      },
      {
        path: 'games/play/:name',
        component: GamesComponent,
        canDeactivate: [CanDeactivateGuard],
        canActivate: [CanActivateFeatureGuard],
        data: {
          module: 'games',
          moduleSource: 'param',
          paramName: 'name'
        }
      },
      {
        path: 'electrostim/:name', component: ElectrostimComponent,
        canActivate: [CanActivateFeatureGuard, CanStartElectrostimGuardGuard],
        canDeactivate: [CanDeactivateGuard],
        data: {
          module: 'stim',
          moduleSource: 'param',
          paramName: 'name'
        }
      },
      {
        path: 'idle', component: WaitingRoomComponent
      },
      {
        path: ':concept', component: ConceptComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrainingRoutingModule { }
