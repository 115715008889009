import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

const colorCode = {
  ERROR: '\x1B[0;31m',
  INFO: '\x1B[0;32m',
  LOG: '\x1B[0;30m'
};

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  logs$ = new BehaviorSubject<string[]>([]);

  log(message: string): void {
    this.addLog(message, 'LOG');
  }

  error(message: string) {
    this.addLog(message, 'ERROR');
  }

  info(message: string) {
    this.addLog(message, 'INFO');
  }

  private addLog(message: string, type: 'ERROR' | 'INFO' | 'LOG') {
    const val = this.logs$.getValue();
    val.push(`${colorCode[type]}(${type}) ${message}`);
    this.logs$.next(val);
  }

  clear() {
    this.logs$.next([]);
  }
}
