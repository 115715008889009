import { HostListener } from '@angular/core';

export abstract class BaseTrainingComponent {
  private hasPlayed = false;

  constructor() {}

  pause() {
  }

  onPlay() {
    this.hasPlayed = true;
  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHandler(event) {
    event.preventDefault();
    this.pause();
  }    
}
