<mat-dialog-content>
  <h2>
    {{ (edit ? "common.editClinic" : "common.createClinic") | translate }}
  </h2>

  <form class="signup--form" [formGroup]="clinic" (ngSubmit)="onSubmit()">
    <perfect-scrollbar class='scroll'>
      <div class="sides">
        <div class="left">
          <label class="clinic-avatar">
            <input
              type="file"
              [disabled]="isLoading"
              (change)="onFileChange($event)"
            />
            <img
              [src]="clinic.value.logo"
              onerror="this.src='assets/svg/icon_medical_facility.svg'"
            />
            <p class="change-avatar">{{ "common.avatarChange" | translate }}</p>
          </label>
        </div>
        <div class="right">
          <mat-form-field class="full-width">
            <input
              matInput
              autocomplete="off"
              [placeholder]="'common.name' | translate"
              class="clinic-name"
              formControlName="clinicName"
            />
            <mat-error *ngIf="clinic.controls.clinicName.invalid">{{
              clinic.get("clinicName").errors | Error | translate
            }}</mat-error>
          </mat-form-field>
          <div class="owner-avatar-container">
            <div class="avatar-container">
              <sba-user-avatar
                [avatar]="user.avatar"
                [gender]="user.gender"
                size="medium"
                role="1"
              ></sba-user-avatar>
            </div>
            <mat-form-field class="full-width">
              <input
                matInput
                [placeholder]="'roles.owner' | translate"
                type="text"
                [value]="user.displayName"
                disabled
              />
            </mat-form-field>
          </div>

          <div class="slide-toggle-container">
            <mat-slide-toggle
              [checked]="showAddressFields"
              (change)="toggleAddress()"
              [disabled]="isLoading"
              >{{ "common.address" | translate }}</mat-slide-toggle
            >
          </div>
          <ng-container *ngIf="showAddressFields" formGroupName="address">
            <mat-form-field class="full-width">
              <input
                matInput
                [placeholder]="'common.street' | translate"
                type="text"
                formControlName="street"
              />
              <mat-error
                *ngIf="clinic.controls.address.controls.street.invalid"
              >
                {{ clinic.get("address.street").errors | Error | translate }}
              </mat-error>
            </mat-form-field>
            <div class="flex-row zipcity">
              <mat-form-field class="full-width">
                <input
                  matInput
                  [placeholder]="'common.zipcode' | translate"
                  type="text"
                  formControlName="zipcode"
                />
                <mat-error
                  *ngIf="clinic.controls.address.controls.zipcode.invalid"
                  >{{
                    clinic.get("address.zipcode").errors | Error | translate
                  }}</mat-error
                >
              </mat-form-field>
              <mat-form-field class="full-width">
                <input
                  matInput
                  [placeholder]="'common.city' | translate"
                  type="text"
                  formControlName="city"
                />
                <mat-error
                  *ngIf="clinic.controls.address.controls.city.invalid"
                  >{{
                    clinic.get("address.city").errors | Error | translate
                  }}</mat-error
                >
              </mat-form-field>
            </div>
            <mat-form-field class="full-width">
              <input
                matInput
                [placeholder]="'common.country' | translate"
                type="text"
                formControlName="country"
              />
              <mat-error
                *ngIf="clinic.controls.address.controls.country.invalid"
                >{{
                  clinic.get("address.country").errors | Error | translate
                }}</mat-error
              >
            </mat-form-field>
          </ng-container>

          <mat-form-field class="full-width">
            <input
              matInput
              [placeholder]="'common.website' | translate"
              type="text"
              formControlName="website"
            />
            <mat-error *ngIf="clinic.controls.website.invalid">{{
              clinic.get("website").errors | Error | translate
            }}</mat-error>
          </mat-form-field>

          <mat-form-field class="full-width">
            <textarea
              matInput
              [placeholder]="'common.about' | translate"
              type="text"
              formControlName="description"
              autosize
            ></textarea>
            <mat-error *ngIf="clinic.controls.description.invalid">{{
              clinic.get("description").errors | Error | translate
            }}</mat-error>
          </mat-form-field>
        </div>

        <mat-error *ngIf="clinic.invalid && clinic.errors">{{
          clinic.errors | Error | translate
        }}</mat-error>
      </div>

      <div class="actions-container">
        <div class="outline-button" mat-ripple (click)="cancel()">
          {{ "common.cancel" | translate }}
        </div>

        <button
          type="submit"
          [disabled]="clinic.invalid"
          class="outline-button"
          mat-ripple
        >
          {{ "common.save" | translate }}
        </button>
      </div>
    </perfect-scrollbar>
  </form>
</mat-dialog-content>
