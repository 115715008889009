import { secondsToTimeOnCharts } from '@app/utils/utils';
import {
  lightningChart,
  DataPatterns,
  emptyFill,
  SolidFill,
  AxisTickStrategies,
  SolidLine,
  ColorRGBA,
  ColorHEX,
  ChartXY,
  LineSeries,
  AutoCursorModes,
  Point,
  Themes,
  ConstantLine,
  NumericTickStrategy,
  VisibleTicks
} from '@arction/lcjs';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { EgzoChart, ChartOptions, ThresholdLine, MovingTimeAxisScaleStrategy, ChartConfig } from './EgzoChart';

interface LineChartOptions extends ChartOptions {
  channels: number;
  chartConfig: ChartConfig;
}

export class MoveLineTimeChart extends EgzoChart {
  private approxPointsPerSecondChannel = 20;
  private guideLineSeries: LineSeries[] = [];
  private timeGuide: ConstantLine;
  private options: LineChartOptions;

  constructor(options: Partial<LineChartOptions> = {}, private translateService: TranslateService) {
    super();
    this.options = {
      container: 'line-chart',
      theme: Themes.light,
      showFPS: !environment.production,
      channels: 8,
      chartConfig: {
        preloadTime: 30, // s
        horizont: 30, // s
        ticks: 30,
        allowShifting: true
      },
      ...options
    };
    this._chart = this.initializeChart(this.options);
    this.initializeFPSIndicator(this.options.showFPS);
  }


  private initializeChart(options: LineChartOptions): ChartXY {

   const chart = lightningChart(environment.lightningChartLicense ?? undefined)
        .ChartXY(options)
        .setTitleFillStyle(emptyFill)
        .setAutoCursorMode(AutoCursorModes.disabled)
        .setBackgroundFillStyle(new SolidFill({
          color: ColorRGBA(255, 0, 0, 0)
        }))
        .setChartBackgroundFillStyle(
          new SolidFill({
            color: ColorRGBA(255, 0, 0, 0)
          })
        )
        .setMouseInteractions(false);
    this.xAxis = chart
      .getDefaultAxisX()
      .disableAnimations()
      .setTitle(`[${this.translateService.instant('common.units.seconds')}]`)
      .setScrollStrategy(new MovingTimeAxisScaleStrategy())
      .setInterval(0, this.approxPointsPerSecondChannel)
      .setTickStrategy(
        AxisTickStrategies.Numeric,
        (numericTickStrategy: NumericTickStrategy) =>
          numericTickStrategy
            .setFormattingFunction((value) => {
              return secondsToTimeOnCharts(value)
            })
      )
      .setMouseInteractions(false);

    this.yAxis = chart.getDefaultAxisY()
      .disableAnimations()
      .setScrollStrategy(undefined)
      .setTickStrategy(AxisTickStrategies.Numeric)
      .setMouseInteractions(false)
      .setTitle("%");

    this.timeGuide = this.xAxis.addConstantLine()
      .setValue(0)
      .setStrokeStyle(
        new SolidLine({
          thickness: 3,
          fillStyle: new SolidFill({
            color: ColorHEX('#888')
          })
        })
      );

    this.xAxis.setScrollStrategy({
      start: () => {
        if (!this.options.chartConfig.allowShifting) {
          return 0;
        }
        const max = this.timeGuide.getValue();
        return max < 15 ? 0 : max - 15;
      },
      end: () => {
        if (!this.options.chartConfig.allowShifting) {
          return this.options.chartConfig.preloadTime + this.options.chartConfig.horizont;
        }
        const max = this.timeGuide.getValue();
        return max < 15 ? 30 : max + 15;
      }
    });
    this.thresholdLine = new ThresholdLine(this.yAxis);

    return chart;
  }

  createGuideLine(points: Point[], color = '#66cccc') {
    const guideLine = this._chart
      .addLineSeries({
        dataPattern: DataPatterns.horizontalProgressive
      })
      .setStrokeStyle(new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: ColorHEX(color) })
      }))
      .setName('guide')
      .setMaxPointCount(this.approxPointsPerSecondChannel * 15);

    points.forEach(p => guideLine.add(p));
    this.guideLineSeries.push(guideLine);
  }

  setColorArray(newArray: string[]): void {
    this.colorArray = newArray;
  }

  setTime(point: number) {
    this.timeGuide.setValue(point);
  }
}
