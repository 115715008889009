import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { StellaConnectService } from './stella-connect.service';
import { StellaDirectService } from './stella-direct.service';
import { filter, map, switchMap, toArray, tap, flatMap } from 'rxjs/operators';
import { StellaNamesQuery } from '@app/store/stellaNames';
import { AuthQuery } from '@app/store/auth';

@Injectable({
  providedIn: 'root'
})
export class ListStellaService {

  stellaList$ = new BehaviorSubject([]);
  isAuthorized$ = this.authQuery.isAuthorized$;

  private subscription: Subscription;

  constructor(
    private readonly stellaService: StellaConnectService,
    private readonly directStella: StellaDirectService,
    private readonly stellaNames: StellaNamesQuery,
    private readonly authQuery: AuthQuery,
  ) { }

  startListening() {
    this.subscription = this.stellaService.subject.pipe(
      filter(data => data),
      flatMap(data => this.filterData(data)),
      tap(stellas => this.stellaList$.next(stellas)),
      tap(stellas => {
        if (this.directStella.currentStella) {
          try {
            const one = stellas
              .filter(st => st.net.local.basemac === this.directStella.currentStella.net.local.basemac)
              .find(st => st.net.local.address !== this.directStella.currentStella.net.local.address);
            if (one) {
              this.directStella.setStella(one);
              this.directStella.connect();
            }
          } catch (err) {
            console.error(err);
          }
        }
      })
    ).subscribe();
  }

  stopListening() {
    this.subscription.unsubscribe();
  }

  private filterData(data: { clients: any }): Observable<any[]> {
    const serialNumbers = (window as any).egzotechHostApi?.getDevicesSerialNumbers?.() ?? null;
    return of(data)
      .pipe(
        switchMap(data => Object.values<any>(data.clients)),
        filter(s => s.net),
        filter(s => s.net.local),
        filter (s => !serialNumbers || serialNumbers.indexOf(s.sn.toUpperCase()) >= 0),
        map(s => this.addCustomName(s)),
        toArray()
      );
  }

  selectStella(stella) {
    this.directStella.setStella(stella);
  }

  implicit() {
    return this.directStella.implicit;
  }

  clearStella() {
    return this.directStella.clearStella();
  }

  private addCustomName(s) {
    const stellaName = this.stellaNames.getAll().find(sn => sn.mac.toLowerCase() === s.net.local.basemac.toLowerCase());
    const customName = stellaName ? stellaName.name : s.sn;
    return {
      ...s,
      customName
    };
  }
}
