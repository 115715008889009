import { Subject } from 'rxjs';
import { GLAZER } from '@app/diagnosticTests';
// tslint:disable-next-line
import { Change, DiagnosticTestConfiguration, DiagnosticTestEvent, LineConfigurationOption, MovementType, TestEventType, ContractionType, Line } from '@app/types';
import { TestController } from '@app/training/TestController';

export class StepTestController implements TestController {
  constructor(configuration?: Partial<DiagnosticTestConfiguration>) {
    this.configuration = {
      ...this.configuration,
      ...configuration
    };
  }

  lines: Line[] = [];
  event$ = new Subject<DiagnosticTestEvent>();
  private configuration: DiagnosticTestConfiguration = GLAZER;
  private changes: Change[] = [];
  private time = 0;

  prepareLineConfiguration(options: LineConfigurationOption): { lines: any[], thresholdLine: any } {
    const START_RELAX_DURATION = 10;
    const lines = [];
    let time = 0;
    const thresholdLine: Object[][] = new Array(this.configuration.ports.length).fill([]).map(() => []);
    for (const [index, step] of this.configuration.steps.entries()) {
      for(const mi in thresholdLine) {
        thresholdLine[mi].push({
          x: time,
          y: 0
        });
      }

      this.changes.push({
        type: MovementType.RELAX,
        time,
        value: 0
      });
      time += step.startRelaxDuration;
      for (let contr = 0; contr < step.repetitions; contr++) {
        lines.push(options.lineCreator(MovementType.CONTRACTION, time));
        for(const mi in thresholdLine) {
          thresholdLine[mi].push({
            x: time,
            y: step.threshold * this.configuration.ports[mi].mvc
          });
        }
        this.changes.push({
          type: MovementType.CONTRACTION,
          time,
          value: step.threshold * this.configuration.ports[0].mvc,
          detail: getContractionType(step.workTime)
        });
        
        time += step.workTime;
        lines.push(options.lineCreator(MovementType.RELAX, time));
        for(const mi in thresholdLine) {
          thresholdLine[mi].push({
            x: time,
            y: this.configuration.threshold
          });
        }
        this.changes.push({
          type: MovementType.RELAX,
          time,
          value: this.configuration.threshold
        });
        time += step.restTime;
      }
      
      // thresholdLine.pop();
    }
    this.changes.push({
      event: TestEventType.END,
      time
    });
    lines.push(options.lineCreator('END', time));
    this.lines = lines;

    const wrongIndexes = [];
    let lastState = MovementType.RELAX;
    for (let i = 1; i < this.changes.length; i++) {
      if (this.changes[i].type === lastState) {
        wrongIndexes.push(i);
      }
      lastState = this.changes[i].type;
    }
    this.changes = this.changes.filter((_, i) => !wrongIndexes.includes[i]);

    return {
      lines,
      thresholdLine
    };
  }

  updateTime(time: number): void {
    this.time = time;
    if (!this.changes.length) {
      return;
    }
    if (time > this.changes[0].time - 0.8) {
      const change = this.changes.shift();
      if (!change.event) {
        this.event$.next({
          ...change,
          event: TestEventType.SOUND
        });

        setTimeout(() => {
          this.event$.next({
            ...change,
            event: TestEventType.THRESHOLD
          });
        }, 800);
      } else {
        setTimeout(() => {
          this.event$.next({
            event: TestEventType.END
          });
        }, 800);
      }
    }
  }

  getCurrentTime(): number {
    return this.time;
  }
}

function getContractionType(duration: number): ContractionType {
  if (duration < 2) {
    return ContractionType.FLICK;
  }
  if (duration < 10) {
    return ContractionType.NORMAL;
  }

  return ContractionType.HOLD;
}
