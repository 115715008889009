import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RestEndpointsService } from '@app/api/rest-endpoints.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { StellaNamesStore } from '@app/store/stellaNames';
import { StellaConnectService } from '@app/stella/services/stella-connect.service';

@Component({
  selector: 'sba-stella-name',
  templateUrl: './stella-name.component.html',
  styleUrls: ['./stella-name.component.scss']
})
export class StellaNameComponent {

  stella = new FormGroup({
    name: new FormControl('', [Validators.required])
  });

  constructor(
    private readonly stellaConnect: StellaConnectService,
    private readonly rest: RestEndpointsService,
    private readonly names: StellaNamesStore,
    public dialogRef: MatDialogRef<StellaNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.stella.patchValue({
      name: data.stella.customName
    });
  }

  onSubmit() {
    const payload = {
      mac: this.data.stella.net.local.basemac,
      name: this.stella.value.name
    };
    this.rest.setStellaName(payload).subscribe({
      next: res => {
        if (res.op === 'UPDATE') {
          this.names.update(null, ent => ent.mac !== payload.mac ? ent : { ...ent, name: payload.name });
        } else {
          this.names.add({ id: -1, ...payload });
        }
        this.stellaConnect.repeatList();
        this.dialogRef.close(true);
      },
      error: err => {
        console.log(err);
        this.dialogRef.close(false);
      }
    });
  }
}
