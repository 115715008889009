import { CurrentExerciseService } from '@app/training/services/current-excercise.service';
import { CurrentSessionService } from '@app/training/services/current-session.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { FormControl, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';
import { Exercise } from '@app/models/Exercise.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sba-end-training-dialog',
  templateUrl: './end-training-dialog.component.html',
  styleUrls: ['./end-training-dialog.component.scss']
})
export class EndTrainingDialogComponent {
  inProgress: boolean;
  buttons = [];

  template: Exercise;
  newTitleGroup :FormGroup;
  hasNewTitleInput: boolean;
  
  constructor(
    public dialogRef: MatDialogRef<EndTrainingDialogComponent>,
    private session: CurrentSessionService,
    private exercise: CurrentExerciseService,
    dashboard: DashboardService,
    private readonly router: Router,
    private translation: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;

    this.hasNewTitleInput = this.getExerciseNameForInquiry() !== undefined && this.exercise.hasStarted;    
    this.newTitleGroup = new FormGroup({
      newTitle: new FormControl(this.getExerciseTranslatedName(), []),
    });
  
    
    const shouldShowCancel = data ? !data.trainingEnd : true;
    if (dashboard.trainingTemplate) {
      if (!dashboard.isLastExercise) {
        this.buttons.push(
          {
            label: 'endTrainingDialog.buttons.next',
            action: () => this.quitTraining()
          });
      } else {
        this.buttons.push(
          {
            label: 'endTrainingDialog.buttons.quit',
            action: () => this.quitSession()
          });
        }
    } else {
      if (data && data.showOnlyQuit) {
        this.buttons = [
          {
            label: 'common.quit',
            action: () => this.quitSession()
          }
        ];
      } else {
        this.buttons = [
          {
            label: 'endTrainingDialog.buttons.endTraining',
            action: () => this.quitTraining()
          },
        ];
      }
      if (shouldShowCancel) {
        this.buttons.push(
          {
            label: 'common.cancel',
            action: () => this.dialogRef.close()
          }
        );
      }
    }
  }

  async quitSession() {      
    try {
      this.updateTrainingMetadata();      
      this.inProgress = true;
      this.dialogRef.disableClose = true;
      await this.exercise.stop();
      await this.session.stop();
      this.dialogRef.close(2);
    } finally {
      this.inProgress = false;
      this.dialogRef.disableClose = false;
    }
  }

  getExerciseNameForInquiry() {
    return this.data.exerciseNameForInquiry;
  }

  getExerciseTranslatedName() {
    const exName = this.getExerciseNameForInquiry();
    return exName ? this.translation.instant('exercises.' + exName) : '';
  }


  updateTrainingMetadata() {
    const name = this.newTitleGroup.controls['newTitle'].value;
    if( name && name !== this.getExerciseTranslatedName()) {
      this.exercise.update({
        newTitle: name
      });       
    }
  }

  async quitTraining() {
    try {
      this.updateTrainingMetadata();
      this.inProgress = true;
      this.dialogRef.disableClose = true;
      await this.exercise.stop();
      this.dialogRef.close(1);
    } finally {
      this.inProgress = false;
      this.dialogRef.disableClose = false;
    }
  }
}
