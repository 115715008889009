import { Subject } from 'rxjs';

export class PacketCounter {
  private data: number[] = [];
  private currentCounter = 0;
  private sum = 0;
  stream$ = new Subject<any>();
  ref: any;

  start() {
    if (!this.ref) {
      this.ref = setInterval(() => {
        this.data.push(this.currentCounter);
        this.sum += this.currentCounter;
        this.currentCounter = 0;
        this.stream$.next({ packets: this.data, total: this.sum });
      }, 1000);
    }
  }

  increment() {
    this.currentCounter += 1;
  }

  stop() {
    clearInterval(this.ref);
    this.stream$.complete();
  }
}
