import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SCALES_UV } from '@app/enums';
import { SCALES_PCT } from '@app/enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sba-scale-selector',
  templateUrl: './scale-selector.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScaleSelectorComponent {
  @Input() value;
  @Input() unit;
  @Output() change = new EventEmitter();
  scales;

  constructor(private translateService: TranslateService) {}

  ngOnChanges() {
    this.scales =
      this.unit === 'pctmvc'
        ? SCALES_PCT
        : SCALES_UV.map(scale => ({
            ...scale,
            label: `${scale.value} ${this.translateService.instant('common.units.microvolts')}`,
          }));
  }
}
