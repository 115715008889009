import { Injectable } from '@angular/core';
import { DashboardMode } from '@app/enums';
import { AuthService } from '../../auth/services/auth.service';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { Pages } from '@app/pages';
import { StellaCareService } from '@app/shared/services/stella-care.service';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';

const roleMode = {
  1: DashboardMode.OWNER,
  2: DashboardMode.SPECIALIST,
  3: DashboardMode.PATIENT
};

const endPath = {
  [DashboardMode.SPECIALIST]: Pages.PATIENT_MEDICAL_CARD,
  [DashboardMode.PATIENT]: Pages.PATIENT_DASHBOARD
};

const modules = {
  SIDENAV: {
    specialist: true,
    owner: false
  },
  PATIENT_SELECTOR: {
    specialist: true
  },
  MENU_BILLINGS: {
    specialist: true,
    owner: true
  },
  MENU_FEATURES: {
    specialist: true,
    owner: true
  },
  TRAINING_CONTROLS: {
    patient: true,
    specialist: true
  },
  CONCEPTS: {
    patient: false,
    specialist: true,
    owner: false
  },
  PATIENT_DATA: {
    patient: false,
    specialist: true,
    owner: false
  }
};

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    authService: AuthService,
    private readonly router: Router,
    private readonly stellaCare: StellaCareService,
    private readonly stella: StellaDirectService
  ) {
    const user = authService.currentUser;
    if (user) {
      this.mode = roleMode[user.role];
    }
  }

  mode: DashboardMode;
  private _trainingTemplate: any;
  currentExercise = 0;
  started = false;
  nextExercise$ = new BehaviorSubject<any>(null);

  private _isLastExercise = false;

  get exercise() {
    if (this.trainingTemplate) {
      return this.trainingTemplate.meetings[this.currentExercise];
    } else {
      return null;
    }
  }

  checkIfIsLastExercise() {
    if (!this._isLastExercise) {
      this._isLastExercise = this.currentExercise >= this.trainingTemplate.meetings.map(meeting => meeting.completed).lastIndexOf(false);
    }
  }

  get isLastExercise() {
    return this._isLastExercise;
  }

  get trainingTemplate() {
    return this._trainingTemplate;
  }

  set trainingTemplate(template) {
    if (!template) {
      this._trainingTemplate = null;
      return;
    }
    this._trainingTemplate = {
      ...template,
      meetings: template.meetings.map(s => ({
        ...s,
        template: this.stellaCare.decorateExercise(s.template)
      }))
    };
  }

  isAllowed(module: string) {
    if (modules[module]) {
      return modules[module][this.mode];
    }
    return false;
  }

  resetTraining() {
    this.currentExercise = 0;
    this.started = false;
    this.nextExercise$.next(null);
    this._isLastExercise = false;
  }

  async startScheduledTraining() {
    if (!this.stella.isStellaSelected) {
      this.router.navigate([Pages.STELLA_CONNECT]);
      return;
    }
    this.resetTraining();
    const firstValidExercise = this.trainingTemplate.meetings.findIndex(meeting => !meeting.completed);
    this.currentExercise = firstValidExercise;

    await this.initExercise();
  }

  async startSingleExercise(index) {
    if (!this.stella.isStellaSelected) {
      this.router.navigate([Pages.STELLA_CONNECT]);
      return;
    }
    this.resetTraining();
    this.currentExercise = index;
    this._isLastExercise = true;

    await this.initExercise();
  }

  private async initExercise() {
    this.started = true;
    const exercise = this.trainingTemplate.meetings[this.currentExercise];
    const path = exercise.template.path;
    await this.router.navigate([path]);
    this.nextExercise$.next({
      index: this.currentExercise,
      ...exercise.template,
    });
  }

  async stopScheduledTraining(page: string = Pages.PATIENT_MEDICAL_CARD) {
    const res = await this.router.navigate([page]);
    if (res) {
      this.started = false;
      this.nextExercise$.next(null);
    }
  }

  nextExercise() {
    this.checkIfIsLastExercise();
    if (this.isLastExercise) {
      this.stopScheduledTraining(endPath[this.mode]);
    } else {
      this.currentExercise = this.trainingTemplate.meetings.findIndex((meeting, index) => !meeting.completed && index > this.currentExercise);

      this.startExercise(this.trainingTemplate.meetings[this.currentExercise]);
    }
  }

  async startExercise(exercise) {
    const path = exercise.template.path;
    const res = await this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => this.router.navigate([path]));
    if (!res) {
      this.currentExercise--;
    } else {
      this.nextExercise$.next({
        index: this.currentExercise,
        ...exercise.template,
      });
    }
  }

  getPathForTemplate(template) {
    return `/dashboard/trainings/${template.type}/${template.name}`;
  }

  getPathForExercise(exercise: any) {
    if (!exercise) {
      return '/dashboard/trainings/emg/view';
    }
    return {
      Emg: '/dashboard/trainings/emg',
      EMG: '/dashboard/trainings/emg',
      emg: '/dashboard/trainings/emg',
      'EMG Calibration': '/dashboard/trainings/emg',
      Glazer: '/dashboard/trainings/emg/glazer',
      glazer: '/dashboard/trainings/emg/glazer',
      quick_flicks: '/dashboard/trainings/emg/quick_flicks',
      endurance: '/dashboard/trainings/emg/endurance',
      'biofeedback_10-10': '/dashboard/trainings/emg/biofeedback_10-10',
      'biofeedback_5-5': '/dashboard/trainings/emg/biofeedback_5-5',
      biofeedback_10_10: '/dashboard/trainings/emg/biofeedback_10-10',
      biofeedback_5_5: '/dashboard/trainings/emg/biofeedback_5-5',
      'upper_limbs_muscle_re-education': '/dashboard/trainings/electrostim/upper_limbs_muscle_re-education',
      'back_muscle_re-education': '/dashboard/trainings/electrostim/back_muscle_re-education',
      'abdominal_muscle_re-education': '/dashboard/trainings/electrostim/abdominal_muscle_re-education',
      'buttocks_muscle_re-education': '/dashboard/trainings/electrostim/buttocks_muscle_re-education'
    }[exercise.name];
  }
}
