import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { ExercisesQuery } from '@app/store/exercises/exercise.query';
import { CurrentExerciseService } from '@app/training/services/current-excercise.service';
import { CurrentSessionService } from '@app/training/services/current-session.service';
import { SelectedMuscle } from '@app/types';
import { flatMap, tap } from 'rxjs/operators';
import { BaseDiagnosticComponent } from '../baseDiagnostic.component';
import { Location } from '@angular/common';
import { AuthService } from '@app/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { BatteryService } from '@app/shared/services/low-battery.service';

@Component({
  selector: 'sba-custom-diagnostic-test',
  templateUrl: './custom-diagnostic-test.component.html',
  styleUrls: ['./custom-diagnostic-test.component.scss']
})
export class CustomDiagnosticTestComponent extends BaseDiagnosticComponent
  implements OnInit, OnDestroy {
  threshold = 100;

  constructor(
    private readonly authService: AuthService,
    translation: TranslateService,
    router: Router,
    dialog: MatDialog,
    stellaDirect: StellaDirectService,
    sessionService: CurrentSessionService,
    exerciseService: CurrentExerciseService,
    exerciseQuery: ExercisesQuery,
    dashboard: DashboardService,
    location: Location,
    activatedRoute: ActivatedRoute,
    batteryService: BatteryService
  ) {
    super(stellaDirect, sessionService, exerciseService, exerciseQuery, dashboard, dialog, location, router, translation, activatedRoute, batteryService);

    router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  getOnlyValid(muscles: SelectedMuscle[]): number[] {
    if (!muscles) {
      return [];
    }
    return muscles.map(q => q.channel);
  }

  async initializeExercise() {
    await super.initializeExercise();

    await this.exerciseService.update({
      threshold: new Array(8).fill(this.threshold),
      muscles: this.muscles
    });
  }
}
