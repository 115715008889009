<div class="connecting" *ngIf="_program | async as val">
  <perfect-scrollbar class="container">
    <h2 class="mapper-title hidden-sm">
      <button mat-icon-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <ng-container> {{ "exercises." + val?.name | translate }} *</ng-container>
      {{ "channelMuscleMapper.title" | translate }}
    </h2>
    <sba-cable-select-message
      *ngIf="!cable || cable.code === 65535"
    ></sba-cable-select-message>
    <ng-container *ngIf="cable && cable.code !== 65535">
      <mat-form-field class="body-filter">
        <mat-label>{{ "parts.bodyPart" | translate }}</mat-label>
        <mat-select [(value)]="selectedBodyPart">
          <mat-select-trigger>
            <div class="selected-value">
              <!-- <mat-icon [svgIcon]="selectedBodyPart.icon"></mat-icon> -->
              {{ selectedBodyPart.label | translate }}
            </div>
          </mat-select-trigger>
          <mat-option
            class="body-part"
            *ngFor="let item of PARTS | localFunction:getExisting:allMuscles"
            [value]="item"
          >
            <img class="part-img" [src]="item.icon"/>
            <!-- <mat-icon [svgIcon]="item.icon"></mat-icon> -->
            {{ item.label | translate }} ({{ item.count}})
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div
        class="models {{ val.type }}"
        *ngIf="allMuscles | electrostimBodyFilter: selectedBodyPart as muscles"
      >
        <div
          class="muscle--container"
          *ngFor="let model of muscles; trackBy: trackByName"
        >
          <sba-single-muscle
            [programType]="val.type"
            [model]="model"
            [otherChannels]="otherChannels"
            [nextActive]="nextActive"
            (release)="releaseChannel($event)"
            (select)="selectMuscle($event)"
          ></sba-single-muscle>
        </div>
        <div class="muscle--container" *ngIf="muscles.length % 2 !== 0"></div>
      </div>
    </ng-container>
  </perfect-scrollbar>
  <div class="guide--container">
    <h2 class="mapper-title hidden-md">
      <button mat-icon-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <ng-container> {{ "exercises." + val?.name | translate }} *</ng-container>
      {{ "channelMuscleMapper.title" | translate }}
    </h2>
    <div class="actions-fixed">
      <button
        mat-button
        class="outline-button fixed"
        [disabled]="!canStart() || cable?.code === 65535"
        [ngClass]="{ warnings: hasWarnings() }"
        (click)="startTraining()"
      >
        {{ "channelMuscleMapper.set" | translate }}
      </button>
    </div>
    <perfect-scrollbar class="container channels">
      <div class="messages-container">
        <div class="guide--item" *ngIf="cable && cable.code !== 65535 && connectionStatus.enoughChannelsChoosen">
          <mat-icon class="connection-icon" [ngClass]="[connectionStatus.enoughChannelsChoosen]">{{ getIconForChannelConnection({quality: connectionStatus.enoughChannelsChoosen}) }}</mat-icon>
          <div class="connection-checker">
            {{ "channelMuscleMapper.enoughChannelsChoosen" | translate }}
          </div>
        </div>
        <div class="guide--item" *ngIf="cable && cable.code !== 65535 && connectionStatus.electrodesConnection">
          <mat-icon class="connection-icon" [ngClass]="[connectionStatus.electrodesConnection]">{{ getIconForChannelConnection({quality: connectionStatus.electrodesConnection}) }}</mat-icon>
          <div class="connection-checker">
            {{ "channelMuscleMapper.electrodesConnection" | translate }}
          </div>
        </div>
        <div class="guide--item" *ngIf="cable && cable.code !== 65535 && connectionStatus.enoughChannelsInCable">
          <mat-icon class="connection-icon" [ngClass]="[connectionStatus.enoughChannelsInCable]">{{ getIconForChannelConnection({quality: connectionStatus.enoughChannelsInCable}) }}</mat-icon>
          <div class="connection-checker">
            {{ "channelMuscleMapper.enoughChannelsInCable" | translate }}
          </div>
        </div>
        <div class="guide--item" *ngIf="cable && cable.code !== 65535 && connectionStatus.requiredPelvicChannel">
          <mat-icon class="connection-icon" [ngClass]="[connectionStatus.requiredPelvicChannel]">{{ getIconForChannelConnection({quality: connectionStatus.requiredPelvicChannel}) }}</mat-icon>
          <div class="connection-checker">
            {{ "channelMuscleMapper.requiredPelvicChannel" | translate }}
          </div>
        </div>
        <div class="guide--item" *ngIf="cable && cable.code !== 65535 && connectionStatus.connectedChannelsChoosen">
          <mat-icon class="connection-icon" [ngClass]="[connectionStatus.connectedChannelsChoosen]">{{ getIconForChannelConnection({quality: connectionStatus.connectedChannelsChoosen}) }}</mat-icon>
          <div class="connection-checker">
            {{ (isEMSBased() ? "channelMuscleMapper.connectedChannelsChoosenEMS" : "channelMuscleMapper.connectedChannelsChoosen") | translate }}
          </div>
        </div>        
      </div>
      <h2>{{ "channelMuscleMapper.cable" | translate }}</h2>
      <div class="guide--item">
        <div class="muscle">
          {{ cable?.description.slug | translate }}
        </div>
      </div>
      <div class="guide--item" *ngIf="cableRequirements as step">
        <mat-icon
          [ngStyle]="{
            color: step.satisfied ? 'green' : 'red'
          }"
          >{{ step.satisfied ? "done" : "close" }}</mat-icon
        >

        <div class="muscle">{{ step.slug | translate }}: {{ step.value }}</div>
      </div>
      <div class="guide--item" *ngIf="pelvicRequirements as step">
        <mat-icon
          [ngStyle]="{
            color: step.satisfied ? 'green' : 'red'
          }"
          >{{ step.satisfied ? "done" : "close" }}</mat-icon
        >
        <div class="muscle">{{ step.slug | translate }}</div>
      </div>
      <div class="guide--item" *ngIf="val | localFunction:requireReference">
        {{ "channelMuscleMapper.reference" | translate }}
      </div>
      <h2>{{ "channelMuscleMapper.selectedChannels" | translate }}</h2>
      <div class="guide--selected" style="margin-left: 10px; margin-top: 10px;" *ngIf="otherChannels.length === 0">
        {{ "channelMuscleMapper.noneSelected" | translate }}
      </div>
      <div class="guide--selected" *ngFor="let step of otherChannels">
          <div
          class="guide--item"
          [ngClass]="{
            'next-active': step | localFunction: isNextActive:nextActive,
            pelvic: step | localFunction: isPelvic:cable
          }"
          appClickCounter
          clickMax="3"
          matTooltip="{{
            'channelMuscleMapper.clickThreeTimesToRelease' | translate
          }}"
          (onMax)="releaseChannel(step.channel)"
          (cclick)="setNextActive(step)"
        >
          <div
            class="badge"
            [ngStyle]="{
              backgroundColor: step.channel | channelcolor
            }"
          >
            {{ step.channel + 1 }}
          </div>
          <div class="muscle" [ngClass]="{ weak: !step.bodyModel }">
            {{
              (step.bodyModel
                ? "muscles." + step.bodyModel.name
                : "channelMuscleMapper.checkMuscle"
              ) | translate
            }}
            {{ step.side === "M" ? "" : step.side }}
          </div>
          <button
            mat-icon-button
            class="quality-icon"
            [ngClass]="step.quality"
            *ngIf="step.quality"
          >
            <mat-icon>{{ getIconForChannelConnection(step) }}</mat-icon>
          </button>
        </div>
        <button
          mat-icon-button
          matTooltip="{{ 'channelMuscleMapper.clickToUnselect' | translate }}"
          (click)="unselectChannel(step)"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <h2 style="margin-top: 10px">{{ "channelMuscleMapper.availableChannels" | translate }}</h2>
      <div
        class="guide--item"
        [ngClass]="{
          'next-active': step | localFunction: isNextActive:nextActive,
          pelvic: step | localFunction: isPelvic:cable
        }"
        *ngFor="let step of availableChannels"
        [ngStyle]="{
          pointerEvents: canSelectMoreChannels() ? '' : 'none'
        }"
        matTooltip="{{
          'channelMuscleMapper.clickToSelect' | translate
        }}"
        (click)="selectChannel(step)"
      >
        <div
          class="badge"
          [ngStyle]="{
            backgroundColor: step | localFunction: getAvailableChannelColor:availableChannels
          }"
        >
          {{ step.channel + 1 }}
        </div>
        <div class="muscle" [ngClass]="{ weak: true }">
          {{ "channelMuscleMapper.selectChannel" | translate }}
        </div>
        <button
          mat-icon-button
          class="quality-icon"
          [ngClass]="step.quality"
          *ngIf="step.quality"
        >
          <mat-icon>{{ getIconForChannelConnection(step) }}</mat-icon>
        </button>
      </div>
    </perfect-scrollbar>
  </div>
</div>
