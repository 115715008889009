import { ProgramModule } from '@egzotech/exo-electrostim';
import { ElectrostimGuideBuilder } from './ElectrostimGuideBuilderNew';

export class ElectrostimGuide {
    readonly chartPoints: any[];

    constructor(private readonly programModule: ProgramModule, options?: ElectrostimGuideBuilder["options"]) {
        const builder = new ElectrostimGuideBuilder(this.programModule.config, this.programModule.channelMapping, options);
        const result = builder.build();
        const points = result.points;

        this.chartPoints = points;
    }
}
