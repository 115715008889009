import { Directive, Input, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appClickCounter]'
})
export class ClickCounterDirective {

  @Input() clickMax: number;
  @Output() onMax = new EventEmitter();
  @Output() cclick = new EventEmitter();
  private count = 0;
  private timeoutRef;

  @HostListener('click', ['$event'])
  onClick($event) {
    clearTimeout(this.timeoutRef);
    this.cclick.emit($event);
    this.count += 1;
    if (this.count >= this.clickMax) {
      this.onMax.emit();
      this.count = 0;
    }
    this.timeoutRef = setTimeout(() => {
      this.count = 0;
    }, 5000);
  }
}
