import { Component, OnInit, Input } from '@angular/core';

const ASSETS_PATH_PREFIX = '/assets/png/body_model/EGZOTech_StellaBIO_ikony_rozmieszczenie_';
@Component({
  selector: 'sba-taping-icons',
  templateUrl: './taping-icons.component.html',
  styleUrls: ['./taping-icons.component.scss']
})
export class TapingIconsComponent {

  @Input() icons: string[];
  @Input() type;
  currentIndex = 0;

  getUrl(icon) {
    return ASSETS_PATH_PREFIX + icon;
  }

  next(event, which) {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    this.currentIndex = which;
  }

  count(icons) {
    return Array(icons.length);
  }

  onSwipeLeft(event) {
    if (this.currentIndex === 0) {
      this.next(event, this.icons.length - 1);
    } else {
      this.next(event, this.currentIndex - 1);
    }

  }

  onSwipeRight(event) {
    if (this.currentIndex === this.icons.length - 1) {
      this.next(event, 0);
    } else {
      this.next(event, this.currentIndex + 1);
    }
  }
}
