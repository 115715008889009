
export class StandardChannelMapper {
  transform(channel: number): number {
    return channel;
  }
}

export class PelvicChannelMapper {
  transform(channel: number): number {
    const channelsMap = {
      0: 6,
      1: 7,
      2: 0,
      3: 1,
      4: 2,
      5: 3,
      6: 4,
      7: 5
    };
    return channelsMap[channel];
  }
}

export function ChannelMapper(prog: {incontinence: boolean}) {
  // return new StandardChannelMapper();
  return prog.incontinence ? new PelvicChannelMapper() : new StandardChannelMapper();
}
