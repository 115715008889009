import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MINIMUM_STELLA_OS_VERSION } from '@app/enums';
import { StellaNameComponent } from '../stella-name/stella-name.component';

@Component({
  selector: 'sba-single-stella',
  templateUrl: './single-stella.component.html',
  styleUrls: ['./single-stella.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleStellaComponent {

  @Input() stella: StellaItem;
  @Input() isLocal: boolean;
  @Output() clicked = new EventEmitter();

  constructor(
    private readonly dialog: MatDialog
  ) { }

  isValidVersion() {
    return (this.stella as any).device?.os?.name === "BioMea" && (this.stella as any).device?.os?.version >= MINIMUM_STELLA_OS_VERSION;
  }

  handleSelect() {
    this.clicked.emit(this.stella);
  }

  handleEdit(event) {
    event.stopPropagation();
    // this.dialog.open(StellaNameComponent, {
    //   data: {
    //     stella: this.stella
    //   }
    // });
  }
}

interface StellaItem {
  color: string;
  net: Network;
  wifi: Wifi;
  battery: Battery;
  bat: Battery;
  customName: string;
  ledringColor: string;
}

interface Network {
  local: SingleNetwork;
}

interface SingleNetwork {
  address: string;
  gateway: string;
  port: string;
}

interface Battery {
  level: number;
}

interface Wifi {
  level: number;
}
