import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Subscription, BehaviorSubject } from 'rxjs';
import { BluetoothService } from '@app/stella/services/bluetooth.service';
import { environment } from '@env/environment';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Pages } from '@app/pages';

interface BluetoothConfigurationState {
  devicePaired: boolean;
  networkSelected: boolean;
  finished: boolean;
  name: string;
}
const STATES: { [key: string]: BluetoothConfigurationState } = {
  PREP: {
    name: 'PREP',
    devicePaired: false,
    networkSelected: false,
    finished: false,
  },
  START: {
    name: 'START',
    devicePaired: false,
    networkSelected: false,
    finished: false,
  },
  SCAN: {
    name: 'SCAN',
    devicePaired: true,
    networkSelected: false,
    finished: false,
  },
  SELECTED: {
    name: 'SELECTED',
    devicePaired: true,
    networkSelected: true,
    finished: false,
  },
  FINISHED: {
    name: 'FINISHED',
    devicePaired: true,
    networkSelected: true,
    finished: true,
  }

};
@Component({
  selector: 'sba-bt-configure',
  templateUrl: './bt-configure.component.html',
  styleUrls: ['./bt-configure.component.scss'],
  // animations: [
  //   trigger('fadeInOut', [
  //     state(
  //       'void',
  //       style({
  //         opacity: 0
  //       })
  //     ),
  //     transition('void <=> *', animate(500)),

  //   ]),
  //   trigger('slideTop', [
  //     state(
  //       'void',
  //       style({
  //         opacity: 0,
  //         transform: 'translateY(-200%)'
  //       })
  //     ),
  //     transition('void <=> *', animate(300))
  //   ]),
  // ]
})
export class BtConfigureComponent implements OnInit, OnDestroy {
  constructor(
    public bt: BluetoothService,
    private router: Router,
    title: Title) {
    title.setTitle('Stella BT Configuration | Stella BIO App');
  }

  @ViewChild('passwordForm') passwordForm;
  environment = environment;
  enabled = true;
  subscription: Subscription = new Subscription();
  state$ = new BehaviorSubject<BluetoothConfigurationState>(STATES.PREP);
  currentNetwork$ = new BehaviorSubject(null);
  passwordFormGroup = new FormGroup({
    essid: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });
  hide = true;
  resetWifis = false;

  ngOnInit() { this.runBluetooth(); }

  connectManually = async () => await this.bt.connect();

  async runBluetooth() {
    this.subscription.add(
      this.bt.connectionStream$
        .pipe(
          tap(val => {
            if (!val && this.state$.value === STATES.SCAN) {
              this.state$.next(STATES.FINISHED);
            } else if (val) {
              this.state$.next(this.environment.skipNetworkSelection ? STATES.SELECTED : STATES.SCAN);
              if (this.environment.skipNetworkSelection) {
                this.currentNetwork$.next({});
              }
            }
          })
        )
        .subscribe()
    );
  }

  setState(state) {
    if (STATES[state]) {
      this.state$.next(STATES[state]);
    }
    if (state === 'START') {
      this.connectManually();
    }
  }

  setResetWifis(event) {
    this.resetWifis = event.checked;
  }

  selectNetwork(network) {
    this.state$.next(STATES.SELECTED);
    this.currentNetwork$.next(network);
    this.passwordFormGroup.patchValue({
      essid: network.ssid
    });
  }

  setManualSsid() {
    this.state$.next(STATES.SELECTED);
    this.currentNetwork$.next({});
  }

  backToNetworkSelection() {
    this.state$.next(STATES.SCAN);
    this.currentNetwork$.next(null);
    this.passwordFormGroup.reset();
    this.passwordForm.nativeElement.reset();
  }

  synchronizeWifi() {
    this.bt.syncWifis();
  }

  async finishConfiguration() {
    await this.bt.closeConnection();
    this.subscription.unsubscribe();
    this.state$.next(STATES.START);
    this.goBack();
  }

  async onPasswordSubmit() {
    if (this.passwordFormGroup.valid) {
      if (this.resetWifis) {
        await this.bt.send({ net: { add: false } });
        await this.wait();
      }
      await this.bt.addWifi({
        ssid: this.passwordFormGroup.value.essid,
        pass: this.passwordFormGroup.value.password,
        bssid: this.currentNetwork$.value.bssid || '00:00:00:00:00:00'
      });

      this.passwordFormGroup.reset();
      this.passwordForm.nativeElement.reset();
      this.state$.next(STATES.FINISHED);
      setTimeout(async () => {
        await this.bt.closeConnection();
      }, 1000);
    }
  }

  wait(time = 1000): Promise<void> {
    return new Promise(resolve => {
      setTimeout(resolve, time);
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.bt.closeConnection();
  }

  getTrackId = (index, network) => `${network.ssid}-${index})`;
  resetPasses = () => this.bt.send({ net: { add: false } });
  goBack = () => this.router.navigate([Pages.STELLA_CONNECT]);
}
