<mat-dialog-content>
  <div class="title-row">
    <h2 class="intensity-header">{{ "electrostim.intensity" | translate }}</h2>
    <button class="outline-button" (click)="confirm()" [disabled]="!(electrostimLevel | localFunction:isValid:program)">
      {{ "training.finishCalibration" | translate }}
    </button>
  </div>
  <div class="controls-row">
    <div class="intensity--container">
      <div class="badges">
        <div class="badge-container" *ngFor="let ch of channels">
          <div
            class="badge {{ ch === currentChannelSelected ? 'active' : '' }}"
            [ngStyle]="{ backgroundColor: ch | channelcolor}"
            (click)="changeOn(ch)"
          >
            {{ ch + 1 }}
          </div>
          <p>{{ electrostimLevel[ch] }} {{ 'common.units.miliamperes' | translate }}</p>
        </div>
      </div>
      <sba-electro-slider
        [lvl]="electrostimLevel[currentChannelSelected]"
        [range]="currentChannelRange"
      >
      </sba-electro-slider>
      <div class="buttons-container">
        <div class="button--column">
          <button mat-fab (click)="modifyLevel(-1)">
            <mat-icon>exposure_neg_1</mat-icon>
          </button>
          <p class="button--label">{{ 'electrostim.decrementLevel' | translate }}</p>
        </div>
        <div class="button--column">
          <button mat-fab (click)="modifyLevel(1)">
            <mat-icon>exposure_plus_1</mat-icon>
          </button>
           <p class="button--label">{{ 'electrostim.incrementLevel' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="deny-button--container" *ngIf="!noBackToMuscleSelection">
    <button mat-button class="deny-button" (click)="deny()">{{ 'training.back_to_muscle' | translate  }}</button>
  </div>
</mat-dialog-content>
