import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { inRange } from 'lodash';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'stella-wifi-icon',
  templateUrl: './wifi-icon.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WifiIconComponent {
  @Input() level: number;

  getIcon() {
    let iconName = 'wifi_none';
    if (inRange(this.level, -100, -80) || !this.level) {
      iconName = 'wifi_none';
    }
    if (inRange(this.level, -80, -70)) {
      iconName = 'wifi_0';
    }
    if (inRange(this.level, -70, -60)) {
      iconName = 'wifi_1';
    }
    if (inRange(this.level, -60, -40)) {
      iconName = 'wifi_2';
    }
    if (inRange(this.level, -40, 0)) {
      iconName = 'wifi';
    }
    return iconName;
  }
}
