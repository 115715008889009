import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MedicalCardsQuery } from '@store/medicalCard/medicalCards.query';
import { Patient } from '@app/models/Patient.model';
import { PatientsState, PatientsStore } from './patients.store';

@Injectable({ providedIn: 'root' })
export class PatientsQuery extends QueryEntity<PatientsState> {

  constructor(
    protected store: PatientsStore,
    protected medicalCardQuery: MedicalCardsQuery
  ) {
    super(store);
  }

  selectAllSorted(): Observable<Patient[]> {
    return this.selectAll()
      .pipe(map(sortPatients));
  }

  getPatientById(patientId: number): Patient {
    return this.getAll({ filterBy: e => e.patientId === patientId })[0];
  }

  getPatientByUserId(userId: number): Patient {
    const patients = this.getAll({ filterBy: e => e.id === userId });

    return patients[0];
  }

  getActivePatientId(): number {
    if (!this.getActive()) { return null; }
    return (this.getActive() as Patient).patientId;
  }

  getActivePatientOrMock(): any {
    return this.getActive()
      ? (this.getActive() as Patient)
      : { id: '0', patientId: 0 };
  }
}

function sortPatients(patients: Patient[]): Patient[] {
  return patients
    .sort((a, b) => {
      if (a.displayName < b.displayName) {
        return -1;
      }
      if (a.displayName > b.displayName) {
        return 1;
      }
      return 1;
    })
    .sort((a, b) => {
      if (a.selection.last > b.selection.last) {
        return -1;
      }
      if (a.selection.last < b.selection.last) {
        return 1;
      }
      return 1;
    });
}
