import { getChartLabel, secondsToTimeOnCharts } from '@app/utils/utils';
import {
  lightningChart,
  DataPatterns,
  emptyFill,
  emptyLine,
  SolidFill,
  AxisTickStrategies,
  SolidLine,
  ColorRGBA,
  ColorHEX,
  ChartXY, LineSeries, AutoCursorModes, Point, Themes, EmptyFill, NumericTickStrategy
} from '@arction/lcjs';
import { environment } from '@env/environment';
import { EgzoChart, ChartOptions, ThresholdLine, MovingTimeAxisScaleStrategy } from './EgzoChart';

interface LineChartOptions extends ChartOptions {
  channels: number;
}

const CHART_TIMEFRAME = 30;

export class LineChart extends EgzoChart {
  private selectedChannel = 0;
  private approxPointsPerSecondChannel = 20;
  private series: LineSeries[];
  private bufferedPoints: Point[][];
  private guideLine: LineSeries;
  private options: LineChartOptions;

  constructor(options: Partial<LineChartOptions> = {}, xAxis: string, yAxis: string) {
    super();
    this.options = {
      container: 'line-chart',
      theme: Themes.light,
      showFPS: !environment.production,
      channels: 8,
      ...options
    };
    this._chart = this.initializeChart(this.options, xAxis, yAxis);
    this.initializeFPSIndicator(this.options.showFPS);
    this.bufferedPoints = Array.from({ length: this.options.channels }).map(_ => []);
  }

  private initializeChart(options: LineChartOptions, xAxis: string, yAxis: string): ChartXY {
    const chart = lightningChart(environment.lightningChartLicense ?? undefined)
        .ChartXY(options)
        .setTitleFillStyle(emptyFill)
        .setAutoCursorMode(AutoCursorModes.disabled)
        .setMouseInteractions(false);

    this.xAxis = chart
      .getDefaultAxisX()
      .setTitle(xAxis)
      .setScrollStrategy(new MovingTimeAxisScaleStrategy())
      .setInterval(0, this.approxPointsPerSecondChannel)
      .setTickStrategy(
        AxisTickStrategies.Numeric,
        (numericTickStrategy: NumericTickStrategy) =>
          numericTickStrategy.setFormattingFunction((value) => {
            return secondsToTimeOnCharts(value);
          })
      )
      .setMouseInteractions(false);

    this.yAxis = chart.getDefaultAxisY()
      .setTitle(yAxis)
      .setScrollStrategy(undefined)
      .setTickStrategy(AxisTickStrategies.Numeric)
      .setMouseInteractions(false)
      .disableAnimations();

    this.series = new Array(this.options.channels).fill('ch').map((ch, i) => {
      const series = chart
        .addLineSeries({
          dataPattern: DataPatterns.horizontalProgressive
        })
        .setStrokeStyle(new SolidLine({
          thickness: 2,
          fillStyle: new SolidFill({ color: ColorHEX(this.colorArray[i]) })
        }))
        .setName(ch + i)
        .setMaxPointCount(this.approxPointsPerSecondChannel * CHART_TIMEFRAME / 2);

      return series;
    });

    this.xAxis.setScrollStrategy({
      start: () => {
        const max = this.series[this.selectedChannel].getXMax();
        return max < 15 ? 0 : max - 15;
      },
      end: () => {
        const max = this.series[this.selectedChannel].getXMax();
        return max < 15 ? 30 : max + 15;
      }
    });
    this.thresholdLine = new ThresholdLine(this.yAxis, options.threshold);

    return chart;
  }

  createGuideLine(points: Point[], color = '#66cccc') {
    if (this.guideLine) {
      this.guideLine.dispose();
    }
    this.guideLine = this._chart
      .addLineSeries({
        dataPattern: DataPatterns.horizontalProgressive
      })
      .setStrokeStyle(new SolidLine({
        thickness: 2,
        fillStyle: new SolidFill({ color: ColorHEX(color) })
      }))
      .setName('guide')
      .setMaxPointCount(this.approxPointsPerSecondChannel * CHART_TIMEFRAME / 2);

    rectangularizeSeries(points).forEach(p => this.guideLine.add(p));
  }

  addVerticalLine(value: number, color: string) {
    this.xAxis.addConstantLine()
      .setValue(value)
      .setStrokeStyle(
        new SolidLine({
          thickness: 3,
          fillStyle: new SolidFill({
            color: ColorHEX(color)
          })
        })
      );
  }

  setVisibleChannels(channels: number[]) {
    for (let i = 0; i < this.series.length; i++) {
      const show = channels.includes(i);
      if (show) {
        this.series[i].setStrokeStyle(new SolidLine({
          thickness: 2,
          fillStyle: new SolidFill({
            color: ColorHEX(this.colorArray[i])
          })
        }));
      } else {
        this.series[i].setStrokeStyle(new SolidLine({
          thickness: 0,
          fillStyle: new SolidFill({
            color: ColorHEX(this.colorArray[i])
          })
        }));
      }
    }
  }

  setCurrentChannel(channel: number) {
    this.selectedChannel = channel;
    // this.series[0]
    //   .setStrokeStyle(new SolidLine({
    //     thickness: 2,
    //     fillStyle: new SolidFill({ color: ColorHEX(this.colorArray[this.selectedChannel]) })
    //   }));
  }

  setColorArray(newArray: string[]): void {
    // this.colorArray = newArray;
    // this.series[0].getStrokeStyle().setFillStyle(new SolidFill({ color: ColorHEX(this.colorArray[this.selectedChannel]) }))
  }


  private addPointToBuffer(point: Point, channel) {
    this.bufferedPoints[channel].push(point);
    if (this.bufferedPoints[channel].length > 1000) {
      this.bufferedPoints[channel].shift();
    }
  }

  public setFactors(factors: number[]): void {
    super.setFactors(factors);
    this.fillSeriesWithData();
  }

  fillSeriesWithData() {
    if( !this.series ) {
      return;
    }
    for (let channel = 0; channel < this.series.length; channel++) {
      const serie = this.series[channel];
      const bufferedPointsForChannel = this.bufferedPoints[channel];
  
      // Clear the current serie
      serie.clear();
  
      // Add buffered points to the serie
      for (const point of bufferedPointsForChannel) {
        serie.add({ x: point.x, y: point.y / this.channelFactor[channel] });
      }
    }
  }

  addValue(point: Point, channel = 0) {
    this.series[channel].add({x: point.x, y: point.y / this.channelFactor[channel] });
    this.addPointToBuffer(point,channel);
  }
}

function rectangularizeSeries(points: Point[]): Point[] {
  const newPoints = [];
  points.reduce((previous, next) => {
    if (!previous) {
      return next;
    }
    newPoints.push({
      x: next.x,
      y: previous.y
    });
    newPoints.push(next);
    return next;
  });

  return newPoints;
}
