import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ExercisesState, ExercisesStore } from './exercise.store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class ExercisesQuery extends QueryEntity<ExercisesState> {

  constructor(
    protected store: ExercisesStore
  ) {
    super(store);
  }

  findIconForExercise(exName: string): string {
    const exercise = this.getAll().find(ex => ex.name === exName);
    if (!exercise) {
      return null;
    }
    return exercise.icon || exercise.steps.iconName;
  }

  selectAll(): Observable<any> {
    if (environment.telemedicine) {
      return super.selectAll();
    }

    return super.selectAll()
      .pipe(
        map(all => all
          .map(a => this.setInitialLicenseValue(a))
        ),
      );
  }

  private setInitialLicenseValue(program): any {
    // Temporary removed license feature - all programs 'allowed' param must initially be set to true
    return {
      ...program,
      allowed: true
    };
  }
}
