import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

const ORDER = [
  'pelvic',
  'ems',
  'tens',
  'fes',
  'emg_triggered',
  'emg',
  'game'
];

@Component({
  selector: 'sba-programs-grouped',
  templateUrl: './programs-grouped.component.html',
  styleUrls: ['./programs-grouped.component.scss']
})
export class ProgramsGroupedComponent {

  @Output() programSelected = new EventEmitter();
  @Input()
  set programs(programs: any[]) {
    if (!programs) { return; }
    this.groupedPrograms = programs.reduce((r, a) => {
      const category = (a.extras || {}).functionalCategory;
      r[category] = r[category] || [];
      r[category].push(a);
      return r;
    }, Object.create(null));
  }
  groupedPrograms: any;

  handleClick(program) {
    this.programSelected.emit(program);
  }

  getChannelsAvailable(program): string {
    if (program.name === 'abordage') {
      return '1/2';
    }
    return '1';
  }

  hasDescription(group): boolean {
    return ['fes', 'emg_triggered'].includes(group);
  }

  sortByOrder(groups) {
    return groups.sort((a, b) => ORDER.indexOf(a.key) - ORDER.indexOf(b.key));
  }
}
