import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ColorService } from '@app/shared/services/color.service';

@Component({
  selector: 'sba-channel-selector',
  templateUrl: './channel-selector.component.html',
  styleUrls: ['./channel-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelSelectorComponent {
  constructor(public readonly colorService: ColorService) {

  }
  @Input() value: number[];
  @Input() multiple = true;
  @Output() change = new EventEmitter();

  @Input() channels = [0, 1 , 2, 3, 4, 5, 6, 7];
}
