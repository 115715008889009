import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

export class SemVer {
  private static semverRegex = /^(\d+).(\d+)(?:.(\d+))?/;

  static parse(version: string) {
    const match = version.match(this.semverRegex);

    if (match && match.length < 3) {
      throw new Error("Invalid version format");
    }  

    return new SemVer(parseInt(match[1]), parseInt(match[2]), parseInt(match[3] ?? "0"));
  }

  constructor(
    public major: number, 
    public minor: number, 
    public revision: number
  ) {

  }

  compare(version: SemVer) {
    if (this.major > version.major) 
      return 1;
    else if (this.major < version.major) 
      return -1;
    
    if (this.minor > version.minor) 
      return 1;
    else if (this.minor < version.minor) 
      return -1;
    
    if (this.revision > version.revision) 
      return 1;
    else if (this.revision < version.revision) 
      return -1;
    
  return 0;
  }

  toString() {
    return `${this.major}.${this.minor}.${this.revision}`;
  }
}

@Component({
  selector: 'sba-changelog-dialog',
  templateUrl: './changelog-dialog.component.html',
  styleUrls: ['./changelog-dialog.component.scss']
})
export class ChangelogDialogComponent {
  changelog: { version: string, content: string }[];

  constructor(
    public dialogRef: MatDialogRef<ChangelogDialogComponent>,
    translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { fromVersion: SemVer | null, toVersion: SemVer }
  ) {
    dialogRef.disableClose = true;
    data.fromVersion = data.fromVersion ?? new SemVer(0, 0, 0);

    translate.get("changelog.versions").subscribe(changelogTranslations => {
      const result: { version: string, content: string }[] = [];

      for (const prop in changelogTranslations) {
        const semver = SemVer.parse(prop.replace(/-/gm, "."));

        if (semver.compare(data.fromVersion) > 0 && semver.compare(data.toVersion) <= 0) {
          result.push({ version: semver.toString(), content: changelogTranslations[prop] });
        }
      }

      if (result.length === 0) {
        // TODO: PLS fix me in the future, this check should be done BEFORE displaying a dialog
        // ... but this way is definitely easier
        this.dialogRef.close(true);
        return;
      }

      this.changelog = result;
    });
  }

  ok() {
    this.dialogRef.close(true);
  }
}
