export type ChannelProgramParameterDefinitionUnit = "number" | "Hz" | "s" | "ms" | "us" | "A" | "mA";

export interface ChannelProgramParameterDefinition<T extends ChannelProgramParameterDefinitionUnit> {
    unit: T;
    default: number;
    values: number[];
}

export interface ChannelProgramParameters {
    [key: string]: ChannelProgramParameterDefinition<ChannelProgramParameterDefinitionUnit>
        | ChannelProgramParameters
        | (ChannelProgramParameters | null)[]
        | undefined;
}

export type ChannelProgramParametersValue<T> = T extends ChannelProgramParameterDefinition<any> ? T["default"]
    : T extends any[] ? {
        [key in keyof T]: ChannelProgramParametersValue<T[key]> | null
    }
    : {
        [key in keyof T]: ChannelProgramParametersValue<T[key]>
    }

export type ChannelProgramParametersValues = ChannelProgramParametersValue<ChannelProgramParameters>;

export function isChannelProgramParameterDefinition(obj: object): obj is ChannelProgramParameterDefinition<ChannelProgramParameterDefinitionUnit> {
    return "unit" in obj && "default" in obj && "values" in obj;
}
