import { BehaviorSubject } from 'rxjs';

type ExerciseStateEnum = 'INITIAL' | 'RUNNING' | 'PAUSED' | 'STOPPED';

export class ExerciseState {

  current$ = new BehaviorSubject<any>({
    current: 'INITIAL',
    canBeStarted: true,
    canBePause: false
  });

  get current(): ExerciseStateEnum {
    return this._current;
  }

  private _current: ExerciseStateEnum = 'INITIAL';

  setState(newState: ExerciseStateEnum) {
    this._current = newState;
    this.current$.next({
      current: this._current,
      canBeStarted: ['INITIAL', 'PAUSED'].includes(this._current),
      canBePaused: ['RUNNING'].includes(this._current)
    });
  }
}
