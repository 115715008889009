import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'muscle'
})
export class MusclePipe implements PipeTransform {


  transform(value: number, ...args: any[]): any {
    const muscles = args[0];
    if (!muscles) {
      return undefined;
    }
    const foundMuscle = muscles.find(m => m.channel === value);
    if (!foundMuscle || !foundMuscle.bodyModel) {
      return undefined;
    }
    return { name: foundMuscle.bodyModel.name, side: foundMuscle.side };
  }
}
