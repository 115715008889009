import { Current, CurrentType, ElectrostimTest } from '@app/types';
import { BodyPart } from '@app/enums';

export const DEV = {
    id: 'DEV_INC',
    name: 'DEV_TEST',
    bodyPart: BodyPart.BUTTOCKS,
    tags: ['rom', 'reeducation'],
    slug: 'dev_inc',
    steps: {
        details: {
            duration: 15 * 60 * 1e6,
            pulseDuration: 200,
            frequency: 11,
            period: 90909,
            rampUp: 1e6,
            rampDown: 1e6,
            workTime: 3 * 1e6,
            restTime: 3 * 1e6,
            current: Current.BIPHASIC_SYMMETRIC,
            currentType: CurrentType.RECTANGULAR,
            currentRange: { min: 0, max: 100 },
        }
    }
};




