<div class="stellas-module">
  <router-outlet></router-outlet>
</div>
<div class="right-corner" *ngIf="isAuthorized$ | async as authorized">
  <div class="therapist therapist--container">
    <button mat-button [matMenuTriggerFor]="menu">
      <div class="therapist-name--container" *ngIf="!hideAdditionalInfo">
        <p class="therapist--name">
          {{ user?.displayName }}
        </p>
        <p class="therapist--clinic" *ngIf="currentClinic$ | async as clinic  ">
          {{ clinic?.name }}
        </p>
      </div>
      <div class="flex-row">
        <div class="therapist--avatar">
          <sba-user-avatar [role]="user?.role" [gender]="user?.gender" [avatar]="user?.avatar"></sba-user-avatar>
        </div>
        <mat-icon class="whity">arrow_drop_down</mat-icon>
      </div>
    </button>
    <mat-menu class="navigation-menu" xPosition="before" #menu="matMenu">
      <button *ngIf="hideAdditionalInfo && authorized" mat-menu-item>
        <span class="in-menu">{{ user?.displayName }}</span>
      </button>

      <button mat-menu-item [disabled]="!authorized" (click)="goToClinicSelection()">
        <span>{{ "navigation.changeClinic" | translate }}</span>
      </button>
      <button mat-menu-item (click)="handleLogout()">
        <span>{{
              (authorized ? "navigation.logout" : "navigation.signIn")
                | translate
            }}</span>
      </button>
    </mat-menu>
  </div>
  <sba-language-selector class='language-selector'></sba-language-selector>
</div>