import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { AnalyticsAction, AnalyticsCategory, GTagService } from '@app/shared/gtag.service';

@Component({
  selector: 'sba-show-more-paragraph',
  templateUrl: './show-more-paragraph.component.html',
  styleUrls: ['./show-more-paragraph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShowMoreParagraphComponent {

  @Input() limit: number;
  @Input() set text(val: string) {
    this._givenText = val;
    if (val.length <= this.limit) {
      this.showMore = false;
      this._displayText = val;

      return;
    }
    this.showMore = true;
    this._displayText = val.substring(0, this.limit);
  }

  _givenText: string;
  _displayText: string;
  showMore: boolean;

  constructor(
    private readonly gtag: GTagService,
    private readonly dialog: MatDialog
    ) { }


  handleClickMore(event) {
    this.gtag.emit(AnalyticsCategory.CONCEPT, AnalyticsAction.CONCEPT_MORE_CLICKED, '', this.limit);
    event.stopPropagation();
    this.dialog.open(MessageDialogComponent, { width: '50%', data: { prompt: this._givenText }});
  }
}
