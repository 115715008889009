import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  get(key: string) {
    return environment[key];
  }


  getAll() {
    return environment;
  }

}
