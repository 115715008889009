import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Clinic } from '@app/models/Clinic.model';
import { ClinicsState, ClinicsStore } from './clinics.store';

@Injectable({ providedIn: 'root' })
export class ClinicsQuery extends QueryEntity<ClinicsState> {
  constructor(protected store: ClinicsStore) {
    super(store);
  }

  getActive(): Clinic {
    return super.getActive() as Clinic;
  }

  selectAllSorted(): Observable<Clinic[]> {
    return this.selectAll().pipe(map(sortClinics));
  }

  isOnlyFromLocal(): boolean {
    const all = this.getAll();
    return all.length <= 1 || (all[0] as any).ls;
  }
}

function sortClinics(clinics: Clinic[]): Clinic[] {
  return clinics
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 1;
    })
    .sort((a, b) => {
      if (!a.selection || !b.selection) {
        return 0;
      }
      if (a.selection.length && !b.selection.length) {
        return -1;
      }
      if (!a.selection.length && b.selection.length) {
        return 1;
      }
      if (!a.selection.length && !b.selection.length) {
        return 0;
      }
      if (a.selection[0].last > b.selection[0].last) {
        return -1;
      }
      if (a.selection[0].last < b.selection[0].last) {
        return 1;
      }
      return 1;
    });
}
