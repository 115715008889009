import { Component, ElementRef, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'sba-signal-waveform-dialog',
  templateUrl: './signal-waveform-dialog.component.html',
  styleUrls: ['./signal-waveform-dialog.component.scss']
})
export class SignalWaveformDialogComponent implements AfterViewInit {

  @ViewChild('waveform') waveform: ElementRef<HTMLCanvasElement>;

  constructor(
    public dialogRef: MatDialogRef<SignalWaveformDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any) {

  }

  ngAfterViewInit() {
  // width: 400,  height: 200
    const context = this.waveform.nativeElement.getContext('2d');
    this.waveform.nativeElement.height = 200;
    this.waveform.nativeElement.width = 400;

    this.drawXAxis(context);
    this.drawYAxis(context);

    this.drawRectPath(context);
    this.drawText(context);

    this.drawGrid(context);
  }

  drawText(context: CanvasRenderingContext2D) {
    context.fillStyle = '#66cccc';
    context.fillText('I [mA]', 10 , 10);
    context.fillText('t [us]', 378, 115);
    context.fillText('10', 0, 32);
    context.fillText('-10', 0, 172);
    context.fillText('400', 34, 97);
    context.fillText('10000', 280, 110);
  }

  drawGrid(context: CanvasRenderingContext2D) {
    context.beginPath();
    context.setLineDash([5, 5]);
    context.moveTo(17, 30);
    context.lineTo(383, 30);

    context.moveTo(17, 170);
    context.lineTo(383, 170);

    context.strokeStyle = '#66cccc';
    context.stroke();
  }


  drawRectPath(context: CanvasRenderingContext2D) {
    context.beginPath();
    context.moveTo(20, 30);
    context.lineTo(30, 30);
    context.lineTo(30, 170);
    context.lineTo(40, 170);
    context.lineTo(40, 100);

    context.lineTo(290, 100);
    context.lineTo(290, 30);
    context.lineTo(300, 30);
    context.lineTo(300, 170);
    context.lineTo(310, 170);
    context.lineTo(310, 100);

    context.strokeStyle = '#66cccc';
    context.stroke();
  }

  drawXAxis(context: CanvasRenderingContext2D) {
    context.beginPath();
    context.moveTo(20, 100);
    context.lineTo(380, 100);

    context.moveTo(380, 100);
    context.lineTo(375, 95);
    context.moveTo(380, 100);
    context.lineTo(375, 105);
    context.strokeStyle = '#444';
    context.stroke();
  }

  drawYAxis(context: CanvasRenderingContext2D) {
    context.beginPath();
    context.moveTo(20, 20);
    context.lineTo(20, 180);

    context.moveTo(20, 20);
    context.lineTo(15, 25);
    context.moveTo(20, 20);
    context.lineTo(25, 25);
    context.strokeStyle = '#444';
    context.stroke();
  }


  confirm() {
    this.dialogRef.close();
  }
}
