import { ContractionType, DiagnosticTestConfiguration } from '@app/types';

export interface DiagnosticTest {
    name: string;
    configuration: DiagnosticTestConfiguration;
}

export const test5on5: DiagnosticTestConfiguration = {
    eventPreTime: 1,
    threshold: 0,
    ports: [],
    
    steps: [
        {
            workTime: 5,
            restTime: 5,
            repetitions: 5,
            threshold: 0.5,
            duration: 60,
            endTime: 60
        }
    ]
};

export const test10on10: DiagnosticTestConfiguration = {
    eventPreTime: 1,
    threshold: 0,
    ports:[],
    steps: [
        {
            workTime: 10,
            restTime: 10,
            repetitions: 10,
            threshold: 0.5,
            duration: 210,
            endTime: 210
        }
    ]
};

export const quickFlicks: DiagnosticTestConfiguration = {
    eventPreTime: 1,
    threshold: 0,
    ports: [],
    steps: [
        {
            workTime: 3,
            restTime: 3,
            repetitions: 5,
            threshold: 0.2,
            duration: 40,
            endTime: 40
        },
        {
            workTime: 2,
            restTime: 2,
            repetitions: 5,
            threshold: 0.2,
            duration: 35,
            endTime: 75
        }
    ]
};

export const endurance: DiagnosticTestConfiguration = {
    eventPreTime: 1,
    threshold: 0,
    ports: [],
    steps: [
        {
            workTime: 30,
            restTime: 15,
            repetitions: 3,
            threshold: 0.2,
            duration: 145,
            endTime: 145
        }
    ]
};

export const GLAZER: DiagnosticTestConfiguration = {
    eventPreTime: 1,
    threshold: 0,
    ports: [],
    steps: [
        {
            workTime: 2,
            restTime: 10,
            repetitions: 5,
            threshold: 0.7,
            duration: 110,
            endTime: 110,
            startRelaxDuration: 60
        },
        {
            workTime: 10,
            restTime: 10,
            repetitions: 5,
            threshold: 0.4,
            duration: 100,
            endTime: 210,
            startRelaxDuration: 10
        },
        {
            workTime: 60,
            restTime: 60,
            repetitions: 1,
            threshold: 0.2,
            duration: 130,
            endTime: 330,
            startRelaxDuration: 10
        }
    ]
};

export const lowBackPain: DiagnosticTestConfiguration = {
    eventPreTime: 1,
    threshold: 0,
    ports: [],
    steps: [
        {
            workTime: 5,
            restTime: 10,
            repetitions: 1,
            startRelaxDuration: 10,
            threshold: 0.5,
            removable: false,
            duration: 15,
            endTime: 25,
        },
        {
            workTime: 5,
            restTime: 10,
            repetitions: 1,
            startRelaxDuration: 10,
            threshold: 0.5,
            removable: false,
            duration: 15,
            endTime: 40,
        },
        {
            workTime: 5,
            restTime: 10,
            repetitions: 1,
            startRelaxDuration: 10,
            threshold: 0.5,
            removable: false,
            duration: 15,
            endTime: 55,
        },
        {
            workTime: 5,
            restTime: 10,
            repetitions: 1,
            startRelaxDuration: 10,
            threshold: 0.5,
            removable: false,
            duration: 15,
            endTime: 70,
        },
        {
            workTime: 5,
            restTime: 10,
            repetitions: 1,
            startRelaxDuration: 10,
            threshold: 0.5,
            removable: false,
            duration: 15,
            endTime: 85,
        }
    ]
};

export const sorens: DiagnosticTestConfiguration = {
    eventPreTime: 1,
    threshold: 0,
    ports: [],
    steps: [{
        workTime: 120,
        restTime: 60,
        repetitions: 1,
        startRelaxDuration: 60,
        threshold: 0.2,
        removable: false,
        duration: 240,
        endTime: 240,
      }]
};
