import { DeepReadonly, StimChannelConfiguration } from "@egzotech/exo-electrostim";
import { ChannelProgramController, ChannelProgramControllerOptions } from "./ChannelProgramController";
import { ProgramConfiguration, ProgramConfigurationParametersValues } from "./ProgramConfiguration";
import { toMicroseconds } from "./stim-programs";

export interface ProgramControllerOptions extends ChannelProgramControllerOptions {
    parameters?: DeepReadonly<ProgramConfigurationParametersValues> | null;
}

export class ProgramController extends ChannelProgramController<ProgramConfiguration> {
    get minRequiredChannels() {
        if (!this.coercedProgram) {
            throw new Error("Cannot read minRequiredChannels without setting configuration");
        }

        return this.coercedProgram.maxIntensity.length;
    }

    get stimConfiguration(): DeepReadonly<ProgramConfiguration> {
        return this.coercedProgram;
    }

    constructor() {
        super({
            allowMappingSingleVirtualChannelToMultiplePhyscialChannels: true
        });
        this.onApplyParameters = (program, coercedProgram, parameters) =>
            this.afterApplyParameters(program, coercedProgram, parameters as DeepReadonly<ProgramConfigurationParametersValues>);
    }

    setConfiguration(configuration: DeepReadonly<ProgramConfiguration>, options?: ProgramControllerOptions) {
        super.setProgram(configuration, options);
    }

    private afterApplyParameters(
        program: DeepReadonly<ProgramConfiguration>,
        coercedProgram: ProgramConfiguration,
        parameters: DeepReadonly<ProgramConfigurationParametersValues>
    ) {
        if (!program.parameters) {
            throw new Error("Program does not define any parameters. Cannot apply parameter values to program.");
        }

        if (parameters.phases && program.parameters.phases) {
            // Reset phases to original values
            coercedProgram.phases = JSON.parse(JSON.stringify(program.phases));

            for (let i = 0; i < parameters.phases.length; i++) {
                const phase = parameters.phases[i];
                const phaseDefinition = program.parameters.phases[i];

                if (!phase) {
                    continue;
                }

                if (!phaseDefinition) {
                    throw new Error("Phase parameters does not have their corresponding phase definition. Cannot apply parameter values to program.");
                }

                // Handle plateauTime, pauseTime, riseTime and fallTime to calculate burstOnDuration and burstOffDuration
                const phaseChannel: Partial<StimChannelConfiguration> & Required<ProgramConfigurationParametersValues>["phases"][number] = {
                    ...Object.entries(phaseDefinition.channels)
                        .reduce((prev, next) => (prev[next[0]] = next[1].default, prev), {} as { [key: string]: number }),
                    ...phase.channels
                };

                
                const finalPhaseChannels = coercedProgram.maxIntensity.map((_, j) => ({
                    ...coercedProgram.defaultChannelValues.find(v => v.channelIndex === j),
                    ...coercedProgram.phases[i].channels.find(v => v.channelIndex === j),
                    ...phaseChannel
                })) as StimChannelConfiguration[];

                
                if (i === 0) {
                    // Update the calibration to match first phase
                    coercedProgram.stimCalibration = JSON.parse(JSON.stringify(finalPhaseChannels));
                    
                    // Coerce the time on calibration
                    coercedProgram.stimCalibration = coercedProgram.stimCalibration.map(v => {
                        const burstOnDuration = v.riseTime + (v.plateauTime ?? 0) + v.fallTime;
           
                        return {
                            ...v,
                            delay: 0,
                            pauseTime: 0,
                            runTime: burstOnDuration
                            ? burstOnDuration < 5000000 ? 5000000 : burstOnDuration
                            : 5000000
                        };
                    });
                }

                // Update all channels for current phase if it exists
                if (!coercedProgram.phases[i]) {
                    continue;
                }

                coercedProgram.phases[i].channels = finalPhaseChannels;

                coercedProgram.phases[i].endRelaxTime = phase.endRelaxTime ?? phaseDefinition.endRelaxTime?.default ?? coercedProgram.phases[i].endRelaxTime ?? 0;
              
                coercedProgram.maxIntensity.forEach((_, j) => {
                    // Calculate runTime only when is not provided by program definition
                    if (!coercedProgram.phases[i].channels[j].runTime) {
                        let runTime = ['riseTime','plateauTime','fallTime','pauseTime','delay']
                        .map(
                            (key) =>
                            phase[key] ??
                            coercedProgram.phases[i].channels?.[j]?.[key] ??
                            coercedProgram.defaultChannelValues[j][key]
                        )
                        .reduce((prev, curr) => prev + curr, 0);
                        runTime += phase.channels.offset ?? 0;

                        coercedProgram.phases[i].channels[j].runTime = runTime;
                    }
                    
                    // Update pauseTime with endRelaxTime value choosen by user
                    const currentChannel = coercedProgram.phases[i].channels[j];
                    if (typeof currentChannel.pauseTime === 'number') {
                        currentChannel.pauseTime += toMicroseconds(coercedProgram.phases[i].endRelaxTime, "s");
                    }
                    if (typeof currentChannel.runTime === 'number' && coercedProgram.phases[i].needsTrigger) {
                        // We reduce runTime only for triggered exercises to not impact exercise time (we want more bursts not shorter time of exercise)
                        currentChannel.runTime += toMicroseconds(coercedProgram.phases[i].endRelaxTime, "s");
                    }
                });      
            }
        }

        coercedProgram.programTime = coercedProgram.phasesRepetition * coercedProgram.phases.map(v =>
            Math.max(...coercedProgram.defaultChannelValues.map(d => ({
                ...d,
                ...v.channels.find(c => c.channelIndex === d.channelIndex)
            })).map(v => v.runTime!))
        ).reduce((prev, next) => prev + next, 0);

        return coercedProgram;
    }

}
