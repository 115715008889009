import { ActionDispatcher } from '@app/training/ActionDispatcher';
import { EventEmitter } from 'events';

interface DspHandler {
  pre: (data: any) => void;
  post: (data: any) => void;
}

export class DirectConnection {

  /**
   * Calls handlers provided by StellaDirectService on specific dispatches.
   */
  private handler: DspHandler;

  /**
   * Emits events for StellaDirectService to handle.
   */
  private emitter = new EventEmitter();

  private worker;

  static newInstance(url: string, options): DirectConnection {
    return new DirectConnection(url, options);
  }

  protected constructor(private url: string, options) {
    this.worker = new Worker('./stellaWebsocket.worker', { name: 'stellabio', type: 'module' });
    this.worker.postMessage({
      type: 'create',
      data: {
        url: this.url,
        configuration: {
          quantity: 8,
          setup: JSON.parse(localStorage.getItem('FILTERS_CONFIGURATION'))
        }
      }
    });
    this.setSendingFlag(options.sendFlag);
  }

  on(event, handler) {
    this.emitter.on(event, handler);
  }

  subscribe(handler: DspHandler): void {
    this.handler = handler;
  }

  setSendingFlag(flag: boolean) {
    this.worker.postMessage({
      type: 'sendingFlag',
      data: {
        flag
      }
    });
  }

  public async open(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.worker.onmessage = event => {
        const ev = event.data;
        switch (ev.type) {
          case 'opened':
            this.bindEvents();
            resolve();
            break;
          case 'close':
            this.terminateWorker();
            reject();
            break;
        }
      };
      this.worker.postMessage({
        type: 'open',
      });
    });
  }

  private bindEvents() {
    const dispatcher = new ActionDispatcher({
      pre: ev => this.handler.pre(ev.data.pre),
      post: ev => this.handler.post(ev.data.post),
      state: ev => this.emitter.emit('state', ev.data.state),
      packet: _ => this.emitter.emit('packet'),
      close: ev => this.emitter.emit('close', ev.data.event),
      timeout: _ => this.emitter.emit('timeout'),
      error: ev => this.emitter.emit('error', ev.data.e),
      requestTermination: () => this.terminateWorker()
    });
    window.onbeforeunload = () => {
      this.worker.postMessage('close');
    };
    this.worker.onmessage = event => {
      const ev = event.data;
      dispatcher.dispatch({
        event: ev.type,
        ...ev
      });
    };
  }

  public close(): void {
    this.worker.postMessage({
      type: 'close'
    });
    // do not terminate immediately, wait for the worker to finish its cleanup and post message requestTermination
  }

  protected terminateWorker() {
    this.worker.terminate();
  }

  public send(json: any): boolean {
    this.worker.postMessage({
      type: 'send',
      data: {
        message: json
      }
    });
    return true;
  }
}

export class DirectConnectionSingleton extends DirectConnection {
  private static instance: DirectConnectionSingleton;

  static newInstance(url: string, options): DirectConnectionSingleton {
    if (this.instance) {
      this.instance.terminateWorker();
    }
    
    this.instance = new DirectConnectionSingleton(url, options);
    return this.instance;
  }
}