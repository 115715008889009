import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StellaNamesStore, StellaNamesState } from './stellaNames.store';


@Injectable({ providedIn: 'root' })
export class StellaNamesQuery extends QueryEntity<StellaNamesState> {

  constructor(protected store: StellaNamesStore) {
    super(store);
  }
}
