import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { from, of, Observable, defer } from 'rxjs';
import { catchError, map, tap, delay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { GameService, GameDescription, GameServiceLocalizedString, TimeSpan } from '@egzotech/egzotech-game-service';

@Injectable({
  providedIn: 'root'
})
export class GamesService {
  private service: GameService;
  private serviceUrl: string;

  constructor(
    private readonly env: EnvironmentService,
    private readonly translation: TranslateService
  ) {
    this.serviceUrl = this.env.get('gamesUrl');
    this.service = new GameService({ url: this.env.get('gamesUrl') });
  }

  getGames(): Observable<GameService['games']> {
    // return of({});
    return defer(() => of(this.service.games))
      .pipe(
        catchError(_ => from(this.service.fetch())
            .pipe(
              catchError(err => of({ message: err, errors: this.service.errors })),
              map((val: any) => {
                // if(!val || val.errors) {
                //   return {};
                // }
                return this.service.games;
              }),
              tap(g => this.addTranslations(g))
            )
        ));
  }

  private addTranslations(games) {
    Object.values<GameDescription>(games).forEach(g => {
      for (const prop in g.description) {
        if (prop === "*") {
          this.translation.setTranslation('en', {
            descriptions: {
              [g.id]: g.description['*']
            }
          }, true);
        }
        else {
          this.translation.setTranslation(prop, {
            descriptions: {
              [g.id]: g.description[prop]
            }
          }, true);
        }
      }
    });
  }

  getResourceUrl(game: string | GameDescription, resource: string, dir: string = '') {
    let foundGame: GameDescription;

    if (typeof game === 'object') {
      foundGame = Object.entries(this.service.games).find(([_, value]) => value === game)[1];
    } else {
      foundGame = this.service.games[game];
    }

    if (!foundGame) {
      throw new Error('Specified game does not have an entry in the game service');
    }

    return `${this.service.url}/${foundGame.path}/${dir ? dir + "/" : ""}${resource}`;
  }

  getLocalizedString(str: GameServiceLocalizedString) {
    if (this.translation.currentLang === 'en') {
      return str['*'];
    } else if (!str[this.translation.currentLang]) {
      return str['*'];
    } else {
      return str[this.translation.currentLang];
    }
  }

  getMinutesFromTimespan(timespan: string) {
    const miliseconds = TimeSpan.fromString(timespan);

    return miliseconds / 1000 / 60;
  }
}
