<mat-dialog-content class="upgrade-dialog">
    <h2>{{ 'stella.upgradeTitle' | translate }} </h2>
    <div class="row">
      <img class="warning" src="/assets/png/warning_iso.png">
      <div class="column">
        <p class="second">
          {{ message | translate }} <span class="bold">{{ 'stella.automaticStart' | translate }}</span>
        </p>
        <p class="second">
          <span class="bold">{{ 'stella.contactWithSupport' | translate }}</span>
        </p>
      </div>
    </div>
    <div *ngIf="allowUserClose" class="actions-container">
      <button class="outline-button" mat-ripple (click)="ok()">
        {{ 'common.close' | translate }}
      </button>
    </div>
  </mat-dialog-content>
