<div class="labels-container">
  <div class="infos">
    <div class="configuration-info" *ngFor="let step of steps; let i = index">
      <div class="time-setter" [ngClass]="{'disabled': blockEditingDefaultValues || isAlreadySet}">
        <div class="time-setter--title">
          {{ "biofeedback.repetitions" | translate }}
        </div>
        <div class="time-setter--buttons">
          <button
            mat-icon-button
            (click)="removeValue('repetitions', i)"
            [disabled]="step.repetitions === 1"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <p>{{ step.repetitions }}<span></span></p>
          <button mat-icon-button (click)="addValue('repetitions', i)" [disabled]="step.repetitions === 20">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="time-setter" [ngClass]="{'disabled': blockEditingDefaultValues || isAlreadySet}">
        <div class="time-setter--title">
          {{ "biofeedback.initialRelaxationTime" | translate }}
        </div>
        <div class="time-setter--buttons">
          <button
            mat-icon-button
            (click)="removeValue('startRelaxDuration', i)"
            [disabled]="step.startRelaxDuration === 1"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <p>{{ step.startRelaxDuration }}<span> {{ 'common.units.seconds' | translate }}</span></p>
          <button mat-icon-button (click)="addValue('startRelaxDuration', i)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="time-setter" [ngClass]="{'disabled': blockEditingDefaultValues || isAlreadySet}">
        <div class="time-setter--title">
          {{ "biofeedback.workTime" | translate }}
        </div>
        <div class="time-setter--buttons">
          <button
            mat-icon-button
            (click)="removeValue('workTime', i)"
            [disabled]="step.workTime === 1"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <p>{{ step.workTime }}<span> {{ 'common.units.seconds' | translate }}</span></p>
          <button mat-icon-button (click)="addValue('workTime', i)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="time-setter" [ngClass]="{'disabled': blockEditingDefaultValues || isAlreadySet}">
        <div class="time-setter--title">
          {{ "biofeedback.restTime" | translate }}
        </div>
        <div class="time-setter--buttons">
          <button
            mat-icon-button
            (click)="removeValue('restTime', i)"
            [disabled]="step.restTime === 1"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <p>{{ step.restTime }}<span> {{ 'common.units.seconds' | translate }}</span></p>
          <button mat-icon-button (click)="addValue('restTime', i)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <div class="time-setter" [ngClass]="{'disabled': blockEditingDefaultValues || isAlreadySet}">
        <div class="time-setter--title">
          {{ "biofeedback.threshold" | translate }}
        </div>
        <div class="time-setter--buttons">
          <button
            mat-icon-button
            (click)="removeValue('threshold', i, 0.1)"
            [disabled]="step.threshold * 100 <= 1"
          >
            <mat-icon>remove</mat-icon>
          </button>
          <p>{{ step.threshold * 100 | number: "1.0-0" }}%<span></span></p>
          <button
            mat-icon-button
            (click)="addValue('threshold', i, 0.1)"
            [disabled]="step.threshold * 100 >= 99"
          >
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
      <button mat-icon-button class="remove-button" *ngIf="step.removable" (click)="removeStep(i)">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
  <div class="buttons">
      <button mat-button *ngIf="!blockEditingDefaultValues || isAlreadySet" class="add-button" (click)="addStep()">
        {{ "diagnostic.add_step" | translate }}
      </button>
      <div class="play-button" (click)="startTraining.emit(steps)">
        {{ "diagnostic.start" | translate }}
      </div>
    </div>
</div>
