<mat-dialog-content>
  <div class="title-row">
    <h2 class="setting-header">{{ "stimSetting.title" | translate }}</h2>
    <div class="outline-button" (click)="confirm()">
      {{ "training.acceptSettings" | translate }}
    </div>
  </div>
  <div class="controls-row" *ngIf="program.adjustable" [ngClass]="{'disabled': isAlreadySet}">
    <mat-form-field *ngIf="program.adjustable.duration">
      <mat-label>{{ "stimSetting.treatmentDuration" | translate }}</mat-label>
      <mat-select
        [value]="program.details.duration"
        (selectionChange)="changeVal($event, 'duration')"
      >
        <mat-select-trigger>
          {{ program.details.duration / (60 * 1e6) }} min
        </mat-select-trigger>
        <mat-option
          *ngFor="let duration of program.adjustable.duration"
          [value]="duration"
          innerHtml="{{ duration / (60 * 1e6) }} min"
        >
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="program.adjustable.pulseDuration">
      <mat-label>{{ "stimSetting.pulseDuration" | translate }}</mat-label>
      <mat-select
        [value]="program.details.pulseDuration"
        (selectionChange)="changeVal($event, 'pulseDuration')"
      >
        <mat-select-trigger>
          {{ program.details.pulseDuration }} us
        </mat-select-trigger>
        <mat-option
          *ngFor="let duration of program.adjustable.pulseDuration"
          [value]="duration"
          innerHtml="{{ duration }} &#181;s"
        >
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="program.adjustable.repetitions">
      <mat-label>{{ "stimSetting.repetitions" | translate }}</mat-label>
      <mat-select
        [value]="program.details.repetitions"
        (selectionChange)="changeVal($event, 'repetitions')"
      >
        <mat-select-trigger>
          {{ program.details.repetitions }}
        </mat-select-trigger>
        <mat-option
          *ngFor="let rep of program.adjustable.repetitions"
          [value]="rep"
          innerHtml="{{ rep }}"
        >
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="program.adjustable.frequency">
      <mat-label>{{ "stimSetting.frequency" | translate }}</mat-label>
      <mat-select
        [value]="program.details.frequency"
        (selectionChange)="changeVal($event, 'frequency')"
      >
        <mat-select-trigger>
          {{ program.details.frequency }} Hz
        </mat-select-trigger>
        <mat-option
          *ngFor="let fq of program.adjustable.frequency"
          [value]="fq"
          innerHtml="{{ fq }} Hz"
        >
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="program.adjustable.rampUp">
      <mat-label>{{ "stimSetting.rampUp" | translate }}</mat-label>
      <mat-select
        [value]="program.details.rampUp"
        (selectionChange)="changeVal($event, 'rampUp')"
      >
        <mat-select-trigger>
          {{ program.details.rampUp / 1000000 }} s
        </mat-select-trigger>
        <mat-option
          *ngFor="let rup of program.adjustable.rampUp"
          [value]="rup"
          innerHtml="{{ rup / 1000000 }} s"
        >
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="program.adjustable.rampDown">
      <mat-label>{{ "stimSetting.rampDown" | translate }}</mat-label>
      <mat-select
        [value]="program.details.rampDown"
        (selectionChange)="changeVal($event, 'rampDown')"
      >
        <mat-select-trigger>
          {{ program.details.rampDown / 1000000 }} s
        </mat-select-trigger>
        <mat-option
          *ngFor="let rd of program.adjustable.rampDown"
          [value]="rd"
          innerHtml="{{ rd / 1000000 }} s"
        >
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="program.adjustable.workTime">
      <mat-label>{{ "stimSetting.workTime" | translate }}</mat-label>
      <mat-select
        [value]="program.details.workTime"
        (selectionChange)="changeVal($event, 'workTime')"
      >
        <mat-select-trigger>
          {{ program.details.workTime / 1000000 }} s
        </mat-select-trigger>
        <mat-option
          *ngFor="let wt of program.adjustable.workTime"
          [value]="wt"
          innerHtml="{{ wt / 1000000 }} s"
        >
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="program.adjustable.restTime">
      <mat-label>{{ "stimSetting.restTime" | translate }}</mat-label>
      <mat-select
        [value]="program.details.restTime"
        (selectionChange)="changeVal($event, 'restTime')"
      >
        <mat-select-trigger>
          {{ program.details.restTime / 1000000 }} s
        </mat-select-trigger>
        <mat-option
          *ngFor="let rt of program.adjustable.restTime"
          [value]="rt"
          innerHtml="{{ rt / 1000000 }} s"
        >
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle
      *ngIf="program.adjustable.warmup"
      [checked]="program.details.warmup"
      (change)="changeVal({ value: $event.checked }, 'warmup')"
    >
      {{ "stimSetting.warmup" | translate }}
    </mat-slide-toggle>
    <mat-slide-toggle
      *ngIf="program.adjustable.cooldown"
      [checked]="program.details.cooldown"
      (change)="changeVal({ value: $event.checked }, 'cooldown')"
    >
      {{ "stimSetting.cooldown" | translate }}
    </mat-slide-toggle>
  </div>
  <div class="deny-button--container">
    <button mat-button class="deny-button" (click)="deny()">
      {{ "training.back_to_muscle" | translate }}
    </button>
  </div>
</mat-dialog-content>
