import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'electrostimBodyFilter'
})
export class ElectrostimBodyFilterPipe implements PipeTransform {

  transform(value: any[], part: any): any[] {
    if (part.value === 'all') {
      return value;
    }
    return value.filter(v => {
      return part ? v.part === part.value : true;
    });
  }

}
