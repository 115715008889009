import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { StellaCareService } from '@app/shared/services/stella-care.service';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseDialogComponent } from '@app/components/purchase-dialog/purchase-dialog.component';
import { RouterExtService } from '@app/shared/services/router-ext.service';
import { Pages } from '@app/pages';

@Injectable({
  providedIn: 'root'
})
export class CanActivateFeatureGuard implements CanActivate {

  constructor(
    private readonly routerExt: RouterExtService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly stellaCare: StellaCareService) {

  }
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    let mod;
    if (next.data.moduleSource === 'direct') {
      mod = next.data.module;
    } else if (next.data.moduleSource === 'param') {
      const suffix = next.params[next.data.paramName];
      mod = `${next.data.module}.${suffix}`;
    }

    if (!this.stellaCare.isVisible(mod)) {
      if (!this.routerExt.getPreviousUrl()) {
        await this.router.navigate([Pages.STELLA_CONNECT]);
      }
      return false;
    }

    if (!this.stellaCare.isAllowed(mod)) {
      if (!this.routerExt.getPreviousUrl()) {
        await this.router.navigate([Pages.STELLA_CONNECT]);
      }
      this.dialog.open(PurchaseDialogComponent, {
        data: {
          module: mod
        }
      });
      return false;
    }

    return true;
  }
}
