<mat-dialog-content>
  <h2>{{ "purchase.notAllowedPurchase" | translate }}</h2>
  <h2 class="subtitle">{{ "purchase.notAllowedPurchase2" | translate }}</h2>

  <div class="packages">
    <div
      matRipple
      class="package"
      *ngFor="let pckg of missingPackages"
    >
      <img class="package--icon" [src]="pckg.icon" />
      <p>{{ pckg.name }}</p>
    </div>
  </div>
  <h2 class="subtitle">{{ "purchase.additionalInfo" | translate }}</h2>
  <div class="actions-container">
    <button class="outline-button" mat-ripple (click)="cancel()">
      {{ "common.ok" | translate }}
    </button>
    <button class="outline-button" mat-ripple (click)="goToStore()">
      {{ "purchase.goToStore" | translate }}
    </button>
  </div>
</mat-dialog-content>
