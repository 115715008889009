<mat-dialog-content>
  <div class="title-row">
    <h2 class="setting-header">
      {{ "stimSetting.emergency_warning" | translate }}
    </h2>
  </div>

  <div class="row">
    <img class="warning" src="/assets/png/warning_iso.png" />
    <div class="advices">
      <p>{{ "stimSetting.emergency_advice" | translate }}</p>
      <p>{{ "stimSetting.confirm_to_handle" | translate }}</p>
    </div>
  </div>

  <div class="dialog-actions">
    <div class="outline-button" (click)="cancel()">
      {{ "common.exit" | translate }}
    </div>
    <div class="outline-button" (click)="confirm()">
      {{ "common.confirm" | translate }}
    </div>
  </div>
</mat-dialog-content>
