import { Component, Input, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'sba-version-date',
  templateUrl: './version-date.component.html',
  styleUrls: ['./version-date.component.scss']
})
export class VersionDateComponent {

  @Input() theme: 'dark' | 'light' = 'light';
  version = environment.version;
  lastUpdate = environment.lastUpdate;
  constructor() { }
}
