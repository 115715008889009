import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Exercise } from '@app/models/Exercise.model';

export interface ExercisesState extends EntityState<Exercise> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'exercises', resettable: true })
export class ExercisesStore extends EntityStore<ExercisesState> {
  constructor() {
    super();
  }
}

