import { Pipe, PipeTransform } from '@angular/core';
import { getColorForChannel } from '@app/utils/ColorDictionary';

@Pipe({
  name: 'channelcolor'
})
export class ChannelBadgePipe implements PipeTransform {
  transform(value: number, alpha?: number): any {
    return getColorForChannel(value).alpha(alpha!==undefined ? alpha : 1);
  }
}
