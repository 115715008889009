import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { DEV } from '@app/electrostimTests';
import { environment } from '@env/environment';
import { templateOverride, templateOverrideAdditional } from '@app/templates/services/templates-override';

export enum FeatureState {
  HIDDEN = 'hidden',
  DISABLED = 'disabled',
  ENABLED = 'enabled'
}
export const AVAILAIBLE_DIAGNOSTIC_TESTS = [
  {
    id: 'view',
    label: 'EMG View'
  },
  {
    id: 'biofeedback',
    label: 'Biofeedback'
  },
  {
    id: 'biofeedback_5-5',
    label: '5-5'
  },
  {
    id: 'biofeedback_10-10',
    label: '10-10'
  },
  {
    id: 'endurance',
    label: 'Endurance'
  },
  {
    id: 'quick_flicks',
    label: 'Quick Flicks'
  },
  {
    id: 'low_back_pain',
    label: 'Low Back Pain'
  },
  {
    id: 'sorens',
    label: 'Sorens Pain'
  },
  {
    id: 'relaxation_analysis',
    label: 'EMG Relaxation Analysis'
  },
  {
    id: 'view_with_mvc',
    label: 'View with MVC'
  }
];

export const AVAILAIBLE_ELECTROSTIM_TESTS = [
  DEV
];

const AVAILAIBLE_GAMES = [
  {
    id: 'birburgers',
    label: 'Birgubergs'
  },
  {
    id: 'abordage',
    label: 'Abordage'
  },
];

@Injectable({
  providedIn: 'root'
})
export class StellaCareService {

  constructor(
  ) { }

  readonly ACL = {
    games: {
      state: FeatureState.ENABLED,
      createdAt: moment().subtract(2, 'd').toISOString()
    },
    'games.abordage': {
      state: FeatureState.ENABLED,
      createdAt: moment().subtract(2, 'd').toISOString()
    },
    'games.birburgers': {

      state: FeatureState.ENABLED,
      createdAt: moment().subtract(2, 'd').toISOString()
    },
    'stim.upperLimbsMuscleReeducation': {
      state: FeatureState.ENABLED,
      createdAt: moment().subtract(2, 'd').toISOString()
    },
    'stim.abdominalMuscleReeducation': {
      state: FeatureState.ENABLED,
      createdAt: moment().subtract(2, 'm').toISOString()
    },
    'diag.glazer': {
      state: FeatureState.ENABLED,
      createdAt: moment().subtract(2, 'm').toISOString()
    }
  };

  getStimList() {
    return AVAILAIBLE_ELECTROSTIM_TESTS
      .map(this.decorateStimProgram)
      .filter(g => this.filterOutHidden(g))
      .map(g => this.decorateWithBadges(g));
  }

  getGamesList() {
    return AVAILAIBLE_GAMES
      .map(this.decorateGame)
      .filter(g => this.filterOutHidden(g))
      .map(g => this.decorateWithBadges(g));
  }

  getDiagnosticList() {
    return AVAILAIBLE_DIAGNOSTIC_TESTS
    .filter(() => {
      return true;
    })
      .map(this.decorateDiagnostic)
      .filter(g => this.filterOutHidden(g))
      .map(g => this.decorateWithBadges(g));
  }

  filterOutHidden(g): boolean {
    if (!this.ACL[g.module]) { return true; }
    return this.ACL[g.module].state !== FeatureState.HIDDEN;
  }

  decorateWithBadges(g) {
    if (!this.ACL[g.module]) {
      return {
        ...g,
        // badges: ['free']
      };
    }
    return {
      ...g,
      badges: this.getBadges(g.module)
    };
  }

  decorateReportStat(ex) {
    const trainingPrefix = '/dashboard/trainings';
    if( Object.keys(ex.template).length === 0 ) {
      // FIXME
      // It should be simplified
      if( ex.name === 'view') {
        ex.template = templateOverride[ex.name];  
      }
      if( ex.name === 'relaxation_analysis' || ex.name === 'view_with_mvc') {
        ex.template = templateOverrideAdditional.find(item => item.name === ex.name)
      }
    }

    return {
      ...ex,
      path: `${trainingPrefix}/${this.createPathEnding(ex)}`
    };
  }

  decorateExercise(ex) {
    const trainingPrefix = '/dashboard/trainings';
    return {
      ...ex,
      path: `${trainingPrefix}/${this.createPathEnding(ex)}`,
      module: `module.${ex.name}`,
      icon: this.getIconUrl(ex),
      short: this.getShort(ex),
      id: ex.name,
      label: `exercises.${ex.name}`
    };
  }

  private getShort(ex) {
    if (ex.short) {
      if (ex.short.startsWith('descriptions.')) {
        return ex.short;
      }
      return `descriptions.${ex.short}`;
    }

    if( ex.steps.short ) {
      return `descriptions.${ex.steps.short}`;
    } else {
      return `descriptions.${ex.steps.steps.short}`;
    }
  }

  private getIconUrl(ex) {
    if (ex.icon) {
      return ex.icon;
    }
    if (ex.iconName) {
      return `${environment.assetsUrl}/${ex.iconName}`;
    }
    return ex.steps ? `${environment.assetsUrl}/${ex.steps.iconName}` : '';
  }

  private createPathEnding(ex): string {
    return {
      games: `games/${ex.name}`,
      electrostim: `electrostim/${ex.name}`,
      fes: `electrostim/${ex.name}`,
      diagnostic: `emg/${ex.name}`,
      custom: `emg/${ex.name}`
    }[ex.type];
  }

  decorateGame(g: any) {
    return {
      ...g,
      path: `/dashboard/trainings/games/${g.name}`,
      module: `games.${g.name}`,
      icon: `${environment.assetsUrl}/${g.steps.iconName}`,
    };
  }

  decorateDiagnostic(g ) {
    if (g.id === 'custom') {
      return {
        ...g,
        path: `/dashboard/trainings/emg/biofeedback`,
        module: `diag.${g.id}`,
        icon: `${environment.assetsUrl}/${g.steps.iconName}`
      };
    }
    return {
      ...g,
      path: `/dashboard/trainings/emg/${g.id}`,
      module: `diag.${g.id}`,
      icon: `${environment.assetsUrl}/${g.steps.iconName}`
    };
  }


  decorateStimProgram(g: any) {
    return {
      ...g,
      path: `/dashboard/trainings/electrostim/${g.name}`,
      module: `stim.${g.name}`,
      icon: `${environment.assetsUrl}/${g.steps.iconName}`
    };
  }

  isVisible(mod: string): boolean {
    if (!this.ACL[mod]) { return true; }
    return this.ACL[mod].state !== FeatureState.HIDDEN;
  }

  isAllowed(mod: string): boolean {
    if (!this.ACL[mod]) { return true; }
    return this.ACL[mod].state === FeatureState.ENABLED;
  }

  getBadges(mod: string): string[] {
    const badgesArray: string[] = [];
    // if (this.isNew(mod)) { badgesArray.push('new') }
    // if (this.ACL[mod].state === FeatureState.DISABLED) { badgesArray.push('locked'); }
    return badgesArray;
  }

  private isNew(mod: string): boolean {
    return moment(this.ACL[mod].createdAt).isAfter(moment().subtract(1, 'month'));
  }
}
