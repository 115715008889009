import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@app/auth/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { ElectrostimWarningDialoComponent } from '../components/electrostim-warning-dialog/electrostim-warning-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CanStartElectrostimGuardGuard implements CanActivate {
  constructor(
    private readonly dialog: MatDialog,
    private readonly authService: AuthService) { }
  canActivate(_): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.currentUser.stimRiskAccepted) {
      return this.dialog.open(ElectrostimWarningDialoComponent, {
        width: '700px'
      })
        .afterClosed()
    }
    return true;
  }
}
