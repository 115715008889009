import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'sba-calibration-dialog',
  templateUrl: './calibration-dialog.component.html',
  styleUrls: ['./calibration-dialog.component.scss']
})
export class CalibrationDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CalibrationDialogComponent>
  ) {
    dialogRef.disableClose = true;
  }
}
