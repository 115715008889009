import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/auth/services/auth.service';

@Component({
  selector: 'sba-end-training-dialog',
  templateUrl: './electrostim-warning-dialog.component.html',
  styleUrls: ['./electrostim-warning-dialog.component.scss']
})
export class ElectrostimWarningDialoComponent {

  inProgress: boolean;
  buttons = [];

  constructor(
    private readonly authService: AuthService,
    public dialogRef: MatDialogRef<ElectrostimWarningDialoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  confirm() {
    this.authService.updateProfile({ stimRiskAccepted: true})
    .subscribe(() => {
      this.dialogRef.close(true);
    });
  }

  deny() {
    this.dialogRef.close(false);
  }
}
