import { ChannelBadgeComponent } from './channel-badge/channel-badge.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ChannelBadgePipe } from './channel-badge.pipe';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { AutosizeDirective } from './directives/autosize.directive';
import { DurationPipe } from './duration.pipe';
import { ErrorPipe } from './error.pipe';
import { GenderSelectorComponent } from './gender-selector/gender-selector.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { LoadableButtonComponent } from './loadable-button/loadable-button.component';
import { LoadingOrErrorComponent } from './loading-or-error/loadingOrError.component';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { NewClinicDialogComponent } from './new-clinic-dialog/new-clinic-dialog.component';
import { TileComponent } from './tile/tile.component';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { VersionDateComponent } from './version-date/version-date.component';
import { LoggerConsoleComponent } from './logger-console/logger-console.component';
import { ASCIIPipe } from './ascii.pipe';
import { SafeHtmlSanitizedPipe } from './safe-html-sanitized.pipe';
import { PurchaseDialogComponent } from './purchase-dialog/purchase-dialog.component';
import { PurchaseReadyComponent } from './purchase-ready/purchase-ready.component';
import { ScaleSelectorComponent } from './scale-selector/scale-selector.component';
import { UnitSelectorComponent } from './unit-selector/unit-selector.component';
import { VersionDialogComponent } from './version-dialog/version-dialog.component';
import { ConditionsOfUseComponent } from './conditions-of-use/conditions-of-use.component';
import { PrivacyNoticeComponent } from './privacy-notice/privacy-notice.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { AcceptInvitationComponent } from './accept-invitation/accept-invitation.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DebugPipe } from './debug.pipe';
import { ShowMoreParagraphComponent } from './show-more-paragraph/show-more-paragraph.component';
import { LocalFunctionPipe } from './local-function.pipe';
import { WaitDotsComponent } from './wait-dots/wait-dots.component';
import { MarkdownMessageDialogComponent } from './markdown-message-dialog/markdown-message-dialog.component';
import { MarkdownModule } from 'ngx-markdown';
import { ClickCounterDirective } from './click-counter.directive';
import { TapingIconsComponent } from './taping-icons/taping-icons.component';
import { ElectrostimBodyFilterPipe } from './electrostim-body-filter.pipe';
import { SingleMuscleComponent } from './single-muscle/single-muscle.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UniversalLoaderComponent } from './universal-loader/universal-loader.component';
import { UpgradeDialogComponent } from './upgrade-dialog/upgrade-dialog.component';
import { CalibrationDialogComponent } from './calibration-dialog/calibration-dialog.component';
import { LongPressDirective } from './long-press.directive';
import { PageTitleComponent } from './page-title/page-title.component';
import { LowBatteryLevelDialogComponent } from './low-battery-level-dialog/low-battery-level-dialog.component';
import { ChangelogDialogComponent } from './changelog-dialog/changelog-dialog.component';
import { NoInternetDialogComponent } from './no-internet-dialog/no-internet-dialog.component';
import { UploadingDataDialogComponent } from './uploading-data-dialog/uploading-data-dialog.component';
import { InitializeExerciseDialogComponent } from './initializing-exercise-dialog/initialize-exercise-dialog.component';
import { UnknownErrorDialogComponent } from './unknown-error-dialog/unknown-error-dialog.component';
import { ResendEmailDialogComponent } from './resend-email-dialog/resend-email-dialog.component';
import { ChannelSelectorComponent } from '@app/components/channel-selector/channel-selector.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  entryComponents: [
    NewClinicDialogComponent,
    ConfirmationDialogComponent,
    MessageDialogComponent,
    MarkdownMessageDialogComponent,
    PurchaseDialogComponent,
    UpgradeDialogComponent,
    ChangelogDialogComponent,
    CalibrationDialogComponent,
    LowBatteryLevelDialogComponent,
    ResendEmailDialogComponent
  ],
  declarations: [
    ChannelSelectorComponent,
    ScaleSelectorComponent,
    UnitSelectorComponent,
    ChannelBadgeComponent,
    NewClinicDialogComponent,
    AutosizeDirective,
    UserAvatarComponent,
    GenderSelectorComponent,
    ErrorPipe,
    ChannelBadgePipe,
    ConfirmationDialogComponent,
    MessageDialogComponent,
    ChangelogDialogComponent,
    MarkdownMessageDialogComponent,
    VersionDateComponent,
    DurationPipe,
    LoadingOrErrorComponent,
    LoadableButtonComponent,
    LanguageSelectorComponent,
    TileComponent,
    LoggerConsoleComponent,
    ASCIIPipe,
    SafeHtmlSanitizedPipe,
    PurchaseDialogComponent,
    PurchaseReadyComponent,
    VersionDialogComponent,
    ConditionsOfUseComponent,
    PrivacyNoticeComponent,
    NavigationMenuComponent,
    AcceptInvitationComponent,
    CheckboxComponent,
    DebugPipe,
    ShowMoreParagraphComponent,
    LocalFunctionPipe,
    WaitDotsComponent,
    ClickCounterDirective,
    TapingIconsComponent,
    ElectrostimBodyFilterPipe,
    SingleMuscleComponent,
    UniversalLoaderComponent,
    UpgradeDialogComponent,
    CalibrationDialogComponent,
    LongPressDirective,
    PageTitleComponent,
    LowBatteryLevelDialogComponent,
    NoInternetDialogComponent,
    UploadingDataDialogComponent,
    InitializeExerciseDialogComponent,
    UnknownErrorDialogComponent,
    ResendEmailDialogComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    PerfectScrollbarModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    TranslateModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MarkdownModule.forRoot()
  ],
  exports: [
    ChannelSelectorComponent,
    ScaleSelectorComponent,
    UnitSelectorComponent,
    NewClinicDialogComponent,
    AutosizeDirective,
    UserAvatarComponent,
    GenderSelectorComponent,
    ErrorPipe,
    ChannelBadgePipe,
    ConfirmationDialogComponent,
    MessageDialogComponent,
    VersionDateComponent,
    ChangelogDialogComponent,
    DurationPipe,
    LoadingOrErrorComponent,
    LoadableButtonComponent,
    TranslateModule,
    LanguageSelectorComponent,
    TileComponent,
    LoggerConsoleComponent,
    PurchaseReadyComponent,
    ChannelBadgeComponent,
    VersionDialogComponent,
    ConditionsOfUseComponent,
    PrivacyNoticeComponent,
    NavigationMenuComponent,
    AcceptInvitationComponent,
    CheckboxComponent,
    DebugPipe,
    ShowMoreParagraphComponent,
    LocalFunctionPipe,
    WaitDotsComponent,
    ClickCounterDirective,
    TapingIconsComponent,
    ElectrostimBodyFilterPipe,
    SingleMuscleComponent,
    UniversalLoaderComponent,
    UpgradeDialogComponent,
    LongPressDirective,
    PageTitleComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class ComponentsModule { }
