// tslint:disable: no-string-literal
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { HubConnection } from './HubConnection';
import { Router } from '@angular/router';
import { AuthService } from "../../auth/services/auth.service";
import { Role } from '@app/models/Role.enum';
import { PatientsService } from '@app/clinics/services/patients.service';
import { ClinicsService } from '@app/clinics/services/clinics.service';
import { StellaDirectService } from './stella-direct.service';
import { StellaKioskService } from '@app/shared/services/stella-kiosk.service';

const AUTHED_MSG = 'AUTHED';
const CLOSED_MSG = 'CLOSED';

@Injectable({
  providedIn: 'root'
})
export class StellaConnectService {

  subject = new BehaviorSubject<any>(null);
  private connection: HubConnection = null;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly clinicsService: ClinicsService,
    private readonly patientsService: PatientsService,
    private readonly stellaDirectService: StellaDirectService,
    private readonly kioskService: StellaKioskService
  ) {}

  repeatList(): void {
    const val = this.subject.value;
    this.subject.next(val);
  }

  private getLocation(): Promise<any> {
    return new Promise(resolve => {
      if (!navigator.geolocation) {
        resolve(null);
      } else {
        navigator.geolocation.getCurrentPosition(data => {
          resolve(data.coords);
        }, _ => {
          resolve({});
        }, {
          timeout: 1000
        });
      }
    });

  }

  async openConnection(): Promise<void> {
    try {
      if (!this.connection) {
        console.log("Conecting to direct-hub:", environment.allowDirectHubOverride ? (this.kioskService.getDirectHubOverride() ?? environment.stellaAwareServerUrl) : environment.stellaAwareServerUrl);

        this.connection = HubConnection.newInstance(
          environment.allowDirectHubOverride
              ? (this.kioskService.getDirectHubOverride() ?? environment.stellaAwareServerUrl)
              : environment.stellaAwareServerUrl
        );

        // TODO: Do it better, not blocking
        // const geo = await this.getLocation();
        await this.connection.open();
        this.connection.subscribe(message => {
          if (message.event && message.event === CLOSED_MSG) {
            setTimeout(async () => {
              this.connection = null;
              this.openConnection();
            }, 1000);
          } else if (message.event && message.event === AUTHED_MSG) {
            const clinic = this.clinicsService.currentClinic();
            const patient = this.patientsService.currentPatient();
            const stella = this.stellaDirectService.currentStella;

            this.connection.send({
              application: {
                os: {
                  environment: environment.environment,
                  version: environment.version,
                  lastBuild: environment.lastUpdate
                },
                hardware: {
                  cores: navigator.hardwareConcurrency
                },
                geo: {
                  lat: 0, // geo.latitude,
                  long: 0, // geo.longitude,
                },
                origin: location.origin,
                page: this.router.url,
                user: {
                  id: this.authService.currentUser ? this.authService.currentUser.id : "<anonymous>",
                  role: this.authService.currentUser && this.authService.currentUser.role ?  Role[this.authService.currentUser.role] : "UNKNOWN",
                  lastNavigation: new Date().toISOString(),
                  clinic: clinic ? clinic.id : "<none>",
                  patient: patient ? (Array.isArray(patient) ? patient.map(v => v.id).join(", ") : patient.id) : "<none>"
                },
                focused: document.visibilityState,
                connectedDevice: stella?.token,
                chosenDevice: stella
              },
            }, false);
          } else {
            this.subject.next(message);
          }
        });
      }
    } catch (err) {
      console.log(err);
      // this.subject.error(err);
      setTimeout(async () => {
        this.connection = null;
        await this.openConnection();
      }, 1000);
    }
  }

  send(data: any) {
    if (this.connection) {
      this.connection.send(data, true);
    }
  }
}
