import { Injectable } from '@angular/core';
import { SmartledProgram } from '@app/enums';

const RED = 0x100000;
const GREEN = 0x001000;
const BLUE = 0x000010;
const STELLA = 0x345050;
// tslint:disable-next-line: no-bitwise
const WHITE = RED | GREEN | BLUE;
const OFF = 0x000000;

export class StaticLight {
  colorHolder: Uint32Array;
  constructor(
    private ledSize = 12
  ) {
    this.colorHolder = new Uint32Array(ledSize);
  }

  getColor(color: number): Uint32Array {
    for (let i = 0; i < this.ledSize; i++) {
      this.colorHolder[i] = color;
    }

    return this.colorHolder;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SmartledFactoryService {

  constructor() { }

  getLed(program: SmartledProgram) {
    switch (program) {
      case SmartledProgram.OFF:
        return new StaticLight().getColor(OFF);
      case SmartledProgram.STATIC_RED:
        return new StaticLight().getColor(RED);
      case SmartledProgram.STATIC_GREEN:
        return new StaticLight().getColor(GREEN);
      case SmartledProgram.STATIC_WHITE:
        return new StaticLight().getColor(WHITE);
      case SmartledProgram.STATIC_STELLA:
        return new StaticLight().getColor(STELLA);
    }
  }
}

