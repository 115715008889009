<sba-page-title [title]="'concepts.' + concept" [translate]="true"></sba-page-title>
<div class="diagnostic-list">
  <div class="title-row">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>{{ "concepts." + concept | translate }}</h2>
  </div>
  <div class="diagnostic-list--content" *ngIf="programStream$ | async as list">
    <sba-programs-grouped
      [programs]="list"
      (programSelected)="openProgram($event)"
    ></sba-programs-grouped>
  </div>
</div>
