import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StellaKioskService {

  constructor() { }

  isKioskMode(): boolean {
    const egzotechHostApi = (window as any).egzotechHostApi;

    return (egzotechHostApi && egzotechHostApi.type === 'stella-app-kiosk');
  }

  showRegisterDevicesPopup(currentLang: string) {
    const egzotechHostApi = (window as any).egzotechHostApi;

    egzotechHostApi.showRegisterDevicesPopup(currentLang);
  }

  getDirectHubOverride() {
    const egzotechHostApi = (window as any).egzotechHostApi;
    const params = new URLSearchParams(window.location.search);
    let paramsOverride = params.get("directHubUrlOverride") ?? localStorage.getItem("DIRECT_HUB_URL_OVERRIDE");

    if (paramsOverride) {
      window.localStorage.setItem("DIRECT_HUB_URL_OVERRIDE", paramsOverride);
    }

    if (params.get("directHubUrlOverride")) {
      location.href = location.origin;
    }

    return paramsOverride ?? egzotechHostApi?.overrides?.directHubUrl;
  }

}
