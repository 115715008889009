import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BatteryService } from '@app/shared/services/low-battery.service';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';

@Component({
  selector: 'sba-low-battery-level-dialog',
  templateUrl: './low-battery-level-dialog.component.html',
  styleUrls: ['./low-battery-level-dialog.component.scss']
})
export class LowBatteryLevelDialogComponent implements OnInit, OnDestroy {

  constructor(
    public dialogRef: MatDialogRef<LowBatteryLevelDialogComponent>,
    private readonly stella: StellaDirectService,
    private batteryService: BatteryService,
    @Inject(MAT_DIALOG_DATA) public readonly data: any) {
  }

  ngOnInit(): void {
    this.batteryService.updateStatus('LOW');
  }

  ngOnDestroy(): void {
    this.batteryService.updateStatus('OK');
  }

  exit() {
    this.stella.state.modify({ bat: { notified:  true}});
    this.dialogRef.close(true);
  }
}
