<div class="level-control-component" style="min-width: 350px;">
  <div class="badges">
    <div class="badge-container" *ngFor="let ch of _channels">
      <div
        class="badge {{ ch === currentChannelSelected ? 'active' : '' }}"
        [ngStyle]="{ backgroundColor: ch | channelcolor }"
        (click)="changeSelectedChannel(ch)"
      >
        {{ ch + 1 }}
      </div>
      <p>{{ levels[ch] }} {{ 'common.units.miliamperes' | translate }}</p>
    </div>
  </div>
  <sba-electro-slider [lvl]="levels[currentChannelSelected]" [range]="range">
  </sba-electro-slider>
  <div class="buttons-container" *ngIf="!disallowIntensityChange">
    <div class="button--column">
      <button mat-fab (click)="modifyLevel(-1)">
        <mat-icon>exposure_neg_1</mat-icon>
      </button>
      <p class="button--label">
        {{ "electrostim.decrementLevel" | translate }}
      </p>
    </div>
    <div class="button--column">
      <button [ngClass]="{'disabled': !canIncrement}"mat-fab (click)="modifyLevel(1)" [disabled]="!canIncrement">
        <mat-icon>exposure_plus_1</mat-icon>
      </button>
      <p class="button--label">
        {{ "electrostim.incrementLevel" | translate }}
      </p>
    </div>
  </div>
</div>
