import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BodyModel, getMusclesForProgram, IconModel } from '@app/bodyModels';
import { ConnectionQuality } from '@app/enums';
import { ColorService } from '@app/shared/services/color.service';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { CLEAR_STIM_FLAG } from '@app/stella/services/StellaCommands';
import { CableDetails, ChannelMuscleMapperValidEvent, IBodyPart, PARTS, SelectedMuscle } from '@app/types';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, takeUntil, throttleTime } from 'rxjs/operators';
import { GTagService, AnalyticsAction, AnalyticsCategory } from '@app/shared/gtag.service';
import { validChannels } from '@app/training/ChannelResolver';
import { MatDialog } from '@angular/material';
import { getColorForChannel } from '@app/utils/ColorDictionary';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { ProgramConfiguration } from '@app/utils/electrostim/ProgramConfiguration';
import { ProgramController } from '@app/utils/electrostim/ProgramController';
import { cableTypes } from '@app/utils/electrostim/CableType';
import { isProgramViewType } from '@app/utils/utils';

class ConncectionStatus {
  enoughChannelsChoosen: ConnectionQuality | null;
  connectedChannelsChoosen: ConnectionQuality | null;
  electrodesConnection: ConnectionQuality | null;
  enoughChannelsInCable: ConnectionQuality | null;
  requiredPelvicChannel: ConnectionQuality | null;
}

@Component({
  selector: 'sba-channel-selector-muscle-mapper',
  templateUrl: './channel-selector-muscle-mapper.component.html',
  styleUrls: ['./channel-selector-muscle-mapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelSelectorMuscleMapperComponent implements OnInit, OnDestroy {

  PARTS = PARTS;
  selectedBodyPart: IBodyPart = PARTS[0];
  steps: any[] = [];
  @Output() isValid = new EventEmitter<ChannelMuscleMapperValidEvent>();
  @Input() alreadyStarted = false;
  @Input() set program(val) {
    this._program.next(val);
    if (val) {
      this.allMuscles = getMusclesForProgram(val, localStorage.getItem('CURRENT_CONCEPT'));
      if (val.steps.details && val.steps.details.channels > 1) {
        this.cableRequirements = {
          slug: 'channelMuscleMapper.cableWithChannels',
          value: val.steps.details.channels,
          satisfied: ((this.cable || { channels: [] }).channels).length >= val.steps.details.channels
        };
      }
      if (val.steps.incontinence) {
        this.pelvicRequirements = {
          slug: 'channelMuscleMapper.hasInternalChannels',
          value: '',
          satisfied: ((this.cable || { internal: [] }).internal).length,
          connected: false
        };
      }
    }
    this.cdr.detectChanges();
  }

  @Input()
  stimConfig: ProgramConfiguration;

  _program = new BehaviorSubject<any>(null);

  programController: ProgramController = new ProgramController();
  nextActive: SelectedMuscle;
  otherChannels: SelectedMuscle[] = [];
  availableChannels: SelectedMuscle[] = [];
  currentStep = 0;
  unsubscribe$ = new Subject<void>();
  cable: CableDetails;
  allMuscles = [];
  cableRequirements: any;
  pelvicRequirements: any;
  connectionStatus: ConncectionStatus = {
    enoughChannelsChoosen:  ConnectionQuality.NONE,
    connectedChannelsChoosen:  ConnectionQuality.POOR,
    electrodesConnection:  ConnectionQuality.NONE,
    enoughChannelsInCable:  ConnectionQuality.NONE,
    requiredPelvicChannel: null
  };
  isPelvicChannelRequired: boolean;

  constructor(
    public readonly colorService: ColorService,
    private readonly stellaDirect: StellaDirectService,
    private readonly location: Location,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly gtag: GTagService,
    private readonly dashboard: DashboardService
  ) {

  }

  initializeNextActive() {
    const isPelvic = localStorage.getItem('CURRENT_CONCEPT') === 'pelvic';
    if (!isPelvic) {
      this.nextActive = this.otherChannels.find(ff => (!ff.bodyModel)) ?? this.availableChannels[0];
    } else {
      const selectedUnassignedChannel = this.otherChannels.find(ff => (!ff.bodyModel && ff.channel >= 6)) ?? this.otherChannels.find(ff => !ff.bodyModel)
      this.nextActive = selectedUnassignedChannel ?? (this.availableChannels.find(ch => ch.channel >= 6) || this.availableChannels[0]);
    }
  }

  requireReference(program): boolean {
    if (['fes', 'electrostim'].includes(program.type) && program.steps.details.emgTriggered) {
      return true;
    }
    if (['diagnostic', 'custom', 'games'].includes(program.type)) {
      return true;
    }

    return false;
  }

  ngOnInit() {
    let cableChannels = this.stellaDirect.state.getCable().channels

    if (this.stimConfig) {
      this.programController.setConfiguration(this.stimConfig);
      this.programController.setCable(cableTypes[this.stellaDirect.state.getCable().code]);

      cableChannels = this.programController.availableChannels;
    }

    this.availableChannels = cableChannels
        .filter(ch => !this.steps.find(s => s.channel === ch))
        .map(ch => ({
          channel: ch,
          bodyModel: null,
          selected: false,
          side: null,
          purpose: "primary"
        }));
    this.initializeNextActive();
    this.stellaDirect.cable$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(s => Boolean(s))
      )
      .subscribe(cable => {
        this.cable = cable;
        const exercise = this.dashboard.exercise;

        this.otherChannels = exercise && exercise.muscles ? exercise.muscles : [];

        let cableChannels = cable.channels

        if (this.stimConfig) {
          this.programController.setCable(cableTypes[cable.code]);
          cableChannels = this.programController.availableChannels;
        }
        const hasPelvicMuscles = this.allMuscles.find(m => m.part === 'pelvic');
        const hasNonPelvicMuscles = this.allMuscles.find(m => m.part !== 'pelvic');

        if (this.pelvicRequirements && hasPelvicMuscles && hasNonPelvicMuscles) {
          this.isPelvicChannelRequired = true;
        }

        this.availableChannels = cableChannels
          .filter(ch => !this.steps.find(s => s.channel === ch))
          .filter(ch => this.otherChannels.findIndex(muscle => muscle.channel === ch) === -1)
          .filter(ch => {
            const isPelvicChannel = this.cable.internal.find( ich => ich === ch);
            return (isPelvicChannel && hasPelvicMuscles) || (!isPelvicChannel && hasNonPelvicMuscles);
          })
          .map(ch => ({
            channel: ch,
            bodyModel: null,
            selected: false,
            side: null,
            purpose: "primary"
          }));

        if (this.cableRequirements) {
          this.cableRequirements = {
            ...this.cableRequirements,
            satisfied: this.cable.channels.length >= this.cableRequirements.value
          };
        }
        if (this.pelvicRequirements) {
          this.pelvicRequirements = {
            ...this.pelvicRequirements,
            satisfied: ((this.cable || { internal: [] }).internal).length,
          };
        }
        this.initializeNextActive();
        this.cdr.detectChanges();
      });
    this.stellaDirect.state$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(s => Boolean(s)),
        throttleTime(100)
      )
      .subscribe(state => {
        if (state.channelsConnected && state.channelsConnected[0]) {
          this.steps.forEach(s => {
            s.quality = state.channelsConnected[s.channel].quality;
          });
          this.otherChannels.forEach(s => {
            s.quality = state.channelsConnected[s.channel].quality;
          });
          this.availableChannels.forEach(s => {
            s.quality = state.channelsConnected[s.channel].quality;
          });
          this.cdr.detectChanges();
        }
      });

    this.initializeNextActive()
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getExisting(parts: any[], allMuscles): any[] {
    const fltr = part => m => part.value === 'all' ? true : m.part === part.value;
    return parts
      .map(p => ({...p, count: allMuscles.filter(fltr(p)).length}))
      .filter((p, i) => p.count !== 0 || i === 0);
  }

  maxAvailableChannels() {
    if (!this.cable || this.cable.code == 65535) {
      return 0;
    }

    if (this.stimConfig) {
      const maxChannels = Math.min(this.stimConfig.maxSupportedChannels || this.cable.channels.length, this.cable.channels.length);

      if (this._program?.value?.steps?.details?.emgTriggered) {
        return maxChannels + 1;
      }

      return maxChannels;
    }

    if (this._program && this._program.value && this._program.value.steps && this._program.value.steps.details) {
      return Math.min(this._program.value.steps.details.channels || this.cable.channels.length, this.cable.channels.length);
    }
    else {
      // EMG Diagnostic programs or Game
      return this.cable.channels.length;
    }
  }

  minAvailableChannels() {
    if (this.stimConfig) {
      return this.stimConfig.maxIntensity.length;
    }

    if (!this._program.value?.steps?.details) {
      return 1;
    }

    if (this._program.value.steps.report !== 'stim') { // Block for FES and FES EMG Triggered. Biofeedback and Games remains ok.
      return 1;
    }
    return this._program.value.steps.details.channels || 1;
  }

  hasWarnings() {
    return Object.keys(this.connectionStatus).some(status => this.connectionStatus[status] === ConnectionQuality.POOR);
  }

  canStart() {
    const result = this.canStartReal();
    return result;
  }

  canStartReal() {
    let isCableValidForProgram = true;
    const areChannelsCorrect = this.alreadyStarted ? true : (validChannels(this.otherChannels).length === this.otherChannels.length)
      && validChannels([...this.otherChannels, ...this.availableChannels]).length >= this.minAvailableChannels();
    this.connectionStatus.electrodesConnection = areChannelsCorrect ? null : ConnectionQuality.NONE;
    if  (this.pelvicRequirements) {
      this.pelvicRequirements.connected = this.otherChannels.some(ch => this.cable.internal.includes(ch.channel))
    }

    if (!this._program) {
      return false;
    }
    if (!this.cable) {
      return false;
    }

    if (this._program.value && this._program.value.steps) {
      isCableValidForProgram = this.minAvailableChannels() <= this.cable.channels.length;
      this.connectionStatus.enoughChannelsInCable = isCableValidForProgram && (!this.stimConfig || this.programController.isCableCorrect) ? null : ConnectionQuality.NONE;
    }
    else {
      this.connectionStatus.enoughChannelsInCable = ConnectionQuality.NONE;
    }

    this.connectionStatus.connectedChannelsChoosen = null;

    if (!this.otherChannels.length) {
      this.connectionStatus.enoughChannelsChoosen = ConnectionQuality.NONE;
      return false;
    }

    if (this.otherChannels.length < this.minAvailableChannels()) {
      this.connectionStatus.enoughChannelsChoosen = ConnectionQuality.NONE;
      return false;
    }

    this.connectionStatus.enoughChannelsChoosen = null;
    if (this.availableChannels.length && this.availableChannels.some(channel => channel.quality !== ConnectionQuality.NONE) && this.canSelectMoreChannels()) {
        this.connectionStatus.connectedChannelsChoosen = ConnectionQuality.POOR;
    }

    if (this.isPelvicChannelRequired ) {
      this.connectionStatus.requiredPelvicChannel = ConnectionQuality.NONE;
      if (!this.pelvicRequirements?.connected) {
        return false;
      } else {
        this.connectionStatus.requiredPelvicChannel = null;
      }
    }

    if (isCableValidForProgram && areChannelsCorrect) {
      return true;
    } else {
      this.isValid.emit({ res: 'invalid' });
      return false;
    }
  }

  isChannelComplete(s) {
    return s.quality === ConnectionQuality.WELL && s.muscle;
  }

  getIconForChannelConnection(args: { quality: ConnectionQuality }): string {
    switch (args.quality) {
      case ConnectionQuality.POOR: return 'error_outline';
      case ConnectionQuality.WELL: return 'check_circle_outline';
      default:
        return 'remove_circle_outline';
    }
  }

  getChannels(model, args): number[] {
    return args.others
      .filter(o => o.bodyModel && o.selected && o.bodyModel.name === model.name && o.side === args.side)
      .map(o => o.channel);
  }

  releaseChannel(channel: number) {
    this.otherChannels = this.otherChannels.map(oc => {
      if (oc.channel === channel) {
        return {
          ...oc,
          bodyModel: null,
          selected: false,
          side: null
        };
      }

      return oc;
    });
    this.initializeNextActive();
    this.cdr.detectChanges();
  }

  isPelvic(step, cable): boolean {
    return cable.internal.includes(step.channel);
  }

  selectChannel(channel: SelectedMuscle) {
    if (!this.canSelectMoreChannels()) {
      return;
    }

    const availableIndex = this.availableChannels.indexOf(channel);

    if (availableIndex < 0) {
      return;
    }

    this.otherChannels = [...this.otherChannels, this.availableChannels[availableIndex]].sort((a, b) => a.channel - b.channel);
    this.availableChannels.splice(availableIndex, 1);
    this.availableChannels = this.availableChannels.slice();

    this.initializeNextActive();

    this.cdr.detectChanges();
  }

  unselectChannel(channel: SelectedMuscle) {
    const selectedIndex = this.otherChannels.indexOf(channel);

    if (selectedIndex < 0) {
      return;
    }

    const selected = this.otherChannels[selectedIndex];
    selected.bodyModel = null;
    selected.side = null;
    this.availableChannels = [...this.availableChannels, selected];
    this.availableChannels.sort((a, b) => a.channel - b.channel);
    this.otherChannels.splice(selectedIndex, 1);
    this.otherChannels = this.otherChannels.slice();

    this.initializeNextActive();

    this.canStart();

    this.cdr.detectChanges();
  }

  getAvailableChannelColor = (step: SelectedMuscle) => {
    if (!this.canSelectMoreChannels()) {
      return "#aaaaaa";
    }

    return getColorForChannel(step.channel);
  }

  canSelectMoreChannels() {
    return this.otherChannels.length < this.maxAvailableChannels();
  }

  selectMuscle(event: { model, side: 'L' | 'R' | 'M' }) {
    if (this.otherChannels.findIndex(ch => ch.channel === this.nextActive.channel) < 0) {
      this.selectChannel(this.nextActive);
    }
    this.otherChannels = this.otherChannels.map(ms => {
      if (ms.channel === this.nextActive.channel) {
        if (!ms.bodyModel) {
          return {
            ...ms,
            bodyModel: event.model,
            selected: true,
            side: event.side
          };
        }
        if (ms.bodyModel && !ms.selected) {
          return {
            ...ms,
            bodyModel: event.model,
            selected: true,
            side: event.side
          };
        }
        const erase = ms.selected;
        return {
          ...ms,
          bodyModel: erase ? null : event.model,
          selected: !erase,
          side: erase ? null : event.side
        };
      }
      return ms;
    });

    if (this.canSelectMoreChannels() || this.otherChannels.some(ch => !ch.bodyModel)) {
      this.initializeNextActive();
    } else {
      this.nextActive = undefined;
    }
    this.cdr.detectChanges();
  }

  async startTraining() {
    this.gtag.emitMany([
      { category: AnalyticsCategory.TRAINING, action: AnalyticsAction.TRAINING_CABLE, label: String(this.cable.code) },
      { category: AnalyticsCategory.TRAINING, action: AnalyticsAction.TRAINING_MUSCLES, label: String(this.otherChannels.filter(oc => oc.bodyModel).length) },
      { category: AnalyticsCategory.TRAINING, action: AnalyticsAction.TRAINING_CHANNELS, label: String(this.otherChannels.filter(oc => oc.quality !== 'none').length) }
    ]);
    if (this.willUseEmg(this._program.value)) {
      let mask = 0;
      const wellChannels = this.otherChannels.filter(oc => oc.quality !== 'none');
      for (const v of wellChannels) {
        // eslint-disable-next-line no-bitwise
        mask |= 1 << v.channel;
      }

      this.stellaDirect.setMask(mask);
    }
    if (this.stellaDirect.stimEnabled$.value) {
      this.stellaDirect.send(CLEAR_STIM_FLAG);
    }

    this.isValid.emit({
      muscles: this.otherChannels.map(v => ({ ...v }))
    });
  }

  willUseEmg(program) {
    if (!program.steps.details) {
      return isProgramViewType(program.name) || program.steps.calibration;
    }
    return program.steps.details.calibration;
  }

  isEMSBased() {
    return this._program.value.steps.details !== undefined;
  }

  getMuscleForBadge(channel) {
    return this.otherChannels.find(sm => sm.channel === channel).bodyModel.name;
  }

  isNextActive(step, nextActive) {
    if (!nextActive) {
      return false;
    }
    return nextActive.channel === step.channel;
  }

  setNextActive(step) {
    this.nextActive = step;
    this.cdr.detectChanges();
  }

  trackByName(item) {
    return item.name;
  }

  goBack() {
    this.location.back();
  }
}
