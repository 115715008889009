import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { AuthService } from '@app/auth/services/auth.service';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { Role } from '@app/models/Role.enum';
import { lowBackPain, sorens } from '@app/diagnosticTests';
import { BiofeedbackStepConfiguration } from '@app/types';

@Component({
  selector: 'sba-biofeedback-setup',
  templateUrl: './biofeedback-setup.component.html',
  styleUrls: ['./biofeedback-setup.component.scss']
})
export class BiofeedbackSetupComponent implements OnInit {

  @Output() startTraining = new EventEmitter<BiofeedbackStepConfiguration[]>();
  @Input() set biofeedbackProgram(program) {
    if (!program.name) {
      return;
    }

    if (program.steps.steps) {
      this.steps = program.steps.steps.map(s => ({ ...s }));
    }
    else {
      this.steps = [{
        workTime: 5,
        restTime: 10,
        repetitions: 5,
        startRelaxDuration: 10,
        threshold: 0.5,
        removable: false
      }];
    }

    switch (program.name) {
      case 'low_back_pain':
      case 'sorens':
      case 'glazer':
        this.blockEditingDefaultValues = true;
        break;
    }
  }
  @Input() canModify = true;

  role = Role.THERAPIST;
  blockEditingDefaultValues = false;
  isAlreadySet = false;
  steps: BiofeedbackStepConfiguration[] = [{
      workTime: 5,
      restTime: 10,
      repetitions: 5,
      startRelaxDuration: 10,
      threshold: 0.5,
      removable: false
    }
  ];

  constructor(
    private readonly authService: AuthService,
    private readonly dashboard: DashboardService
  ) { }

  ngOnInit(): void {
    this.role = this.authService.currentUser.role;
    if (this.role === Role.PATIENT) {
      this.isAlreadySet = true;
    }
  }

  addValue(where: 'workTime' | 'restTime' | 'repetitions' | 'mvc' | 'startRelaxDuration', step: number, val = 1): void {
    this.steps[step][where] += val;
  }

  removeValue(where: 'workTime' | 'restTime' | 'repetitions' | 'mvc' | 'startRelaxDuration', step: number, val = 1): void {
    this.steps[step][where] = this.steps[step][where] === val ? val : this.steps[step][where] - val;
  }

  removeStep(index: number) {
    this.steps = this.steps.filter((_, ind) => ind !== index);
  }

  addStep() {
    this.steps.push({
      workTime: 5,
      restTime: 10,
      repetitions: 5,
      startRelaxDuration: 10,
      threshold: 0.5,
      removable: true
    });
  }
}
