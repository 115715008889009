import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadingState, LoadingStore } from './loading.store';

@Injectable({ providedIn: 'root' })
export class LoadingQuery extends Query<LoadingState> {

  constructor(protected store: LoadingStore) {
    super(store);
  }

  isLoading(keys: string[]): Observable<boolean> {
    return this.select(keys)
      .pipe(
        map(vals => Object.values(vals).some(v => v))
      );
  }
}
