import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localFunction'
})
export class LocalFunctionPipe implements PipeTransform {

  transform(value: any, handler: (arg: any, ...args: any) => {}, ...args): any {
    return handler(value, ...args);
  }

}
