import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { CLEAR_STIM_FLAG } from '@app/stella/services/StellaCommands';

@Component({
  selector: 'sba-over-threshold-dialog',
  templateUrl: './over-threshold-dialog.component.html',
  styleUrls: ['./over-threshold-dialog.component.scss']
})
export class OverThresholdDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OverThresholdDialogComponent>,
    private readonly stella: StellaDirectService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public readonly data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.stella.send(CLEAR_STIM_FLAG);
    this.dialogRef.close(true);
  }
}
