import { Injectable } from '@angular/core';
import { RestEndpointsService } from '@app/api/rest-endpoints.service';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { LocalStoreService } from '@app/dashboard/services/local-store.service';
import { UploadServiceService } from '@app/dashboard/services/upload-service.service';
import { GTagService, AnalyticsAction, AnalyticsCategory } from '@app/shared/gtag.service';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { CurrentSessionService } from '@app/training/services/current-session.service';
import { ChannelSignal, MeetingMemory, SelectedMuscle, SignalPack } from '@app/types';
import { Float32Concat } from '@app/utils/float32concat';
import { SessionStore } from '@store/session';
import { Subject, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment } from '@env/environment';

const SURROUND_LIMIT = 2;
const exerciseTemplate = {
  completed: 0,
  endDate: null,
  uploaded: 0,
  threshold: new Array(8).fill(0),
  results: {}
};

@Injectable({
  providedIn: 'root'
})
export class CurrentExerciseService {
  constructor(
    private directStella: StellaDirectService,
    private storage: LocalStoreService,
    private session: CurrentSessionService,
    private rest: RestEndpointsService,
    private sessionStore: SessionStore,
    private uploadService: UploadServiceService,
    private dashboard: DashboardService,
    private gtag: GTagService
  ) {

  }

  exerciseId;
  channels: SignalPack = this.createEmptySignalPack();
  rmss: SignalPack = this.createEmptySignalPack();
  guideLine: SignalPack = this.createEmptySignalPack();
  dataSub: Subscription = new Subscription();
  hasStarted = false;
  isLogging = false;
  isSavingData: boolean = true;
  interval: number;
  muscles: SelectedMuscle[];
  raw$: Subject<any> = new Subject();
  source$: Subject<ChannelSignal> = new Subject();

  debugLogger(fnc) {
    if (!environment.production) {
      fnc();
    }
  }

  async initializeExercise(name: string, opts?): Promise<number> {
    this.debugLogger(() => console.group('[INITIALIZATION STARTED]'));

    let tr;
    this.gtag.emit(AnalyticsCategory.TRAINING, AnalyticsAction.TRAINING_START, name);
    const tempTime = performance.now();
    await this.session.start();

    this.stop();

    this.sessionStore.update({
      exercise: true
    });

    const partialTrainingInfo = {
      startDate: new Date(),
      name,
      module: opts.module,
      muscles: this.muscles,
    };

    const trainingInfo = {
      ...partialTrainingInfo,
      extras: {
        channels: this.directStella.state.getCable().channels,
        concept: localStorage.getItem('CURRENT_CONCEPT'),
        cable: this.directStella.state.getCable(),
        primary: opts.primary,
        secondary: opts.secondary,
        emgCalibration: opts.emgCalibration,
        emsCalibration: opts.emsCalibration
      }
    };

    this.debugLogger(() => console.log('START SESSION', performance.now() - tempTime));

    this.exerciseId = await this.storage.addExercise({
      ...exerciseTemplate,
      ...partialTrainingInfo,
      session: this.session.sessionId,
      cable: this.directStella.state.getCable()
    });
    this.storage.initializeStreams(this.exerciseId, 8);

    this.debugLogger(() => console.log('ADD EXERCISE', performance.now() - tempTime));
    const { clinic, patient, remoteId } = await this.session.getCurrent(false);

    this.debugLogger(() => console.log('RETRIEVE CURRENT SESSION', performance.now() - tempTime));
    if (!this.dashboard.exercise?.id) {
      tr = await this.rest.createTraining(
        clinic.id,
        patient.patientId,
        remoteId,
        {
          ...trainingInfo,
          template: opts.template
        }).toPromise();
    } else {
      tr = { id: this.dashboard.exercise.id };
      this.rest.updateTraining(
        clinic.id,
        patient.patientId,
        remoteId,
        tr.id,
        {
          ...trainingInfo,
          endDate: null,
          completed: false,
          rmss: [],
          raws: [],
          guideLine: [],
          duration: tr.duration,
          template: opts?.template,
          newTitle: tr.newTitle
        }
      ).toPromise();
    }
    this.debugLogger(() => console.log('SAVE/UPDATE TRAINING', performance.now() - tempTime));
    this.storage.updateExercise(this.exerciseId, {
      remoteId: tr.id
    });
    this.debugLogger(() => console.log('UPDATE EXERCISE', performance.now() - tempTime));

    this.dataSub = new Subscription();
    this.dataSub.add(this.createSourceSubscription());
    this.dataSub.add(this.createRawSubscription());
    this.debugLogger(() => console.log('BIND SOURCES', performance.now() - tempTime));
    this.debugLogger(() => console.groupEnd());
    return this.exerciseId;
  }

  private startCollectingData() {
    if (this.interval) {
      window.clearInterval(this.interval);
    }

    this.interval = window.setInterval(async () => {
      await this.saveChunk();
    }, 1000);
  }

  private createRawSubscription(): Subscription {
    return this.directStella.raw$
      .pipe(
        filter(() => this.isLogging),
        tap(data => this.raw$.next(data)),
        filter(() => this.isSavingData),
        filter(data => data && data.channels),
        tap(data => {
          for (const channel in data.channels) {
            this.channels[channel] = Float32Concat(this.channels[channel], data.channels[channel] as Float32Array);
          }
        })
      )
      .subscribe();
  }

  private createSourceSubscription(): Subscription {
    return this.directStella.source$
      .pipe(
        filter(() => this.isLogging),
        tap(data => this.source$.next(data)),
        filter(() => this.isSavingData),
        tap(data => {
          for (const channel in data) {
            const vals = data[channel];
            this.rmss[channel] = Float32Concat(this.rmss[channel], vals);
          }
        }))
      .subscribe();
  }

  private async saveChunk(isLast = false) {
    this.storage.addChunk(this.exerciseId, {
      rmss: this.rmss,
      channels: this.channels,
      guideLine: this.guideLine
    },isLast);
    this.channels = this.createEmptySignalPack();
    this.rmss = this.createEmptySignalPack();
    this.guideLine = this.createEmptySignalPack();
  }

  async start(shouldSaveData: boolean = true) {
    this.isLogging = true;
    this.hasStarted = this.hasStarted ? true : shouldSaveData;
    this.isSavingData = shouldSaveData;

    if (shouldSaveData) {
      this.startCollectingData();
    } 
    console.log('[RUNNING]');
  }

  async pause(withDataRunning: boolean = false) {
    console.log('[PAUSED]');
    this.gtag.emit(AnalyticsCategory.TRAINING, AnalyticsAction.TRAINING_PAUSE);
    this.isLogging = withDataRunning;
    this.isSavingData = false;
    await this.saveChunk();
    window.clearInterval(this.interval);
  }

  updateGuideLine(val: Float32Array, channel = 0): void {
    this.guideLine[channel] = Float32Concat(this.guideLine[channel], val);
  }

  async update(change: Partial<MeetingMemory>, exerciseId?: number): Promise<void> {
    if (change.muscles) {
      this.muscles = change.muscles;
    }
    // avoid update storage when exercise is not initialized
    const currentExerciseId = exerciseId ? exerciseId : this.exerciseId;
    if( currentExerciseId !== undefined ) {
      await this.storage.updateExercise(exerciseId ? exerciseId : this.exerciseId, change);
    }
  }

  async stop(): Promise<void> {
    console.log('[STOPPED]');
    this.gtag.emit(AnalyticsCategory.TRAINING, AnalyticsAction.TRAINING_END);

    // streams always should be destroyed like that
    await this.saveChunk(true); // add chunk marked as 'last'
    await this.storage.destroyStreams();
    
    if (this.hasStarted) {
      this.sessionStore.update({ exercise: false });
      this.hasStarted = false;

      this.upload();
    } 

    this.isLogging = false;

    if (this.dataSub) {
      this.dataSub.unsubscribe();
      this.dataSub = null;
    }

    clearInterval(this.interval);
  }

  private async upload() {
    try {
      console.log('[EXERCISE: UPLOAD START]');
      let time = performance.now();
      this.sessionStore.update({ uploading: true, progress: 0 });

      // Checkpoints are for debugging purposes and for analyze bottlenecks
      // in the future.
      console.log(`[UPLOAD CHECKPOINT 1]`,performance.now() - time)
      this.sessionStore.update({ progress: 10 });
      const partialUploadInfo = { endDate: new Date() };
      const [tr, { clinic, patient, remoteId }] = await Promise.all([
        this.storage.getExercise(this.exerciseId),
        this.session.getCurrent()
      ]);

      console.log(`[UPLOAD CHECKPOINT 2]`,performance.now() - time)
      this.sessionStore.update({ progress: 20 });

      await Promise.all([
        this.storage.updateExercise(this.exerciseId, {
          completed: 1,
          session: this.session.sessionId,
          ...partialUploadInfo
        }),
        this.rest.updateTraining(
          clinic.id,
          patient.patientId,
          remoteId,
          tr.remoteId,
          {
            startDate: tr.startDate,
            isChargedFor: true,
            completed: true,
            results: tr.results,
            muscles: this.muscles,
            ...partialUploadInfo,
            extras: {
              intensityChanges: tr.intensityChanges,
              duration: tr.duration,
              repetitions: tr.repetitions,
              newTitle: tr.newTitle,
              timeBreaks: tr.timeBreaks,
            }
          }
        ).toPromise(),
      ]);

      console.log(`[UPLOAD CHECKPOINT 3]`,performance.now() - time)
      this.sessionStore.update({ progress: 30 });

      await this.uploadService.uploadExercise(this.exerciseId, remoteId);
      console.log(`[UPLOAD CHECKPOINT 4]`,performance.now() - time)
      this.sessionStore.update({ progress: 40 });
      this.storage.removeExercise(this.exerciseId);
      console.log(`[UPLOAD CHECKPOINT 5]`,performance.now() - time)
      this.sessionStore.update({ progress: 50 });
      console.log('[EXERCISE: UPLOAD END]');
    } catch (err) {
      console.warn(err);
    }
  }

  private createEmptySignalPack(): Float32Array[] {
    return Array(8).fill(0).map(() => new Float32Array());
  }
}
