import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { LoadingQuery } from '@store/loading';
import { environment } from '@env/environment';
import { AuthService } from '../../auth/services/auth.service';
import { ClinicsService } from '../../clinics/services/clinics.service';

const CLINIC_CREATE = 'CLINIC_CREATE';

@Component({
  selector: 'sba-new-clinic-dialog',
  templateUrl: './new-clinic-dialog.component.html',
  styleUrls: ['./new-clinic-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewClinicDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NewClinicDialogComponent>,
    private authService: AuthService,
    private clinicService: ClinicsService,
    private loadingQuery: LoadingQuery,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  edit = false;
  now = new Date();
  user = this.authService.currentUser;
  file: any;
  showAddressFields = false;
  isLoading: boolean;

  loadingProcessing = this.loadingQuery.isLoading([CLINIC_CREATE]).pipe(
    tap(val => {
      this.isLoading = val;
      this.clinic[val ? 'disable' : 'enable']();
    })
  );

  clinic = new FormGroup({
    clinicName: new FormControl('', [Validators.required]),
    website: new FormControl('', [Validators.pattern(/^[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/)]),
    description: new FormControl(''),
    logo: new FormControl(''),
    address: new FormGroup({
      street: new FormControl(''),
      city: new FormControl(''),
      zipcode: new FormControl(''),
      country: new FormControl('')
    })
  });

  ngOnInit() {
    this.loadingProcessing.subscribe();
    if (this.data) {
      this.edit = true;
      this.showAddressFields = this.data.address;
      this.clinic.patchValue({
        clinicName: this.data.name,
        website: this.data.website ? this.data.website
          .replace('https://', '')
          .replace('http://', '') : '',
        description: this.data.description,
        logo: `${environment.assetsUrl}/${this.data.logo}`,
        address: this.data.address
      });
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    const saveFnc =
      (values) => {
        return this.edit ?
          this.clinicService.editClinic(values) : this.clinicService.createClinic(values);
      };
    saveFnc({
      id: this.edit ? this.data.id : undefined,
      name: this.clinic.value.clinicName,
      web: this.clinic.value.website,
      description: this.clinic.value.description || '',
      logo: this.file,
      address: this.showAddressFields ? this.clinic.value.address : undefined
    })
      .subscribe({
        next: _ => {
          this.dialogRef.close(true);
        },
        error: _ => {
          this.clinic.setErrors({
            validationError: true
          });
        }
      });
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      const [file] = event.target.files;
      this.file = file;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.clinic.patchValue({
          logo: reader.result
        });
        this.cd.markForCheck();
      };
    }
  }

  toggleAddress() {
    this.showAddressFields = !this.showAddressFields;
    this.clinic.get('address').get('street').setValidators(this.showAddressFields ? [Validators.required] : null);
    this.clinic.get('address').get('street').updateValueAndValidity();
  }
}
