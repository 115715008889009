import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { LocalStoreService } from '@app/dashboard/services/local-store.service';
import { DEFAULT_STELLA_HOSTNAME, DEFAULT_STELLA_WSS_PORT, LAST_ACCEPTED_VERSION, StellaConnectionStatus, STELLA_LAST_CHART_PCT_SCALE, STELLA_LAST_CHART_SCALE, LanguageSettings } from '@app/enums';
import { StellaName } from '@app/models/StellaName.model';
import { ConnectionService } from '@app/shared/services/connectionMonitor.service';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { ListStellaService } from '@app/stella/services/list-stella.service';
import { StellaConnectService } from '@app/stella/services/stella-connect.service';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { STIM_PROGRAM_OFF } from '@app/stella/services/StellaCommands';
import { Notification } from '@app/types';
import { CalibrationDialogComponent } from '@components/calibration-dialog/calibration-dialog.component';
import { UpgradeDialogComponent } from '@components/upgrade-dialog/upgrade-dialog.component';
import { akitaDevtools } from '@datorama/akita';
import { environment } from '@env/environment';
import * as msgpack from '@msgpack/msgpack';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StellaNamesStore } from '@store/stellaNames';
import * as Chart from 'chart.js';
import * as AnnotationPlugin from 'chartjs-plugin-annotation';
import { of, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { AuthService } from './auth/services/auth.service';
import { ClinicsService } from './clinics/services/clinics.service';
import { PatientsService } from './clinics/services/patients.service';
import { ChangelogDialogComponent, SemVer } from './components/changelog-dialog/changelog-dialog.component';
import { LowBatteryLevelDialogComponent } from './components/low-battery-level-dialog/low-battery-level-dialog.component';
import { NoInternetDialogComponent } from './components/no-internet-dialog/no-internet-dialog.component';
import { UploadingDataDialogComponent } from './components/uploading-data-dialog/uploading-data-dialog.component';
import { Role } from './models/Role.enum';
import { SessionQuery } from '@app/store/session/session.query';
import { LANGUAGES } from '@app/enums';

// Types are defined here because typescript installed with this app
// had some issues with @types/dom-screen-wake-lock
interface WakeLockSentinel extends EventTarget {
  release: () => Promise<undefined>;
  readonly released: boolean;
}

// tslint:disable-next-line: ban-types
declare let gtag: Function;

export function getCurrentLang(): LanguageSettings {
  const localStorageLangLabel = localStorage.getItem('LANGUAGE_LABEL');
  const localStorageLangVal = localStorage.getItem('LANGUAGE');
  const browserLang = navigator.language.split('-')[0];

  return  LANGUAGES.find(l => l.label === localStorageLangLabel) ??
          LANGUAGES.find(l => l.value === localStorageLangVal) ??
          LANGUAGES.find(l => l.value === browserLang) ??
          LANGUAGES[0];
}

@Component({
  selector: 'sba-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  title = 'StellaDashboard';
  internetConnection: boolean;
  electrostimRef;
  pendingUpdate: boolean;
  upgradeDialogRef: MatDialogRef<UpgradeDialogComponent>;
  calibrationDialogRef: MatDialogRef<CalibrationDialogComponent>;
  batteryWarningDialogRef: any;
  wakeLock: WakeLockSentinel;

  sessionState: boolean = false;
  uploadingState: boolean = false;

  @HostListener('window:beforeunload', [ '$event' ])
  beforeunloadHandler(event: BeforeUnloadEvent) {
    this.stellaService.send(STIM_PROGRAM_OFF);
    if (this.sessionState || this.uploadingState) {
       event.preventDefault();
       event.returnValue = false;
    }
  }

  private noInternetDialog: MatDialogRef<NoInternetDialogComponent>
  private uploadingDataDialog: MatDialogRef<UploadingDataDialogComponent>

  async requestWakeLock() {
    try {
      const nav = navigator as Navigator & { wakeLock?: { request: (type: "screen") => Promise<WakeLockSentinel> } };
      if( 'wakeLock' in nav ) {
        this.wakeLock = await nav.wakeLock.request("screen");
        this.wakeLock.addEventListener('release', () => {
          console.log('Screen Wake Lock released:', this.wakeLock.released);
        });
      }
    } catch (err) {
      console.error(`${err.name}, ${err.message}`);
    }
  };

  releaseWakeLock() {
    if( this.wakeLock ) {
      this.wakeLock.release();
      this.wakeLock = null;
    }
  }

  constructor(
    router: Router,
    route: ActivatedRoute,
    connectionMonitor: ConnectionService,
    translate: TranslateService,
    ngZone: NgZone,
    private readonly dialog: MatDialog,
    private readonly http: HttpClient,
    private readonly stellaService: StellaDirectService,
    private readonly localStoreService: LocalStoreService,
    private readonly directHubService: StellaConnectService,
    private readonly listStella: ListStellaService,
    private readonly notificationService: NotificationsService,
    private readonly snackbar: MatSnackBar,
    private readonly swUpdate: SwUpdate,
    private readonly stellaNames: StellaNamesStore,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly authService: AuthService,
    private readonly clinicsService: ClinicsService,
    private readonly patientsService: PatientsService,
    private readonly sessionQuery: SessionQuery
  ) {
    this.sessionQuery.isSessionStarted().subscribe((event) => {
      this.sessionState = event;
      if( event ) {
        this.requestWakeLock();
      } else {
        this.releaseWakeLock();
      }
    });

    this.sessionQuery.isProgress().subscribe((event) => {
      if( this.uploadingDataDialog) {
        this.uploadingDataDialog.componentInstance.progress = event;
      }
    });

    this.sessionQuery.isUploading().subscribe((event) => {
      this.uploadingState = event;

      if (this.uploadingState) {
        this.uploadingDataDialog = this.dialog.open( UploadingDataDialogComponent);
      } else {
        if (this.uploadingDataDialog) {
          this.uploadingDataDialog.close();
          delete this.uploadingDataDialog;
        }
      }
    });

    [
      'avatar_feature_observer',
      'avatar_feature_owner',
      'avatar_feature_patient',
      'avatar_feature_reception',
      'avatar_feature_specialist'
    ].forEach(name => {
      this.matIconRegistry.addSvgIcon(
        name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `../../../assets/svg/icon_${name}.svg`
        )
      );
    });
    if (!environment.production) {
      akitaDevtools(ngZone);
    }
    connectionMonitor.monitor()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(val => {
        if (val === 'offline-request-triggered') {
          this.noInternetDialog = this.dialog.open(NoInternetDialogComponent)
        }
        if (this.noInternetDialog && val ==='online'){
          this.noInternetDialog.close();
          delete this.noInternetDialog;
        }
      });
    
    const lang = getCurrentLang();

    localStorage.setItem('LANGUAGE', lang.value);
    localStorage.setItem('LANGUAGE_LABEL', lang.label);
    localStorage.setItem('LANGUAGE_SOUNDS', lang.sounds);
    translate.setDefaultLang('en');
    translate.use(lang.value);
    // translate.reloadLang('pl').subscribe();
    translate.onLangChange
    .pipe(
      filter((event: LangChangeEvent) => !event.translations.auth),
      tap(async (event: LangChangeEvent) => {
        await translate.reloadLang(event.lang).toPromise();
        translate.setTranslation(event.lang, event.translations, true);
      })
    )
    .subscribe();
    Chart.plugins.register(AnnotationPlugin);
    router.events
      .pipe(
        tap(event => {
          if (event instanceof NavigationEnd) {
            // gtag('set', 'page', event.urlAfterRedirects);
            gtag('event', 'page_view', {
              page_path: event.urlAfterRedirects
            });
          }
        }),
        filter(event => event instanceof NavigationStart),
        map((event: NavigationStart) => event.url)
      )
      .subscribe((url: string) => {
        const clinic = this.clinicsService.currentClinic();
        const patient = this.patientsService.currentPatient();

        directHubService.send({
          application: {
            page: url,
            origin: location.origin,
            user: {
              id: this.authService.currentUser ? this.authService.currentUser.id : "<anonymous>",
              role: this.authService.currentUser && this.authService.currentUser.role ?  Role[this.authService.currentUser.role] : "UNKNOWN",
              lastNavigation: new Date().toISOString(),
              clinic: clinic ? clinic.id : "<none>",
              patient: patient ? (Array.isArray(patient) ? patient.map(v => v.id).join(", ") : patient.id) : "<none>"
            }
          }
        });
      });
    route.queryParams.subscribe({
      next: params => {
        if (params.directAddress) {
          const master = params.directAddress;
          stellaService.setStella(
            {
              net: {
                direct: { master, local: `wss://${DEFAULT_STELLA_HOSTNAME}:${DEFAULT_STELLA_WSS_PORT}` },
                local: {
                  address: master
                }
              }
            },
            true
          );
        }
      }
    });

    document.addEventListener("visibilitychange", (ev) => {
      directHubService.send({
        application: {
          focused: document.visibilityState
        }
      })
    })

    this.stellaService.state$
      .pipe(
        filter(state => Boolean(state)),
        filter(state => Boolean(state.battery)),
        filter(state => !Boolean(state.battery.notified))
      )
      .subscribe(state => {
        if (state.battery.level > state.battery.low && this.batteryWarningDialogRef) {
          this.batteryWarningDialogRef.close();
          this.batteryWarningDialogRef = null;
        }
        if (state.battery.level < state.battery.low && !this.batteryWarningDialogRef) {
          this.batteryWarningDialogRef = this.dialog.open(LowBatteryLevelDialogComponent, {
            disableClose: true
          });
        }
      });

    this.stellaService.currentStella$
      .subscribe(stella => {
        directHubService.send({
          application: {
            connectedDevice: stella.token,
            chosenDevice: stella
          }
        })
      })
  }

  async ngOnInit() {
    await this.localStoreService.init();
    (window as any).msgpack = msgpack;
    if (!localStorage.getItem(STELLA_LAST_CHART_SCALE)) {
      localStorage.setItem(STELLA_LAST_CHART_SCALE, '100');
    }
    if (!localStorage.getItem(STELLA_LAST_CHART_PCT_SCALE)) {
      localStorage.setItem(STELLA_LAST_CHART_PCT_SCALE, '1.25');
    }
    this.http
      .get<StellaName[]>('https://functions.egzotech.com/fetch_stella_bio_names')
      .subscribe(names => {
        this.stellaNames.add(names);
      });
    // this.http
    //   .get(environment.filtersConfigUrl)
    // tslint:disable-next-line: no-use-before-declare
    of(FILTER)
      .subscribe(filterConfig => {
        localStorage.setItem('FILTERS_CONFIGURATION', JSON.stringify(filterConfig));
      });

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        this.pendingUpdate = true;
      });
      console.log("Checking for update...");
      this.swUpdate.checkForUpdate().then(() => {
        console.log("Done checking updates");
      }).catch((ex) => {
        console.warn("Checking for update failed", ex);
      })
    }
    else {
      console.log("Service worker is disabled in development mode");
    }

    this.notificationService.notifications$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (val: Notification) => {
      });
    this.directHubService.openConnection();
    this.listStella.startListening();

    this.stellaService.connected$.pipe(
      filter(val => val.connected === StellaConnectionStatus.DISCONNECTED),
      tap(() => {
        this.closeDialogs();
      })
    ).subscribe();

    this.listenForUpgrade();
  }

  closeDialogs() {
    if (this.upgradeDialogRef) {
      this.upgradeDialogRef.close();
      this.upgradeDialogRef = null;
    }
    if (this.calibrationDialogRef) {
      this.calibrationDialogRef.close();
      this.calibrationDialogRef = null;
    }
  }

  private listenForUpgrade() {
    this.showChangelogDialog(environment.version);

    this.stellaService.status$
      .pipe(
        tap(_ => {

        }),
        tap(val => {
          // tslint:disable-next-line: no-bitwise
          if (val === 1 << 0) {
            this.showUpgradeDialog();
          }
        }),
        tap(val => {
          // tslint:disable-next-line: no-bitwise
          if (val === 1 << 1) {
            this.showCalibrationDialog();
          }
        })
      )
      .subscribe();
  }

  private showUpgradeDialog() {
    this.upgradeDialogRef = this.dialog.open(UpgradeDialogComponent, { maxWidth: '450px' });
  }

  private showCalibrationDialog() {
    this.calibrationDialogRef = this.dialog.open(CalibrationDialogComponent, { maxWidth: '450px' });
  }

  private showChangelogDialog(newVersion: string) {
    const newSemVer = SemVer.parse(newVersion);
    const lastVersion = localStorage.getItem(LAST_ACCEPTED_VERSION);
    let lastSemVer = lastVersion ? SemVer.parse(lastVersion) : null;

    if (lastSemVer && newSemVer.compare(lastSemVer) <= 0) {
      return;
    }

    // Clear stella connection when version changes
    this.stellaService.clearStella();

    if (!lastSemVer) {
      lastSemVer = SemVer.parse(newVersion);

      lastSemVer.revision -= 1;
    }

    return this.dialog.open(ChangelogDialogComponent, {
      maxWidth: '450px',
      data: {
        fromVersion: lastSemVer,
        toVersion: newSemVer
      }
    }).afterClosed()
      .subscribe(() => {
        localStorage.setItem(LAST_ACCEPTED_VERSION, newVersion);
      })
  }


  reloadPage() {
    window.location.reload();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

const FILTER = {
  name: 'RMS',
  description: '1000sps 2.4Vref G6',
  revision: 0,
  require: 0,

  filters: {
    input: {
      type: 'Filter',
      description: 'scale raw to volts: 2.4 / (Math.pow(2, 24 - 1) - 1) / 24',
      args: [
        {
          a: [1],
          b: [1.192093037616377e-08]
        }
      ]
    },
    lowpass: {
      type: 'Filter',
      description: 'butter 7 100 pass',
      args: [
        {
          b: [0.0000903489625198, 0.0006324427376387, 0.0018973282129160, 0.0031622136881933, 0.0031622136881933, 0.0018973282129160, 0.0006324427376387, 0.0000903489625198],
          a: [1.0000000000000000, -4.1823300893206152, 7.8717192022128089, -8.5309421292984577, 5.7099448078894817, -2.3492472280524366, 0.5482647747786813, -0.0558446710069274]
        }
      ]
    },
    highpass: {
      type: 'Filter',
      description: 'butter 7 20 stop',
      args: [
        {
          b: [0.7538189738698696, -5.2767328170890870, 15.8301984512672629, -26.3836640854454352, 26.3836640854454352, -15.8301984512672629, 5.2767328170890870, -0.7538189738698696],
          a: [1.0000000000000000, -6.4353247568865815, 17.7697886452064324, -27.2910083321281860, 25.1760517733513396, -13.9498380050513724, 4.2985740973532502, -0.5682430453662239]
        }
      ]
    },
    bandstop50: {
      type: 'Filter',
      description: 'butter 4 45-55 stop',
      args: [
        {
          b: [0.9390916523119579, -5.3614209495705367, 13.0203382141187358, -17.1951621297454800, 13.0203382141187394, -5.3614209495705376, 0.9390916523119579],
          a: [1.0000000000000000, -5.5896035337476695, 13.2911829367785934, -17.1881021377421490, 12.7457836654903112, -5.1402983573967358, 0.8818931305924853]
        }
      ]
    },
    rms: {
      type: 'RMS',
      description: '',
      args: [
        {
          count: 500
        }
      ]
    },
    smoothbuffer: {
      type: 'RateSmoother',
      description: 'smooth',
      args: [
        {
          sps: 1000,
          delay: 40,
          autoSps: true
        }
      ]
    },
    output: {
      type: 'Filter',
      description: '',
      args: [
        {
          a: [1],
          b: [1]
        }
      ]
    }
  },
  connections: [
    ['input', 'bandstop50', 'lowpass', 'highpass', 'rms', 'smoothbuffer', 'output']
  ]
};
