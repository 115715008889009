import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface VisitsState extends EntityState<any> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'visits',
  cache: {
    ttl: 3600000
  },
  resettable: true
})
export class VisitsStore extends EntityStore<VisitsState> {
  constructor() {
    super();
  }
}
