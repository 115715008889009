import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface LoadingState {
  [key: string]: boolean;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'loading'
})
export class LoadingStore extends Store<LoadingState> {

  constructor() {
    super({});
  }
}
