import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { StellaCareService } from '@app/shared/services/stella-care.service';
import { GamesService } from '@app/shared/services/games.service';
import { map, concatMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Pages } from '@app/pages';
import { Location } from '@angular/common';
import { GameDescription, GameServiceLevelInfo, GameServiceLevelSettings } from '@egzotech/egzotech-game-service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DashboardService } from '@app/dashboard/services/dashboard.service';

function tuple<T extends any[]>(...args: T): T {
  const result = [];

  for (let i = 0; i < args.length; i++) {
    result[i] = args[i];
  }

  return result as T;
}

@Component({
  selector: 'sba-games-list',
  templateUrl: './game-level-list.component.html',
  styleUrls: ['./game-level-list.component.scss']
})
export class GameLevelListComponent implements OnInit, OnDestroy {
  gameName: string;
  private game: GameDescription;

  navigationState: { levels?: string[] };
  levels: (GameServiceLevelInfo & { key: string, selected: boolean })[];
  errorText: string;
  langChangeSub: Subscription;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly stellaCare: StellaCareService,
    private readonly games: GamesService,
    private readonly translate: TranslateService,
    private readonly location: Location,
    public readonly dashboard: DashboardService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.navigationState = this.router.getCurrentNavigation() ? this.router.getCurrentNavigation().extras.state ?? {} : {};
  }

  ngOnInit(): void {
    this.levels = [];

    this.route.paramMap
      .pipe(
        map(params => params.get('name')),
        concatMap(() => this.games.getGames(), tuple)
      )
      .subscribe(([gameName, games]) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth'});
        this.gameName = gameName;
        this.game = games[gameName];

        this.levels = this.getLevels()
          .filter(g => this.filterOutLevels(g))
          .filter(g => this.stellaCare.filterOutHidden(g))
          .map(([key, lvl]) => ({
            ...lvl,
            key,
            icon: this.games.getResourceUrl(this.game, lvl.cover, 'dist'),
            label: this.games.getLocalizedString(lvl.name),
            short: this.games.getLocalizedString(lvl.description),
            selected: this.getDefaultSelectionForLevel()
          }));

        // document.querySelector('.diagnostic-list').scrollTo({ top: 0, behavior: 'smooth' });
        this.cdr.detectChanges();
      });
    this.langChangeSub = this.translate
      .onLangChange
      .subscribe(() => {
        this.levels = this.getLevels()
          .filter(g => this.filterOutLevels(g))
          .filter(g => this.stellaCare.filterOutHidden(g))
          .map(([key, lvl]) => ({
            ...lvl,
            key,
            icon: this.games.getResourceUrl(this.game, lvl.cover, 'dist'),
            label: this.games.getLocalizedString(lvl.name),
            short: this.games.getLocalizedString(lvl.description),
            selected: this.getDefaultSelectionForLevel()
          }));

        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.langChangeSub.unsubscribe();
  }

  handleSelect(level: { selected: boolean, selectable: boolean }) {
    if (this.navigationState.levels) {
      this.errorText = 'games.cannot_override_levels';
      return;
    }

    level.selected = !level.selected;

    if (level.selected) {
      this.errorText = null;
    }

    this.cdr.detectChanges();
  }

  handleStart() {
    const levels: { [key: string]: GameServiceLevelSettings } = {};

    for (const level of this.levels) {
      if (level.selected) {
        levels[level.key] = level.settings;
      }
    }

    if (Object.keys(levels).length === 0) {
      this.errorText = 'games.no_level_chosen';
      this.cdr.detectChanges();
      return;
    }

    this.router.navigate([`${Pages.GAMEPLAY}/${this.gameName}`], {
      state: {
        game: this.game,
        levels
      }
    });
  }

  goBack() {
    this.location.back();
  }

  private getLevels() {
    if (Object.keys(this.game.levels.neurological).length !== 0) {
      return Object.entries(this.game.levels.neurological);
    }

    // TODO: Remove it when birburgers game will receive neurological levels.
    return Object.entries(this.game.levels.all);
  }

  private filterOutLevels([key]: [string, GameServiceLevelInfo]) {
    if (this.navigationState.levels) {
      return this.navigationState.levels.indexOf(key) >= 0;
    }
    // Filter selected games for exercise from template
    if (this.dashboard.exercise?.template?.steps.levels && this.dashboard.exercise.template.steps.levels?.length) {
      return this.dashboard.exercise.template.steps.levels.map(lv => lv.key).includes(key);
    }

    return true;
  }

  private getDefaultSelectionForLevel() {
    // if there is exercise and some levels, then all levels are selected
    if (this.dashboard.exercise?.template.steps.levels?.length) {
      return true;
    }
    return !!this.navigationState.levels;
  }
}
