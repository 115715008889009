import { Injectable } from '@angular/core';

// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GTagService {

  constructor() { }

  public emit(
    category: AnalyticsCategory,
    action: AnalyticsAction | string,
    label: string = null,
    value: number = null) {
    gtag('event', action, {
      event_category: category,
      event_label: label,
      value
    });
  }

  public emitMany(events: Event[]) {
    events.forEach(ev => {
      this.emit(ev.category, ev.action, ev.label, ev.value);
    });
  }
}

export interface Event {
  category: AnalyticsCategory;
  action: AnalyticsAction | string;
  label?: string;
  value?: number;
}

export enum AnalyticsAction {
  CHANGE_LANGUAGE = 'change_language',
  SIGNIN = 'signin',
  SIGNUP = 'signup',
  PASS_RESET = 'pass_reset',
  PATIENT_SELECT = 'patient_select',
  PATIENT_SKIP = 'patient_skip',
  PATIENT_DIALOG_CREATE = 'patient_dialog_create',
  CLINIC_SELECT = 'clinic_select',
  CLINIC_DIALOG_CREATE = 'clinic_dialog_create',
  CONCEPT_SELECT = 'concept_select',
  CONCEPT_MORE_CLICKED = 'concept_more_clicked',
  CONCEPT_GAME_DETAILS_CLICKED = 'concept_game_details_clicked',
  TRAINING_START = 'training_start',
  TRAINING_SELECT = 'training_select',
  TRAINING_CABLE = 'training_cable',
  TRAINING_CHANNELS = 'training_channels',
  TRAINING_MUSCLES = 'training_muscles',
  TRAINING_INTERUPTIONS = 'training_interuptions',
  TRAINING_END = 'training_end',
  TRAINING_PAUSE = 'training_pause'
}

export enum AnalyticsCategory {
  LANGUAGE_SELECTOR = 'language_selector',
  AUTH = 'auth',
  PATIENT_SELECTION = 'patient_selection',
  CLINIC_SELECTION = 'clinic_selection',
  CONCEPT = 'concept',
  TRAINING = 'training',
  TRAINING_SELECTION = 'training_selection'
}
