import { ChartUnit } from "@app/types";

export function adjustToRange(value: number, range: {min: number, max: number}) {
  if (value < range.min) { return range.min; }
  if (value > range.max) { return range.max; }
}

export function clamp(value: number, min: number, max: number) {
  return value < min ? min
    : value > max ? max
    : value;
}

export function camelCaseToKebabCase(value: string): string {
  return value.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
}

export function checkUrlStartsWithProtocol(url: string): boolean {
  return url.startsWith('https://') &&
    url.startsWith('http://');
}

export function maskEmailAddress(email: string) {
  if (!email) {
    return "";
  }

  const emailSplit = email.split("@").map(v => v.trim());
  const leftPart = emailSplit[0];
  let leftPartMasked = "";

  if (leftPart.length > 3) {
    leftPartMasked = leftPart.substring(0, 2) + "*".repeat(leftPart.length - 3) + leftPart.substring(leftPart.length - 1);
  }
  else if (leftPart.length === 3) {
    leftPartMasked = leftPart[0] + "*" + leftPart[2];
  }
  else {
    leftPartMasked = leftPart;
  }

  const rightPartDomains = emailSplit[1].split(".").map(v => v.trim());
  const rightPartGlobalDomain = rightPartDomains[rightPartDomains.length - 1];
  const rightPart = rightPartDomains.slice(0, rightPartDomains.length - 1).join(".");
  let rightPartMasked = "";

  if (rightPart.length > 4) {
    rightPartMasked = rightPart.substring(0, 2) + "*".repeat(rightPart.length - 4) + rightPart.substring(rightPart.length - 2);
  }
  else if (rightPart.length === 4) {
    rightPartMasked = rightPart[0] + "*" + "*" + rightPart[3];
  }
  else if (rightPart.length === 3) {
    rightPartMasked = rightPart[0] + "*" + rightPart[2];
  }
  else {
    rightPartMasked = rightPart;
  }

  rightPartMasked += "." + rightPartGlobalDomain;

  return leftPartMasked + "@" + rightPartMasked;
}

export function secondsToTimeOnCharts(value: number) {
    const seconds = value % 60;
    const minutes = ((value - seconds) / 60) % 60;
    const hours = (value - minutes * 60 - seconds) / (60 * 60);

    return (
      (hours ? `${hours.toString()}:` : "") +
      `${minutes.toString().padStart(hours ? 2 : 1, "0")}:` +
      `${seconds.toString().padStart(2, "0").slice(0, (hours ? hours.toString().length + 2 : 1) + 2)}`
    );
}

export function isProgramViewType(programName: string) {
  switch (programName) {
    case 'view':
    case 'relaxation_analysis':
    case 'view_with_mvc':
      return true;
    default:
      return false;
  }
}

export function isChartUnit(type: any): type is ChartUnit {
  return type === 'microvolts' || type === 'pctmvc';
}

export function getChartLabel(type: ChartUnit): string {
  switch(type) {
    case 'microvolts':
      return 'EMG RMS [µV]';
    case 'pctmvc':
      return '% MVC';
  }
}