import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { Pages } from '@app/pages';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndTrainingDialogComponent } from '../components/end-training-dialog/end-training-dialog.component';
import { CurrentSessionService } from '../services/current-session.service';

export interface TrainingLikeComponent {
  canClose?: boolean;
  forceEndTraining?: boolean;
  exerciseNameForInquiry?: string
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<any> {
  constructor(
    private dialog: MatDialog,
    private session: CurrentSessionService,
    private router: Router
  ) { }

  canDeactivate(
    component: TrainingLikeComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const force = (this.router.getCurrentNavigation().extras.state || { force: false }).force;

    if (!this.session.isLogged || !this.session.isStarted || component.canClose) {
      return true;
    }
    return this.dialog.open(EndTrainingDialogComponent, {
      data: {
        showOnlyQuit: nextState.url.includes(Pages.SIGNIN),
        trainingEnd: force,
        exerciseNameForInquiry: component.exerciseNameForInquiry
      }
    })
      .afterClosed()
      .pipe(
        map(result => {
          return Boolean(result);
        })
      );
  }
}
