<sba-exercise-container #exerciseContainer (start)="onStart($event)">
  <div class="test-page" *ngIf="test">
    <ng-container *ngIf="vals$ | async as vals">
      <div class="title-row">
        <sba-training-title-row
          (back)="goBack()"
          [name]="'exercises.' + test.name | translate"
          [short]="'descriptions.' + test.steps.short | translate"
        ></sba-training-title-row>
      </div>
      <div class="test-page--content">
        <div class="scale" *ngIf="initialSetupDone">
          <sba-channel-selector
            [value]="displayedChannels"
            [channels]="muscles | localFunction: getOnlyValid"
            (change)="changeChannel($event)"
          ></sba-channel-selector>
          <sba-scale-selector [value]="vals.max" (change)="changeScale($event)">
          </sba-scale-selector>
          <div class="mvc-container">
            <p class="mvc">
              {{ 'emg.mvc' | translate }}: {{ vals.ports.length && vals.ports[0].mvc | number: "1.0-0" }} {{ 'common.units.microvolts' | translate }} ({{
                test.steps.steps[0].threshold * 100 | number:"1.0-0"
              }}{{ 'common.units.pct' | translate }})
            </p>
          </div>
          <button mat-icon-button (click)="muted = !muted"><mat-icon>{{ muted ? 'volume_off' : 'volume_up'}}</mat-icon></button>
        </div>
        <div class="chart-slider-container">
          <div class="chart-content">
            <div class="chart-container" *ngIf="initialSetupDone">
              <div id="line-chart" #lineChart></div>
            </div>
            <sba-biofeedback-setup
            *ngIf="!initialSetupDone"
            [canModify]="false"
            [biofeedbackProgram]="dashboard.exercise ? dashboard.exercise.template : test"
            (startTraining)="startTraining($event)"
          ></sba-biofeedback-setup>
          </div>
        </div>
        <div class="footer-row" *ngIf="initialSetupDone">
          <sba-contaction-legend class="legend"></sba-contaction-legend>
          <div>
            <sba-emg-training-controls
            *ngIf="trainingReady"
            class="controls"
              [state]="state.current$ | async"
              (start)="start()"
              (stop)="stop()"
              (pause)="pause()"
            ></sba-emg-training-controls>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</sba-exercise-container>
