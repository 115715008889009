<mat-form-field>
  <mat-label>{{ 'common.channel' | translate }}</mat-label>
  <mat-select [value]="value" (selectionChange)="change.emit($event.value)" [multiple]="multiple">
    <mat-select-trigger *ngIf="value !== undefined">
      <div class="selected-value" *ngIf="value.length">
        <div *ngFor="let val of value" class="circle many" [style.backgroundColor]="val | channelcolor"></div>
      </div>
      <div class="selected-value" *ngIf="!value.length">
        <div class="circle single" [style.backgroundColor]="value | channelcolor"></div>
      </div>
    </mat-select-trigger>
    <mat-option class="channel-selector" *ngFor="let channel of channels" [value]="channel">
      <div class="circle" [style.backgroundColor]="channel | channelcolor"></div>
      {{ channel + 1 }}
    </mat-option>
  </mat-select>
</mat-form-field>