import { Injectable } from "@angular/core";
import { Observable, throwError, timer } from "rxjs";
import { finalize, mergeMap } from "rxjs/operators";


@Injectable({
  providedIn: "root",
})
export class RetryRequestService {

  private connectionStatus: 'unknown' | 'pending' | 'online' | 'offline'  = "unknown";
  private isNoConnectionPopupOpen: boolean;

  constructor() {
  }

  async checkOnlineStatus(): Promise<void> {
    if (!window.navigator.onLine) { 
      this.connectionStatus = 'offline';
      return;
    }

    // avoid CORS errors with a request to your own origin
    const url = new URL(window.location.origin)
    
    // random value to prevent cached responses
    url.searchParams.set("rand", String(Date.now()));

    try {
      const response = await fetch(url.toString(), { method: "HEAD" });

      this.connectionStatus = response.ok ? 'online' : 'offline' ;
    } catch {
      this.connectionStatus = 'offline'
    }
  }

  public handleRetryRequest(attempts: Observable<any>) {
      return attempts.pipe(
        mergeMap((error) => {
          switch(this.connectionStatus) {
            case 'unknown': {
              this.connectionStatus = 'pending';
              this.checkOnlineStatus();

              console.info('Request failed. Verifying internet connection status');
              return timer(1000);
            };
            case 'pending': {
              return timer(1000);
            };
            case 'online': {
              return throwError(error);
            };
            case 'offline': {
              if (!this.isNoConnectionPopupOpen) {
                window.dispatchEvent(new Event('offline-request-triggered'));
                this.isNoConnectionPopupOpen = true;
              }
              console.info('Lost internet connection. Will retry in 5 seconds');
              return timer(5000);
            }
          }
        }),
        finalize(() => {
          window.dispatchEvent(new Event('online'));
          this.isNoConnectionPopupOpen = false;
        })
      );
    };
}
