import { SessionStore, SessionState } from './session.store';
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {

  constructor(protected store: SessionStore) {
    super(store);
  }

  isSessionStarted(): Observable<boolean> {
    return this.select('session').pipe(map( Boolean));
  }

  isExerciseStarted(): Observable<boolean> {
    return this.select('exercise').pipe(map( Boolean));
  }

  isUploading(): Observable<boolean> {
    return this.select('uploading').pipe(map( Boolean));
  }

  isProgress(): Observable<number> {
    return this.select('progress').pipe(map(Number));
  }

  isLogging(): Observable<boolean> {
    return this.select('logging').pipe(map( Boolean));
  }  
}
