<sba-page-title title="title.conditions_of_use"></sba-page-title>
<div class="background">
  <div class="text-container">
    <h1>{{ "conditionsOfUse.title" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s1" | translate }}
    </p>
    <p>
      {{ "conditionsOfUse.s2" | translate }}
    </p>
    <p>
      <b>
        {{ "conditionsOfUse.s3" | translate }}
      </b>
      {{ "conditionsOfUse.s4" | translate }}
    </p>
    <h1>1. {{ "conditionsOfUse.p1" | translate }}</h1>
    <h2>1.1. {{ "conditionsOfUse.p1-1" | translate }}</h2>
    <p>
      {{ "conditionsOfUse.s1-1-1" | translate }}
    </p>
    <h2>1.2. {{ "conditionsOfUse.p1-2" | translate }}</h2>
    <p>
      {{ "conditionsOfUse.s1-2-1" | translate }}
    </p>

    <ol>
      <li>
        {{ "conditionsOfUse.s1-2-2" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s1-2-3" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s1-2-4" | translate }}
      </li>
    </ol>
    <h1>2. {{ "conditionsOfUse.p2" | translate }}</h1>
    <h2>2.1. {{ "conditionsOfUse.p2-1" | translate }}</h2>
    <p>
      {{ "conditionsOfUse.s2-1-1" | translate }}
    </p>
    <h2>2.2. {{ "conditionsOfUse.p2-2" | translate }}</h2>
    <p>
      {{ "conditionsOfUse.s2-2-1" | translate }}
    </p>
    <h2>2.3. {{ "conditionsOfUse.p2-3" | translate }}</h2>
    <p>
      {{ "conditionsOfUse.s2-3-1" | translate }}
    </p>
    <ol>
      <li>{{ "conditionsOfUse.s2-3-1-1" | translate }}</li>
      <li>
        {{ "conditionsOfUse.s2-3-1-2" | translate }}
      </li>
      <li>{{ "conditionsOfUse.s2-3-1-3" | translate }}</li>
      <li>
        {{ "conditionsOfUse.s2-3-1-4" | translate }}
      </li>

      <li>
        {{ "conditionsOfUse.s2-3-1-5" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s2-3-1-6" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s2-3-1-7" | translate }}
      </li>
    </ol>
    <h2>2.4. {{ "conditionsOfUse.p2-4" | translate }}</h2>
    <ol>
      <li>
        {{ "conditionsOfUse.s2-4-1" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s2-4-2" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s2-4-3" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s2-4-4" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s2-4-5" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s2-4-6" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s2-4-7" | translate }}
      </li>
    </ol>
    <h1>3. {{ "conditionsOfUse.p3" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s3-1" | translate }}(<a
        href="https://egzotech.com/policy.html"
        >https://egzotech.com/policy.html</a
      >){{ "conditionsOfUse.s3-2" | translate }}
    </p>
    <h1>4. {{ "conditionsOfUse.p4" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s4-1" | translate }}
    </p>
    <ol>
      <li>
        {{ "conditionsOfUse.s4-1-1" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s4-1-2" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s4-1-3" | translate }}
      </li>
    </ol>
    <h1>5. {{ "conditionsOfUse.p5" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s5-1" | translate }}
    </p>
    <h1>6. {{ "conditionsOfUse.p6" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s6-1" | translate }}
    </p>
    <h1>7. {{ "conditionsOfUse.p7" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s7-1" | translate }}
    </p>
    <h1>8. {{ "conditionsOfUse.p8" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s8-1" | translate }}
    </p>
    <h1>9. {{ "conditionsOfUse.p9" | translate }}</h1>
    <ol>
      <li>
        {{ "conditionsOfUse.s9-1" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s9-2" | translate }}
      </li>
    </ol>
    <h1>10. {{ "conditionsOfUse.p10" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s10-1" | translate }}
    </p>
    <h1>11. {{ "conditionsOfUse.p11" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s11-1" | translate }}
    </p>
    <ol>
      <li>
        {{ "conditionsOfUse.s11-1-1" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s11-1-2" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s11-1-3" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s11-1-4" | translate }}
        <ol type="a">
          <li>
            {{ "conditionsOfUse.s11-1-4-a" | translate }}
          </li>
          <li>
            {{ "conditionsOfUse.s11-1-4-b" | translate }}
          </li>
        </ol>
      </li>
      <li>
        {{ "conditionsOfUse.s11-1-5" | translate }}
      </li>
    </ol>
    <h1>12. {{ "conditionsOfUse.p12" | translate }}</h1>
    <ol>
      <li>
        {{ "conditionsOfUse.s12-1" | translate }}
      </li>
      <li>
        {{ "conditionsOfUse.s12-2" | translate }}
        <ol type="a">
          <li>
            {{ "conditionsOfUse.s12-2-a" | translate }}
          </li>
          <li>
            {{ "conditionsOfUse.s12-2-b" | translate }}
          </li>
          <li>
            {{ "conditionsOfUse.s12-2-c" | translate }}
          </li>
        </ol>
      </li>
    </ol>
    <h1>13. {{ "conditionsOfUse.p13" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s13-1" | translate }} (<a
        href="https://egzotech.com/policy.html"
        >https://egzotech.com/policy.html</a
      >).
    </p>
    <h1>{{ "conditionsOfUse.p14" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s14-1" | translate }}
    </p>
    <h1>{{ "conditionsOfUse.p15" | translate }}</h1>
    <p>
      {{ "conditionsOfUse.s15-1" | translate }}
    </p>
  </div>
  <sba-language-selector class="language-selector"></sba-language-selector>
</div>

<sba-version-date></sba-version-date>
