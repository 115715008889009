<div class="muscle" *ngIf="model.icons as icons">
  <sba-taping-icons [type]="programType" [icons]="icons"></sba-taping-icons>
  <div class="muscle--right-container">
    <div class="muscle--name">
      {{ "muscles." + model.name | translate }}
    </div>
    <div class="muscle--channels" *ngIf="!model.supportSide">
      <div
        class="cell-equal"
        *ngIf="
          model
            | localFunction
              : getChannels
              : { side: 'M', others: otherChannels } as channels
        "
      >
        <div
          class="side-button left"
          matRipple
          [ngClass]="{
            disabled: channels.length >= model.channels || !nextActive
          }"
          (click)="selectMuscle($event, model, 'M')"
        >
          <div class="side-button--text">+</div>
        </div>
        <div class="badges">
          <div
            class="badge"
            matRipple
            (click)="release.emit(channel)"
            matTooltip="{{ 'channelMuscleMapper.clickToRelease' | translate }}"
            [ngStyle]="{
              backgroundColor: channel | channelcolor
            }"
            *ngFor="let channel of channels"
          >
            {{ channel + 1 }}
          </div>
        </div>
      </div>
    </div>
    <div class="muscle--channels" *ngIf="model.supportSide">
      <div
        class="cell-equal"
        *ngIf="
          model
            | localFunction
              : getChannels
              : { side: 'L', others: otherChannels } as channels
        "
      >
        <div
          class="side-button left"
          matRipple
          [ngClass]="{
            disabled: channels.length >= model.channels || !nextActive
          }"
          (click)="selectMuscle($event, model, 'L')"
        >
          <div class="side-button--text">{{ 'channelMuscleMapper.leftMuscle' | translate }}</div>
        </div>
        <div class="badges">
          <div
            class="badge"
            matRipple
            (click)="release.emit(channel)"
            matTooltip="{{ 'channelMuscleMapper.clickToRelease' | translate }}"
            [ngStyle]="{
              backgroundColor: channel | channelcolor
            }"
            *ngFor="let channel of channels"
          >
            {{ channel + 1 }}
          </div>
        </div>
      </div>
      <div
        class="cell-equal"
        *ngIf="
          model
            | localFunction
              : getChannels
              : { side: 'R', others: otherChannels } as channels
        "
      >
        <div
          class="side-button right"
          matRipple
          [ngClass]="{
            disabled: channels.length >= model.channels || !nextActive
          }"
          (click)="selectMuscle($event, model, 'R')"
        >
          <div class="side-button--text">{{ 'channelMuscleMapper.rightMuscle' | translate }}</div>
        </div>
        <div class="badges">
          <div
            class="badge"
            matRipple
            (click)="release.emit(channel)"
            matTooltip="{{ 'channelMuscleMapper.clickToRelease' | translate }}"
            [ngStyle]="{
              backgroundColor: channel | channelcolor
            }"
            *ngFor="let channel of channels"
          >
            {{ channel + 1 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
