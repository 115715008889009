<div mat-dialog-content>
  <p>
    {{ "components.connection.prompt" | translate }}
  </p>
  <mat-progress-spinner diameter="64" mode="indeterminate">
  </mat-progress-spinner>
  <div class="dialog-actions">
    <div class="outline-button" mat-ripple (click)="changeStella()">
      {{ "components.connection.changeStella" | translate }}
    </div>
    <div class="outline-button" mat-ripple (click)="quit()">
      {{ "common.quit" | translate }}
    </div>
  </div>
</div>
