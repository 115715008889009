import { Component, Input } from '@angular/core';

@Component({
  selector: 'sba-clock',
  templateUrl: './clock.component.html',
  styleUrls: ['./clock.component.scss']
})
export class ClockComponent {

  @Input() progress;
  @Input() text;
  @Input() phases = null;
}
