<div class="controls--container">
  <div class="button--column play">
    <div
      matRipple
      class="button play"
      [ngClass]="{ initial: state.current === 'INITIAL' }"
      (click)="state.canBePaused ? pause.emit() : start.emit()"
    >
      <mat-icon>{{ state.canBePaused ? "pause" : "play_arrow" }}</mat-icon>
    </div>
    <p class="button--label">
      {{ "electrostim." + (state.canBePaused ? "pause" : "play") | translate }}
    </p>
  </div>
  <div class="button--column">
    <div class="button--column stop">
      <div
        matRipple
        class="button stop"
        [ngClass]="{ initial: true }"
        (click)="stop.emit()"
      >
        <mat-icon>stop</mat-icon>
      </div>
      <p class="button--label">
        {{ "electrostim.stop" | translate }}
      </p>
    </div>
  </div>
</div>
