<sba-page-title title='title.training'></sba-page-title>
<div class="waiting-room">
  <h1>{{ 'idle.title' | translate }}</h1>
  <h2>{{ 'idle.subtitle' | translate }}</h2>
  <div class="content">
    <div matRipple class="package" (click)="selectPackage(pckg)" *ngFor="let pckg of packages">
      <img class="package--icon" [src]="pckg.icon"/>
      <p>{{ "concepts."+pckg.name | translate }}</p></div>
  </div>
</div>
