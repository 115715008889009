import { DeepReadonly } from "@egzotech/exo-electrostim";

/**
 * Represents a unique cable identifier
 */
export enum CableIdentifier {
    EMGEMS_2_0 = 3115,
    EMGEMS_6_2 = 65295,
    EMGEMS_4_0 = 3096,
    EMGEMS_2_1 = 3108,
    EMGEMS_2_2 = 3102,
    EMGEMS_4_2 = 3087,
    EMGEMS_4_1 = 3092,
    EMGEMS_8_0 = 65290,
    NONE = 65535,
    UNKNOWN = -1
}

export type CableChannelFeature = "emg" | "ems" | "emg-pelvic" | "ems-pelvic";

export interface CableType {
    /** Cable unique identifier */
    id: number;
    description: string;
    /** Gets available channels for the cable */
    channels: readonly number[];
    /** Gets available fetures for channels */
    channelFeatures: CableChannelFeature[][];
}

export enum ChannelConnectionQuality {
    NONE = 'none',
    POOR = 'poor',
    WELL = 'well'
}

export const cableTypes: { [key: string]: DeepReadonly<CableType> } = {
    [CableIdentifier.EMGEMS_8_0]: {
        id: CableIdentifier.EMGEMS_8_0,
        // Future application - walking reeducation
        description: 'cable.emg_ems_8_0',
        channels: [0, 1, 2, 3, 4, 5, 6, 7],
        channelFeatures: [
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"]
        ],
    },
    [CableIdentifier.EMGEMS_4_1]: {
        id: CableIdentifier.EMGEMS_4_1,
        // Default Home Use Advanced for US
        description: 'cable.emg_ems_4_1',
        channels: [0, 1, 2, 3, 6],
        channelFeatures: [
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg-pelvic", "ems-pelvic"],
        ],
    },
    [CableIdentifier.EMGEMS_2_2]: {
        id: CableIdentifier.EMGEMS_2_2,
        // Pain + Pelvic floor Upgrade for EU, low demand
        description: 'cable.emg_ems_2_2',
        channels: [0, 1, 6, 7],
        channelFeatures: [
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg-pelvic", "ems-pelvic"],
            ["emg-pelvic", "ems-pelvic"],
        ]
    },
    [CableIdentifier.EMGEMS_4_0]: {
        id: CableIdentifier.EMGEMS_4_0,
        // Default Neuro/Otho/Sports cable
        description: 'cable.emg_ems_4_0',
        channels: [0, 1, 2, 3],
        channelFeatures: [
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
        ],
    },
    [CableIdentifier.EMGEMS_2_0]: {
        id: CableIdentifier.EMGEMS_2_0,
        // Default Pain/ TENS Home Use for EU & USA, low demand
        description: 'cable.emg_ems_2_0',
        channels: [0, 1],
        channelFeatures: [
            ["emg", "ems"],
            ["emg", "ems"],
        ],
    },
    [CableIdentifier.EMGEMS_6_2]: {
        id: CableIdentifier.EMGEMS_6_2,
        // Default Professional for EU
        description: 'cable.emg_ems_6_2',
        channels: [0, 1, 2, 3, 4, 5, 6, 7],
        channelFeatures: [
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg-pelvic", "ems-pelvic"],
            ["emg-pelvic", "ems-pelvic"],
        ],
    },
    [CableIdentifier.EMGEMS_2_1]: {
        id: CableIdentifier.EMGEMS_2_1,
        // Pain + Pelvic floor Upgrade for US, low demand
        description: 'cable.emg_ems_2_1',
        channels: [0, 1, 6],
        channelFeatures: [
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg-pelvic", "ems-pelvic"],
        ],
    },
    [CableIdentifier.EMGEMS_4_2]: {
        id: CableIdentifier.EMGEMS_4_2,
        // Default Home Use Advanced for EU
        description: 'cable.emg_ems_4_2',
        channels: [0, 1, 2, 3, 6, 7],
        channelFeatures: [
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg", "ems"],
            ["emg-pelvic", "ems-pelvic"],
            ["emg-pelvic", "ems-pelvic"],
        ],
    },
    [CableIdentifier.NONE]: {
        id: CableIdentifier.NONE,
        description: 'cable.none',
        channels: [],
        channelFeatures: [],
    },
    [CableIdentifier.UNKNOWN]: {
        id: CableIdentifier.UNKNOWN,
        description: 'cable.unknown',
        channels: [],
        channelFeatures: [],
    }
};
