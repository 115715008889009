import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConditionsOfUseComponent } from '@components/conditions-of-use/conditions-of-use.component';
import { PrivacyNoticeComponent } from '@components/privacy-notice/privacy-notice.component';
import { AcceptInvitationComponent } from '@components/accept-invitation/accept-invitation.component';
import { NotFoundComponent } from './not-found/not-found.component';

export const routes: Routes = [
  {
    path: 'setup',
    redirectTo: '/stella/connect/new'
  },
  {
    path: 'acceptInvitation',
    component: AcceptInvitationComponent
  },
  {
    path: 'conditions-of-use',
    component: ConditionsOfUseComponent
  },
  {
    path: 'privacy-notice',
    component: PrivacyNoticeComponent
  },
  {
    path: 'nfc/:serialNumber',
    redirectTo: '/sign-in'
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule)
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'select',
    loadChildren: () => import('./clinics/clinics.module').then(m => m.ClinicModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'stella',
    loadChildren: () => import('./stella/stella.module').then(m => m.StellaModule)
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: 'patient',
    loadChildren: () => import('./patient/patient.module').then(m => m.PatientModule)
  },
  {
    path: 'chat',
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: 'superadmin',
    loadChildren: () => import('./license-managment/license-managment.module').then(m => m.LicenseManagmentModule)
  },
  {
    path: "**", component: NotFoundComponent, pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
