import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MusclesModule } from '@app/muscles/muscles.module';
import { StellaModule } from '@app/stella/stella.module';
import { CableSelectMessageComponent } from '@app/training/components/cable-select-message/cable-select-message.component';
import { ChannelMuscleMapperComponent } from '@app/training/components/channel-muscle-mapper/channel-muscle-mapper.component';
import { ClockComponent } from '@app/training/components/clock/clock.component';
import { ConnectionDialogComponent } from '@app/training/components/connection-dialog/connection-dialog.component';
import { ContactionLegendComponent } from '@app/training/components/contaction-legend/contaction-legend.component';
import { ElectroSliderComponent } from '@app/training/components/electro-slider/electro-slider.component';
import { ElectrostimLevelControlComponent } from '@app/training/components/electrostim-level-control/electrostim-level-control.component';
import { ElectrostimWarningDialoComponent } from '@app/training/components/electrostim-warning-dialog/electrostim-warning-dialog.component';
import { EmgCalibrationComponent } from '@app/training/components/emg-calibration/emg-calibration.component';
import { EndTrainingDialogComponent } from '@app/training/components/end-training-dialog/end-training-dialog.component';
import { EnterPasswordDialogComponent } from '@app/training/components/enter-password-dialog/enter-password-dialog.component';
import { ExerciseContainerComponent } from '@app/training/components/exercise-container/exercise-container.component';
import { GameCopyrightActionComponent } from '@app/training/components/game-copyright-action/game-copyright-action.component';
import { OverThresholdDialogComponent } from '@app/training/components/over-threshold-dialog/over-threshold-dialog.component';
import { ProgramsGroupedComponent } from '@app/training/components/programs-grouped/programs-grouped.component';
import { SelectMuscleComponent } from '@app/training/components/select-muscle/select-muscle.component';
import { SessionIndicatorComponent } from '@app/training/components/session-indicator/session-indicator.component';
import { SignalWaveformDialogComponent } from '@app/training/components/signal-waveform-dialog/signal-waveform-dialog.component';
import { StellaConnectedEmgBarChartComponent } from '@app/training/components/stella-connected-emg-bar-chart/stella-connected-emg-bar-chart.component';
import { StellaConnectedEmgChartComponent } from '@app/training/components/stella-connected-emg-chart/stella-connected-emg-chart.component';
import { StimCalibrationComponent } from '@app/training/components/stim-calibration/stim-calibration.component';
import { StimSettingAdjustmentComponentComponent } from '@app/training/components/stim-setting-adjustment-component/stim-setting-adjustment-component.component';
import { UploadProgressDialogComponent } from '@app/training/components/upload-progress-dialog/upload-progress-dialog.component';
import { BiofeedbackComponent } from '@app/training/pages/biofeedback/biofeedback.component';
import { ConceptComponent } from '@app/training/pages/concept/concept.component';
import { CustomDiagnosticTestComponent } from '@app/training/pages/custom-diagnostic-test/custom-diagnostic-test.component';
import { NoSuchProgramComponent } from '@app/training/pages/electrostim/components/no-such-program/no-such-program.component';
import { ElectrostimComponent } from '@app/training/pages/electrostim/electrostim.component';
import { EmgComponent } from '@app/training/pages/emg/emg.component';
import { GameLevelListComponent } from '@app/training/pages/game-level-list/game-level-list.component';
import { GamesComponent } from '@app/training/pages/games/games.component';
import { WaitingRoomComponent } from '@app/training/pages/waiting-room/waiting-room.component';
import { FrequenciesPipe } from '@app/training/pipes/frequencies.pipe';
import { MusclePipe } from '@app/training/pipes/muscle.pipe';
import { ProgressPipePipe } from '@app/training/pipes/progress-pipe.pipe';
import { PulsesPipe } from '@app/training/pipes/pulses.pipe';
import { TrainingRoutingModule } from '@app/training/training-routing.module';
import { TrainingComponent } from '@app/training/training.component';
import { ComponentsModule } from '@components/components.module';
import { ConfirmationDialogComponent } from '@components/confirmation-dialog/confirmation-dialog.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { MomentModule } from 'ngx-moment';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { EmergencyButtonClickedDialogComponent } from './components/emergency-button-clicked-dialog/emergency-button-clicked-dialog.component';
import { EmgTrainingControlsComponent } from './components/emg-training-controls/emg-training-controls.component';
import { EmgTrainingTimersComponent } from './components/emg-training-timers/emg-training-timers.component';
import { TrainingTitleRowComponent } from './components/training-title-row/training-title-row.component';
import { BiofeedbackSetupComponent } from './components/biofeedback-setup/biofeedback-setup.component';
import { MatProgressBarModule } from '@angular/material';
import { CableWarningDialogComponent } from './components/cable-warning-dialog/cable-warning-dialog.component';
import { StimCalibrationProgramModuleComponent } from './components/stim-calibration-program-module/stim-calibration-program-module.component';
import { ChannelSelectorMuscleMapperComponent } from './components/channel-selector-muscle-mapper/channel-selector-muscle-mapper.component';
import { NewExerciseContainerComponent } from './components/new-exercise-container/new-exercise-container.component';
import { StimConfigurationSettingAdjustmentComponentComponent } from './components/stim-configuration-setting-adjustment-component/stim-configuration-setting-adjustment-component.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    TrainingComponent,
    EmgComponent,
    BiofeedbackComponent,
    CustomDiagnosticTestComponent,
    ElectrostimComponent,
    ElectroSliderComponent,
    NoSuchProgramComponent,
    SelectMuscleComponent,
    GamesComponent,
    GameLevelListComponent,
    ChannelMuscleMapperComponent,
    ChannelSelectorMuscleMapperComponent,
    ProgramsGroupedComponent,
    EnterPasswordDialogComponent,
    ConnectionDialogComponent,
    ExerciseContainerComponent,
    NewExerciseContainerComponent,
    ClockComponent,
    EndTrainingDialogComponent,
    SessionIndicatorComponent,
    UploadProgressDialogComponent,
    ElectrostimWarningDialoComponent,
    EmgCalibrationComponent,
    StimCalibrationComponent,
    StimCalibrationProgramModuleComponent,
    WaitingRoomComponent,
    ConceptComponent,
    ElectrostimLevelControlComponent,
    StellaConnectedEmgChartComponent,
    ProgressPipePipe,
    FrequenciesPipe,
    PulsesPipe,
    StellaConnectedEmgBarChartComponent,
    StimSettingAdjustmentComponentComponent,
    StimConfigurationSettingAdjustmentComponentComponent,
    MusclePipe,
    GameCopyrightActionComponent,
    ContactionLegendComponent,
    SignalWaveformDialogComponent,
    CableSelectMessageComponent,
    OverThresholdDialogComponent,
    CableWarningDialogComponent,
    EmergencyButtonClickedDialogComponent,
    EmgTrainingControlsComponent,
    EmgTrainingTimersComponent,
    TrainingTitleRowComponent,
    BiofeedbackSetupComponent,
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    EndTrainingDialogComponent,
    EnterPasswordDialogComponent,
    ConnectionDialogComponent,
    ElectrostimWarningDialoComponent,
    EmgCalibrationComponent,
    StimCalibrationComponent,
    StimCalibrationProgramModuleComponent,
    StimSettingAdjustmentComponentComponent,
    StimConfigurationSettingAdjustmentComponentComponent,
    SignalWaveformDialogComponent,
    OverThresholdDialogComponent,
    CableWarningDialogComponent,
    EmergencyButtonClickedDialogComponent,
  ],

  imports: [
    CommonModule,
    TrainingRoutingModule,
    MatToolbarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    HttpClientModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    DragDropModule,
    MusclesModule,
    MatSliderModule,
    MatMenuModule,
    StellaModule,
    MatDialogModule,
    MatSelectModule,
    DeviceDetectorModule.forRoot(),
    MatInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatDatepickerModule,
    ComponentsModule,
    MomentModule,
    ClickOutsideModule,
    PerfectScrollbarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressBarModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  exports: [EnterPasswordDialogComponent, SessionIndicatorComponent]
})
export class TrainingModule { }
