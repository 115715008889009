import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, flatMap, map, tap } from 'rxjs/operators';
import { LAST_LOGGED_IN, STELLA_CURRENT_PATIENT } from '@app/enums';
import { AuthEndpointsService } from '../../api/auth-endpoints.service';
import { LoginCredentials } from '../../api/dtos/LoginCredentials';
import { RestEndpointsService } from '../../api/rest-endpoints.service';
import { ACCESS_TOKEN_KEY, STELLA_CURRENT_CLINIC, STELLA_CURRENT_USER } from '@app/enums';
import { AppMode, AuthStore } from '@app/store/auth/auth.store';
import { ClinicsStore } from '@app/store/clinics/clinics.store';
import { LoadingStore } from '@app/store/loading/loading.store';
import { LoginInfo, Passwords, SimpleValue } from '@app/types';
import { SignupCredentials } from './../../api/dtos/SignupCredentials';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { UpdateProfileRequest } from '../types';
import { Me } from '@app/models/Me.model';
import { Role } from '@app/models/Role.enum';

interface StellaAppUser extends Me {
  extras?: { [key: string]: any };
  role?: Role;
  stimRiskAccepted?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private readonly api: AuthEndpointsService,
    private readonly rest: RestEndpointsService,
    private readonly authStore: AuthStore,
    private readonly clinicStore: ClinicsStore,
    private readonly loadingStore: LoadingStore,
    private readonly toastr: ToastrService,
    private readonly translation: TranslateService
  ) { }

  set currentUser(value: StellaAppUser) {
    this.authStore.update({ mode: value ? AppMode.FULL : AppMode.GUEST });
    localStorage.setItem(STELLA_CURRENT_USER, JSON.stringify(value));
  }

  get currentUser(): StellaAppUser | null {
    try {
      return JSON.parse(localStorage.getItem(STELLA_CURRENT_USER));
    } catch (err) {
      return null;
    }
  }

  isSuperadmin(): boolean {
    return this.currentUser.extras.superadmin;
  }

  setRole(role: Role) {
    this.authStore.update({role});
    const us = this.currentUser;
    this.currentUser = { ...us, role};
  }

  saveLastLoggedIn(entry: LoginInfo) {
    let list: any[];
    const val = this.fetchLastLoggedIn();
    if (val) {
      list = [entry, ...val.filter(d => d.email !== entry.email)].slice(0, 5);
    } else {
      list = [entry];
    }
    localStorage.setItem(LAST_LOGGED_IN, JSON.stringify(list));
  }

  fetchLastLoggedIn(): LoginInfo[] {
    const lsValue = localStorage.getItem(LAST_LOGGED_IN);
    if (!lsValue || lsValue === 'null') {
      return [];
    }
    try {
      return JSON.parse(lsValue);
    } catch (err) {
      return [];
    }
  }

  removeFromLastLoggedIn(email: string) {
    let list: any[];
    const val = this.fetchLastLoggedIn();
    list = val.filter(d => d.email !== email);
    localStorage.setItem(LAST_LOGGED_IN, JSON.stringify(list));
  }

  loginAnonymous(): void {
    this.clinicStore.reset();
    this.currentUser = null;
    this.clearLocalStorage();
    this.authStore.update({ isLoggedIn: false });
  }

  login(payload: LoginCredentials): Observable<boolean> {
    this.loadingStore.update(s => ({ ...s, LOGIN: true }));
    return this.api.login(payload).pipe(
      tap(() => {
        this.clinicStore.reset();
        this.clearLocalStorage();
      }),
      tap(token => {
        const { accessToken } = token;
        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
      }),
      flatMap(() => this.rest.fetchMe()),
      tap(user => this.saveLastLoggedIn({
        email: payload.email,
        date: new Date(),
        id: user.id,
        gender: user.gender
      })),
      tap(_ => this.authStore.update({ isLoggedIn: true })),
      tap(user => (this.currentUser = user)),
      map(() => true),
      finalize(() => {
        this.loadingStore.update(s => ({ ...s, LOGIN: false }));
      })
    );
  }

  updateProfile(data: UpdateProfileRequest) {
    return this.rest.updateMe(data)
    .pipe(
      tap(() => {
        const user = this.currentUser;
        this.currentUser = {...user, ...data};
      })
    );
  }

  signup(payload: SignupCredentials): Observable<boolean> {
    this.loadingStore.update(s => ({ ...s, SIGNUP: true }));
    return this.api.signup(payload).pipe(
      map(rs => rs.status === 'OK'),
      finalize(() => {
        this.loadingStore.update(s => ({ ...s, SIGNUP: false }));
      })
    );
  }

  resetPassword(token: string, passwords: Passwords): Observable<boolean> {
    return this.api
      .resetPassword({
        token,
        ...passwords
      })
      .pipe(map(rs => rs.status === 'OK'));
  }

  forgetPassword(data: SimpleValue<string>): Observable<boolean> {
    this.loadingStore.update(s => ({ ...s, FORGET: true }));
    return this.api
      .forgetPassword({ email: data.value })
      .pipe(
        map(rs => rs.status === 'OK'),
        finalize(() => this.loadingStore.update(s => ({ ...s, FORGET: false }))));
  }

  logout(): void {
    this.clinicStore.reset();
    this.clearLocalStorage();
    this.authStore.update({
      currentUser: null,
      isLoggedIn: false,
      mode: null,
      role: null
    });
    this.translation.get('auth.logoutMessage')
      .subscribe(translation => {
        this.toastr.info(translation, '');
      });
  }

  private clearLocalStorage() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(STELLA_CURRENT_USER);
    localStorage.removeItem(STELLA_CURRENT_CLINIC);
    localStorage.removeItem(STELLA_CURRENT_PATIENT);
  }
}
