import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UNITS } from '@app/enums';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sba-unit-selector',
  templateUrl: './unit-selector.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitSelectorComponent {
  @Input() value;
  @Output() change = new EventEmitter();
  units: any;

  constructor(private translateService: TranslateService) {
    this.units = UNITS.map(unit => {
      return {
        ...unit,
        label: this.translateService.instant(`common.units.${unit.value}`)
      }
    })
  }
}
