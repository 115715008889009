<sba-page-title title="title.stella_list"></sba-page-title>
<div>
  <div class="back-arrow" [@slideTop]="true">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>

  <div class="stellas">
    <ng-container *ngIf="listStella.stellaList$ | async as list">
      <h1 [@fadeInOut]>{{ "selectStella.title" | translate }}</h1>
      <p>{{ "selectStella.clickToContinue" | translate }}</p>
      <mat-icon
        class="stella-icon"
        [ngClass]="{ notempty: list.length }"
        svgIcon="stellabio_vert"
      ></mat-icon>
      <div class="stellas--container">
        <sba-single-stella
          *ngFor="let stella of list; trackBy: trackStella"
          [stella]="stella"
          (clicked)="handleClick(stella)"
          [@fadeInOut]
        >
        </sba-single-stella>
        <div class="no-stella">
          <p>{{ "selectStella.empty" | translate }}</p>
          <div class="buttons-row">
            <button
              mat-button
              class="action-button"
              (click)="handleConnectClick()"
            >
              {{ "selectStella.configureNew" | translate }}
            </button>
            <p class="or">{{ "common.or_low" | translate }}</p>
            <button mat-button class="skip-button" (click)="skipStella()">
              {{ "common.skip" | translate | lowercase }}
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<sba-version-date></sba-version-date>
