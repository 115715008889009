import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Pages } from '@app/pages';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { DashboardMode } from '@app/enums';

@Component({
  selector: 'sba-connection-dialog',
  templateUrl: './connection-dialog.component.html',
  styleUrls: ['./connection-dialog.component.scss']
})
export class ConnectionDialogComponent {

  constructor(
    private readonly router: Router,
    private readonly dashboard: DashboardService,
    private readonly stella: StellaDirectService
  ) {}

  changeStella() {
    this.router.navigate([Pages.STELLA_CONNECT], { state: { change: true}});
  }

  quit() {
    this.stella.disconnect();
    if (this.dashboard.mode === DashboardMode.PATIENT) {
      this.router.navigate([Pages.PATIENT_DASHBOARD]);
    } else if (this.dashboard.mode === DashboardMode.SPECIALIST) {
      this.router.navigate([Pages.PATIENT_MEDICAL_CARD]);
    }
  }
}
