import { OnInit } from '@angular/core';
import { StellaCareService } from '@app/shared/services/stella-care.service';
import { ExercisesQuery } from '@app/store/exercises';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PurchaseDialogComponent } from '@app/components/purchase-dialog/purchase-dialog.component';
import { Observable, of } from 'rxjs';
import { map, flatMap, tap } from 'rxjs/operators';
import { GamesService } from '@app/shared/services/games.service';

export abstract class BaseProgramListComponent implements OnInit {

  programStream$: Observable<any[]>;

  constructor(
    protected readonly stellaCare: StellaCareService,
    protected readonly exercises: ExercisesQuery,
    protected readonly dialog: MatDialog,
    protected readonly router: Router,
    protected readonly gamesService: GamesService
  ) {}

  ngOnInit(): void {
    this.programStream$ =
      this.exercises.selectAll()
        .pipe(
          map(all => all
            .filter(a => this.filterProgram(a))
            .map(a => this.mapProgram(a))
            .map(test => this.stellaCare.decorateExercise(test))
            .filter(g => this.stellaCare.filterOutHidden(g))
            .map(g => this.stellaCare.decorateWithBadges(g))
          ),
        );
  }
  
  abstract mapProgram(program): any;
  abstract filterProgram(program): boolean;

  openProgram(program) {
    if (!program.allowed) {
      return this.dialog.open(PurchaseDialogComponent, {
        data: {
          module: program.licenses.join(', ')
        }
      });
    }
    return this.router.navigate([program.path]);
  }
}
