import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { CLEAR_STIM_FLAG } from '@app/stella/services/StellaCommands';

@Component({
  selector: 'sba-cable-warning-dialog',
  templateUrl: './cable-warning-dialog.component.html',
  styleUrls: ['./cable-warning-dialog.component.scss']
})
export class CableWarningDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CableWarningDialogComponent>,
    private readonly stella: StellaDirectService,
    @Inject(MAT_DIALOG_DATA) public readonly data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  confirm() {
    this.stella.send(CLEAR_STIM_FLAG);
    this.dialogRef.close(true);
  }
}
