<div class="tile" [class.selected]="selected === true" [ngClass]="{'disallowed': disallowed}">
  <div class="icon-container" [ngClass]="{'nope': ddata.icon}">
    <img *ngIf="ddata.icon" [attr.src]="ddata.icon" [ngClass]="{'rounded': rounded}" loading="lazy">
  </div>
  <div class="content">
    <div class="title-line">
      <h2>{{ ddata.title | translate }}</h2>
      <div class="badge-container" *ngIf="ddata.badges?.length">
        <span *ngFor="let item of ddata.badges" [ngClass]="item">{{ 'badge.' + item | translate }}</span>
      </div>
      <div class="filler"></div>
      <ng-content select="[actions]"></ng-content>
      <sba-checkbox *ngIf="selected !== undefined" [selected]="selected"></sba-checkbox>
    </div>
    <sba-show-more-paragraph text="{{ddata.description | translate}}" limit="150"></sba-show-more-paragraph>
    <ng-content select="[stim]"></ng-content>
  </div>
</div>
