<div class="language-sel">
  <button [matMenuTriggerFor]="menu" mat-button class="language-sel--button">
    {{ lang.label }}
  </button>
  <mat-menu xPosition="before" #menu="matMenu" class="customize {{theme}}" >
    <button *ngFor="let lang of languages" mat-menu-item (click)="change(lang)">
      <span class="text space-from-flag">{{ lang.label }}</span>
    </button>
  </mat-menu>
</div>
