import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { Clinic } from '@app/models/Clinic.model';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '@app/auth/services/auth.service';
import { ClinicsService } from '@app/clinics/services/clinics.service';
import { AuthQuery } from '@app/store/auth';
import { filter } from 'rxjs/operators';
import { Pages } from '@app/pages';

@Component({
  selector: 'sba-stella',
  templateUrl: './stella.component.html',
  styleUrls: ['./stella.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StellaComponent {
  isAuthorized$;
  hideAdditionalInfo = false;
  user = {
    displayName: '',
    gender: 'ND',
    avatar: '',
    role: null
  };
  currentClinic$: Observable<Clinic> = this.clinic.clinic$;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private readonly router: Router,
    private authService: AuthService,
    public clinic: ClinicsService,
    authQuery: AuthQuery
  ) {
    [
      'add',
      'buy',
      'stella',
      'battery_empty',
      'battery_full',
      'battery_1',
      'battery_2',
      'battery_3',
      'battery_4',
      'wifi_0',
      'wifi_1',
      'wifi_2',
      'wifi_none',
      'wifi',
      'stellabio_vert'
    ].forEach(name => {
      this.matIconRegistry.addSvgIcon(
        name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `../../../assets/svg/icon_${name}.svg`
        )
      );
    });
    this.matIconRegistry.addSvgIcon(
      'logo',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `../../../assets/svg/app_icon.svg`
      )
    );

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(_ => {
        this.user.displayName = authService.currentUser
          ? authService.currentUser.displayName
          : 'Anonymous';
        this.user.gender = authService.currentUser
          ? authService.currentUser.gender
          : 'not_defined';
        this.user.avatar = authService.currentUser.avatar;
        this.user.role = authService.currentUser.role;
      });

    this.isAuthorized$ = authQuery.isAuthorized$;
  }


  async handleLogout() {
    if (await this.router.navigate([Pages.SIGNIN])) {
      this.authService.logout();
    }
  }

  goToClinicSelection() {
    this.router.navigate([Pages.SELECT_CLINIC]);
  }
}
