import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'sba-electrostim-level-control',
  templateUrl: './electrostim-level-control.component.html',
  styleUrls: ['./electrostim-level-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElectrostimLevelControlComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef) {}

  @Input() levels: number[];
  @Input() range: any;
  @Input() set channels(value: number[]) {
    if (value) {
      this.currentChannelSelected = value[0];
    }
    this._channels = value;
    this.cdr.detectChanges();
  }
  _channels: number[];
  @Input() canIncrement: boolean;
  @Output() intensityChange = new EventEmitter();
  @Input() disallowIntensityChange: boolean;

  currentChannelSelected = 0;

  ngOnInit(): void {
  }

  changeSelectedChannel(channel) {
    this.currentChannelSelected = channel;
    this.cdr.detectChanges();
  }

  modifyLevel(val) {
    if (this.levels[this.currentChannelSelected] + val > this.range.max
      || this.levels[this.currentChannelSelected] + val < this.range.min) {
      console.warn('[Electrostim] Amperage value is out of range');
      return;
    }
    this.levels[this.currentChannelSelected] += val;

    this.intensityChange.emit({
      channel: this.currentChannelSelected,
      value: this.levels[this.currentChannelSelected]
    });
  }
}
