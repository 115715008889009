import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap, map } from 'rxjs/operators';
import { ExercisesQuery } from '@app/store/exercises/exercise.query';
import { StellaCareService } from '@app/shared/services/stella-care.service';
import { MatDialog } from '@angular/material/dialog';
import { BaseProgramListComponent } from '@app/training/components/BaseProgramList.component';
import { Title } from '@angular/platform-browser';
import { GamesService } from '@app/shared/services/games.service';
import { Location } from '@angular/common';
import { PurchaseDialogComponent } from '@components/purchase-dialog/purchase-dialog.component';
import { GTagService, AnalyticsCategory, AnalyticsAction } from '@app/shared/gtag.service';
import { AuthQuery } from '@store/auth';
import { DashboardService } from '@app/dashboard/services/dashboard.service';

@Component({
  selector: 'sba-concept',
  templateUrl: './concept.component.html',
  styleUrls: ['./concept.component.scss']
})
export class ConceptComponent extends BaseProgramListComponent implements OnInit {
  concept: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly dashboard: DashboardService,
    private readonly gtag: GTagService,
    private readonly authQuery: AuthQuery,
    games: GamesService,
    stellaCare: StellaCareService,
    exercises: ExercisesQuery,
    dialog: MatDialog,
    router: Router,
    title: Title) {
    super(stellaCare, exercises, dialog, router, games);
    title.setTitle('Concepts | Stella BIO App');
  }

  filterProgram(ex): boolean {
    return ex.extras.conceptCategories.includes(this.concept);
  }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        map(params => params.get('concept')),
        tap(param => this.concept = param),
        tap(() => super.ngOnInit()),
        tap(() => this.gtag.emit(AnalyticsCategory.CONCEPT, AnalyticsAction.CONCEPT_SELECT, this.concept))
      )
      .subscribe();
  }

  mapProgram(ex): any {
    const licenses = this.authQuery.licenses();
    if (!licenses.includes(this.concept)) {
      return {
        ...ex,
        allowed: false
      };
    }

    return ex;
  }

  goBack() {
    this.location.back();
  }

  openProgram(program) {
    if (!program.allowed) {
      return this.dialog.open(PurchaseDialogComponent, {
        data: {
          module: [this.concept].join(', ')
        }
      });
    }
    localStorage.setItem('CURRENT_CONCEPT', this.concept);
    this.gtag.emit(AnalyticsCategory.TRAINING_SELECTION, this.concept, program.name);
    this.dashboard.trainingTemplate = null;
    return this.router.navigate([program.path]);
  }
}
