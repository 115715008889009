import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StellaConnectionStatus } from '@app/enums';
import { Exercise } from '@app/models/Exercise.model';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { ExercisesQuery } from '@app/store/exercises/exercise.query';
import { oldToNewProgramNamesMapping } from '@app/training/pages/electrostim/electrostim.component';
import { ChannelMuscleMapperValidEvent, SelectedMuscle } from '@app/types';
import { getPredefinedElectrostimConfigurations } from '@app/utils/electrostim/stim-programs';
import { getNameFromParams } from '@app/utils/UtilsRX';
import { DeepReadonly, StimConfiguration } from '@egzotech/exo-electrostim';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ConnectionDialogComponent } from '../connection-dialog/connection-dialog.component';

@Component({
  selector: 'sba-new-exercise-container',
  templateUrl: './new-exercise-container.component.html',
  styleUrls: ['./new-exercise-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewExerciseContainerComponent implements OnInit, OnDestroy {
  @Output() start = new EventEmitter<{ muscles: SelectedMuscle[], wellChannels: SelectedMuscle[] }>();
  alreadyStarted = false;
  isValid = false;
  program: Exercise;
  stimConfig: DeepReadonly<StimConfiguration>;
  sub = new Subscription();
  connectionDialogRef: MatDialogRef<ConnectionDialogComponent>;

  constructor(
    private stellaDirect: StellaDirectService,
    private route: ActivatedRoute,
    private exercises: ExercisesQuery,
    private matDialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.sub.add(this.prepareProgram());
  }

  restart() {
    this.alreadyStarted = false;
    this.isValid = false;

    this.cdr.detectChanges();
  }

  prepareProgram() {
    return getNameFromParams(this.route)
      .pipe(
        map(name => {
          if (!name) {
            const parts = this.router.url.split('emg/');
            return parts.pop();
          }
          return name;
        }),
        map(name => this.getTestForName(name)),
        tap(program => {
          this.program = program;

          const stimProgram = oldToNewProgramNamesMapping[this.program.name]
          if (!stimProgram) {
            throw new Error("Missing electrostim program named: " + this.program.name);
          }

          this.stimConfig = getPredefinedElectrostimConfigurations()[stimProgram];
        }),
        tap(() => this.isValid = false),
        tap(program => {
          if (program.steps.requireStella || true) {
            if (this.stellaDirect.connected$.value.connected === StellaConnectionStatus.DISCONNECTED) {
              this.stellaDirect.connect();
            }
            this.sub.add(this.createConnectionStateObservable().subscribe());
          }
        })
      )
      .subscribe(_ => {
        this.cdr.detectChanges();
      });
  }

  createConnectionStateObservable() {
    return this.stellaDirect.connected$
      .pipe(
        filter(() => environment.expectStellaConnection),
        tap(data => {
          if (!this.connectionDialogRef && data.connected !== StellaConnectionStatus.CONNECTED) {
            this.connectionDialogRef = this.matDialog.open(ConnectionDialogComponent, {
              disableClose: true
            });
            return;
          }
          if (this.connectionDialogRef && data.connected === StellaConnectionStatus.CONNECTED) {
            this.connectionDialogRef.close();
            this.connectionDialogRef = null;
          }
        })
      );
  }

  getTestForName(name: string): Exercise {
    const programs = this.exercises.getAll();
    const test = programs.find(e => e.name === name);
    return test;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.connectionDialogRef) {
      this.connectionDialogRef.close();
    }
  }

  async changeIsValid(event: ChannelMuscleMapperValidEvent) {
    if (event.res && event.res === 'invalid' && this.isValid === true) {
      this.isValid = false;
      this.cdr.detectChanges();
      return;
    }
    if (event.muscles) {
      this.isValid = true;
      if (!this.alreadyStarted) {
        this.alreadyStarted = true;
        this.start.emit({ muscles: event.muscles ?? [], wellChannels: event.wellChannels ?? [] });
        this.cdr.detectChanges();
      }
      return;
    }
  }
}
