import { Injectable } from '@angular/core';
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, flatMap, map, tap } from 'rxjs/operators';
import { STELLA_CURRENT_CLINIC, ACCESS_TOKEN_KEY } from '@app/enums';
import { Clinic } from '../../models/Clinic.model';
import { Role } from '../../models/Role.enum';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { ClinicsQuery, ClinicsStore } from '@app/store/clinics';
import { InvitationsStore } from '@app/store/invitation';
import { LoadingStore } from '@app/store/loading';
import { CreateInvitation, MinimalClinic, ProcessInvitation, ProcessUserInvitation, TokenProcessInvitation } from '@app/types';
import { StatusResponse } from '../../api/dtos/responses/StatusResponse';
import { RestEndpointsService } from '../../api/rest-endpoints.service';
import { checkUrlStartsWithProtocol } from '../../utils/utils';
import { AuthService } from './../../auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ExercisesStore } from '@store/exercises';
import { VisitsStore } from '@app/store/visits';
import { PatientDashboardService } from '@app/patient/services/patient-dashboard.service';
import { JoinRequest } from '../types';


@Injectable({
  providedIn: 'root'
})
export class ClinicsService {
  constructor(
    private readonly authService: AuthService,
    private readonly rest: RestEndpointsService,
    private readonly clinicStore: ClinicsStore,
    private readonly clinicQuery: ClinicsQuery,
    private readonly invitationStore: InvitationsStore,
    private readonly notification: NotificationsService,
    private readonly loadingStore: LoadingStore,
    private readonly toastr: ToastrService,
    private readonly translation: TranslateService,
    private readonly exercisesStore: ExercisesStore,
    private readonly visitStore: VisitsStore,
    private readonly patientDashboard: PatientDashboardService
  ) { }

  clinic$: Observable<Clinic> = this.clinicQuery.selectActive() as Observable<Clinic>;
  private requests$ = new Subject<JoinRequest[]>();

  currentClinic(): Clinic {
    return this.clinicQuery.getActive() as Clinic;
  }

  fetchClinics(): Observable<Clinic[]> {
    if (this.clinicQuery.getHasCache() && !this.clinicQuery.isOnlyFromLocal()) {
      return this.clinicQuery.selectAllSorted();
    }
    return this.rest.fetchClinics().pipe(
      map(cls =>
        cls.map(cl => ({
          ...cl.clinic,
          roles: cl.roles
        }))
      ),
      catchError(() => {
        this.notification.showError('http.clinics.cannot_fetch');
        return of([]);
      }),
      tap(cls => {
        this.clinicStore.set(cls);
      }),
      flatMap(_ => this.clinicQuery.selectAllSorted())
    );
  }


  fetchClinicDetails(): Observable<boolean> {
    return this.rest.fetchClinicDetails(this.currentClinic().id)
      .pipe(
        tap(cl => {
          this.clinicStore.updateActive({
            assignes: cl.assignes
          });
        }),
        map(_ => true));
  }

  fetchRequests(): Observable<JoinRequest[]> {
    this.rest.fetchClinicRequests(this.currentClinic().id).subscribe({
      next: ar => this.requests$.next(ar)
    });

    return this.requests$;
  }

  fetchPendingInvitations(): Observable<boolean> {
    return this.rest.fetchPendingInvitations(this.currentClinic().id)
      .pipe(
        tap(rs => {
          this.clinicStore.updateActive({
            assignesPending: rs
          });
        }),
        map(_ => true)
      );
  }

  askForInvitation(): Observable<boolean> {
    return this.rest.fetchUserInvitations()
      .pipe(
        map(
          rs => rs.map(inv => (
            {
              ...inv.clinic,
              clinicId: inv.clinic.id,
              as: inv.as,
              id: inv.id
            }
          ))
        ),
        tap(
          rs => this.invitationStore.set(rs)
        ),
        map(_ => true)
      );
  }

  async setCurrentClinic({ clinic, role }, notifySelect = true) {
    localStorage.setItem(STELLA_CURRENT_CLINIC, JSON.stringify(clinic));
    this.clinicStore.setActive(clinic.id);
    this.clinicStore.update(cl => cl.new, cl => {
      return { ...cl, new: false };
    });
    if (notifySelect) {
      return this.rest
        .selectClinic(clinic.id, role)
        .pipe(
          tap(res => localStorage.setItem(ACCESS_TOKEN_KEY, res.updatedToken)),
          tap(_ => this.exercisesStore.reset()),
          tap(_ => this.visitStore.reset()),
          tap(_ => this.patientDashboard.invalidateCache()),
          tap(_ => this.clinicStore.incrementActiveClinic(clinic.id)))
        .toPromise();
    }
  }

  createClinic(data: Partial<Clinic>): Observable<Clinic> {
    this.loadingStore.update(s => ({ ...s, CLINIC_CREATE: true }));
    if (
      data.web && !checkUrlStartsWithProtocol(data.web)
    ) {
      data.web = `https://${data.web}`;
    }
    return this.rest.createClinic(data).pipe(
      map(cl => {
        return {
          ...cl,
          roles: [{ id: Role.OWNER, name: 'owner' }, { id: Role.THERAPIST, name: 'therapist' }],
          selection: [{ last: new Date().toISOString() }]
        };
      }),
      tap(cl => {
        this.clinicStore.add({ ...cl, new: true });
      }),
      finalize(() => {
        this.loadingStore.update(s => ({ ...s, CLINIC_CREATE: false }));
      })
    );
  }

  editClinic(data: Partial<Clinic>): Observable<Clinic> {
    this.loadingStore.update(s => ({ ...s, CLINIC_CREATE: true }));
    if (
      data.web && !checkUrlStartsWithProtocol(data.web)
    ) {
      data.web = `https://${data.web}`;
    }
    return this.rest.editClinic(data).pipe(
      tap(rs => {
        const active = this.clinicQuery.getActive() as Clinic;
        const updatedClinic = {
          ...active,
          ...data,
          address: data.address,
          logo: rs.logo || active.logo
        };
        // TODO: Determine current role
        this.setCurrentClinic({ clinic: updatedClinic, role: -1 }, false);
        this.clinicStore.updateActive(updatedClinic);
      }),
      finalize(() => {
        this.loadingStore.update(s => ({ ...s, CLINIC_CREATE: false }));
      })
    );
  }

  askForInvitationAsTherapist(clinic: MinimalClinic): Observable<StatusResponse> {
    return this.rest.askForInvitation(clinic.id, Role.THERAPIST);
  }

  processInvitation(data: ProcessInvitation) {
    return this.rest.processInvitation(this.currentClinic().id, data);
  }

  acceptInvitation(token: string): Observable<any> {
    return this.rest.acceptUserInvitation(token)
      .pipe(
        flatMap(() => this.translation.get('acceptInvitation.success')),
        tap(translation => this.toastr.success(translation, '')),
        catchError(() => this.translation.get('acceptInvitation.failure').pipe(tap(translation => this.toastr.error(translation, ''))))
      );
  }

  processUserInvitation(data: ProcessUserInvitation): Observable<StatusResponse> {
    return this.rest.processUserInvitation(data)
    .pipe(
      tap(() => {
        if (data.result) {
          if (this.clinicQuery.getEntity(data.clinic)) {
            this.clinicStore.update(data.clinic, state => {
              return {
                ...state,
                roles: [...state.roles, data.role]
              };
            });
          } else {
            this.fetchClinics().subscribe();
          }
        }
        this.invitationStore.remove(data.id);
      })
    );
  }

  createInvitation(data: CreateInvitation): Observable<any> {
    const obs = data.role.map(r => this.rest.createInvitation(this.currentClinic().id, { email: data.email, role: r })
      .pipe(catchError(err => throwError(err))));
    return concat(...obs);
  }

  revokePatientInvitation(userId: number): Observable<any> {
    return this.rest.revokeInvitation(this.currentClinic().id, userId).pipe(catchError(err => throwError(err)));
  }

  getInvitations(): Observable<any> {
    return this.rest.getInvitations(this.currentClinic().id);
  }

  removeFromClinic(user: any): Observable<any> {
    return this.rest.removeFromClinic(this.currentClinic().id, {
      id: user.id,
      role: user.role.id
    }).pipe(
      tap(() => {
        if (user.id === this.authService.currentUser.id) {
          this.clinicStore.update(this.currentClinic().id, state => {
            return {
              ...state,
              roles: state.roles.filter(r => r.id !== user.role.id)
            };
          });
        }
      })
    );
  }

  fetchUsageReport(from: Date, to: Date): Observable<any> {
    return this.rest.fetchUsageReport(this.currentClinic().id, { from, to });
  }
}
