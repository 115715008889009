import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import englishLocale from '@angular/common/locales/en';
import deutschLocale from '@angular/common/locales/de';
import czechLocale from '@angular/common/locales/cs';
import ukrainianLocale from '@angular/common/locales/uk';
import chineseSimplifiedLocale from '@angular/common/locales/zh-Hans';
import chineseTraditionalLocale from '@angular/common/locales/zh-Hant';
import polishLocale from '@angular/common/locales/pl';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '@env/environment';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { StellaModule } from '@app/stella/stella.module';
import { NotFoundComponent } from '@app/not-found/not-found.component';
import { TrainingModule } from '@app/training/training.module';
import { GlobalHttpInterceptorService } from '@app/shared/services/global-http-interceptor.service';
import { NoInternetDialogComponent } from './components/no-internet-dialog/no-internet-dialog.component';
import { UploadingDataDialogComponent } from './components/uploading-data-dialog/uploading-data-dialog.component';
import { InitializeExerciseDialogComponent } from './components/initializing-exercise-dialog/initialize-exercise-dialog.component';
import { BatteryService } from './shared/services/low-battery.service';
import { UnknownErrorDialogComponent } from './components/unknown-error-dialog/unknown-error-dialog.component';

registerLocaleData(englishLocale);
registerLocaleData(deutschLocale);
registerLocaleData(czechLocale);
registerLocaleData(ukrainianLocale);
registerLocaleData(chineseSimplifiedLocale);
registerLocaleData(chineseTraditionalLocale);
registerLocaleData(polishLocale);

export function HttpLoaderFactory(http: HttpClient) {
  const loadRemote = false; // environment.production;
  return new TranslateHttpLoader(http,
    loadRemote ? 'https://stella-languages.storage.googleapis.com/' : '/assets/i18n/',
    loadRemote ? '.json?avoidCache=1' : '.json');
}

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StellaModule,
    MatIconModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    MatSnackBarModule,
    HttpClientModule,
    TranslateModule.forRoot({
      useDefaultLang: true,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    TrainingModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
    BatteryService
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    NoInternetDialogComponent,
    UploadingDataDialogComponent,
    InitializeExerciseDialogComponent,
    UnknownErrorDialogComponent
  ]
})
export class AppModule { }
