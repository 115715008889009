import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { STELLA_CURRENT_PATIENT } from '@app/enums';
import { Patient } from '@app/models/Patient.model';

export interface PatientsState extends EntityState<Patient> { }

@Injectable({ providedIn: 'root' })
  @StoreConfig({ name: 'patients', idKey: 'patientId' })
export class PatientsStore extends EntityStore<PatientsState> {

  constructor() {
    super();
    const patientLs = localStorage.getItem(STELLA_CURRENT_PATIENT);
    if (patientLs) {
      const parsed = JSON.parse(patientLs);
      this.set([{ ...parsed, ls: true }]);
      this.setActive(parsed.patientId);
    }
  }

  incrementActivePatient(patId: number) {
    this.update(state => {
      const pat = state.entities[patId];
      return {
        ...state,
        active: patId,
        entities: {
          ...state.entities,
          [patId]: {
            ...pat,
            selection:
            {
              last: new Date().toISOString(),
              selected: 1
            }
          }
        }
      };
    });
  }
}

