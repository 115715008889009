<div class="title-row">
  <div class="title-row--container">
    <button mat-icon-button (click)="back.emit()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h2 class="title-row--title">
      {{ name }}
    </h2>
</div>
  <sba-show-more-paragraph
    text="{{ short }}"
    limit="150"
  ></sba-show-more-paragraph>
</div>
