<mat-dialog-content>
  <div class="uploading-data-dialog-container">
    <h2 class="uploading-data-dialog-heading">{{ "uploadingDataDialog.heading" | translate }}</h2>
    <div class="animated-box">
      <!-- 64 -->
      <div class="animated-icon">
        <mat-progress-spinner diameter="90" mode="determinate" value="{{ progress }}"></mat-progress-spinner>
        <div class="progress-text">{{ progress ? progress + '%': '' }}</div>
      </div>
    </div>
    <p class="uploading-data-dialog-message">{{ "uploadingDataDialog.message" | translate }}</p>
  </div>
</mat-dialog-content>