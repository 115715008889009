export enum Pages {
    PATIENT_MEDICAL_CARD = 'dashboard/card',
    // PATIENT_STATS = 'dashboard/stats',
    THERAPIST_PROFILE = 'dashboard/profile',
    THERAPIST_TEMPLATES = 'dashboard/therapist/templates',
    CLINIC_MANAGE = 'dashboard/clinic/manage',
    REPORTS = 'dashboard/reports/view',
    EXERCISE_DIAGNOSTIC = 'dashboard/trainings/emg/biofeedback',
    EXERCISE_ELECTROSTIM = 'dashboard/trainings/electrostim',
    EXERCISE_EMG = '/dashboard/trainings/emg/view',
    EXERCISES_LIST = 'dashboard/trainings/tr',
    STELLA_CONNECT = 'stella/connect',
    STELLA_CONNECT_NEW = 'stella/connect/new',
    SELECT_CLINIC = 'select/clinic',
    SELECT_PATIENT = 'select/patient',
    REGISTER = 'register',
    SIGNIN = 'sign-in',
    FORGET_PASSWORD = 'forgetPassword',
    MAINTENANCE = 'maintenance',
    FEATURES = 'dashboard/features',
    BILLINGS = 'dashboard/billings',
    BILLINGS_USAGE = 'dashboard/billings/usage',
    PATIENT_DASHBOARD = 'patient',
    PATIENT_CALENDAR = 'patient/calendar',
    GAMEPLAY = 'dashboard/trainings/games/play',
    IDLE = 'dashboard/trainings/idle',
    CONCEPTS = 'dashboard/trainings',
    SUPERADMIN = 'superadmin'
}
