<sba-page-title title="title.privacy_notice"></sba-page-title>
<div class="background">
  <div class="text-container">
    <h1>{{ "privacyPolicy.title" | translate }}</h1>
    <h2>
      1. {{ "privacyPolicy.p1" | translate }}
    </h2>
    <p>
      {{ "privacyPolicy.s1-1" | translate}}<a href="https://app.egzotech.com">https://app.egzotech.com</a>{{ "privacyPolicy.s1-2" | translate}}
    </p>
    <p>
      {{ "privacyPolicy.s1-3" | translate}}
    </p>
    <p>
      {{ "privacyPolicy.s1-4" | translate}}<a href="mailto:privacy@egzotech.com">privacy@egzotech.com</a>{{ "privacyPolicy.s1-5" | translate}}
    </p>
    <h2>2. {{ "privacyPolicy.p2" | translate }}</h2>
    <p>
      {{ "privacyPolicy.s2-1" | translate }}
    </p>
    <h2>
      3. {{ "privacyPolicy.p3" | translate }}
    </h2>
    <p>
      {{ "privacyPolicy.s3-1" | translate }}<a href="https://app.egzotech.com">https://app.egzotech.com</a>{{ "privacyPolicy.s3-2" | translate }}
    </p>
    <p>
      {{ "privacyPolicy.s3-3" | translate }}<a href="https://app.egzotech.com">https://app.egzotech.com</a>{{ "privacyPolicy.s3-4" | translate }}
    </p>
    <p>
      {{ "privacyPolicy.s3-5" | translate }}
    </p>
    <p>
      {{ "privacyPolicy.s3-6" | translate }}
    </p>
    <ul>
      <li>
        {{ "privacyPolicy.s3-6-1" | translate }}
      </li>
      <li>
        {{ "privacyPolicy.s3-6-2" | translate }}
      </li>
    </ul>
    <p>
      {{ "privacyPolicy.s3-7" | translate }}
    </p>
    <h2>4. {{ "privacyPolicy.p4" | translate }}</h2>
    <p>
      {{ "privacyPolicy.s4-1" | translate }}
    </p>
    <p>
      {{ "privacyPolicy.s4-2" | translate }}
    </p>
    <p>
      {{ "privacyPolicy.s4-3" | translate }}
    </p>
    <h2>5. {{ "privacyPolicy.p5" | translate }}</h2>
    <p>
      {{ "privacyPolicy.s5-1" | translate }}
      <a href="https://cloud.google.com/security/gdpr"
        >https://cloud.google.com/security/gdpr</a
      >.
    </p>
    <p>
      {{ "privacyPolicy.s5-2" | translate }}
      <a href="https://egzotech.com/eula">https://egzotech.com/eula</a>.
    </p>
    <h2>6. {{ "privacyPolicy.p6" | translate }}</h2>
    <p>
      {{ "privacyPolicy.s6-1" | translate }}
    </p>
    <ul>
      <li>{{ "privacyPolicy.s6-1-1" | translate }}</li>
      <li>{{ "privacyPolicy.s6-1-2" | translate }}</li>
      <li>{{ "privacyPolicy.s6-1-3" | translate }}</li>
      <li>{{ "privacyPolicy.s6-1-4" | translate }}</li>
      <li>{{ "privacyPolicy.s6-1-5" | translate }}</li>
      <li>{{ "privacyPolicy.s6-1-6" | translate }}</li>
      <li>{{ "privacyPolicy.s6-1-7" | translate }}</li>
    </ul>
    <p>
      {{ "privacyPolicy.s6-2" | translate }}
    </p>
    <p>
      {{ "privacyPolicy.s6-3" | translate }}
    </p>
    <address>
      {{ "privacyPolicy.s6-3-1" | translate }}<br />
      {{ "privacyPolicy.s6-3-2" | translate }}<br />
      {{ "privacyPolicy.s6-3-3" | translate }}<br />
      {{ "privacyPolicy.s6-3-4" | translate }}<br />
      {{ "privacyPolicy.s6-3-5" | translate }}<br />
    </address>
    <h2>7. {{ "privacyPolicy.p7" | translate }}</h2>
    <p>
      {{ "privacyPolicy.s7-1" | translate }}
    </p>
    <h2>8. {{ "privacyPolicy.p8" | translate }}</h2>
    <p>
      {{ "privacyPolicy.s8-1" | translate }}
    </p>
    <h2>9. {{ "privacyPolicy.p9" | translate }}</h2>
    <p>
      {{ "privacyPolicy.s9-1" | translate }}
    </p>
    <h2>10. {{ "privacyPolicy.p10" | translate }}</h2>
    <p>
      {{ "privacyPolicy.s10-1" | translate }}
      <a href="https://egzotech.com/rodo">https://egzotech.com/rodo</a>.
    </p>            
  </div>
</div>
<sba-language-selector class="language-selector"></sba-language-selector>

<sba-version-date></sba-version-date>
