<h2>{{ "changelog.title" | translate }}</h2>
<div class="changelog-changes-container">
  <div *ngFor="let change of changelog">
    <h3>{{ "changelog.version" | translate }} {{ change.version }}</h3>
    <ng-container *ngIf="!data?.translated">
      <markdown ngPreserveWhitespaces class="message">{{
        change.content
      }}</markdown>
    </ng-container>
  </div>
  <div class="actions-container">
    <button class="outline-button" mat-ripple (click)="ok()">
      {{ "common.confirm" | translate }}
    </button>
  </div>
</div>
