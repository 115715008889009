<sba-page-title [title]="'exercises.' + (gameName || 'none')"></sba-page-title>
<div class="diagnostic-list">
  <div class="title-row">
    <div class="left">
      <button mat-icon-button (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <h2>{{ "games.level_list" | translate }}</h2>
    </div>

    <div class="outline-button" (click)="handleStart()">{{ 'navigation.startTraining' | translate }}</div>
  </div>
  <div class="start-button">
    <div class="error-text">
      {{ errorText | translate }}
    </div>
  </div>
  <div class="diagnostic-list--content" [ngClass]="{'already-set': dashboard.exercise?.template?.steps?.levels?.length}">
    <a *ngFor="let level of levels" class="entry">
      <sba-tile
        [data]="level"
        [selected]="level.selected"
        [rounded]="false"
        (click)="handleSelect(level)"
      ></sba-tile>
    </a>
    <div class="entry" *ngIf="levels.length % 2 === 1"></div>
  </div>
</div>
