import { SessionStore } from '@store/session/session.store';
import { Injectable } from '@angular/core';
import { ClinicsQuery } from '@store/clinics';
import { PatientsQuery } from '@store/patients';
import { SessionMemory, SessionWithMeetings } from '@app/types';
import { RestEndpointsService } from '@app/api/rest-endpoints.service';
import { AuthService } from '@app/auth/services/auth.service';
import { PatientsService } from '@app/clinics/services/patients.service';
import { LocalStoreService } from '@app/dashboard/services/local-store.service';
import { STELLA_LAST_SESSION } from '@app/enums';
import { DashboardService } from '@app/dashboard/services/dashboard.service';

const sessionTemplate = {
  completed: 0,
  endDate: null,
  uploaded: 0
};

@Injectable({
  providedIn: 'root'
})
export class CurrentSessionService {
  constructor(
    private patientsQuery: PatientsQuery,
    private patientService: PatientsService,
    private clinicsQuery: ClinicsQuery,
    private authService: AuthService,
    private storage: LocalStoreService,
    private rest: RestEndpointsService,
    private sessionStore: SessionStore,
    private dashboard: DashboardService
  ) { }
  sessionId = +localStorage.getItem(STELLA_LAST_SESSION);
  maxArray = new Array(8).fill(0);
  isStarted = false;
  isLogged = false;

  async start(): Promise<void> {
    let ses;
    if (!this.isStarted) {
      const startTime = performance.now();
      console.group('[SESSION START]');
      this.isLogged = Boolean(this.authService.currentUser);
      this.sessionStore.update({
        session: true
      });
      if (this.isLogged) {
        // let card = { goal: '-', icd: {} };
        const pat = this.patientsQuery.getActivePatientOrMock();
        // if (pat.patientId !== 0) {
        //   card = await this.patientService.getMedicalCard().toPromise();
        // }
        this.sessionId = await this.storage.addSession({
          ...sessionTemplate,
          startDate: new Date(),
          patient: pat,
          therapist: this.authService.currentUser || { id: '0' },
          clinic: this.clinicsQuery.getActive() || { id: '0' },
        });
        console.log('CREATE SESSION MEMORY', performance.now() - startTime);
        if (!this.dashboard?.exercise?.id) {
          ses = await this.rest.createSession(
            this.clinicsQuery.getActive().id,
            pat.patientId,
            {}).toPromise().catch(console.error);
        } else {
          ses = { id: this.dashboard.trainingTemplate.id};
        }

        console.log('CREATE SESSION API', performance.now() - startTime);

        this.storage.updateSession(this.sessionId, { remoteId: ses ? ses.id : -1 });
        localStorage.setItem(STELLA_LAST_SESSION, this.sessionId.toString());
      }
      console.log('FINISH', performance.now() - startTime);
      console.groupEnd();
      this.isStarted = true;
    }
    return;
  }

  async update(change): Promise<void> {
    if (this.isLogged) {
       await this.storage.updateExercise(this.sessionId, change);
    }
  }

  async updateMax(maxes): Promise<void> {
    this.maxArray = maxes;
    return this.update({ max: maxes });
  }

  async stop(extras?: object): Promise<void> {
    if (this.isLogged) {
      const { clinic,
        patient,
        remoteId } = await this.getCurrent(false);
      await Promise.all([
        this.storage.updateSession(this.sessionId, {
          completed: 1,
          endDate: new Date(),
          extras,
          uploaded: 1
        }),
        this.rest.updateSession(clinic.id, patient.patientId, remoteId, {
          completed: true
        }).toPromise()
      ]);
    }
    this.isStarted = false;
    this.sessionStore.update({
      session: false
    });
    return;
  }

  async getSession(sessionId: number): Promise<SessionWithMeetings> {
    const exercises = await this.storage.getExercisesForSession(sessionId, false);
    const session = await this.storage.getSession(sessionId);
    return {
      ...session,
      meetings: exercises
    };
  }

  async getSessionDetails(sessionId: number): Promise<SessionMemory> {
    return this.storage.getSession(sessionId);
  }

  async getCurrent(withMeetings = true): Promise<SessionMemory> {
    if (!this.sessionId) {
      return null;
    }
    return withMeetings ? this.getSession(this.sessionId) : this.getSessionDetails(this.sessionId);
  }
}


