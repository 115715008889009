import { ChartPoint } from '@app/types';
import { DeepReadonly, StimConfiguration } from '@egzotech/exo-electrostim';

const DEFAULT_END_TRAINING_TIME = 10000; // ms -> 10 sec

export type ChannelsPoints = Array<ChartPoint[]>;

export interface StepConfiguration {
  rampUp: number;
  restTime: number;
  workTime: number;
  rampDown: number;
}
export interface ElectrostimGuideBuilderResult {
  points: ChannelsPoints;
}

export class ElectrostimGuideBuilder {
  private time = 0;
  private points: ChannelsPoints;

  constructor(
    private configuration: DeepReadonly<StimConfiguration>,
    private channelMapping: { [key: number]: number },
    private options?: { onlySingleRepetition: boolean }
  ) {
    this.points = [];

    for (let i = 0; i < Object.values(this.channelMapping).length; i++) {
      this.points.push([]);
    }
  }

  build(): ElectrostimGuideBuilderResult {
    this.programBuild();
    this.afterBuild();
    return {
      points: this.points
    };
  }

  private afterBuild() {
    this.time += DEFAULT_END_TRAINING_TIME;
    this.points.forEach(p => p.push({ x: this.time * 1e-6, y: 0 }));
  }

  private programBuild() {
    const channels = Object.values(this.channelMapping);

    for (let mappedChannelIndex = 0; mappedChannelIndex < channels.length; mappedChannelIndex++) {
      const channelIndex = channels[mappedChannelIndex];
      const channelPoints = this.points[mappedChannelIndex];
      const channelDefaultData = this.configuration.defaultChannelValues.find(v => v.channelIndex === channelIndex);
      let time = 0;

      for (let i = 0; i < this.configuration.phasesRepetition; i++) {

        channelPoints.push({ x: time * 1e-6, y: 0});

        for (let j = 0; j < this.configuration.phases.length; j++) {
          const phaseData = this.configuration.phases[j].channels.find(v => v.channelIndex === channelDefaultData.channelIndex);
          const channelData = { ...channelDefaultData, ...phaseData };

          time += channelData.delay;

          if (!channelData.plateauTime) {
            time += this.buildSingleBurst(
              time,
              mappedChannelIndex,
              channelData.riseTime,
              channelData.fallTime,
              channelData.runTime - channelData.riseTime - channelData.fallTime,
              0,
              channelPoints
            );
          }
          else {
            let phaseTime = 0;
            let lastChannelPointLength = channelPoints.length;

            while(phaseTime < channelData.runTime) {
              lastChannelPointLength = channelPoints.length;

              phaseTime += this.buildSingleBurst(
                time + phaseTime,
                mappedChannelIndex,
                channelData.riseTime,
                channelData.fallTime,
                channelData.plateauTime,
                channelData.pauseTime,
                channelPoints
              );
            }

            // When calculating the amount of bursts to generate, we do not want to take into account the last pause,
            // because it does not affect the possibility of burst execution.
            // If last pause time goes beyond the run time, the burst should still be drawn.
            channelPoints.pop();

            time += channelData.runTime - channelData.delay;

            // If the last burst goes beyond the run time it should not be drawn
            if (channelPoints[channelPoints.length - 1].x > time * 1e-6) {
              channelPoints.splice(lastChannelPointLength, channelPoints.length - lastChannelPointLength);
            }
          }
        }

        if (this.options?.onlySingleRepetition) {
          // Single repetition should generate phases only once
          break;
        }
      }

      this.time = Math.max(this.time, time);
    }
  }

  private buildSingleBurst(
    time: number,
    yOffset: number,
    riseTime: number,
    fallTime: number,
    plateauTime: number,
    waitTime: number | undefined,
    output: { x: number, y: number }[]
  ) {
    output.push({
      x: time * 1e-6,
      y: 0
    });

    time += riseTime;

    output.push({
      x: time * 1e-6,
      y: 100 - 2 * yOffset
    });

    time += plateauTime;

    output.push({
      x: time * 1e-6,
      y: 100 - 2 * yOffset
    });

    time += fallTime;

    output.push({
      x: time * 1e-6,
      y: 0
    });

    if (waitTime) {
      time += waitTime;

      output.push({
        x: time * 1e-6,
        y: 0
      });
    }

    return riseTime + plateauTime + fallTime + (waitTime ?? 0);
  }
}
