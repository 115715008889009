import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'sba-cable-select-message',
  templateUrl: './cable-select-message.component.html',
  styleUrls: ['./cable-select-message.component.scss']
})
export class CableSelectMessageComponent implements OnInit, OnDestroy {

  interval;
  current = 0;
  constructor(
    private readonly cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.interval = setInterval(() => {
      this.current = this.current === 0 ? 1 : 0;
      this.cdr.detectChanges();
    }, 750);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }
}
