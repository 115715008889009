import { HostListener, OnDestroy, OnInit } from '@angular/core';
import { LineChart } from '@app/charts/LineChart';
import { BarChart } from '@app/charts/BarChart';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { DashboardMode, StellaConnectionStatus, STELLA_LAST_CHART_SCALE } from '@app/enums';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { CurrentExerciseService } from '@app/training/services/current-excercise.service';
import { ChannelSignal, IChartController, SelectedMuscle, CableDetails } from '@app/types';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Router, NavigationStart } from '@angular/router';
import { Pages } from '@app/pages';
import { ExerciseState } from '../services/ExerciseState';
import { BatteryService } from '@app/shared/services/low-battery.service';
import { validChannels } from '@app/training/ChannelResolver';
import { TrainingLikeComponent } from '../guards/CanDeactivate.guard';
import { BaseTrainingComponent } from './baseTrainingComponent';

export abstract class BaseEmgComponent extends BaseTrainingComponent implements OnInit, OnDestroy, TrainingLikeComponent {
  protected subscriptions = new Subscription();
  protected sub: Subscription;
  chart: LineChart | BarChart;
  values = new Array(8).fill(0);
  connected$: BehaviorSubject<{ connected: StellaConnectionStatus }>;
  muscles: SelectedMuscle[] = this.exerciseService.muscles;
  chartController: IChartController;
  cable: CableDetails;
  state = new ExerciseState();
  displayedChannels: number[];
  exerciseNameForInquiry: string;

  constructor(
    protected readonly stellaDirect: StellaDirectService,
    protected readonly exerciseService: CurrentExerciseService,
    public readonly dashboard: DashboardService,
    protected readonly location: Location,
    protected readonly router: Router,
    protected readonly batteryService: BatteryService,
  ) {
    super();
    this.connected$ = stellaDirect.connected$;
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.state.current === 'RUNNING') {
        this.pause();
      }
    });
  }

  ngOnInit() {
    this.subscriptions.add(this.stellaDirect.cable$
      .pipe(
        tap(cable => this.cable = cable))
      .subscribe()
    );

    this.subscriptions.add(this.batteryService.batteryStatus$.subscribe(val => {
      if (val === 'LOW') {
        this.pause();
      }
    }));
  }

  async ngOnDestroy() {
    await this.exerciseService.stop();

    this.subscriptions.unsubscribe();

    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (this.chart) {
      this.chart.destroy();
    }

    this.stellaDirect.setMask(0);
  }

  async start() {
    this.state.setState('RUNNING');
    this.exerciseService.start();
    super.onPlay();
  }

  abstract onStop(): void;
  abstract onPause(): void;

  getOnlyValid(muscles: SelectedMuscle[]): number[] {
    return validChannels(muscles).ids;
  }

  setDisplayedChannels(channels: number[]) {
    this.displayedChannels = channels;
  }

  changeChannel(value: number[]) {
    this.setDisplayedChannels(value);
    this.chart.setVisibleChannels(value);
  }

  stop() {
    this.exerciseService.pause();
    this.onStop();
    this.endTraining();
    this.state.setState('PAUSED');
  }

  pause(withDataRunning: boolean = false) {
    this.exerciseService.pause(withDataRunning);
    this.onPause();
    this.state.setState('PAUSED');
  }

  endTraining(force = false): void {
    this.pause();

    if (this.dashboard.started) {
      this.dashboard.nextExercise();
    } else {
      if (this.dashboard.mode === DashboardMode.PATIENT) {
        this.router.navigate([Pages.PATIENT_CALENDAR], { state: { force } });
      } else {
        this.router.navigate([Pages.PATIENT_MEDICAL_CARD], { state: { force } });
      }
    }
  }

  goBack() {
    this.location.back();
  }

  changeScale(value) {
    localStorage.setItem(STELLA_LAST_CHART_SCALE, value);
    this.chart.setScale({
      min: 0,
      max: value
    });
  }

  async initStella(): Promise<boolean> {
    try {
      if (this.sub) {
        this.sub.unsubscribe();
      }

      this.sub = this.exerciseService.source$
        .pipe(
          tap((signal: ChannelSignal) => {
            for (const [key, value] of Object.entries<Float32Array>(signal)) {
              this.values[key] = value[value.length - 1] * 1e6;
            }
          }),
          tap(signal => this.handleData(signal)),
          catchError(err => {
            console.log(err);
            return of(err);
          })
        )
        .subscribe();
      return true;
    } catch (err) {
      console.warn(err);
      return false;
    }
  }

  handleThresholdChange(event) {
  }
  
  protected abstract handleData(signal: ChannelSignal): void;

}
