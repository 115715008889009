import { SessionQuery } from '@app/store/session/session.query';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'sba-session-indicator',
  templateUrl: './session-indicator.component.html',
  styleUrls: ['./session-indicator.component.scss']
})
export class SessionIndicatorComponent implements OnInit {
  prod = environment.production;

  constructor(
    public sessionQuery: SessionQuery) { }

  ngOnInit(): void {
  }
}
