// tslint:disable: no-bitwise
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  constructor(private translateService: TranslateService) { }

  transform(value: any, ...args: any[]): any {
    const [simple] = args;

    const time = value;

    const hrs = ~~(time / 3600);
    const min = ~~((time % 3600) / 60);
    const sec = Math.floor(time % 60);
    let secMin = '';
    if (simple) {
      if (hrs > 0) {
        secMin += hrs + ':' + (min < 10 ? '0' : '');
      }
      secMin += min + ':';
      if (sec === 0) {
        secMin += '00';
      } else if (sec < 10) {
        secMin += `0${sec}`;
      } else {
        secMin += sec;
      }
    } else {
      if (hrs > 0) {
        secMin += '' + hrs + this.translateService?.instant('common.units.hours') + ' ' + (min < 10 ? '0' : '');
      }
      secMin += '' + min + this.translateService?.instant('common.units.minutes') + ' ';
      if (sec !== 0) {
        secMin += '' + sec + this.translateService?.instant('common.units.seconds');
      }
    }
    return secMin;
  }

}
