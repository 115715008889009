import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@env/environment';

@Component({
  selector: 'sba-purchase-dialog',
  templateUrl: './purchase-dialog.component.html',
  styleUrls: ['./purchase-dialog.component.scss']
})
export class PurchaseDialogComponent {
  missingPackages: any[];

  constructor(
    public dialogRef: MatDialogRef<PurchaseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.missingPackages = data.module.split(', ').map(pckg => {
      return {
        name: pckg,
        icon: `${environment.assetsUrl}/stella_bio_cdn/package_${pckg}.png`
      };
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  goToStore() {
    window.location.href = 'mailto:bok@egzotech.com';
    this.dialogRef.close(true);
  }
}

