<div class="radial-progress" [attr.data-progress]="progress">
  <div class="circle">
    <div class="mask full">
      <div class="fill"></div>
    </div>
    <div class="mask half">
      <div class="fill"></div>
      <div class="fill fix"></div>
    </div>
    <div class="shadow"></div>
  </div>
  <div class="inset">
    <div class="percentage">
      <div class="text">
        <span>{{text}}</span>
      </div>
    </div>
  </div>
</div>
