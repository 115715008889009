<mat-dialog-content>
  <h2>{{ "stella.nameDialog.title" | translate }}</h2>
  <form
    class="signup--form"
    [formGroup]="stella"
    (ngSubmit)="onSubmit()"
  >
    <mat-form-field class="full-width">
      <input
        matInput
        [placeholder]="'common.name' | translate"
        formControlName="name"
      />
      <mat-error *ngIf="stella.controls.name.invalid">{{
        stella.get("name").errors | Error | translate
      }}</mat-error>
    </mat-form-field>
    <mat-error *ngIf="stella.invalid && stella.errors">{{
      stella.errors | Error | translate
    }}</mat-error>

    <button type="submit" class="outline-button" mat-ripple>
      {{ "stella.nameDialog.button" | translate }}
    </button>
  </form>
</mat-dialog-content>
