import { Component, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs/operators';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { CableDetails, SelectedMuscle } from '@app/types';
import { validChannels } from '@app/training/ChannelResolver';

@Component({
  selector: 'sba-emg-calibration',
  templateUrl: './emg-calibration.component.html',
  styleUrls: ['./emg-calibration.component.scss']
})
export class EmgCalibrationComponent {

  maxArray: number[] = [0, 0, 0, 0, 0, 0, 0, 0];
  values: number[] = [0, 0, 0, 0, 0, 0, 0, 0];
  selectedChannel = 0;
  muscles: SelectedMuscle[];
  cable: CableDetails;
  selectedSecondaryChannel = 0;
  showSecondarySelection = false;
  recalibration = false;
  shouldUseSecondaryChannel = false;

  constructor(
    public readonly dialogRef: MatDialogRef<EmgCalibrationComponent>,
    public readonly stella: StellaDirectService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    this.muscles = data.muscles;
    this.selectedChannel = data.channel;
    this.cable = this.stella.state.getCable();
    this.showSecondarySelection = data.showSecondarySelection && data.muscles.filter(m => m.quality !== 'none'). length > 1;
    this.selectedSecondaryChannel = data.selectedSecondaryChannel;
    this.stella.cable$.pipe(tap(cable => this.cable = cable)).subscribe();

    const wellChannels = this.muscles.filter(oc => oc.quality !== 'none');
    let mask = 0;

    for (const v of wellChannels) {
      // eslint-disable-next-line no-bitwise
      mask |= 1 << v.channel;
    }

    this.stella.setMask(mask);
    // this.recalibration = data.recalibration;
  }

  updateValues($event) {
    this.values = $event;
    this.updateMax($event);
  }
  changeSecondaryChannel($event) {
    this.selectedSecondaryChannel = $event;
  }

  changeShouldUseSecondaryChannel($event) {
    this.shouldUseSecondaryChannel = $event.checked;
  }

  showOnlyConnected(muscles: SelectedMuscle[], primaryChannel: number) {
    return validChannels(muscles).ids.filter(id => id !== primaryChannel);
  }

  updateMax(values: number[]) {
    this.maxArray = values.map((value, index) => Math.max(value, this.maxArray[index] || 0));
  }

  resetMax(channel) {
    this.maxArray[channel] = 0;
    this.cdr.detectChanges();
  }

  selectChannel(val: number) {
    this.selectedChannel = val;
  }

  confirm() {
    this.dialogRef.close({
      max: this.maxArray,
      channel: this.selectedChannel,
      selectedSecondaryChannel: this.shouldUseSecondaryChannel
        && this.selectedSecondaryChannel
        && this.selectedSecondaryChannel !== this.selectedChannel ? this.selectedSecondaryChannel : -1
    });
  }

  deny() {
    this.dialogRef.close(false);
  }
}
