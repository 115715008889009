import Dexie from 'dexie';
import { ChunkMemory, MeetingMemory, SessionMemory } from '@app/types';

export class StellaDexie extends Dexie {

    readonly VERSION: number = 3;

    meetings: Dexie.Table<MeetingMemory, number>;
    sessions: Dexie.Table<SessionMemory, number>;
    chunks: Record<number, ChunkMemory[]> = {};

    constructor(name: string) {
        super(name);
    }

    async init() {
        await this.version(this.VERSION).stores({
            meetings: '++id, completed, startDate, session',
            sessions: '++id, completed, patient.patientId, [patient.patientId+completed]',
        });

    }
}
