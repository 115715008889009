import { SelectedMuscle } from '@app/types';

export interface ValidChannelsResult {
  length: number;
  values: SelectedMuscle[];
  ids: number[];
}

export function validChannels(channels: SelectedMuscle[]): ValidChannelsResult {
  const validChannels = channels.filter(q => q.quality !== 'none');

  return {
    values: validChannels,
    length: validChannels.length,
    ids: validChannels.map(q => q.channel)
  };
}
