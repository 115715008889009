<mat-dialog-content>
  <div class="no-internet-dialog-container">
    <div class="animated-icon">
        <div class="wifi">
            <svg width="100%" height="100%" viewBox="0 0 83 71" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                <g transform="matrix(1,0,0,1,-1151,-380)">
                    <g id="no_wifi" transform="matrix(1,0,0,1,-0.266071,-0.35853)">
                        <rect x="1151.27" y="380.359" width="82.083" height="70.76" style="fill:none;"/>
                        <clipPath id="_clip1">
                            <rect x="1151.27" y="380.359" width="82.083" height="70.76"/>
                        </clipPath>
                        <g clip-path="url(#_clip1)">
                            <g transform="matrix(3.52742,0,0,3.52742,1150.19,375.638)">
                                <path d="M3.27,1.44L2,2.72L4.05,4.77C2.75,5.37 1.5,6.11 0.38,7C4.2,11.8 8.14,16.67 12,21.5L15.91,16.63L19.23,19.95L20.5,18.68C14.87,13.04 3.27,1.44 3.27,1.44M12,3C10.6,3 9.21,3.17 7.86,3.5L9.56,5.19C10.37,5.07 11.18,5 12,5C15.07,5 18.09,5.86 20.71,7.45L16.76,12.38L18.18,13.8C20.08,11.43 22,9 23.65,7C20.32,4.41 16.22,3 12,3M5.57,6.29L14.5,15.21L12,18.3L3.27,7.44C4,7 4.78,6.61 5.57,6.29Z" style="fill:rgb(102,204,204);fill-rule:nonzero;"/>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </div>
        <div class="dots">
            <div class="dot-1"></div>
            <div class="dot-2"></div>
            <div class="dot-3"></div>
        </div>
    </div>

    <h2 class="no-internet-dialog-heading">{{ "noInternetDialog.heading" | translate }}</h2>
    <p class="no-internet-dialog-message">{{ "noInternetDialog.message" | translate }}</p>
  </div>