import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sba-single-muscle',
  templateUrl: './single-muscle.component.html',
  styleUrls: ['./single-muscle.component.scss']
})
export class SingleMuscleComponent {

  @Output() release = new EventEmitter();
  @Output() select = new EventEmitter();
  @Input() programType: string;
  @Input() model;
  @Input() otherChannels: any[];
  @Input() nextActive;

  getChannels(model, args): number[] {
    return args.others
      .filter(o => o.bodyModel && o.selected && o.bodyModel.name === model.name && o.side === args.side)
      .map(o => o.channel);
  }

  selectMuscle($event, model, side) {
    $event.stopPropagation();
    this.select.emit({
      model, side
    });
  }
}
