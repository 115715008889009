<div class="carusel--column">
  <div class="carusel {{ type === 'fes' ? 'fes' : '' }}">
    <img
      class="fade {{ i }} {{ icon }}"
      [ngClass]="{ active: i === currentIndex }"
      [src]="icon | localFunction: getUrl"
      *ngFor="let icon of icons; let i = index"
      (swipeleft)="onSwipeRight($event)"
      (swiperight)="onSwipeLeft($event)"
      loading="lazy"
    />
  </div>
  <ng-container *ngIf="icons.length > 1">
    <div class="dots">
      <div
        class="dot"
        matRipple
        [ngClass]="{ active: j === currentIndex }"
        *ngFor="let item of icons | localFunction: count; let j = index"
        (click)="next($event, j)"
      ></div>
    </div>
  </ng-container>
</div>
