import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AnalyticsAction, AnalyticsCategory, GTagService } from '@app/shared/gtag.service';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ForgetCredentials } from './dtos/ForgetCredentials';
import { LoginCredentials } from './dtos/LoginCredentials';
import { ResetCredentials } from './dtos/ResetCredentials';
import { LoginResponse } from './dtos/responses/LoginResponse';
import { StatusResponse } from './dtos/responses/StatusResponse';
import { SignupCredentials } from './dtos/SignupCredentials';

@Injectable({
  providedIn: 'root'
})
export class AuthEndpointsService {
  constructor(
    private http: HttpClient,
    private gtag: GTagService
  ) { }

  authUrl = environment.authServerUrl;

  login(payload: LoginCredentials): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(this.authUrl + '/login', payload)
      .pipe(
        tap(_ => {
          this.gtag.emit(AnalyticsCategory.AUTH, AnalyticsAction.SIGNIN, 'success');
        }),
        catchError(err => {
          this.gtag.emit(AnalyticsCategory.AUTH, AnalyticsAction.SIGNIN, 'failure');
          return throwError(err);
        })
      );
  }

  signup(payload: SignupCredentials): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(this.authUrl + '/signup', payload)
      .pipe(
        tap(_ => {
          this.gtag.emit(AnalyticsCategory.AUTH, AnalyticsAction.SIGNUP, 'success');
        }),
        catchError(err => {
          this.gtag.emit(AnalyticsCategory.AUTH, AnalyticsAction.SIGNUP, 'failure');
          return throwError(err);
        })
      );
  }

  resetPassword(payload: ResetCredentials): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(this.authUrl + '/resetPassword', payload)
      .pipe(
        tap(_ => {
          this.gtag.emit(AnalyticsCategory.AUTH, AnalyticsAction.PASS_RESET, 'success');
        }),
        catchError(err => {
          this.gtag.emit(AnalyticsCategory.AUTH, AnalyticsAction.PASS_RESET, 'failure');
          return throwError(err);
        })
      );
  }

  forgetPassword(payload: ForgetCredentials): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(this.authUrl + '/forgetPassword', {...payload, lang: localStorage.getItem('LANGUAGE')});
  }
}
