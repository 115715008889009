<mat-dialog-content>
  <div class="title-row">
    <h2 class="setting-header">
      {{ "stimSetting.overvoltage_warning" | translate }}
    </h2>
  </div>

  <div class="row">
    <img class="warning" src="/assets/png/warning_iso.png" />
    <div class="advices">
      <p>{{ "stimSetting.check_advices" | translate }}</p>
      <ul>
        <li>{{ "stimSetting.electrode_connection" | translate }}</li>
        <li>{{ "stimSetting.dry_electrodes" | translate }}</li>
        <li>{{ "stimSetting.dirty_skin" | translate }}</li>
        <li>{{ "stimSetting.snap_connection" | translate }}</li>
        <li>{{ "stimSetting.cable_connection" | translate }}</li>
      </ul>
      <p>{{ "stimSetting.confirm_to_handle" | translate }}</p>
    </div>
  </div>

  <div class="dialog-actions">
    <div class="outline-button" (click)="cancel()">
      {{ "common.exit" | translate }}
    </div>
    <div class="outline-button" (click)="confirm()">
      {{ "common.confirm" | translate }}
    </div>
  </div>
</mat-dialog-content>
