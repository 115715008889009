import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '@app/auth/services/auth.service';
import { Role } from '@app/models/Role.enum';
import { ElectrostimProgramDefinition } from '@app/training/pages/electrostim/electrostim.component';

const STIM_DURATIONS = [
  { label: '10 min', value: 10 * 60 * 1e6 },
  { label: '20 min', value: 20 * 60 * 1e6 },
  { label: '30 min', value: 30 * 60 * 1e6 },
  { label: '40 min', value: 40 * 60 * 1e6 },
  { label: '50 min', value: 50 * 60 * 1e6 },
  { label: '60 min', value: 60 * 60 * 1e6 },
  { label: '90 min', value: 90 * 60 * 1e6 },
  { label: '120 min', value: 120 * 60 * 1e6 },
];

@Component({
  selector: 'sba-stim-setting-adjustment-component',
  templateUrl: './stim-setting-adjustment-component.component.html',
  styleUrls: ['./stim-setting-adjustment-component.component.scss']
})
export class StimSettingAdjustmentComponentComponent implements OnInit {

  program: ElectrostimProgramDefinition;
  treatmentDurations = STIM_DURATIONS;
  isAlreadySet = false;

  constructor(
    public dialogRef: MatDialogRef<StimSettingAdjustmentComponentComponent>,
    private readonly authService: AuthService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public readonly data: { program: ElectrostimProgramDefinition }) {
    dialogRef.disableClose = true;
    this.program = data.program;
    if (!this.program.adjustable || Object.keys(this.program.adjustable).length === 0) {
      this.dialogRef.close(this.data.program);
    }
  }

  ngOnInit(): void {
    const role = this.authService.currentUser.role;
    if (role === Role.PATIENT) {
      this.isAlreadySet = true;
    }
  }

  changeVal(event, field) {
    this.program.details = {
      ...this.program.details,
      [field]: event.value
    };
    this.cdr.detectChanges();
  }

  cancel() {
    this.dialogRef.close(this.data.program);
  }

  confirm() {
    this.dialogRef.close(this.program);
  }

  deny() {
    this.dialogRef.close(false);
  }

}
