import { Component, OnDestroy, OnInit } from '@angular/core';
import { CurrentExerciseService } from '@app/training/services/current-excercise.service';
import { CurrentSessionService } from '@app/training/services/current-session.service';
import { environment } from '@env/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'sba-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit, OnDestroy {

  sub: Subscription = new Subscription();
  showStellaDiagnostic = !environment.production;

  constructor(
    private readonly exercise: CurrentExerciseService,
    private readonly session: CurrentSessionService
  ) { }

  async ngOnInit() {
  }

  async ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.exercise.hasStarted) {
      await this.exercise.stop();
    }
    if (this.session.isStarted) {
      await this.session.stop();
    }
  }
}
