import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MusclesBackComponent } from './muscles-back/muscles-back.component';
import { MusclesFrontComponent } from './muscles-front/muscles-front.component';
import { MusclesPelvicComponent } from './muscles-pelvic/muscles-pelvic.component';

@NgModule({
  declarations: [
    MusclesFrontComponent,
    MusclesBackComponent,
    MusclesPelvicComponent
  ],
  imports: [CommonModule, MatIconModule],
  exports: [MusclesBackComponent, MusclesFrontComponent, MusclesPelvicComponent]
})
export class MusclesModule {}
