import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@env/environment';

@Component({
  selector: 'sba-enter-password-dialog',
  templateUrl: './enter-password-dialog.component.html',
  styleUrls: ['./enter-password-dialog.component.scss']
})
export class EnterPasswordDialogComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<EnterPasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  maintenance = new FormGroup({
    password: new FormControl('', [Validators.required])
  });

  onSubmit() {
    if (this.maintenance.value.password === environment.maintenancePassword) {
      this.dialogRef.close(true);
    } else {
      this.maintenance.get('password').setErrors({
        notCorrect: true
      });
    }
  }
}
