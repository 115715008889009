<mat-dialog-content>
  <div class="title-row">
    <h2 class="setting-header">{{ "electrostim.waveform" | translate }}</h2>
    <div class="outline-button" (click)="confirm()">
      {{ "common.ok" | translate }}
    </div>
  </div>
  <div class="canvas-container">
    <canvas #waveform></canvas>
  </div>
</mat-dialog-content>
