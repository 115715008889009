<div class="channel-info">
  <div 
    [style.background-color]="color"
    [style.outline-color]="selected ? selectedColor : 'transparent'"
    class="label">
    {{ channel + 1 }}
  </div>
  <div
    [ngStyle]="{
      color: maintenance ? '#454545' : color,
      fontWeight: selected ? 'bold' : ''
    }"
    class="muscle-name"
    *ngIf="!hideMuscle"
  >
    {{ (muscle ? "muscles." + muscle.name : "common.not_set") | translate }}
    {{ muscle?.side }}
  </div>
  <div
    [ngStyle]="{
      color: maintenance ? '#454545' : color,
      fontWeight: selected ? 'bold' : ''
    }"
    class="value"
  >
    {{ value | number: "1.3-3" }} {{ 'common.units.microvolts' | translate }}
  </div>
  <div
    [ngStyle]="{
      color: maintenance ? '#454545' : color,
      fontWeight: selected ? 'bold' : ''
    }"
    class="value"
    *ngIf="showImpedance"
  >
    {{ this.impedance / 1000 | number: "1.3-3" }} kΩ
  </div>
  <div class="value max" *ngIf="showMax" (click)="reset.emit()">
    <div
      [ngStyle]="{
        color: color,
        borderBottomColor: maintenance ? '#454545' : color,
        fontWeight: selected ? 'bold' : ''
      }"
    >
      {{ 'emg.mvc' | translate }}: {{ max | number: "1.0-0" }} {{ 'common.units.microvolts' | translate }}
    </div>

    <mat-icon
      class="clear-icon"
      [ngStyle]="{
        color: color
      }"
      >backspace</mat-icon
    >
  </div>
</div>
