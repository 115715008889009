import { Injectable } from '@angular/core';
import Color from 'color';

const colors = {
  0: '#ed1c24',
  1: '#00a0eb',
  2: '#45db5a',
  3: '#f79cc4',
  4: '#E6C700',
  5: '#00c8df',
  6: '#9051e2',
  7: '#8f725c'
};

@Injectable({
  providedIn: 'root'
})
export class ColorService {
  getColorForChannel(channel: number): Color {
    return new Color(colors[channel] || '#66cccc');
  }
}
