import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'sba-unknown-error-dialog',
  templateUrl: './unknown-error-dialog.component.html',
  styleUrls: ['./unknown-error-dialog.component.scss']
})
export class UnknownErrorDialogComponent implements OnDestroy {
  errorCode: string;
  errorMessage: string;

  constructor(
    public dialogRef: MatDialogRef<UnknownErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: {errorCode: string, errorMessage: string}
  ) {
    this.errorCode = data.errorCode;
    this.errorMessage = data.errorMessage
  }

  ngOnDestroy(): void {
    delete this.errorCode, this.errorMessage;
  }

  ok() {
    this.dialogRef.close(true);
  }
}
