import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'sba-uploading-data-dialog',
  templateUrl: './uploading-data-dialog.component.html',
  styleUrls: ['./uploading-data-dialog.component.scss']
})

export class UploadingDataDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<UploadingDataDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public progress: number = 0
  ) {
    this.dialogRef.disableClose = true;
   }

}
