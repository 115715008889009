<div mat-dialog-content class="electrostim-warning-dialog">
  <h2>{{ 'warningDialog.safety_warning' | translate }} </h2>
  <div class="row">
    <img class="warning" src="/assets/png/warning_iso.png">
    <div class="column">
      <p class="second">
        {{ 'warningDialog.second_1' | translate}} <span class="bold">{{ 'warningDialog.second_2' | translate }}</span>
        
      </p>
      <p class="second">
        
        <span class="bold">{{ 'warningDialog.consult_1' | translate}}</span> {{ 'warningDialog.consult_2' | translate }}
      </p>
    </div>
  </div>
  <div class="dialog-actions">
    <div class="outline-button" (click)="confirm()">
      {{ 'warningDialog.confirm' | translate }}
    </div>
    <div class="outline-button deny" (click)="deny()">
      {{ 'warningDialog.back' | translate }}
    </div>
  </div>
</div>