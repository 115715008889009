<div class="contraction-legend">
  <div class="contraction-legend--item contraction">
    <div class="line"></div>
    <div class="text">{{ 'common.contraction' | translate }}</div>
  </div>
  <div class="contraction-legend--item relax">
    <div class="line"></div>
    <div class="text">{{ 'common.relax' | translate }}</div>
  </div>
  <div class="contraction-legend--item end">
    <div class="line"></div>
    <div class="text">{{ 'common.end' | translate }}</div>
  </div>
</div>