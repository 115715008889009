import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import * as decode from 'jwt-decode';
import * as moment from 'moment';
import { ACCESS_TOKEN_KEY } from '@app/enums';
import { Role } from '@app/models/Role.enum';

export enum AppMode {
  GUEST = 'GUEST',
  FULL = 'FULL'
}

export interface AuthState {
  isLoggedIn: boolean;
  currentUser: any;
  mode: AppMode;
  role: Role;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'auth'
})
export class AuthStore extends Store<AuthState> {

  constructor() {
    super({
      isLoggedIn: isAuthorized(),
      currentUser: null
    });
  }
}

// TODO: Duplicated code
function isAuthorized(): boolean {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);

  if (!token) {
    return false;
  }
  const info = decode(token);
  return moment(info.exp * 1000).isAfter();
}
