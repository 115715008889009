<mat-dialog-content>
  <div class="message">{{ data?.prompt | translate }}</div>

  <div class="actions-container">
    <button class="outline-button" mat-ripple (click)="cancel()">
      {{ 'common.no' | translate }}
    </button>
    <button class="outline-button" mat-ripple (click)="ok()">
      {{ 'common.yes' | translate }}
    </button>
  </div>
</mat-dialog-content>
