import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getCurrentLang } from '@app/app.component';
import { LANGUAGES, LanguageSettings } from '@app/enums';
import { AnalyticsAction, AnalyticsCategory, GTagService } from '@app/shared/gtag.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sba-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LanguageSelectorComponent {
  @Input() theme: 'white' | 'transparent' = 'transparent';
  languages;
  lang: LanguageSettings;

  constructor(
    private readonly translateService: TranslateService,
    private readonly ga: GTagService
  ) {
    const browserLang = navigator.language.split('-')[0];
    this.lang = getCurrentLang();
    this.languages = LANGUAGES.filter(l => l.label !== this.lang.label);
  }

  change(lang: LanguageSettings) {
    this.lang = lang;
    this.translateService.use(lang.value);
    this.languages = LANGUAGES.filter(l => l.label !== this.lang.label);
    localStorage.setItem('LANGUAGE', lang.value);
    localStorage.setItem('LANGUAGE_LABEL', lang.label);
    localStorage.setItem('LANGUAGE_SOUNDS', lang.sounds);
    this.ga.emit(
      AnalyticsCategory.LANGUAGE_SELECTOR,
      AnalyticsAction.CHANGE_LANGUAGE,
      lang.value
    );
  }
}

