<div class="emg-page--content">
  <div class="chart-slider-container">
    <div class="chart-content">
      <sba-stella-connected-emg-bar-chart id="bar-chart" class="hidden-sm" (emgValue)="updateValues($event)"
        [showOnlyChannels]="muscles | localFunction: showOnlyConnected" [showSlider]="false" [source$]="stella.source$"
        [cable$]="stella.cable$" autoscale="true">
        <div rightTop class="outline-button" (click)="confirm()">
          {{ "training.finishCalibration" | translate }}
        </div>

        <div badges class="labels-container">
          <div class="channels-description" *ngIf="muscles.length > 1">{{ "training.selectChannel_1" | translate }}<br/>{{ "training.selectChannel_2" | translate }}</div>
          <div class="channels-container">
            <div channelBadge class="channel-info" *ngFor="
              let val of muscles | localFunction: showOnlyConnected;
              let channel = index;
              trackById
            " [channel]="val" [value]="values[channel]" [color]="val | channelcolor"
              [selectedColor]="val | channelcolor:0.4" [max]="maxArray[channel]" [muscle]="val | muscle: muscles"
              [selected]="val === selectedChannel" showMax="true" (reset)="resetMax(channel)"
              (click)="selectChannel(val)"></div>
          </div>
        </div>
      </sba-stella-connected-emg-bar-chart>
      <div class="hidden-md">
        <div class="outline-button" (click)="confirm()">
          {{ "training.finishCalibration" | translate }}
        </div>
        <div class="labels-container hidden-md">
          <div class="channels-description" *ngIf="muscles.length > 1">{{ "training.selectChannel_1" | translate }}<br/>{{ "training.selectChannel_2" | translate }}</div>
          <div class="sm-channels-container">
            <div channelBadge class="channel-info" *ngFor="
                let val of muscles | localFunction: showOnlyConnected;
                let channel = index;
                trackById
              " [channel]="val" [value]="values[channel]" [color]="val | channelcolor"
              [selectedColor]="val | channelcolor:0.4" [max]="maxArray[channel]" [muscle]="val | muscle: muscles"
              [selected]="val === selectedChannel" showMax="true" (reset)="resetMax(channel)"
              (click)="selectChannel(val)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="deny-button--container">
    <button mat-button class="deny-button" (click)="deny()">{{ 'training.back_to_muscle' | translate  }}</button>
  </div>
</div>
