import { Component, Input } from '@angular/core';

@Component({
  selector: 'sba-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent {

  @Input()
  disallowed = false;

  @Input()
  rounded = true;

  @Input()
  set data(data) {
    this.ddata = {
      description: data.short || 'No description',
      title: data.label || 'Program name',
      badges: data.badges,
      icon: data.icon
    };
  }

  @Input()
  selected: boolean;

  ddata;
}
