<sba-new-exercise-container #exerciseContainer (start)="onStart($event)">
  <div class="electrostim">
    <!-- <sba-no-such-program *ngIf="!electrostimProgram"></sba-no-such-program> -->
    <div class="electrostim--container" *ngIf="electrostimProgram">
      <div class="electrostim--title-container">
        <sba-training-title-row
          (back)="goBack()"
          [name]="
            (electrostimProgramDefinition.name
              ? 'exercises.' + electrostimProgramDefinition.name
              : 'electrostim.title'
            ) | translate
          "
          [short]="'descriptions.' + electrostimProgramDefinition.short | translate"
        ></sba-training-title-row>
        <div class="details">
          <div class="detail">
            <div class="label">{{ "electrostim.pulse" | translate }}</div>
            <div class="value">{{ electrostimProgramDefinition | pulses }} {{ "common.units.microseconds" | translate }}</div>
          </div>
          <div class="detail">
            <div class="label">{{ "electrostim.frequency" | translate }}</div>
            <div class="value">{{ electrostimProgramDefinition | frequencies }} {{ "common.units.hertz" | translate }}</div>
          </div>
          <div class="detail">
            <div class="label">{{ "electrostim.shape" | translate }}</div>
            <div class="value">
              {{
                "electrostim.current." + electrostimProgramDefinition.details.currentType
                  | translate
              }}
            </div>
          </div>
          <div>
            <img [src]="chartIconSrc" class="waveform-button" />
          </div>
        </div>
      </div>
      <div class="electrostim--content">
        <div class="chart-content">
          <sba-stella-connected-emg-bar-chart
            class="emg-bar-chart"
            [initialialThreshold]="trigger.threshold"
            (emgValue)="updateTrigger($event)"
            [showOnlyChannels]="[primaryTriggerChannel]"
            (thresholdChange)="handleThresholdChange($event)"
            [source$]="stella.source$"
            [cable$]="stella.cable$"
            *ngIf="electrostimProgramDefinition?.details.emgTriggered"
          >
          </sba-stella-connected-emg-bar-chart>
          <div class="chart-container">
            <div id="line-chart" #lineChart></div>
          </div>
        </div>
        <div class="controls-row">
          <div class="intensity--container">
            <h2 class="intensity-header">
              {{ "electrostim.intensity" | translate }}
            </h2>
            <sba-electrostim-level-control
              [levels]="electrostimIntensities"
              [range]="electrostimProgramDefinition?.details?.currentRange"
              [channels]="electrostimChannels"
              [canIncrement]="state == State.Running"
              [disallowIntensityChange]="true"
              (intensityChange)="modifyLevel($event)"
            ></sba-electrostim-level-control>
            <div style="text-align: center;">
              <button class="outline-button" mat-ripple (click)="calibrate()">
                {{ 'training.calibration' | translate }}
              </button>
            </div>
          </div>
          <div class="controls--container">
            <div class="phases-counter" *ngIf="programModule.phaseCount() > 1">
              <p class="button--label">
                {{ "electrostim.phases" | translate }}
              </p>
              <p class="value">
                {{ programModule.phase() + 1 }}/{{ programModule.phaseCount() }}
              </p>
            </div>
            <div class="row">
              <div class="button--column play">
                <div
                  matRipple
                  class="button play"
                  [ngClass]="{ initial: isStopped }"
                  (click)="canPause() ? pause() : start()"
                >
                  <mat-icon>{{ canPause() ? "pause" : "play_arrow" }}</mat-icon>
                </div>
                <p class="button--label">
                  {{
                    "electrostim." + (canPause() ? "pause" : "play") | translate
                  }}
                </p>
              </div>
              <div class="button--column">
                <sba-clock
                  [progress]="calculateProgress()"
                  [text]="
                    electrostimProgramDefinition.details.emgTriggered
                      ? getRepetitionCounter()
                      : (remainingTimeSeconds() | duration: 'simple')
                  "
                ></sba-clock>
                <p class="button--label" *ngIf="!electrostimProgramDefinition.details.emgTriggered">
                  {{ "electrostim.remainingTime" | translate }}
                </p>
                <p class="button--label" *ngIf="electrostimProgramDefinition.details.emgTriggered">
                  {{ "electrostim.repetition" | translate }}
                </p>
              </div>
              <div class="button--column stop">
                <div
                  matRipple
                  class="button stop"
                  [ngClass]="{ initial: isStopped }"
                  (click)="stop({ reason: 'user' })"
                >
                  <mat-icon>stop</mat-icon>
                </div>
                <p class="button--label">
                  {{ "electrostim.stop" | translate }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</sba-new-exercise-container>
