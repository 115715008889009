import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'sba-upgrade-dialog',
  templateUrl: './upgrade-dialog.component.html',
  styleUrls: ['./upgrade-dialog.component.scss']
})
export class UpgradeDialogComponent {
  message: string;
  allowUserClose: boolean;

  constructor(
    public dialogRef: MatDialogRef<UpgradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.allowUserClose = Boolean(data?.allowUserClose);
    this.message = data?.message ?? "stella.upgrading";

    dialogRef.disableClose = this.allowUserClose ? false : true;
  }

  ok() {
    this.dialogRef.close(true);
  }
}
