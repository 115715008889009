import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import * as decode from 'jwt-decode';
import * as moment from 'moment';
import { ACCESS_TOKEN_KEY } from '@app/enums';
import { AuthState, AuthStore } from './auth.store';
import { Observable, timer } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {

  constructor(protected store: AuthStore) {
    super(store);
  }

  isAuthorized$ = timer(0, 1000).pipe(
    map(_ => this.isAuthorized()),
    distinctUntilChanged()
  );

  isAuthorized(): boolean {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token) {
      return false;
    }
    
    const info = decode(token);
    return moment(info.exp * 1000).isAfter();
  }

  licenses(): any[] {
    const token = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (!token) {
      return [];
    }

    if (environment.telemedicine) {
      const info = decode(token);
      return info.licenses;
    }

    return ['emg_biofeedback', 'multi_home', 'neuro', 'ortho', 'pain', 'pelvic', 'professional', 'sport', 'tele'];
  }
}
