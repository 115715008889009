import { Injectable } from '@angular/core';
import { ChunkMemory, SessionMemory, Signals, MeetingWithSignals, MeetingMemory, SessionWithMeetings, SignalPack, CompressedPack } from '@app/types';
import { AnyRecordWithTtl } from 'dns';
import { StellaDexie } from './StellaDexie';
import * as fflate from 'fflate'

const worker = new Worker('./update.worker', { name: 'update', type: 'module' });
interface GzipStream {
  handle: fflate.AsyncGzip;
  // started === true means that stream has compressed data which has to be properly handled by 
  // added chunk marked as last before upload.
  started: boolean;
}
const VALID_CLOUD_TYPES = ['rmss','guideLine'];
@Injectable({
  providedIn: 'root'
})


export class LocalStoreService {
  readonly DB_NAME: string = 'PROD_STELLA_DASHBOARD';
  private db: StellaDexie;
  private streams: Record<string,GzipStream[]> = {};
  private initializedCounter: number = 0;
  private completedTarget = new EventTarget();

  async init(): Promise<void> {
    this.db = new StellaDexie(this.DB_NAME);
    try {
      await this.db.init();
    } catch (err) {
    await this.db.delete();
      await this.db.init();
    }
  }

  initializeStreams(exerciseId: number, maxChannel) {  
    for(const type of VALID_CLOUD_TYPES) {
      this.streams[type] = [];
      for(let channel = 0; channel < maxChannel; channel++) {
        let seqNo = 0; 
        this.streams[type][channel] = {
          // use fflate.Gzip for synchronous gzip
          handle: new fflate.AsyncGzip({ level: 9 }, (err, chunk, isLast) => {
            if( err ) {
              console.log(`Corrupted compressed data ${err}`);
              return;
            }
            this.db.chunks[exerciseId][seqNo][`${type}Compressed`][channel] = chunk;
            if( !this.streams[type][channel].started ) {
              this.initializedCounter++;
            }
            this.streams[type][channel].started = !isLast;
            seqNo++;

            if( isLast) {
              this.initializedCounter--;
              if( !this.initializedCounter) {
                this.completedTarget.dispatchEvent(new Event('completed'));
              }
            }
          }),

          started: false
        }
      }
    }
  }

  async destroyStreams() {
    // Wait for streams to be marked as completed
    if( this.initializedCounter ) {
      try { 
        await new Promise((resolve,reject) => {
          this.completedTarget.addEventListener('completed', () => {
            resolve(true);
          });
          
          setTimeout(() => {
            reject();
          }, 5000)
        })
      } catch( err ) {
        throw new Error('Timeout from async workers');
      }
    }

    for(const type in this.streams) {
      for(const chIdx in this.streams[type]) {
        this.streams[type][chIdx].handle.terminate();
        this.streams[type][chIdx].handle = null;
      }
    }
    this.streams = {};
  }

  requestCompressedData(name: string, idx: number, data: Uint8Array, isLast = false) {
      if( this.streams[name]) {
        this.streams[name][idx].handle.push(data,isLast);
      }
  }

  addSession(session: SessionMemory): Promise<number> {
    return this.db.sessions.add(session);
  }

  updateSession(id: number, changes: Partial<SessionMemory>) {
    return this.db.sessions.update(id, changes);
  }

  removeSession(id: number): Promise<any> {
    return this.db.sessions.delete(id);
  }

  async removeExercise(id: number): Promise<any> {
    delete this.db.chunks[id];
    return this.db.meetings.delete(id);
  }

  sweepSession(): Promise<void> {
    return this.db.sessions.clear();
  }

  addExercise(exercise: MeetingMemory): Promise<number> {
    return this.db.meetings.add(exercise);
  }

  addChunk(exercise: number, data: ChunkMemory, isLast: boolean): void {
    if (exercise) {
      if (!this.db.chunks[exercise]) {
        this.db.chunks[exercise] = [];
      }

      const binary = {};
      for(const key of VALID_CLOUD_TYPES) {
        binary[`${key}Compressed`] = [];
        data[key].forEach((chData, chIdx) => {         
          const chunk = chData.byteLength ? new Uint8Array(chData.buffer, chData.byteOffset, chData.byteLength) : new Uint8Array(0);
          binary[`${key}Compressed`][chIdx] = new Uint8Array();
          this.requestCompressedData(key,chIdx,chunk,isLast); 
        });
      }

      this.db.chunks[exercise].push({ 
        ...data,
        ...binary
      });
    }
  }

  async getExercise(id: number): Promise<MeetingMemory> {
    const meets = await this.db.meetings
      .where({ id })
      .limit(1)
      .toArray();
    if (meets.length === 0) {
      throw new Error('exercise not found');
    }
    return { 
      ...meets[0]
    }
  }

  private async collectExerciseData(exercise: MeetingMemory): Promise<MeetingWithSignals> {
    const { channels, rmss, guideLine, channelsCompressed, rmssCompressed, guideLineCompressed } = await this.getSignalsForExercise(
      exercise.id
    );
    return {
        ...exercise,
        channels: (channels as SignalPack),
        rmss: (rmss as SignalPack),
        guideLine: (guideLine as SignalPack),
        rmssCompressed: (rmssCompressed as CompressedPack),
        channelsCompressed: (channelsCompressed as CompressedPack),
        guideLineCompressed: (guideLineCompressed as CompressedPack),
    };
  }

  async getSignalsForExercise(exercise: number): Promise<ChunkMemory> {
    if (!this.db.chunks[exercise] || this.db.chunks[exercise].length === 0) {
      return {
        rmss: [],
        channels: [],
        guideLine: []
      };
    }

    const numberOfProbes = {
      rmss: Array(8).fill(0),
      channels: Array(8).fill(0),
      guideLine: Array(8).fill(0),
      channelsCompressed: Array(8).fill(0),
      rmssCompressed: Array(8).fill(0),
      guideLineCompressed: Array(8).fill(0)
    };

    this.db.chunks[exercise].forEach(element => {
      for (const key in element.channels) {
        numberOfProbes.rmss[key] += element.rmss[key].length;
        numberOfProbes.guideLine[key] += element.guideLine[key].length;
        numberOfProbes.rmssCompressed[key] += element.rmssCompressed[key].byteLength;
        numberOfProbes.guideLineCompressed[key] += element.guideLineCompressed[key].byteLength;
      }
    });

    const signals = {
      rmss: this.createEmptySignalPack(numberOfProbes.rmss),
      guideLine: this.createEmptySignalPack(numberOfProbes.guideLine),
      rmssCompressed: this.createEmptyBuffer(numberOfProbes.rmssCompressed),
      guideLineCompressed: this.createEmptyBuffer(numberOfProbes.guideLineCompressed),
    };

    this.concatCompressedChunks(this.db.chunks[exercise], "rmssCompressed", signals.rmssCompressed);
    this.concatCompressedChunks(this.db.chunks[exercise], "guideLineCompressed", signals.guideLineCompressed);

    return signals;
  }

  concatChunks(chunks: ChunkMemory[], type: keyof ChunkMemory, result: Float32Array[]): void {
    const channels = chunks[0][type];
    
    for (const channel in channels) {
      let offset = 0
      for (const chunk of chunks) {
        result[channel].set(chunk[type][channel], offset);
        offset += chunk[type][channel].length;
      }
    }
  }

  concatCompressedChunks(chunks: ChunkMemory[], type: keyof ChunkMemory, result: Uint8Array[]) {
    const channels = chunks[0][type];
    
    for (const channel in channels) {
      let offset = 0
      for (const chunk of chunks) {
        result[channel].set(chunk[type][channel], offset);
        offset += chunk[type][channel].length;
      }
    }
  }  


  updateExercise(id: number, changes: Partial<MeetingMemory>) {
    return this.db.meetings.update(id, changes);
  }

  sweepExercises(): Promise<void> {
    return this.db.meetings.clear();
  }

  async getExerciseById(exerciseId: number): Promise<MeetingWithSignals> {
    const ex = await this.db.meetings.where({ id: exerciseId }).first();
    const exRes = {
      id: ex.id,
      name: ex.name,
      startDate: ex.startDate,
      endDate: ex.endDate,
      muscles: ex.muscles,
      cable: ex.cable
    };
    return await this.collectExerciseData(ex);
  }

  async getExercisesForSession(sessionId: number, withSignal = true): Promise<MeetingWithSignals[]> {
    const exercises: MeetingWithSignals[] = [];
    const exs = [];
    await this.db.meetings
      .where({ session: sessionId })
      .each(ex => {
        exs.push({
          id: ex.id,
          name: ex.name,
          startDate: ex.startDate,
          endDate: ex.endDate,
          muscles: ex.muscles,
          cable: ex.cable
        });
      });
    for (const ex of exs) {
      exercises.push(withSignal ? await this.collectExerciseData(ex) : ex);
    }
    return exercises;
  }

  getSession(sessionId: number): Promise<SessionMemory> {
    return this.db.sessions.where({ id: sessionId }).first();
  }

  async getSessionsForPatient(patientId: string): Promise<SessionWithMeetings[]> {
    const sessions = [];
    const ses: SessionMemory[] = await this.db.sessions
      .where('[patient.patientId+completed]')
      .equals([patientId, 1])
      .reverse()
      .toArray();

    for (const ex of ses) {
      sessions.push({
        ...ex,
        exercises: await this.getExercisesForSession(ex.id, false)
      });
    }
    return sessions;
  }

  private createEmptySignalPack(numberOfProbes: number[]) {
    const result: Float32Array[] = [];

    numberOfProbes.forEach(element => {
      result.push(new Float32Array(element));
    });

    return result;
  }

  private createEmptyBuffer(numberOfProbes: number[]) {
    const result: Uint8Array[] = [];
    numberOfProbes.forEach(element => {
      result.push(new Uint8Array(element));
    });

    return result;
  }
}
