import { Injectable } from '@angular/core';
import { ACCESS_TOKEN_KEY } from '@app/enums';
import { ClinicsQuery } from '@app/store/clinics/clinics.query';
import { PatientsQuery } from '@app/store/patients/patients.query';
import { validChannels } from '@app/training/ChannelResolver';
import { environment } from '@env/environment';
import { LocalStoreService } from './local-store.service';
import { CompressedPack, MeetingWithSignals } from '../../types';
import { SessionStore } from '@store/session';

export interface UpdateTrainingDataUpload {
  url: string,
  uploaded: boolean,
  filename: string,
  data: string | Uint8Array,
  training: string
}

export interface UpdateTrainingData {
  type: 'upload',
  uploadType: 'text' | 'binary', 
  uploads: UpdateTrainingDataUpload[],
  sessionId: string,
  clinicId: string,
  patientId: number,
  token: string,
  baseUrl: string,
  trainingId: string,
  duration: number,
}

@Injectable({
  providedIn: 'root'
})
export class UploadServiceService {

  constructor(
    private clinicsQuery: ClinicsQuery,
    private patientQuery: PatientsQuery,
    private storage: LocalStoreService,
    private sessionStore: SessionStore
  ) { }

  async uploadSession(sessionId: number): Promise<void> {
    const session = await this.storage.getSession(sessionId);
    const exercises = await this.storage.getExercisesForSession(sessionId);

    // Upload all exercises in session
    for (const ex of exercises) {
      await this.uploadExercise(ex.id, session.remoteId);
    }

    // If every exercise was uploaded then mark whole session as uploaded
    if (exercises.every(ex => Boolean(ex.uploaded))) {
      this.storage.updateSession(sessionId, {
        uploaded: 1
      });
    }
  }

  async uploadExercise(exerciseId: number, createdSessionId: string) {
    const ex = await this.storage.getExerciseById(exerciseId);
    const uploadType = 'gzip' as 'text' | 'binary' | 'gzip';
    const uploads = createUploadObjects(ex, createdSessionId, uploadType);

    const worker = new Worker('./upload-training.worker', { name: 'upload', type: 'module' });

    worker.postMessage({
      type: 'upload',
      uploads,
      uploadType: uploadType === 'binary' || uploadType === 'gzip' ? 'binary' : 'text',
      sessionId: createdSessionId,
      clinicId: this.clinicsQuery.getActive().id,
      patientId: this.patientQuery.getActivePatientId(),
      token: localStorage.getItem(ACCESS_TOKEN_KEY),
      baseUrl: environment.restServerUrl,
      trainingId: ex.remoteId,
      duration: ex.duration
    } as UpdateTrainingData,uploads.map(item => item.data instanceof Uint8Array ? item.data.buffer: null));

    worker.addEventListener('message', async ( event: MessageEvent ) => {
      if(event.data.message === 'onprogress') {
        this.sessionStore.update({ progress: Math.round(50 + event.data.progress / 2) });
      }
      if(event.data.message === 'uploadcomplete') {
         console.log('[APPLICATION: UPLOAD COMPLETE]');
         this.sessionStore.update({
            uploading: false
        });         
      }
    });
  }
}

function createUploadObjects(ex: MeetingWithSignals, sessionId: string, type: 'text' | 'binary' | 'gzip'): UpdateTrainingDataUpload[] {
  const suffix = type === 'binary' ? '_bin'
    : type === 'gzip' ? '_gz'
    : '';

  // Generate data that will be sent to main storage
  const extract = (field: keyof MeetingWithSignals, filePart: string) => {
      return ex.muscles.map((muscle) => {
          const channelDataArray = (ex[field] as Float32Array[])[muscle.channel];
          let data;

        if (type === 'text') {
          data = channelDataArray.join(',');
        }
        else if (type === 'binary') {
          data = new Uint8Array(channelDataArray.buffer, channelDataArray.byteOffset, channelDataArray.byteLength);
        }
        else if (type === 'gzip') {
          data = (ex[`${field}Compressed`] as CompressedPack)[muscle.channel]
        }

        return {
          url: '',
          uploaded: false,
          filename: `training_${sessionId}_${ex.remoteId}_${filePart}_${muscle.channel}${suffix}.dat`,
          data,
          training: ex.remoteId
        } as UpdateTrainingDataUpload;
      });
  };

  const returnValue =  [
    ...extract('rmss', 'rms'),
    ...extract('guideLine', 'guide')
  ];
  return returnValue;
}
