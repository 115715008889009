const featureFlags = {
  loginAfterSignup: true,
  autoSelectClinic: false,
  autoSelectPatient: false,
  hideNotImplemented: true,
  showTilesInsteadOfExtraPanel: true,
  maintenance: true,
  maintenanceFakeVersion: '1.0.0',
  maintenancePassword: 'maintenance',
  allowExperimentalOffsetCanvas: false,
  skipNetworkSelection: false
};

export { featureFlags };

