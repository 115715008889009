import { Pipe, PipeTransform } from '@angular/core';
import { BATTERY_MIN, BATTERY_MAX } from '@app/enums';

@Pipe({
  name: 'battery'
})
export class BatteryPipe implements PipeTransform {

  private calculateLevel(battery): number {
    const bb = {
      low: BATTERY_MIN,
      high: BATTERY_MAX,
      ...battery,
    };
    const A = 100 / (bb.high - bb.low);
    const B = 0 - A * bb.low;

    return Math.min(Math.max(A * bb.vol + B, 0), 100);
  }

  transform(value: any, ...args: any[]): any {
    if (value.level) {
      return value.level;
    }
    return this.calculateLevel(value);
  }

}
