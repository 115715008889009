<sba-page-title title="title.configure_wifi"></sba-page-title>
<div class="bt-configuration" *ngIf="state$ | async as state">
  <div class="back-arrow">
    <button mat-icon-button (click)="goBack()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>

  <div class="step prep" *ngIf="state.name === 'PREP'">
    <h2>{{ "btConfigure.prepChecklist" | translate }}</h2>
    <img class="hint-image" src="assets/png/stella_router_white.png" />
    <p>{{ "btConfigure.checklistTitle" | translate }}</p>
    <ul>
      <li>{{ "btConfigure.turnedOnStella" | translate }}</li>
      <li>{{ "btConfigure.routerInArea" | translate }}</li>
      <li>{{ "btConfigure.bleOn" | translate }}</li>
      <li>{{ "btConfigure.wifiPass" | translate }}</li>
    </ul>
    <button mat-button class="continue-button" (click)="setState('START')">
      {{ "btConfigure.continue" | translate }}
    </button>
  </div>
  <div class="step start" *ngIf="state.name === 'START'">
    <p class="bt-configuration--text">
      {{ "btConfigure.unpaired" | translate }}
    </p>
    <p class="bt-configuration--prompt">
      {{ "btConfigure.bluetoothPrompt" | translate }}
    </p>

    <p class="bt-configuration--prompt">
      {{ "btConfigure.openWindowAgain" | translate }}
    </p>
    <button mat-button class="continue-button" (click)="connectManually()">
      {{ "btConfigure.openWindow" | translate }}
    </button>
    <p class="bt-configuration--warning-text">
      {{ "btConfigure.warning" | translate }}
    </p>
  </div>
  <div *ngIf="state.name === 'SCAN'" class="device-wifi">
    <div class="device-wifi--card">
      <h2 class="device-wifi--title">
        {{ "btConfigure.networks" | translate }}
      </h2>
      <p class="help-text">{{ "btConfigure.clickToSelect" | translate }}</p>
      <div
        class="device-wifi--networks"
        *ngIf="bt.dataStream$ | async as networks"
      >
        <div
          matRipple
          class="network-item"
          *ngFor="let net of networks; trackBy: getTrackId"
          (click)="selectNetwork(net)"
        >
          <p>{{ net.ssid || ("btConfigure.unknowNetwork" | translate) }}</p>
          <stella-wifi-icon [level]="net.rssi"></stella-wifi-icon>
        </div>
        <p class="empty-networks" *ngIf="networks.length === 0">
          {{ "btConfigure.rangeAdvice" | translate }}
        </p>
      </div>
      <!-- <button
        *ngIf="state.devicePaired"
        class="finish-button"
        mat-button
        (click)="synchronizeWifi()"
      >
        {{ "btConfigure.synchronizeWifi" | translate }}
      </button> -->
      <button
        *ngIf="state.devicePaired"
        class="finish-button"
        mat-button
        (click)="setManualSsid()"
      >
        {{ "btConfigure.ssidManual" | translate }}
      </button>
    </div>
  </div>

  <div
    class="wifi-password"
    *ngIf="state.devicePaired && state.networkSelected && !state.finished"
  >
    <div
      class="wifi-password--title-container"
      *ngIf="currentNetwork$ | async as network"
    >
      <mat-icon
        (click)="backToNetworkSelection()"
        *ngIf="state.networkSelected && !environment.skipNetworkSelection"
        >chevron_left</mat-icon
      >
      <h2
        *ngIf="!environment.skipNetworkSelection"
        class="wifi-password--title"
      >
        {{ network.ssid || ("btConfigure.ssids" | translate) }}
      </h2>
      <h2 *ngIf="environment.skipNetworkSelection" class="wifi-password--title">
        {{ "btConfigure.manually" | translate }}
      </h2>
    </div>
    <div class="wifi-password--form-container">
      <form
        #passwordForm
        class="password-form"
        [formGroup]="passwordFormGroup"
        (ngSubmit)="onPasswordSubmit()"
      >
        <mat-form-field class="password-form--input-container">
          <input
            type="text"
            data-lpignore="true"
            autocomplete="off"
            matInput
            [placeholder]="'btConfigure.wifiSsid' | translate"
            formControlName="essid"
          />
        </mat-form-field>
        <mat-form-field class="password-form--input-container">
          <input
            [type]="hide ? 'password' : 'text'"
            data-lpignore="true"
            autocomplete="off"
            matInput
            [placeholder]="'btConfigure.wifiPassword' | translate"
            formControlName="password"
          />
          <mat-icon
            matSuffix
            (mousedown)="hide = false"
            (mouseleave)="hide = true"
            (mouseup)="hide = true"
            >{{ hide ? "visibility_off" : "visibility" }}</mat-icon
          >
        </mat-form-field>
        <mat-checkbox
          class="full-width"
          [checked]="resetWifis"
          (change)="setResetWifis($event)"
        >
          <p class="conditions">{{ "btConfigure.resetWifis" | translate }}</p>
        </mat-checkbox>
        <button
          mat-button
          [disabled]="!passwordFormGroup.valid"
          class="password-form--button continue-button"
        >
          {{ "common.save" | translate }}
        </button>
      </form>
    </div>
  </div>
  <div class="finish-configuration" *ngIf="state.finished">
    <div class="finish-configuration--title-container">
      <h2 class="finish-configuration--title">
        {{ "btConfigure.finishStep" | translate }}
      </h2>
    </div>
    <div class="finish-configuration--prompt">
      <p>{{ "btConfigure.finishPrompt" | translate }}</p>
    </div>
    <button class="again-button" mat-button (click)="setState('START')">
      {{ "btConfigure.configureAgain" | translate }}
    </button>
    <button class="finish-button" mat-button (click)="finishConfiguration()">
      {{ "btConfigure.finish" | translate }}
    </button>
  </div>
</div>

<sba-version-date></sba-version-date>
