import { StepConfiguration } from '@app/training/pages/electrostim/ElectrostimGuideBuilder';
import * as StimSignal from '@app/training/pages/electrostim/StimSignal';
export const STIM_TURN_ON = { sup: { stim: true } };
export const STIM_TURN_OFF = { sup: { stim: false } };
export const CLEAR_STIM_FLAG = { stimstat: { flag: 0}};
export const SET_OVERVOLTAGE_TRIGGER = (trigger: number) => ({stimstat: { trig: trigger }});
export const STIM_PROGRAM_OFF = {
  stim: {}
};

export const CHANGE_AMPLITUDE = (channel: number, amp: number) => {
  return {
    stim: {
      [channel]: {
        amp: amp / 1e3
      }
    }
  };
};

export const STIM_PAUSE = {
  stimctrl: 'pause'
};

export const STIM_RESUME = {
  stimctrl: 'resume'
};

export const createStimProgram = (program, amp = [0], clone = false) => {
  if (program.details.customProgram) {
    const { details } = program;
    return {
      stim: {
        0: {
          prg: details.customProgram,
          amp: amp[0],
          bre: Math.round(details.duration / (details.workTime || 10 * 1e6))
        }
      }
    };
  }
  if (program.phases) {
    const { details } = program;
    return {
      stim: {
        [details.channel || 0]: {
          rup: Math.round(calculateRampUp(details)),
          rdn: Math.round(calculateRampDown(details)),
          prg: StimSignal.getProgramForShape(details),
          amp: amp[0] / 1e3,
          dur: 2 * details.pulseDuration,
          per: details.period || Math.round(1e6 / details.frequency),
          cnt: StimSignal.calculateCount(details),
          bre: details.emgTriggered ? 1 : Math.round(details.duration / ((details.workTime + details.restTime))),
          bin: (details.rampUp + details.rampDown + details.workTime + details.restTime),
          off: details.offset || 0
        }
      }
    };
  }
  if (program.sequence) {
    const stim = {};
    const burstInterval = program.details.workTime + program.details.restTime;
    const burstRepetitions = program.details.duration / (program.details.workTime + program.details.restTime);
    program.sequence.forEach(seq => {
      const details = {
        ...program.details,
        ...seq
      };
      stim[seq.channel] = {
        rup: Math.round(calculateRampUp(details)),
        rdn: Math.round(calculateRampDown(details)),
        prg: StimSignal.getProgramForShape(details),
        amp: amp[seq.channel] / 1e3,
        dur: 2 * details.pulseDuration,
        per: details.period || Math.round(1e6 / details.frequency),
        bre: details.emgTriggered ? 1 : Math.round(burstRepetitions), // details.duration / ((details.workTime + details.restTime)),
        bin: burstInterval, // (details.workTime + details.restTime),
        off: details.time || 0,
        cnt: StimSignal.calculateCount(details),
      };
    });
    return {
      stim
    };
  }
  if (program) {
    const {details} = program;
    const stim0 = {
      rup: Math.round(calculateRampUp(details)),
      rdn: Math.round(calculateRampDown(details)),
      prg: StimSignal.getProgramForShape(details),
      amp: amp[0] / 1e3,
      dur: 2 * details.pulseDuration,
      per: details.period || Math.round(1e6 / details.frequency),
      cnt: StimSignal.calculateCount(details),
      bre: (details.emgTriggered ? 1 : Math.round(details.duration / ((details.workTime + details.restTime)))) || 1,
      bin: (details.rampUp + details.rampDown + (details.workTime || details.duration) + (details.restTime || 0)) ,
      off: details.offset || 0
    };
    if (clone) {
      const i = 0;
      const stim = {};
      while (i < details.channes) {
          stim[i] = {
            ...stim0,
            amp: amp[i] / 1e3
          };
      }
      return {
        stim: { ...(Array(details.channels).fill(stim0)) }
      };
    } else {
      return {
        stim: {
          0: stim0
        }
      };
    }


  }

  return {
    stim: {}
  };
};

export const STIM_CHANNEL_OFF = (channel) => {
  return {
    stim: {
      [channel]: false
    }
  };
};

export const createProgram = (change?, channel = 0) => {
  let data = {
    stim: {
      [channel]: {
        ...change
      }
    }
  };
  const setField = change => {
    data = {
      stim: {
        [channel]: {
          ...data.stim[channel],
          ...change
        }
      }
    };
    return setField;
  };

  setField.result = () => {
    for (let i = 0; i < 8; i++) {
      if (data.stim[i] && data.stim[i].amp > 0.1) {
        throw new Error('amperage is to high');
      }
    }
    return data;
  };

  return setField;
};

function calculateRampUp(conf: StepConfiguration) {
  const period = conf.period || (1e6 / conf.frequency);
  return conf.rampUp * (2 * conf.pulseDuration / period);
}

function calculateRampDown(conf: StepConfiguration) {
  const period = conf.period || (1e6 / conf.frequency);
  return conf.rampDown * (2 * conf.pulseDuration / period);
}
