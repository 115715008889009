import { EgzoGameHost, FirstLayerHeader, GameServiceLevelSettings } from '@egzotech/egzotech-game-service';

export const MOD_TIME: ((id: number) => any) = (id: number) => ({
  data: {
    game: {
      response: {
        id,
        modTime: {
          result: 'success',
          info: {}
        }
      }
    }
  }
});

export const PAUSE_COMMAND: FirstLayerHeader = {
  data: {
    game: {
      control: 'pause'
    }
  }
};

export const STOP_COMMAND: FirstLayerHeader = {
  data: {
    game: {
      control: 'stop'
    }
  }
};

export const PLAY_COMMAND: FirstLayerHeader = {
  data: {
    game: {
      control: 'play'
    }
  }
};

export function setupLevelsForStella(levels: { [key: string]: GameServiceLevelSettings }) {
  for (const level of Object.values(levels)) {
    level.delayResults = false;
    level.features = ['emg'];
    level.egzoDevice = 'stellabio';
  }
}

export function setRanges(ranges: any): FirstLayerHeader {
  return {
    data: {
      game: {
        deviceParameters: {
          type: 'LunaEMG', // 'StellaBIO',
          ranges
        }
      }
    }
  };
}

export const setLevels = levels => ({
  data: {
    game: {
      settings: {
        ...levels
      }
    }
  }
});


export function setDarkBackground(host: EgzoGameHost) {
  const style = document.createElement('style');

  style.textContent = `
    .checkboard-pattern-background
    {
        background: #000 !important;
    }

    .checkboard-pattern
    {
        background: linear-gradient(45deg, #000 25%, transparent 25%),
                    linear-gradient(-45deg, #000 25%, transparent 25%),
                    linear-gradient(45deg, transparent 75%, #000 75%),
                    linear-gradient(-45deg, transparent 75%, #000 75%)
                    !important;
    }`;




  host.gameContainer.appendChild(style);
}
