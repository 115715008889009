import { Component, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '@app/dashboard/services/dashboard.service';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { validChannels } from '@app/training/ChannelResolver';
import { CurrentExerciseService } from '@app/training/services/current-excercise.service';
import { CurrentSessionService } from '@app/training/services/current-session.service';
import { TestController } from '@app/training/TestController';
import { SelectedMuscle } from '@app/types';
import { ExercisesQuery } from '@store/exercises';
import { flatMap, tap } from 'rxjs/operators';
import { BaseDiagnosticComponent } from './../baseDiagnostic.component';
import { Biofeedback } from './Biofeedback';
import { Location } from '@angular/common';
import { AuthService } from '@app/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { BatteryService } from '@app/shared/services/low-battery.service';

@Component({
  selector: 'sba-biofeedback',
  templateUrl: './biofeedback.component.html',
  styleUrls: ['./biofeedback.component.scss']
})
export class BiofeedbackComponent extends BaseDiagnosticComponent
  implements OnDestroy {

  constructor(
    authService: AuthService,
    translation: TranslateService,
    router: Router,
    dialog: MatDialog,
    exerciseService: CurrentExerciseService,
    stellaDirect: StellaDirectService,
    sessionService: CurrentSessionService,
    exerciseQuery: ExercisesQuery,
    dashboard: DashboardService,
    location: Location,
    activatedRoute: ActivatedRoute,
    batteryService: BatteryService
    ) {
    super(stellaDirect, sessionService, exerciseService, exerciseQuery, dashboard, dialog, location, router, translation, activatedRoute, batteryService);
  }

  restart() {
    this.initialSetupDone = false;
  }

  getOnlyValid(muscles: SelectedMuscle[]): number[] {
    return validChannels(muscles).ids;
  }

  async initializeExercise() {
    await super.initializeExercise();

    await this.exerciseService.update({
      threshold: new Array(8).fill(this.steps[0].threshold),
      muscles: this.muscles
    });
  }

  protected createTestController(): TestController {
    return new Biofeedback({
      steps: this.steps,
      ports: this.vals$.value.ports
    });
  }
}
