<mat-dialog-content>
  <h2>{{ data?.title || 'Enter password' | translate }}</h2>
  <perfect-scrollbar class="form-container">
    <form class="signup--form" [formGroup]="maintenance" (ngSubmit)="onSubmit()">
      <div class="flex-row">
        <mat-form-field class="full-width">
          <input matInput [placeholder]="'common.password' | translate" type='password' formControlName="password" />
          <mat-error *ngIf="maintenance.controls.password.invalid">{{
            maintenance.get('password').errors | Error | translate
          }}</mat-error>
        </mat-form-field>
        <button mat-icon-button type="submit">
          <mat-icon>send</mat-icon>
        </button>
      </div>
    </form>
  </perfect-scrollbar>
</mat-dialog-content>