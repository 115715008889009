import { Injectable } from '@angular/core';
import { StoreConfig, EntityStore, EntityState } from '@datorama/akita';
import { StellaName } from '@app/models/StellaName.model';

export interface StellaNamesState extends EntityState<StellaName> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'stella-name'
})
export class StellaNamesStore extends EntityStore<StellaNamesState> {

  constructor() {
    super();
  }
}
