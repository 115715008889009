
import * as StimSignal from './StimSignal';
import { StepConfiguration } from './ElectrostimGuideBuilder';
import { ChannelMapper } from './ChannelMapper';

const INT32_MAX_VALUE = Math.pow(2, 31) - 1;

export const createStimCalibration = (program, amp = [0, 0, 0, 0, 0, 0, 0, 0], clone = false, cableChannels = [0, 1, 2, 3]): { time: number, prog: any }[] => {
  const mapper = ChannelMapper(program);
  const { details } = program;
  const { stimCalibration } = details;
  const bin = StimSignal.calculateBurstInterval(stimCalibration);
  let stim0: any = {
    rup: Math.round(calculateRampUp(stimCalibration)),
    rdn: Math.round(calculateRampDown(stimCalibration)),
    prg: StimSignal.getProgramForShape(stimCalibration),
    amp: amp[0] / 1e3,
    dur: 2 * stimCalibration.pulseDuration,
    per: stimCalibration.period || Math.round(1e6 / stimCalibration.frequency),
    cnt: StimSignal.calculateCount(stimCalibration),
    bre: Math.round(2e6 * 60 / bin),
    bin,
    off: 0
  };

  if (stimCalibration.slope) {
    stim0 = {
      ...stim0,
      slp: stimCalibration.slope
    };
  }

  if (stim0.bin > INT32_MAX_VALUE) {
    const reps = stim0.bin / 60e6;

    stim0.bin = 60e6;
    stim0.bre = reps;
  }

  return [
    {
      time: 0,
      prog: cloneStim({
        cableChannels,
        stim0,
        amp,
        doIt: clone,
        mapper
      })
    }
  ];
};

export const createStimProgram = (program, amp = [0, 0, 0, 0, 0, 0, 0, 0], clone = false, cableChannels = [0, 1, 2, 3]): { time: number, prog: any }[] => {
  const mapper = ChannelMapper(program);

  if (program.details.customProgram) {
    const { details } = program;

    const stim0 = {
      prg: details.customProgram,
      amp: amp[0] / 1e3,
      bre: Math.round(details.duration / (details.workTime || 10 * 1e6))
    };
    return [{
      prog: cloneStim({
        cableChannels,
        stim0,
        amp,
        doIt: clone,
        mapper
      }),
      time: 0
    }];
  }

  if (program.phases) {
    const phasses = [...program.phases];
    const { details } = program;
    const phasesArr = [];
    let time = 0;
    while (time < details.duration - 1000) {
      for (const phase of phasses) {
        if (phase.sequence) {
          const stim = {};
          const burstInterval = phase.sequenceTime;
          const burstRepetitions = phase.duration / burstInterval;
          phase.sequence.forEach(seq => {
            const dets = {
              ...program.details,
              ...seq
            };
            stim[seq.channel] = {
              rup: Math.round(calculateRampUp(dets)),
              rdn: Math.round(calculateRampDown(dets)),
              prg: StimSignal.getProgramForShape(dets),
              amp: amp[seq.channel] / 1e3,
              dur: 2 * dets.pulseDuration,
              per: dets.period || Math.round(1e6 / dets.frequency),
              bre: dets.emgTriggered ? 1 : Math.round(burstRepetitions),
              bin: burstInterval,
              off: details.time || 0,
              cnt: StimSignal.calculateCount(dets),
            };

            if (dets.slope) {
              stim[seq.channel] = {
                ...stim[seq.channel],
                slp: dets.slope
              };
            }

          });
          phasesArr.push({
            time,
            prog: stim
          });
          time += phase.duration;
        } else {
          const localDetails = { ...details, ...phase };
          const stimPhase = {};
          let stim0: any = {
            rup: Math.round(calculateRampUp(localDetails)),
            rdn: Math.round(calculateRampDown(localDetails)),
            prg: StimSignal.getProgramForShape(localDetails),
            amp: amp[0] / 1e3,
            dur: 2 * localDetails.pulseDuration,
            per: localDetails.period || Math.round(1e6 / localDetails.frequency),
            cnt: StimSignal.calculateCount(localDetails),
            bre: localDetails.emgTriggered ? 1 : Math.round(localDetails.duration / ((localDetails.workTime + localDetails.restTime))) || 1,
            bin: StimSignal.calculateBurstInterval(localDetails),
            off: localDetails.offset || 0
          };

          if (localDetails.slope) {
            stim0 = {
              ...stim0,
              slp: localDetails.slope
            };
          }

          (phase.channels || [0]).forEach(ch => {
            stimPhase[ch] = {
              ...stim0,
              amp: amp[ch] / 1e3
            };
          });
          phasesArr.push({
            time,
            prog: cloneStim({
              cableChannels,
              stim0: stimPhase[0],
              amp,
              doIt: clone,
              mapper
            })
          });
          time += localDetails.duration;
        }
      }
    }
    return phasesArr;
  }
  if (program.sequence) {
    const stim = {};
    const burstInterval = program.details.workTime + program.details.restTime;
    const burstRepetitions = program.details.duration / (program.details.workTime + program.details.restTime);
    program.sequence.forEach(seq => {
      const details = {
        ...program.details,
        ...seq
      };
      stim[seq.channel] = {
        rup: Math.round(calculateRampUp(details)),
        rdn: Math.round(calculateRampDown(details)),
        prg: StimSignal.getProgramForShape(details),
        amp: amp[seq.channel] / 1e3,
        dur: 2 * details.pulseDuration,
        per: details.period || Math.round(1e6 / details.frequency),
        bre: details.emgTriggered ? 1 : Math.round(burstRepetitions),
        bin: burstInterval,
        off: details.time || 0,
        cnt: StimSignal.calculateCount(details),
      };

      if (details.slope) {
        stim[seq.channel] = {
          ...stim[seq.channel],
          slp: details.slope
        }
      }
    });
    return [
      {
        time: 0,
        prog: stim
      }
    ];
  }
  if (program) {
    const { details } = program;
    let stim0: any = {
      rup: Math.round(calculateRampUp(details)),
      rdn: Math.round(calculateRampDown(details)),
      prg: StimSignal.getProgramForShape(details),
      amp: amp[0] / 1e3,
      dur: 2 * details.pulseDuration,
      per: details.period || Math.round(1e6 / details.frequency),
      cnt: StimSignal.calculateCount(details),
      bre: (details.emgTriggered ? 1 : Math.round(details.duration / ((details.workTime + details.restTime)))) || 1,
      bin: StimSignal.calculateBurstInterval(details),
      off: details.offset || 0
    };

    if (details.slope) {
      stim0 = {
        ...stim0,
        slp: details.slope
      };
    }

    if (stim0.bin > INT32_MAX_VALUE) {
      const reps = stim0.bin / 60e6;

      stim0.bin = 60e6;
      stim0.bre = reps;
    }

    return [
      {
        time: 0,
        prog: cloneStim({
          cableChannels,
          stim0,
          amp,
          doIt: clone,
          mapper
        })
      }
    ];
  }

  return [{ time: 0, prog: {} }];
};

function cloneStim({ cableChannels, stim0, amp, doIt, mapper }) {
  // const chanArray = Array.from(Array(channels), (_, i) => i + 1)
  if (doIt) {
    const stim = {};
    cableChannels.forEach((ch, index) => {
      const chnl = cableChannels[ch - 1];
      // const mapped = mapper.transform(chnl);
      stim[ch] = {
        ...stim0,
        amp: amp[ch] / 1e3
      };
    });
    return stim;
  } else {
    return {
      [mapper.transform(0)]: stim0
    };
  }
}

export const STIM_CHANNEL_OFF = (channel) => {
  return {
    stim: {
      [channel]: false
    }
  };
};

export const createProgram = (change?, channel = 0) => {
  let data = {
    stim: {
      [channel]: {
        ...change
      }
    }
  };
  const setField = chng => {
    data = {
      stim: {
        [channel]: {
          ...data.stim[channel],
          ...chng
        }
      }
    };
    return setField;
  };

  setField.result = () => {
    for (let i = 0; i < 8; i++) {
      if (data.stim[i] && data.stim[i].amp > 0.1) {
        throw new Error('amperage is to high');
      }
    }
    return data;
  };

  return setField;
};

export function calculateRampUp(conf: Partial<StepConfiguration>) {
  const period = conf.period || (1e6 / conf.frequency);
  return (conf.rampUp || 0) * (2 * conf.pulseDuration / period);
}

export function calculateRampDown(conf: Partial<StepConfiguration>) {
  const period = conf.period || (1e6 / conf.frequency);
  return (conf.rampDown || 0) * (2 * conf.pulseDuration / period);
}
