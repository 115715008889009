
<div class="electroslider">
  <div class="slider" #slider [style.background]="gradient"></div>
  <div class="scale">
    <div class="val">0</div>
    <div class="val last">{{ rrange?.max }} [{{ 'common.units.miliamperes' | translate }}]</div>
  </div>
  <div class="pointer" [style.transform]="getTransform()">
    <div class="clip">
      <div class="inner-clip" [style.background-color]="getBackgroundColor()">
        <div class="val">
          {{ lvl | number: "1.1" }}
        </div>
      </div>
    </div>
    <div class="indicator"></div>
  </div>
</div>
