import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { inRange } from 'lodash';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'stella-battery-icon',
  templateUrl: './battery-icon.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BatteryIconComponent {
  @Input() level: number;
  @Input() color = '#fff';

  getIcon() {
    if (inRange(this.level, 5)) {
      return 'battery_empty';
    }
    if (inRange(this.level, 5, 20)) {
      return 'battery_1';
    }
    if (inRange(this.level, 20, 40)) {
      return 'battery_2';
    }
    if (inRange(this.level, 40, 60)) {
      return 'battery_3';
    }
    if (inRange(this.level, 60, 80)) {
      return 'battery_4';
    }
    if (inRange(this.level, 80, 101)) {
      return 'battery_full';
    }
  }
}
