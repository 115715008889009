import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'Error'
})
export class ErrorPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    if (!value) {
      return '';
    }
    if (value.required) {
      return 'validation.errors.fieldRequired';
    }
    if (value.email) {
      return 'validation.errors.invalidEmail';
    }
    if (value.min) {
      const err = value.min;
      return `Min. length is ${err.min} chars`;
    }
    if (value.minlength) {
      const err = value.minlength;
      return `Min. length is ${err.requiredLength} chars`;
    }
    if (value.notSame) {
      return `validation.errors.notSame`;
    }
    if (value.wrongCredentials) {
      return 'validation.errors.wrongCredentials';
    }
    if (value.alreadyExists) {
      return 'validation.errors.alreadyExists';
    }
    if (value.alreadyExistInClinic) {
      return 'validation.errors.alreadyExistInClinic';
    }
    if (value.notExists) {
      return 'validation.errors.notExists';
    }
    if (value.failed) {
      return 'validation.errors.failed';
    }
    if (value.notCorrect) {
      return 'validation.errors.notMatch';
    }
    if (value.tokenExpired) {
      return 'validation.errors.tokenExpired';
    }
    if (value.server) {
      return 'validation.errors.notMatch';
    }
    return '';
  }

}
