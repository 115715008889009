import { Current, CurrentType } from '@app/types';
import { StepConfiguration } from './ElectrostimGuideBuilder';

export function getProgramForShape(conf: Partial<StepConfiguration>): number {
  // "current": "BIPHASIC_SYMMETRIC",
  // "currentType": "RECTANGULAR",

  switch(conf.currentType) { 
    case CurrentType.RECTANGULAR: {
      const returnValue = (conf.current == Current.BIPHASIC_SYMMETRIC) ? 100 : 101;
      return returnValue;
    } 
    case CurrentType.TRIANGULAR: { 
      const returnValue = (conf.current == Current.BIPHASIC_SYMMETRIC) ? 102 : 103;
      return returnValue;
    }
    case CurrentType.TRAPEZOIDAL: { 
      const returnValue = (conf.current == Current.BIPHASIC_SYMMETRIC) ? 104 : 105;
      return returnValue;
    }
    case CurrentType.SINUS: { 
      return 106;
    }
    default: { 
      // Unimplemented type
      return 0; 
    } 
 }
}

export function calculateBurstInterval(details): number {
  const localDetails = {
    workTime: details.duration - (details.rampUp || 0) - (details.rampDown || 0),
    rampUp: 0,
    rampDown: 0,
    restTime: 0,
    ...details
  };

  return localDetails.workTime + localDetails.rampUp + localDetails.rampDown + localDetails.restTime;
}

export function calculateCount(details): number {
  const localDetails = {
    workTime: details.workTime ? (details.workTime + (details.rampUp || 0) + (details.rampDown || 0)) : details.duration,
    rampUp: 0,
    rampDown: 0,
    frequency: details.frequency
  };
  const burstInterval = localDetails.rampUp + localDetails.rampDown + localDetails.workTime;
  return Math.round(localDetails.frequency * burstInterval / 1e6);
}
