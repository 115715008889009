import { Injectable } from '@angular/core';
import { RestEndpointsService } from '../../api/rest-endpoints.service';
import * as moment from 'moment';
import { map, tap, flatMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PatientDashboardService {

  constructor(private readonly rest: RestEndpointsService) {}

  private cachedTrainings;
  selectedDate = new Date();

  scheduledTrainingsForMonth$ = new BehaviorSubject<any>([]);
  scheduledTrainings$ = new BehaviorSubject<any>([]);

  invalidateCache() {
    this.cachedTrainings = null;
  }

  fetchScheduledTrainingForMonth(date: Date = new Date()) {
    of({})
    .pipe(
      flatMap(() => {
        if (!this.cachedTrainings) {
          return this.rest.fetchScheduledTrainings();
        } else {
          return of(this.cachedTrainings);
        }
      }),
      tap(trs => {
        this.cachedTrainings = trs;
      }),
      map(trainings => {
        return trainings.filter(tr => moment(tr.scheduledDate).isSame(moment(date), 'month'));
      }),
      tap(trainings => this.scheduledTrainingsForMonth$.next(trainings))
    ).subscribe();
  }

  fetchScheduledTrainingForDate(date: Date = new Date()) {
    of({})
    .pipe(
      flatMap(() => {
        if (!this.cachedTrainings) {
          return this.rest.fetchScheduledTrainings();
        } else {
          return of(this.cachedTrainings);
        }
      }),
      tap(trs => {
        this.cachedTrainings = trs;
      }),
      map(trainings => {
        return trainings.filter(tr => moment(tr.scheduledDate).isSame(moment(date), 'day'));
      }),
      tap(trainings => this.scheduledTrainings$.next(trainings))
    ).subscribe();
  }
}
