import { DeepReadonly } from "@egzotech/exo-electrostim";
import { ProgramConfiguration } from "./ProgramConfiguration";

function deepFreeze(obj: { [key: string]: any }) {
    for (const prop in obj) {
        const value = obj[prop];

        if (typeof value == 'object' && value !== null) {
            deepFreeze(value);
        }
    }

    return Object.freeze(obj);
}

export function toMicroseconds(value: number, unit: 'ms' | 'us' | 's' | 'min') {
    const UNITS = {
        ms: 1e3,
        us: 1,
        s: 1e6,
        min: 1e6 * 60
    };

    return value * UNITS[unit];
}

export function toVolts(value: number, unit: 'uV' | 'mV' | 'V') {
    const UNITS = {
        uV: 1e6,
        mV: 1e3,
        V: 1
    };

    return value / UNITS[unit];
}

export function toAmperes(value: number, unit: 'uA' | 'mA' | 'A') {
    const UNITS = {
        uA: 1e6,
        mA: 1e3,
        A: 1
    };

    return value / UNITS[unit];
}

export function getPredefinedElectrostimConfigurations(): DeepReadonly<{ [key: string]: ProgramConfiguration }> {
    return predefinedElectrostimConfigurations;
}

const predefinedElectrostimConfigurations: DeepReadonly<{ [key: string]: ProgramConfiguration }> = {
  "emgt_grasp_and_release": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 22000000,
        "plateauTime": 10000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 3000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 22000000,
        "plateauTime": 7000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 6000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 22000000,
        "plateauTime": 4000000,
        "pauseTime": 10000000
      }
    ],
    "maxIntensity": [
      0.1,
      0.1,
      0.1
    ],
    "phases": [
      {
        "needsTrigger": true,
        "channels": [
          {
            "channelIndex": 0
          },
          {
            "channelIndex": 1
          },
          {
            "channelIndex": 2
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 10,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 22000000,
        "plateauTime": 10000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 19000000,
        "plateauTime": 7000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 16000000,
        "plateauTime": 4000000,
        "pauseTime": 10000000
      }
    ],
    "programTime": 220000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -9,
              -8,
              -7,
              -6,
              -5,
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20
            ]
          }
        }
      ],
      "phaseRepetition": {
        "unit": "number",
        "default": 10,
        "values": [
          5,
          10,
          15,
          20,
          25,
          30
        ]
      }
    },
    "minRequiredChannels": 3,
    "maxSupportedChannels": 3,
    "tags": [
      "emg",
      "ems",
      "trigger"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "emgt_open_and_close": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 28000000,
        "plateauTime": 6000000,
        "pauseTime": 20000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 8000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 28000000,
        "plateauTime": 8000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 10000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 28000000,
        "plateauTime": 6000000,
        "pauseTime": 10000000
      }
    ],
    "maxIntensity": [
      0.1,
      0.1,
      0.1
    ],
    "phases": [
      {
        "needsTrigger": true,
        "channels": [
          {
            "channelIndex": 0
          },
          {
            "channelIndex": 1
          },
          {
            "channelIndex": 2
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 10,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 28000000,
        "plateauTime": 6000000,
        "pauseTime": 20000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 20000000,
        "plateauTime": 8000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 6000000,
        "pauseTime": 10000000
      }
    ],
    "programTime": 280000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -9,
              -8,
              -7,
              -6,
              -5,
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20
            ]
          }
        }
      ],
      "phaseRepetition": {
        "unit": "number",
        "default": 10,
        "values": [
          5,
          10,
          15,
          20,
          25,
          30
        ]
      }
    },
    "minRequiredChannels": 3,
    "maxSupportedChannels": 3,
    "tags": [
      "emg",
      "ems",
      "trigger"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "emgt_arm_extension_support": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 7000000,
        "pauseTime": 9000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 1000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 2000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 4000000,
        "pauseTime": 10000000
      }
    ],
    "maxIntensity": [
      0.1,
      0.1,
      0.1
    ],
    "phases": [
      {
        "needsTrigger": true,
        "channels": [
          {
            "channelIndex": 0
          },
          {
            "channelIndex": 1
          },
          {
            "channelIndex": 2
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 10,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 7000000,
        "pauseTime": 9000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 17000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 16000000,
        "plateauTime": 4000000,
        "pauseTime": 10000000
      }
    ],
    "programTime": 180000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -8,
              -7,
              -6,
              -5,
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21
            ]
          }
        }
      ],
      "phaseRepetition": {
        "unit": "number",
        "default": 10,
        "values": [
          5,
          10,
          15,
          20,
          25,
          30
        ]
      }
    },
    "minRequiredChannels": 3,
    "maxSupportedChannels": 3,
    "tags": [
      "emg",
      "ems",
      "trigger"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "emgt_hand_to_mouth": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 7000000,
        "pauseTime": 9000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 1000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 1000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 3,
        "fallTime": 1000000,
        "delay": 1000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      }
    ],
    "maxIntensity": [
      0.1,
      0.1,
      0.1,
      0.1
    ],
    "phases": [
      {
        "needsTrigger": true,
        "channels": [
          {
            "channelIndex": 0
          },
          {
            "channelIndex": 1
          },
          {
            "channelIndex": 2
          },
          {
            "channelIndex": 3
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 10,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 7000000,
        "pauseTime": 9000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 17000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 17000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 3,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 17000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      }
    ],
    "programTime": 180000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -8,
              -7,
              -6,
              -5,
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21
            ]
          }
        }
      ],
      "phaseRepetition": {
        "unit": "number",
        "default": 10,
        "values": [
          5,
          10,
          15,
          20,
          25,
          30
        ]
      }
    },
    "minRequiredChannels": 4,
    "maxSupportedChannels": 4,
    "tags": [
      "emg",
      "ems",
      "trigger"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "emgt_emg_triggered_1ch": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 500000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 500000,
        "runTime": null,
        "plateauTime": 3000000,
        "pauseTime": 0
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "needsTrigger": true,
        "channels": [
          {
            "channelIndex": 0,
            "runTime": null
          }
        ],
        "endRelaxTime": 1
      }
    ],
    "phasesRepetition": 10,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 500000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 500000,
        "runTime": 5000000,
        "plateauTime": 3000000,
        "pauseTime": 0
      }
    ],
    "programTime": null,
    "parameters": {
      "phases": [
        {
          "channels": {
            "fallTime": {
              "unit": "us",
              "default": 500000,
              "values": [
                0,
                500000,
                1000000,
                2000000,
                3000000,
                4000000
              ]
            },
            "pulseWidth": {
              "unit": "us",
              "default": 300,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            },
            "riseTime": {
              "unit": "us",
              "default": 500000,
              "values": [
                0,
                500000,
                1000000,
                2000000,
                3000000,
                4000000
              ]
            },
            "plateauTime": {
              "unit": "us",
              "default": 3000000,
              "values": [
                1000000,
                2000000,
                3000000,
                4000000,
                5000000,
                6000000,
                7000000,
                8000000,
                9000000,
                10000000,
                11000000,
                12000000,
                13000000,
                14000000,
                15000000,
                16000000,
                17000000,
                18000000,
                19000000,
                20000000
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 1,
            "values": [
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24,
              25,
              26,
              27,
              28,
              29,
              30
            ]
          }
        }
      ],
      "phaseRepetition": {
        "unit": "number",
        "default": 10,
        "values": [
          5,
          10,
          15,
          20,
          25,
          30
        ]
      }
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "emg",
      "ems",
      "trigger"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "emgt_emg_triggered_2ch": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 500000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 500000,
        "runTime": 13000000,
        "plateauTime": 3000000,
        "pauseTime": 6000000
      },
      {
        "channelIndex": 1,
        "fallTime": 500000,
        "delay": 3000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 500000,
        "runTime": 13000000,
        "plateauTime": 3000000,
        "pauseTime": 6000000
      }
    ],
    "maxIntensity": [
      0.1,
      0.1
    ],
    "phases": [
      {
        "needsTrigger": true,
        "channels": [
          {
            "channelIndex": 0
          },
          {
            "channelIndex": 1
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 10,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 500000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 500000,
        "runTime": 10000000,
        "plateauTime": 3000000,
        "pauseTime": 6000000
      },
      {
        "channelIndex": 1,
        "fallTime": 500000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 500000,
        "runTime": 10000000,
        "plateauTime": 3000000,
        "pauseTime": 6000000
      }
    ],
    "programTime": 130000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 300,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -5,
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24
            ]
          }
        }
      ],
      "phaseRepetition": {
        "unit": "number",
        "default": 10,
        "values": [
          5,
          10,
          15,
          20,
          25,
          30
        ]
      }
    },
    "minRequiredChannels": 2,
    "maxSupportedChannels": 2,
    "tags": [
      "emg",
      "ems",
      "trigger"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "emgt_fecal_incontinence": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 12000000,
        "plateauTime": 5000000,
        "pauseTime": 5000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "needsTrigger": true,
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 10,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 12000000,
        "plateauTime": 5000000,
        "pauseTime": 5000000
      }
    ],
    "programTime": 120000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24
            ]
          }
        }
      ],
      "phaseRepetition": {
        "unit": "number",
        "default": 10,
        "values": [
          5,
          10,
          15,
          20,
          25,
          30
        ]
      }
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 2,
    "tags": [
      "emg",
      "ems",
      "trigger",
      "pelvic"
    ],
    "allowedCableChannelFeatures": [
      "ems-pelvic"
    ]
  },
  "emgt_incontinence": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 12000000,
        "plateauTime": 4000000,
        "pauseTime": 6000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "needsTrigger": true,
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 10,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 12000000,
        "plateauTime": 4000000,
        "pauseTime": 6000000
      }
    ],
    "programTime": 120000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -5,
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
              24
            ]
          }
        }
      ],
      "phaseRepetition": {
        "unit": "number",
        "default": 10,
        "values": [
          5,
          10,
          15,
          20,
          25,
          30
        ]
      }
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 2,
    "tags": [
      "emg",
      "ems",
      "trigger",
      "pelvic"
    ],
    "allowedCableChannelFeatures": [
      "ems-pelvic"
    ]
  },
  "ems_mild_atrophy": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 20,
        "pulseShape": 102,
        "pulseWidth": 10000,
        "riseTime": 0,
        "runTime": 1200000000,
        "plateauTime": 3000000,
        "pauseTime": 6000000
      }
    ],
    "maxIntensity": [
      0.08
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 20,
        "pulseShape": 102,
        "pulseWidth": 10000,
        "riseTime": 0,
        "runTime": 9000000,
        "plateauTime": 3000000,
        "pauseTime": 6000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseFrequency": {
              "unit": "Hz",
              "default": 20,
              "values": [
                1,
                2,
                5,
                10,
                20
              ]
            },
            "pulseWidth": {
              "unit": "us",
              "default": 10000,
              "values": [
                1000,
                2000,
                5000,
                10000,
                20000
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 4,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_severe_atrophy": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 0.1,
        "pulseShape": 104,
        "pulseWidth": 200000,
        "riseTime": 0,
        "runTime": 900000000,
        "plateauTime": 10000000,
        "pauseTime": 0,
        "pulseSlopeTime": 50000
      }
    ],
    "maxIntensity": [
      0.08
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 0.1,
        "pulseShape": 104,
        "pulseWidth": 200000,
        "riseTime": 0,
        "runTime": 5000000,
        "plateauTime": 10000000,
        "pauseTime": 0,
        "pulseSlopeTime": 50000
      }
    ],
    "programTime": 900000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseFrequency": {
              "unit": "Hz",
              "default": 0.1,
              "values": [
                0.1,
                0.2,
                0.5
              ]
            },
            "pulseWidth": {
              "unit": "us",
              "default": 200000,
              "values": [
                50000,
                100000,
                150000,
                200000,
                250000,
                300000
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 900000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 4,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_user_program": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 50,
        "pulseShape": 100,
        "pulseWidth": 250,
        "riseTime": 1000000,
        "runTime": 1800000000,
        "plateauTime": 1000000,
        "pauseTime": 1000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 50,
        "pulseShape": 100,
        "pulseWidth": 250,
        "riseTime": 1000000,
        "runTime": 5000000,
        "plateauTime": 1000000,
        "pauseTime": 1000000
      }
    ],
    "programTime": 1800000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pauseTime": {
              "unit": "us",
              "default": 1000000,
              "values": [
                1000000,
                2000000,
                3000000,
                4000000,
                5000000,
                6000000,
                7000000,
                8000000,
                9000000,
                10000000,
                11000000,
                12000000,
                13000000,
                14000000,
                15000000,
                16000000,
                17000000,
                18000000,
                19000000,
                20000000
              ]
            },
            "fallTime": {
              "unit": "us",
              "default": 1000000,
              "values": [
                0,
                1000000,
                2000000,
                3000000,
                4000000
              ]
            },
            "pulseFrequency": {
              "unit": "Hz",
              "default": 50,
              "values": [
                5,
                10,
                20,
                25,
                40,
                50,
                80,
                100
              ]
            },
            "pulseWidth": {
              "unit": "us",
              "default": 250,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            },
            "riseTime": {
              "unit": "us",
              "default": 1000000,
              "values": [
                0,
                1000000,
                2000000,
                3000000,
                4000000
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 1800000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            },
            "plateauTime": {
              "unit": "us",
              "default": 1000000,
              "values": [
                1000000,
                2000000,
                3000000,
                4000000,
                5000000,
                6000000,
                7000000,
                8000000,
                9000000,
                10000000,
                11000000,
                12000000,
                13000000,
                14000000,
                15000000,
                16000000,
                17000000,
                18000000,
                19000000,
                20000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_user_program_pelvic": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 1000000,
        "pauseTime": 2000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 5000000,
        "plateauTime": 1000000,
        "pauseTime": 2000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pauseTime": {
              "unit": "us",
              "default": 2000000,
              "values": [
                1000000,
                2000000,
                3000000,
                4000000,
                5000000,
                6000000,
                7000000,
                8000000,
                9000000,
                10000000,
                11000000,
                12000000,
                13000000,
                14000000,
                15000000,
                16000000,
                17000000,
                18000000,
                19000000,
                20000000
              ]
            },
            "fallTime": {
              "unit": "us",
              "default": 1000000,
              "values": [
                0,
                1000000,
                2000000,
                3000000,
                4000000
              ]
            },
            "pulseFrequency": {
              "unit": "Hz",
              "default": 35,
              "values": [
                1,
                2,
                5,
                8,
                10,
                20,
                25,
                35,
                40,
                50
              ]
            },
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                70,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500,
                700,
                1000
              ]
            },
            "riseTime": {
              "unit": "us",
              "default": 1000000,
              "values": [
                0,
                1000000,
                2000000,
                3000000,
                4000000
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            },
            "plateauTime": {
              "unit": "us",
              "default": 1000000,
              "values": [
                1000000,
                2000000,
                3000000,
                4000000,
                5000000,
                6000000,
                7000000,
                8000000,
                9000000,
                10000000,
                11000000,
                12000000,
                13000000,
                14000000,
                15000000,
                16000000,
                17000000,
                18000000,
                19000000,
                20000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 2,
    "tags": [
      "ems",
      "pelvic"
    ],
    "allowedCableChannelFeatures": [
      "ems-pelvic"
    ]
  },
  "ems_muscle_atrophy": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 300000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "fallTime": 1000000,
            "pulseFrequency": 40,
            "riseTime": 2000000,
            "runTime": 900000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "fallTime": 1000000,
            "pulseFrequency": 60,
            "riseTime": 2000000,
            "runTime": 900000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "pulseFrequency": 3
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 5000000
      }
    ],
    "programTime": 2400000000,
    "parameters": {
      "phases": [
        {
          "channels": {}
        },
        {
          "channels": {}
        },
        {
          "channels": {}
        },
        {
          "channels": {}
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_circulation": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 8,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 600000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 8,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 5000000
      }
    ],
    "programTime": 600000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 300,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_fecal_incontinence": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 5000000,
        "pauseTime": 5000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 12000000,
        "plateauTime": 5000000,
        "pauseTime": 5000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 2,
    "tags": [
      "ems",
      "pelvic"
    ],
    "allowedCableChannelFeatures": [
      "ems-pelvic"
    ]
  },
  "ems_incontinence_mixed": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 2000000,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 2000000,
        "runTime": 600000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "pulseFrequency": 50
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 2000000,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 2000000,
        "runTime": 19000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {}
        },
        {
          "channels": {}
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 2,
    "tags": [
      "ems",
      "pelvic"
    ],
    "allowedCableChannelFeatures": [
      "ems-pelvic"
    ]
  },
  "ems_incontinence_urge": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 900000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 17000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      }
    ],
    "programTime": 900000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 900000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 2,
    "tags": [
      "ems",
      "pelvic"
    ],
    "allowedCableChannelFeatures": [
      "ems-pelvic"
    ]
  },
  "ems_incontinence_stress": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 50,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 50,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 17000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 2,
    "tags": [
      "ems",
      "pelvic"
    ],
    "allowedCableChannelFeatures": [
      "ems-pelvic"
    ]
  },
  "ems_muscle_relaxation_pelvic_continuous": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 2,
        "pulseShape": 100,
        "pulseWidth": 150,
        "riseTime": 0,
        "runTime": 1200000000
      }
    ],
    "maxIntensity": [
      0.07
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 2,
        "pulseShape": 100,
        "pulseWidth": 150,
        "riseTime": 0,
        "runTime": 5000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseFrequency": {
              "unit": "Hz",
              "default": 2,
              "values": [
                1,
                2,
                4,
                8,
                10
              ]
            },
            "pulseWidth": {
              "unit": "us",
              "default": 150,
              "values": [
                50,
                70,
                100,
                150,
                200,
                250,
                300,
                400
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 2,
    "tags": [
      "ems",
      "pelvic"
    ],
    "allowedCableChannelFeatures": [
      "ems-pelvic"
    ]
  },
  "ems_muscle_relaxation_pelvic_bursts": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 2,
        "pulseShape": 100,
        "pulseWidth": 220,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 6000000,
        "pauseTime": 10000000
      }
    ],
    "maxIntensity": [
      0.07
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 2,
        "pulseShape": 100,
        "pulseWidth": 220,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 6000000,
        "pauseTime": 10000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseFrequency": {
              "unit": "Hz",
              "default": 2,
              "values": [
                2,
                4,
                8
              ]
            },
            "pulseWidth": {
              "unit": "us",
              "default": 220,
              "values": [
                200,
                220,
                250
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 2,
    "tags": [
      "ems",
      "pelvic"
    ],
    "allowedCableChannelFeatures": [
      "ems-pelvic"
    ]
  },
  "ems_agonist_antagonist": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 300000000
      },
      {
        "channelIndex": 1,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 300000000
      },
      {
        "channelIndex": 2,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 300000000
      },
      {
        "channelIndex": 3,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 300000000
      }
    ],
    "maxIntensity": [
      0.1,
      0.1,
      0.1,
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          },
          {
            "channelIndex": 1
          },
          {
            "channelIndex": 2
          },
          {
            "channelIndex": 3
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "fallTime": 1000000,
            "pulseFrequency": 40,
            "riseTime": 2000000,
            "runTime": 900000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          },
          {
            "channelIndex": 1,
            "fallTime": 1000000,
            "delay": 13000000,
            "pulseFrequency": 40,
            "riseTime": 2000000,
            "runTime": 900000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          },
          {
            "channelIndex": 2,
            "fallTime": 1000000,
            "pulseFrequency": 40,
            "riseTime": 2000000,
            "runTime": 900000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          },
          {
            "channelIndex": 3,
            "fallTime": 1000000,
            "delay": 13000000,
            "pulseFrequency": 40,
            "riseTime": 2000000,
            "runTime": 900000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "fallTime": 1000000,
            "pulseFrequency": 60,
            "riseTime": 2000000,
            "runTime": 900000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          },
          {
            "channelIndex": 1,
            "fallTime": 1000000,
            "delay": 13000000,
            "pulseFrequency": 60,
            "riseTime": 2000000,
            "runTime": 900000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          },
          {
            "channelIndex": 2,
            "fallTime": 1000000,
            "pulseFrequency": 60,
            "riseTime": 2000000,
            "runTime": 900000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          },
          {
            "channelIndex": 3,
            "fallTime": 1000000,
            "delay": 13000000,
            "pulseFrequency": 60,
            "riseTime": 2000000,
            "runTime": 900000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "pulseFrequency": 3
          },
          {
            "channelIndex": 1,
            "pulseFrequency": 3
          },
          {
            "channelIndex": 2,
            "pulseFrequency": 3
          },
          {
            "channelIndex": 3,
            "pulseFrequency": 3
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 5000000
      },
      {
        "channelIndex": 1,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 5000000
      },
      {
        "channelIndex": 2,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 5000000
      },
      {
        "channelIndex": 3,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 5000000
      }
    ],
    "programTime": 2400000000,
    "parameters": {
      "phases": [
        {
          "channels": {}
        },
        {
          "channels": {}
        },
        {
          "channels": {}
        },
        {
          "channels": {}
        }
      ]
    },
    "minRequiredChannels": 4,
    "maxSupportedChannels": 4,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_power_training": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 300000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "fallTime": 1000000,
            "pulseFrequency": 60,
            "riseTime": 2000000,
            "runTime": 600000000,
            "plateauTime": 10000000,
            "pauseTime": 40000000
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "fallTime": 1000000,
            "pulseFrequency": 90,
            "riseTime": 2000000,
            "runTime": 600000000,
            "plateauTime": 7000000,
            "pauseTime": 28000000
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "pulseFrequency": 3
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 5000000
      }
    ],
    "programTime": 1800000000,
    "parameters": {
      "phases": [
        {
          "channels": {}
        },
        {
          "channels": {}
        },
        {
          "channels": {}
        },
        {
          "channels": {}
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_muscle_strengthening": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 3000000,
        "delay": 0,
        "pulseFrequency": 50,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 2000000,
        "runTime": 600000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 3000000,
        "delay": 0,
        "pulseFrequency": 50,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 2000000,
        "runTime": 20000000,
        "plateauTime": 5000000,
        "pauseTime": 10000000
      }
    ],
    "programTime": 600000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 600000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_exercise_prep": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 2000000,
        "runTime": 600000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 2000000,
        "runTime": 5000000
      }
    ],
    "programTime": 600000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 600000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_active_recovery": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 100,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 0,
        "runTime": 600000000,
        "plateauTime": 2000000,
        "pauseTime": 1000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 100,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 0,
        "runTime": 5000000,
        "plateauTime": 2000000,
        "pauseTime": 1000000
      }
    ],
    "programTime": 600000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 600000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_massages": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 80,
        "pulseShape": 100,
        "pulseWidth": 250,
        "riseTime": 0,
        "runTime": 20000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "pulseFrequency": 75
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "pulseFrequency": 10
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "pulseFrequency": 70
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "pulseFrequency": 65
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 18,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 80,
        "pulseShape": 100,
        "pulseWidth": 250,
        "riseTime": 0,
        "runTime": 5000000
      }
    ],
    "programTime": 1800000000,
    "parameters": {
      "phases": [
        {
          "channels": {}
        },
        {
          "channels": {}
        },
        {
          "channels": {}
        },
        {
          "channels": {}
        },
        {
          "channels": {}
        }
      ],
      "phaseRepetition": {
        "unit": "number",
        "default": 18,
        "values": [
          3,
          6,
          9,
          12,
          15,
          18,
          21,
          24,
          27,
          30,
          33,
          36
        ]
      }
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_endurance_training": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 300000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "fallTime": 1000000,
            "pulseFrequency": 40,
            "riseTime": 2000000,
            "runTime": 1200000000,
            "plateauTime": 8000000,
            "pauseTime": 16000000
          }
        ],
        "endRelaxTime": null
      },
      {
        "channels": [
          {
            "channelIndex": 0,
            "pulseFrequency": 3
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 10,
        "pulseShape": 100,
        "pulseWidth": 300,
        "riseTime": 0,
        "runTime": 5000000
      }
    ],
    "programTime": 1800000000,
    "parameters": {
      "phases": [
        {
          "channels": {}
        },
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000
              ]
            }
          }
        },
        {
          "channels": {}
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "ems_muscle_spasm_relaxation": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 2000000,
        "delay": 0,
        "pulseFrequency": 80,
        "pulseShape": 100,
        "pulseWidth": 250,
        "riseTime": 2000000,
        "runTime": 1200000000,
        "plateauTime": 8000000,
        "pauseTime": 4000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 2000000,
        "delay": 0,
        "pulseFrequency": 80,
        "pulseShape": 100,
        "pulseWidth": 250,
        "riseTime": 2000000,
        "runTime": 16000000,
        "plateauTime": 8000000,
        "pauseTime": 4000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "fes_grasp_and_release": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 10000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 3000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 7000000,
        "pauseTime": 13000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 6000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 4000000,
        "pauseTime": 16000000
      }
    ],
    "maxIntensity": [
      0.1,
      0.1,
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          },
          {
            "channelIndex": 1
          },
          {
            "channelIndex": 2
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 22000000,
        "plateauTime": 10000000,
        "pauseTime": 10000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 22000000,
        "plateauTime": 7000000,
        "pauseTime": 13000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 22000000,
        "plateauTime": 4000000,
        "pauseTime": 16000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                500
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -9,
              -8,
              -7,
              -6,
              -5,
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20
            ]
          }
        }
      ]
    },
    "minRequiredChannels": 3,
    "maxSupportedChannels": 3,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "fes_open_and_close": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 6000000,
        "pauseTime": 20000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 8000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 8000000,
        "pauseTime": 18000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 10000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 6000000,
        "pauseTime": 20000000
      }
    ],
    "maxIntensity": [
      0.1,
      0.1,
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          },
          {
            "channelIndex": 1
          },
          {
            "channelIndex": 2
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 28000000,
        "plateauTime": 6000000,
        "pauseTime": 20000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 28000000,
        "plateauTime": 8000000,
        "pauseTime": 18000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 28000000,
        "plateauTime": 6000000,
        "pauseTime": 20000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                500
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -9,
              -8,
              -7,
              -6,
              -5,
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20
            ]
          }
        }
      ]
    },
    "minRequiredChannels": 3,
    "maxSupportedChannels": 3,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "fes_arm_extension_support": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 7000000,
        "pauseTime": 9000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 1000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 5000000,
        "pauseTime": 11000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 2000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 4000000,
        "pauseTime": 12000000
      }
    ],
    "maxIntensity": [
      0.1,
      0.1,
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          },
          {
            "channelIndex": 1
          },
          {
            "channelIndex": 2
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 7000000,
        "pauseTime": 9000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 5000000,
        "pauseTime": 11000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 4000000,
        "pauseTime": 12000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                500
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -8,
              -7,
              -6,
              -5,
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21
            ]
          }
        }
      ]
    },
    "minRequiredChannels": 3,
    "maxSupportedChannels": 3,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "fes_hand_to_mouth": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 7000000,
        "pauseTime": 9000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 1000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 5000000,
        "pauseTime": 11000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 1000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 5000000,
        "pauseTime": 11000000
      },
      {
        "channelIndex": 3,
        "fallTime": 1000000,
        "delay": 1000000,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 1200000000,
        "plateauTime": 5000000,
        "pauseTime": 11000000
      }
    ],
    "maxIntensity": [
      0.1,
      0.1,
      0.1,
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          },
          {
            "channelIndex": 1
          },
          {
            "channelIndex": 2
          },
          {
            "channelIndex": 3
          }
        ],
        "endRelaxTime": 0
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 7000000,
        "pauseTime": 9000000
      },
      {
        "channelIndex": 1,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 5000000,
        "pauseTime": 11000000
      },
      {
        "channelIndex": 2,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 5000000,
        "pauseTime": 11000000
      },
      {
        "channelIndex": 3,
        "fallTime": 1000000,
        "delay": 0,
        "pulseFrequency": 35,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 1000000,
        "runTime": 18000000,
        "plateauTime": 5000000,
        "pauseTime": 11000000
      }
    ],
    "programTime": 1200000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                500
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 1200000000,
              "values": [
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          },
          "endRelaxTime": {
            "unit": "s",
            "default": 0,
            "values": [
              -8,
              -7,
              -6,
              -5,
              -4,
              -3,
              -2,
              -1,
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21
            ]
          }
        }
      ]
    },
    "minRequiredChannels": 4,
    "maxSupportedChannels": 4,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems"
    ]
  },
  "tens_conventional": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 80,
        "pulseShape": 100,
        "pulseWidth": 150,
        "riseTime": 0,
        "runTime": 900000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 80,
        "pulseShape": 100,
        "pulseWidth": 150,
        "riseTime": 0,
        "runTime": 5000000
      }
    ],
    "programTime": 900000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 900000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems",
      "ems-pelvic"
    ]
  },
  "tens_frequency_modulated": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 2,
        "pulseShape": 200,
        "pulseWidth": 200,
        "riseTime": 0,
        "runTime": 900000000,
        "plateauTime": 10000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 2,
        "pulseShape": 200,
        "pulseWidth": 200,
        "riseTime": 0,
        "runTime": 5000000,
        "plateauTime": 10000000
      }
    ],
    "programTime": 900000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 900000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 2,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems",
      "ems-pelvic"
    ]
  },
  "tens_acupuncture": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 4,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 0,
        "runTime": 900000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 4,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 0,
        "runTime": 5000000
      }
    ],
    "programTime": 900000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 900000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems",
      "ems-pelvic"
    ]
  },
  "tens_burst": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 80,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 0,
        "runTime": 900000000,
        "plateauTime": 400000,
        "pauseTime": 100000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 80,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 0,
        "runTime": 5000000,
        "plateauTime": 400000,
        "pauseTime": 100000
      }
    ],
    "programTime": 900000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "runTime": {
              "unit": "us",
              "default": 900000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems",
      "ems-pelvic"
    ]
  },
  "tens_user_program": {
    "defaultChannelValues": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 80,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 0,
        "runTime": 900000000
      }
    ],
    "maxIntensity": [
      0.1
    ],
    "phases": [
      {
        "channels": [
          {
            "channelIndex": 0
          }
        ],
        "endRelaxTime": null
      }
    ],
    "phasesRepetition": 1,
    "stimCalibration": [
      {
        "channelIndex": 0,
        "fallTime": 0,
        "delay": 0,
        "pulseFrequency": 80,
        "pulseShape": 100,
        "pulseWidth": 200,
        "riseTime": 0,
        "runTime": 5000000
      }
    ],
    "programTime": 900000000,
    "parameters": {
      "phases": [
        {
          "channels": {
            "pulseFrequency": {
              "unit": "Hz",
              "default": 80,
              "values": [
                5,
                10,
                20,
                25,
                40,
                50,
                80,
                100
              ]
            },
            "pulseWidth": {
              "unit": "us",
              "default": 200,
              "values": [
                50,
                100,
                150,
                200,
                250,
                300,
                350,
                400,
                450,
                500
              ]
            },
            "runTime": {
              "unit": "us",
              "default": 900000000,
              "values": [
                300000000,
                600000000,
                900000000,
                1200000000,
                1500000000,
                1800000000,
                2100000000,
                2400000000,
                2700000000,
                3000000000,
                3300000000,
                3600000000
              ]
            }
          }
        }
      ]
    },
    "minRequiredChannels": 1,
    "maxSupportedChannels": 8,
    "tags": [
      "ems"
    ],
    "allowedCableChannelFeatures": [
      "ems",
      "ems-pelvic"
    ]
  }
}
deepFreeze(predefinedElectrostimConfigurations);
