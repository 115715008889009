import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map, share, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Role } from '../models/Role.enum';
import { ACCESS_TOKEN_KEY } from '@app/enums';
import { Clinic } from './../models/Clinic.model';
import { ICD } from './../models/ICD.model';
import { ICF } from './../models/ICF.model';
import { Me } from './../models/Me.model';
import { MedicalCard } from './../models/MedicalCard.model';
import { Patient } from './../models/Patient.model';
import { EditableTemplate, SessionTemplate, Identifier } from '@app/types';
import { NewPatientRequest } from './dtos/NewPatientRequest';
import { IdResponse } from './dtos/responses/IdResponse';
import { SessionResponse } from './dtos/responses/SessionResponse';
import { StatusResponse, TokenStatusResponse, SuccessResponse } from './dtos/responses/StatusResponse';
import { TrainingResponse } from './dtos/responses/TrainingResponse';
import { UrlResponse } from './dtos/responses/UrlResponse';
import { TrainingRequest } from './dtos/TrainingRequest';
import { UpdateProfileRequest } from '@app/auth/types';

interface ClinicObserved {
  clinic: Clinic;
  roles: Role[];
}

@Injectable({
  providedIn: 'root'
})
export class RestEndpointsService {
  constructor(private http: HttpClient) { }

  restUrl = environment.restServerUrl;

  fetchMe(): Observable<Me> {
    return this.http.get<Me>(this.restUrl + '/users/me', this.createHeaders());
  }

  updateMe(data: UpdateProfileRequest): Observable<SuccessResponse> {
    return this.http.put<SuccessResponse>(this.restUrl + '/users/me', data, this.createHeaders());
  }

  fetchDiseases(): Observable<string[]> {
    return this.http.get<string[]>(this.restUrl + '/medical/diseases', this.createHeaders());
  }

  fetchClinics(): Observable<ClinicObserved[]> {
    return this.http.get<ClinicObserved[]>(
      this.restUrl + '/clinics?all=true',
      this.createHeaders()
    );
  }

  fetchClinicRequests(clinic: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.restUrl}/clinics/${clinic}/requests`,
      this.createHeaders()
    );
  }

  fetchUserInvitations(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.restUrl}/users/invitations`,
      this.createHeaders()
    );
  }

  fetchPendingInvitations(clinic: string): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.restUrl}/clinics/${clinic}/invitations`,
      this.createHeaders()
    );
  }

  fetchClinicDetails(clinic: string): Observable<Clinic> {
    return this.http.get<Clinic>(
      `${this.restUrl}/clinics/${clinic}`,
      this.createHeaders()
    );
  }

  fetchPatients(clinic: string): Observable<Patient[]> {
    return this.http.get<Patient[]>(
      `${this.restUrl}/clinics/${clinic}/patients`,
      this.createHeaders()
    );
  }

  fetchPatient(clinic: string, patientId: string): Observable<Patient> {
    return this.http.get<Patient>(
      `${this.restUrl}/clinics/${clinic}/patients/${patientId}`,
      this.createHeaders()
    );
  }

  fetchMedicalCard(clinic: string, patientId: Identifier): Observable<MedicalCard> {
    return this.http.get<MedicalCard>(
      `${this.restUrl}/clinics/${clinic}/patients/${patientId}/card`,
      this.createHeaders()
    );
  }

  fetchExerciseTemplates(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.restUrl}/templates/exercises`,
      this.createHeaders()
    ).pipe(
    );
  }

  fetchUsageReport(clinic: string, dateRange: { from: Date, to: Date }): Observable<any> {
    return this.http.get<any>(
      `${this.restUrl}/clinics/${clinic}/billings/usage`,
      {
        ...this.createHeaders(),
        params: new HttpParams()
          .set('from', dateRange.from.toISOString())
          .set('to', dateRange.to.toISOString())
      }
    );
  }

  updateMedicalCard(
    clinic: string,
    patientId: Identifier,
    changes: object
  ): Observable<MedicalCard> {
    return this.http.put<MedicalCard>(
      `${this.restUrl}/clinics/${clinic}/patients/${patientId}/card`,
      changes,
      this.createHeaders()
    );
  }

  getICDs(): Observable<ICD[]> {
    return this.http.get<ICD[]>(`${this.restUrl}/medical/icd`, {
      ...this.createHeaders(),
      params: new HttpParams().set('q', '')
    });
  }

  searchICF(query: string, code: string): Observable<ICF[]> {
    return this.http.get<ICF[]>(`${this.restUrl}/medical/icf`, {
      ...this.createHeaders(),
      params: new HttpParams().set('q', query).set('c', code)
    });
  }

  selectPatient(clinic: Identifier, patient: Identifier): Observable<SuccessResponse> {
    return this.http.put<SuccessResponse>(
      `${this.restUrl}/clinics/${clinic}/patients/${patient}`,
      {},
      this.createHeaders()
    );
  }

  selectClinic(clinic: string, role): Observable<TokenStatusResponse> {
    return this.http.patch<TokenStatusResponse>(
      `${this.restUrl}/clinics/${clinic}`,
      { role },
      this.createHeaders()
    );
  }

  saveTemplate(template: Partial<SessionTemplate>): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${this.restUrl}/templates/sessions`, template, this.createHeaders()
    );
  }

  editTemplate(template: EditableTemplate): Observable<StatusResponse> {
    const { id, ...rest } = template;
    return this.http.post<StatusResponse>(
      `${this.restUrl}/templates/sessions/${id}`, rest, this.createHeaders()
    );
  }

  createPatient(
    clinic: string,
    data: NewPatientRequest
  ): Observable<IdResponse> {
    return this.http.post<IdResponse>(
      `${this.restUrl}/clinics/${clinic}/patients`,
      data,
      this.createHeaders()
    );
  }

  createSession(
    clinic: string,
    patient: Identifier,
    session: any
  ): Observable<SessionResponse> {
    return this.http.post<SessionResponse>(
      `${this.restUrl}/clinics/${clinic}/patients/${patient}/sessions`,
      session,
      this.createHeaders()
    );
  }

  updateSession(clinic: Identifier, patient: Identifier, session: string, changes: any): Observable<any> {
    return this.http.put<any>(
      `${this.restUrl}/clinics/${clinic}/patients/${patient}/sessions/${session}`,
      changes,
      this.createHeaders()
    );
  }

  editClinic(clinic: Partial<Clinic>): Observable<any> {
    const { logo, id, ...all } = clinic;
    const formData = new FormData();
    formData.append('file', logo);
    return this.http
      .put<Clinic>(`${this.restUrl}/clinics/${id}`, all, this.createHeaders())
      .pipe(
        flatMap(() => this.updateClinicAvatar(clinic, logo, formData))
      );
  }

  createClinic(clinic: Partial<Clinic>): Observable<any> {
    const { logo, ...all } = clinic;
    const formData = new FormData();
    formData.append('file', logo);
    return this.http
      .post<Clinic>(`${this.restUrl}/clinics`, all, this.createHeaders())
      .pipe(
        flatMap(cl => {
          return this.updateClinicAvatar({...clinic, ...cl}, logo, formData);
        })
      );
  }

  updateClinicAvatar(cl, logo: string, formData: FormData): Observable<any> {
    if (logo) {
      return this.http
        .patch<any>(
          `${this.restUrl}/clinics/${cl.id}/avatar`,
          formData,
          this.createHeaders()
        ).pipe(
          map(rs => ({ ...cl, logo: rs.logo }))
        );
    }
    return of(cl);
  }

  updateUserAvatar(
    clinic: string,
    patient: Identifier,
    file: any
  ): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.put(
      `${this.restUrl}/clinics/${clinic}/patients/${patient}/avatar`,
      formData,
      this.createHeaders()
    );
  }

  createTraining(
    clinic: string,
    patient: Identifier,
    session: string,
    training: TrainingRequest
  ): Observable<TrainingResponse> {
    return this.http.post<TrainingResponse>(
      `${
      this.restUrl
      }/clinics/${clinic}/patients/${patient}/sessions/${session}/trainings`,
      training,
      this.createHeaders()
    );
  }

  updateTraining(
    clinic: string,
    patient: Identifier,
    session: string,
    training: string,
    change
  ): Observable<any> {
    return this.http.put<any>(
      `${
      this.restUrl
      }/clinics/${clinic}/patients/${patient}/sessions/${session}/trainings/${training}`,
      change,
      this.createHeaders()
    );
  }

  deleteTraining(
    clinic: string,
    patient: Identifier,
    session: string,
    training: string
  ): Observable<any> {
    return this.http.delete<any>(
      `${
      this.restUrl
      }/clinics/${clinic}/patients/${patient}/sessions/${session}/trainings/${training}`,
      this.createHeaders()
    );
  }

  callForUploadUrl(
    clinic: string,
    patient: Identifier,
    session: string,
    fileName: string
  ): Observable<UrlResponse> {
    return this.http.post<UrlResponse>(
      `${
      this.restUrl
      }/clinics/${clinic}/patients/${patient}/sessions/${session}/trainings/${fileName}`,
      {},
      this.createHeaders()
    );
  }

  uploadFile(url: string, file): Observable<void> {
    return this.http.put<void>(url, file);
  }

  updatePatient(
    clinic: string,
    patient: Identifier,
    changes: { firstName?: string; lastName?: string, birthday?: string }
  ) {
    return this.http.patch<any>(
      `${this.restUrl}/clinics/${clinic}/patients/${patient}`,
      changes,
      this.createHeaders()
    );
  }

  changePatientVisibility(
    clinic: string,
    patient: Identifier,
    changes: { visibility: boolean }
  ) {
    return this.http.patch<any>(
      `${this.restUrl}/clinics/${clinic}/patients/${patient}/uc`,
      {extras: changes },
      this.createHeaders()
    );
  }

  askForInvitation(clinic: string, role: Role): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${this.restUrl}/clinics/${clinic}/ask`,
      { role },
      this.createHeaders()
    );
  }

  processUserInvitation(data): Observable<StatusResponse> {
    return this.http.put<StatusResponse>(
      `${this.restUrl}/users/invitations/${data.id}`,
      { result: data.result },
      this.createHeaders()
    );
  }

  processInvitation(clinic: string, data): Observable<StatusResponse> {
    return this.http.put<StatusResponse>(
      `${this.restUrl}/clinics/${clinic}/invitations/${data.id}`,
      { result: data.result },
      this.createHeaders()
    );
  }

  acceptUserInvitation(token): Observable<StatusResponse> {
    return this.http.put<StatusResponse>(
      `${this.restUrl}/deep/invitations`,
      { token }
    );
  }

  createInvitation(clinic: string, data): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${this.restUrl}/clinics/${clinic}/invitations`,
      {...data, lang: localStorage.getItem('LANGUAGE')},
      this.createHeaders()
    );
  }

  revokeInvitation(clinic: string, userId: number): Observable<StatusResponse> {
    return this.http.delete<StatusResponse>(
      `${this.restUrl}/clinics/${clinic}/invitations/${userId}`,
      this.createHeaders()
    );
    return of({status: ''});
  }

  getInvitations(clinic: string): Observable<any> {
    return this.http.get<any>(
      `${this.restUrl}/clinics/${clinic}/invitations`,
      this.createHeaders()
    );
  }

  removeFromClinic(
    clinic: string,
    data: { id: string; role: Role }
  ): Observable<StatusResponse> {
    return this.http.post<StatusResponse>(
      `${this.restUrl}/clinics/${clinic}/team`,
      data,
      this.createHeaders()
    );
  }

  fetchScheduledTrainings(): Observable<any[]> {
    return this.http.get<any[]>(
      `${this.restUrl}/patient-dashboard/visits`,
      this.createHeaders()
    ).pipe(share());
  }

  fetchSessionsForPatient(clinic: Identifier, patient: Identifier): Observable<any[]> {
    return this.http.get<any[]>(
      `${ this.restUrl }/clinics/${clinic}/patients/${patient}/sessions`,
      this.createHeaders()
    );
  }

  fetchSessionForPatient(session: Identifier, clinic: Identifier, patient: Identifier): Observable<any> {
    return this.http.get<any>(
      `${this.restUrl}/clinics/${clinic}/patients/${patient}/sessions/${session}`,
      this.createHeaders()
    );
  }

  fetchWifis(): Observable<any> {
    return this.http.get<StatusResponse>(
      `${this.restUrl}/users/wifis`,
      this.createHeaders()
    );
  }

  addWifi(payload: {bssid: string, ssid: string, password: string}): Observable<any> {
    return this.http.post<StatusResponse>(
      `${this.restUrl}/users/wifis`,
      payload,
      this.createHeaders()
    );
  }

  fetchAvatars(payload: { ids: Identifier[]}): Observable<any[]> {
    return this.http.post<any[]>(
      `${this.restUrl}/deep/avatars`,
      payload
    );
  }

  setStellaName(payload: {mac: string, name: string}): Observable<any> {
    return this.http.post<StatusResponse>(
      'https://functions.egzotech.com/instert_update_stella_bio_name',
      payload,
      this.createHeaders()
    );
  }

  removeMeeting(meetingId, clinic: Identifier, patient: Identifier, session: Identifier): Observable<any> {
    return this.http.delete<any>(
      `${this.restUrl}/clinics/${clinic}/patients/${patient}/sessions/${session}/trainings/${meetingId}`,
      this.createHeaders()
    );
  }

  emailReport(data: FormData, clinic: Identifier): Observable<any> {
    return this.http.post<any>(
      `${this.restUrl}/clinics/${clinic}/report`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
            ContentType: 'multipart/form-data'
          }
        }
    );
  }

  private createHeaders() {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`
      })
    };
  }
}
