import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

type BatteryStatus = "LOW" | "OK";

@Injectable()
export class BatteryService {
  batteryStatus$ = new BehaviorSubject<BatteryStatus>("OK");

  constructor() {}

  updateStatus(status: BatteryStatus) {
    this.batteryStatus$.next(status);
  }
}
