import { Injectable } from '@angular/core';
import { EntityState } from '@datorama/akita';
import { Observable, of, zip } from 'rxjs';
import { catchError, filter, finalize, flatMap, tap } from 'rxjs/operators';
import { STELLA_CURRENT_PATIENT } from '@app/enums';
import { MedicalCard } from '../../models/MedicalCard.model';
import { Patient } from '../../models/Patient.model';
import { NotificationsService } from '@app/shared/services/notifications.service';
import { ClinicsQuery } from '@app/store/clinics/clinics.query';
import { MedicalCardsQuery, MedicalCardsStore } from '@app/store/medicalCard';
import { PatientsStore } from '@app/store/patients';
import { PatientsQuery } from '@app/store/patients/patients.query';
import { RestEndpointsService } from '../../api/rest-endpoints.service';
import { Identifier } from '@app/types';
import { CreatePatient } from '../types';
import { environment } from '../../../environments/environment';

export interface PatientsState extends EntityState<Patient> { }

@Injectable({
  providedIn: 'root'
})
export class PatientsService {
  constructor(
    private readonly rest: RestEndpointsService,
    private readonly clinicsQuery: ClinicsQuery,
    private readonly patientStore: PatientsStore,
    private readonly patientsQuery: PatientsQuery,
    private readonly medicalCardStore: MedicalCardsStore,
    private readonly medicalCardQuery: MedicalCardsQuery,
    private readonly notification: NotificationsService
  ) {
  }

  currentPatient$: Observable<Patient> = this.patientsQuery.selectActive() as Observable<Patient>;

  currentPatient() {
    return this.patientsQuery.getActive() as Patient;
  }

  setCurrentPatient(value: number, guest = false): void {
    if (!guest) {
      this.patientStore.setActive(null);
      const pat: Patient = this.patientsQuery.getPatientById(value);
      if (pat) {
        localStorage.setItem(STELLA_CURRENT_PATIENT, JSON.stringify(pat));
        this.rest
          .selectPatient(this.clinicsQuery.getActive().id, pat.patientId)
          .pipe(
            filter(r => r.success),
            tap(_ => this.patientStore.incrementActivePatient(pat.patientId)),
            tap(_ => this.patientStore.setActive(pat.patientId))
          )
          .subscribe();
      }
    } else {
      this.patientStore.setActive(null);
    }
  }

  getPatientsList(): Observable<Patient[]> {
    if (!this.clinicsQuery.getActive()) {
      return of([]);
    }

    const activePatientId = this.patientsQuery.getActiveId();

    return this.rest
      .fetchPatients(this.clinicsQuery.getActive().id)
      .pipe(
        catchError(() => {
          this.notification.showError('http.patients.cannot_fetch');
          return of([] as Patient[]);
        }),
        tap(pats => {
          this.patientStore.remove();
          this.patientStore.add(pats);
          this.patientStore.setActive(activePatientId);
        }),
        flatMap(_ => {
          return this.patientsQuery.selectAllSorted();
        })
      );
  }

  getPatient(id?: string): Observable<Patient> {
    const patient: any = this.patientsQuery.getActive();
    return this.rest.fetchPatient(
      this.clinicsQuery.getActive().id,
      id ? id : patient.patientId
    );
  }

  changePatientVisibility(id: string, visibility: boolean) {
    const clinicId = this.clinicsQuery.getActive().id;
    const patient: any = this.patientsQuery.getActive();
    this.rest.changePatientVisibility(
      clinicId,
      id ? id : patient.patientId,
      { visibility }
      )
      .pipe(tap(() => {
        this.patientStore.update(id ? id : patient.patientId, (entity) => ({
          ...entity,
          extras: {
            ...entity.extras,
            visibility
          }
        }));
      }))
      .subscribe();
  }

  getMedicalCard(): Observable<MedicalCard> {
    const patientId = this.patientsQuery.getActivePatientId();
    if (this.medicalCardQuery.getEntity(patientId)) {
      return of(this.medicalCardQuery.getEntity(patientId));
    } else {
      this.medicalCardStore.setLoading(true);
      return this.rest
        .fetchMedicalCard(
          this.clinicsQuery.getActive().id,
          this.patientsQuery.getActivePatientId()
        )
        .pipe(
          tap(medicalCard => {
            this.medicalCardStore.upsert(patientId, medicalCard);
            this.medicalCardStore.setActive(patientId);
          }),
          finalize(() => this.medicalCardStore.setLoading(false))
        );
    }
  }

  createPatient(patient: Partial<CreatePatient>, inviteToTelemedicine: boolean = false): Observable<Patient | [MedicalCard, Patient]> {
    return this.rest
      .createPatient(this.clinicsQuery.getActive().id, {
        firstName: patient.firstName,
        lastName: patient.lastName,
        email: patient.email,
        gender: patient.gender,
        birthday: patient.birthday,
        lang: localStorage.getItem('LANGUAGE'),
        telemedic: inviteToTelemedicine
      })
      .pipe(
        flatMap(res => {
          return this.rest.fetchPatient(
            this.clinicsQuery.getActive().id,
            res.id as string
          );
        }),
        tap(pat => {
          this.patientStore.add(pat);
          this.setCurrentPatient(pat.patientId);
          this.patientStore.incrementActivePatient(pat.patientId);
        }),
        flatMap(pat => {
          if (!patient.disease) {
            return of(pat);
          }
          return zip(this.updateMedicalCard({ disease: patient.disease }), of(pat));
        })
      );
  }

  updateUser(patientId: Identifier, changes: { firstName?: string; lastName?: string, birthday?: string }): Observable<any> {
    return this.rest
      .updatePatient(this.clinicsQuery.getActive().id, patientId, changes)
      .pipe(
        tap(_ => {
          const active = this.patientsQuery.getActive() as Patient;
          const updatedPatient = {
            ...active,
            birthday: changes.birthday || active.birthday,
            firstName: changes.firstName || active.firstName,
            lastName: changes.lastName || active.lastName ,
            displayName: `${changes.firstName || active.firstName} ${changes.lastName || active.lastName}`
          };
          this.patientStore.updateActive(updatedPatient);
          this.setCurrentPatient(updatedPatient.patientId, false);
        })
      );
  }

  updateMedicalCard(changes): Observable<MedicalCard> {
    const patientId = this.patientsQuery.getActivePatientId();
    return this.rest.updateMedicalCard(
      this.clinicsQuery.getActive().id,
      patientId,
      changes
    ).pipe(
      tap(() => {
        this.medicalCardStore.update(patientId, entity => ({ ...entity, ...changes }));
      })
    );
  }

  updateUserAvatar(file) {
    return this.rest
      .updateUserAvatar(
        this.clinicsQuery.getActive().id,
        this.patientsQuery.getActivePatientId(),
        file
      )
      .pipe(
        tap(res => {
          const active = this.patientsQuery.getActive() as Patient;
          const editedPatient = {
            ...active,
            avatar: res.avatar

          };
          this.patientStore.updateActive(editedPatient);
          this.setCurrentPatient(active.patientId, false);
        })
      );
  }
}
