import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BarChart } from '@app/charts/BarChart';
import { SCALES_UV, STELLA_LAST_CHART_SCALE } from '@app/enums';
import { StellaDirectService } from '@app/stella/services/stella-direct.service';
import { COLOR_ARRAY } from '@app/utils/ColorDictionary';
import { EgzoRX } from '@app/utils/EgzoRX';
import { Subject, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ChannelSignal, CableDetails } from '@app/types';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sba-stella-connected-emg-bar-chart',
  templateUrl: './stella-connected-emg-bar-chart.component.html',
  styleUrls: ['./stella-connected-emg-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StellaConnectedEmgBarChartComponent implements OnInit, OnDestroy {

  max = +localStorage.getItem(STELLA_LAST_CHART_SCALE);
  chart: BarChart;
  threshold: number;
  sub: Subscription = new Subscription();
  values = [0, 0, 0, 0, 0, 0, 0, 0];
  cable: any;
  @Input() source$: Subject<ChannelSignal>;
  @Input() cable$: Subject<CableDetails>;
  @Input() showOnlyChannels: number[];
  @Input() autoscale = false;
  @Input() showSlider = true;
  @Input()
  set initialialThreshold(val: number) {
    this.threshold = val;
  }
  @Output() emgValue = new EventEmitter();
  @Output() thresholdChange = new EventEmitter();

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private translateService: TranslateService
  ) { }

  async ngOnInit() {
    await this.initChart();
    await this.initStella();
  }

  ngOnDestroy() {
    this.chart.destroy();
    this.sub.unsubscribe();
  }

  changeScale(event) {
    localStorage.setItem(STELLA_LAST_CHART_SCALE, event);
    this.max = event;
    this.chart.setScale({ min: 0, max: this.max });
  }

  handleThresholdChange(event) {
    this.threshold = event.value;
    this.chart.thresholdLine.setValue(event.value);
    this.thresholdChange.emit(this.threshold);
    this.cdr.detectChanges();
  }

  async initStella(): Promise<boolean> {
    this.cable$.pipe(
      tap(cable => this.cable = cable),
      tap(cable => {
        if (this.showOnlyChannels) {
          this.chart.setColorArray(COLOR_ARRAY.filter((_, i) => this.showOnlyChannels.includes(i)));
        } else {
          this.chart.setColorArray(COLOR_ARRAY.filter((_, i) => cable.channels.includes(i)));
        }
      }),
    ).subscribe();
    try {
      this.sub.add(
        this.source$
          .pipe(
            filter(vals => true),
            EgzoRX.signalAmplifierZip(1e6),
            tap((channels) => {
              for (const ch of channels) {
                this.values[ch.channel] = ch.average;
              }
            }),
            tap(() => this.values = this.values.map(v => v > 1e4 ? 0 : v)),
            map(_ =>
              this.values
                .map(v => v || 0)
                .filter((val, index) => {
                  if (!this.showOnlyChannels || !this.showOnlyChannels.length) {
                    return true;
                  }
                  return this.showOnlyChannels.includes(index);
                })
            ),
            tap(dataWithCable => {
              if (dataWithCable.some(d => d > this.max) && this.autoscale) {
                const localMax = Math.max(...dataWithCable);

                const foundScale = SCALES_UV.find(sc => sc.value > localMax);
                this.changeScale((foundScale || { value: undefined }).value || this.max);
              }
            }),
            tap(dataWithCable => {
              this.chart.updateValues(dataWithCable.map(v => ({ category: '', value: v })));
              this.emitValues(dataWithCable);
            }))
          .subscribe()
      );
      return true;
    } catch (err) {
      return false;
    }
  }

  emitValues(values: number[]) {
    this.emgValue.emit(values);
  }

  async initChart() {
    return new Promise((resolve) => {
      setTimeout(() => {
        try {
          this.chart = new BarChart({ container: 'bar-chart-ems' }, this.translateService.instant('common.units.yAxisMicrovolts'));
          this.chart.thresholdLine.setValue(this.threshold);
          this.chart.setScale({ min: 0, max: this.max });
          resolve();
        } catch (err) {
          console.log(err);
        }
      }, 1);
    });
  }
}
