import { Howl } from 'howler';
import { ContractionType, SoundPlayerData, SoundPlayerType } from '@app/types';
import { SoundCommandLanguage, SoundCommandType } from '@app/enums';

export class Player {
    sounds: Record<SoundCommandType, any>;

    constructor(lang: SoundCommandLanguage = 'en') {
        this.sounds = {
            "FLICK_CONTRACTION": new Howl({
                src: [`/assets/sounds/${lang}/${lang === 'en' ? 'QuickFlick' : 'Contract' }.mp3`],
                html5: true
            }),
            "HOLD_CONTRACTION": new Howl({
                src: [`/assets/sounds/${lang}/ContractAndHold.mp3`],
                html5: true
            }),
            "NORMAL_CONTRACTION": new Howl({
                src: [`/assets/sounds/${lang}/Contract.mp3`],
                html5: true
            }),
            "RELAX": new Howl({
                src: [`/assets/sounds/${lang}/Relax.mp3`],
                html5: true
            })
        }
    }

    play(data: SoundPlayerData): void {
        this.sounds[this.getSoundForEvent(data)].play();
    }

    private getSoundForEvent(data: SoundPlayerData): SoundCommandType {
        if (data.type === SoundPlayerType.RELAX_SOUND) {
            return 'RELAX';
        } else {
            switch (data.details) {
                case ContractionType.FLICK:
                    return 'FLICK_CONTRACTION';
                case ContractionType.NORMAL:
                    return 'NORMAL_CONTRACTION';
                case ContractionType.HOLD:
                    return 'HOLD_CONTRACTION';
            }
        }
    }
}
