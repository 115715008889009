<mat-dialog-content>
  <div class="title-row">
    <h2 class="setting-header">
      {{ "low_battery.warning" | translate }}
    </h2>
  </div>

  <div class="row">
    <img class="warning" src="/assets/png/warning_iso.png" />
    <div class="advices">
      <p>{{ "low_battery.advice_1" | translate }}</p>
      <p>{{ "low_battery.advice_2" | translate }}</p>
    </div>
  </div>

  <div class="dialog-actions">
    <div class="outline-button" (click)="exit()">
      {{ "common.exit" | translate }}
    </div>
  </div>
</mat-dialog-content>
