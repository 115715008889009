import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'sba-resend-email-dialog',
  templateUrl: './resend-email-dialog.component.html',
  styleUrls: ['./resend-email-dialog.component.scss']
})
export class ResendEmailDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ResendEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {success: boolean}
  ) { }

  ok() {
    this.dialogRef.close();
  }

}
