import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Invitation } from '@app/models/Invitation.model';

export interface InvitationsState extends EntityState<Invitation> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'invitations',
  cache: {
    ttl: 3600000
  },
  resettable: true
})
export class InvitationsStore extends EntityStore<InvitationsState> {
  constructor() {
    super();
  }
}
