<mat-dialog-content class="unknown-error-dialog">
  <h2>{{ "unknownErrorDialog.title" | translate }}</h2>
  <p class="error-info">
    {{ "unknownErrorDialog.info" | translate }}
    <span class="bold">
      <a href="http://service.egzotech.com/" target="_blank">{{
        "unknownErrorDialog.here" | translate
      }}</a
      >!</span
    >
  </p>
  <p class="error-code" *ngIf="errorCode">
    {{ "unknownErrorDialog.errorCode" | translate }}
    <span>{{ errorCode }}</span>
  </p>
  <p class="error-message" *ngIf="errorMessage">
    {{ "unknownErrorDialog.errorMessage" | translate }}
    <span class="bold">{{ errorMessage }}</span>
  </p>
  <div class="actions-container">
    <button class="outline-button" mat-ripple (click)="ok()">
      {{ "common.close" | translate }}
    </button>
  </div>
</mat-dialog-content>
