import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ExerciseState } from '@app/training/services/ExerciseState';


@Component({
  selector: 'sba-emg-training-controls',
  templateUrl: './emg-training-controls.component.html',
  styleUrls: ['./emg-training-controls.component.scss']
})
export class EmgTrainingControlsComponent {
  @Input() state: any;

  @Output() pause = new EventEmitter();
  @Output() stop = new EventEmitter();
  @Output() start = new EventEmitter();
}
