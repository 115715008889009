import { Component, OnInit } from '@angular/core';
import { ClinicsService } from '../../clinics/services/clinics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Pages } from '@app/pages';

@Component({
  selector: 'sba-accept-invitation',
  templateUrl: './accept-invitation.component.html',
  styleUrls: ['./accept-invitation.component.scss']
})
export class AcceptInvitationComponent implements OnInit {

  private token: string;

  constructor(
    private readonly clinicService: ClinicsService,
    private readonly router: Router,
    route: ActivatedRoute,
  ) {
    route.queryParams.pipe(map(p => p.token)).subscribe({
        next: param => {
          this.token = param;
        }
      });
  }

  ngOnInit(): void {
    this.clinicService.acceptInvitation(this.token).subscribe(() => {
      this.router.navigate([Pages.SIGNIN]);
    });
  }

}
