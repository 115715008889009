import { AutoCursorModes, AxisTickStrategies, ChartXY, ColorHEX, ColorRGBA, emptyFill, emptyLine, lightningChart, RectangleFigure, RectangleSeries, SolidFill, SolidLine, Themes, UIOrigins } from '@arction/lcjs';
import { environment } from '@env/environment';
import { ChartOptions, EgzoChart, ThresholdLine } from './EgzoChart';
import { Point } from '@arction/lcjs';
import { getChartLabel } from '@app/utils/utils';

export interface BarChartOptions extends ChartOptions {
  figureThickness: number;
  figureGap: number;
}

export interface Value {
  value: number;
}

export interface AddResult {
  entry: Value;
  rect: RectangleFigure;
}

export class BarChart extends EgzoChart {
  private bars = [];
  private x: number;
  private nextValues: number[] = [];
  private shownValues: number[] = [];
  private rectangles: RectangleSeries;
  private options: BarChartOptions;
  entries: Record<number, Value> = {};

  constructor(options: Partial<BarChartOptions> = {}, yAxisDescription: string, initializedChannels: number[] = []) {
    super();
    this.options = {
      container: 'bar-chart',
      theme: Themes.light,
      showFPS: !environment.production,
      figureGap: 5,
      figureThickness: 10,
      ...options
    };
    this._chart = this.initializeChart(this.options, yAxisDescription, initializedChannels);
    this.initializeFPSIndicator(this.options.showFPS);
    requestAnimationFrame(() => this.updateView());
  }

  private initializeChart(options: ChartOptions, yAxisDescription: string, initializedChannels: number[] = []): ChartXY {
    const chart = lightningChart(environment.lightningChartLicense ?? undefined)
      .ChartXY(options)
      .setTitleFillStyle(emptyFill)
      .setAutoCursorMode(AutoCursorModes.disabled)
      .setBackgroundFillStyle(new SolidFill({
        color: ColorRGBA(255, 0, 0, 0)
      }))
      .setChartBackgroundFillStyle(
        new SolidFill({
          color: ColorRGBA(255, 0, 0, 0)
        })
      )
      .setMouseInteractions(false);
    this.rectangles = chart.addRectangleSeries();
    chart.setAutoCursor(cursor => cursor
      .disposePointMarker()
      .disposeTickMarkerX()
      .disposeTickMarkerY()
      .setGridStrokeXStyle(emptyLine)
      .setGridStrokeYStyle(emptyLine)
      .setResultTable((table) => {
        table.setOrigin(UIOrigins.CenterBottom);
      })
    );
    // Change how series parses its data-points using series method.
    this.rectangles.setResultTableFormatter((builder, series, figure) => {
      let counter = 0;
      // Find cached entry for the figure.
      const entry = this.bars.find((bar, index) => {
        counter = index;
        return bar.rect === figure;
      }).entry;
      // Parse result table content from values of 'entry'.
      return builder
        .addRow(`Value: ${entry.value}%`);
    });

    this.xAxis = chart.getDefaultAxisX()
      .setTitle('')
      .setMouseInteractions(false)
      .setScrollStrategy(undefined)
      .setTickStrategy(AxisTickStrategies.Empty)
      .disableAnimations();

    // Y-axis of the series
    this.yAxis = chart.getDefaultAxisY()
      .setTitle(yAxisDescription)
      .setMouseInteractions(false)
      .setScrollStrategy(undefined)
      .disableAnimations();

    const constantLine = this.yAxis.addConstantLine();
    constantLine.setValue(0)
      .setMouseInteractions(false)
      .setStrokeStyle(new SolidLine(
        { thickness: 2, fillStyle: new SolidFill({ color: ColorRGBA(20, 20, 20) }) }));

    this.thresholdLine = new ThresholdLine(this.yAxis, options.threshold);

    // Initialize channels
    const entries: Record<number, Value> = {}

    initializedChannels.forEach((initializedChannel) => {
      entries[initializedChannel] = {value: 0};
    });
    this.addValues(entries);

    return chart;
  }

  addVerticalLine(value: number, color: string) {
    // No vertical lines in BarChart
  }

  addValues(entries: Record<number, Value>) {
    if (!entries) {
      return;
    }

    this.x = 0;
    this.bars.forEach(v => v.rect.dispose());
    this.bars = [];
    this.rectangles.clear();
    Object.entries(entries).forEach(([index, entry], chIdx) => {
      this.bars.push(this.add(entry, chIdx));
    });
    this.nextValues = Object.values(entries).map(e => e.value);
    this.shownValues = Object.values(entries).map(e => e.value);
  }

  private updateView() {
    this.shownValues = this.nextValues.map((v, i) => (v + this.shownValues[i]) / 2);
    for (let i = 0; i < Math.min(this.bars.length, this.shownValues.length); i++) {
      const dim = this.bars[i].rect.getDimensionsPositionAndSize();
      this.bars[i].rect.setDimensions({
        ...dim,
        height: this.shownValues[i]
      });
    }
    requestAnimationFrame(() => this.updateView());
  }

  setVisibleChannels(channels: number[]) {
    // Always visible
  }

  setColorArray(newArray: string[]) {
    this.colorArray = newArray;
    this.bars.forEach((v, index) => v.rect.setFillStyle(new SolidFill().setColor(this.colorArray[index] ? ColorHEX(this.colorArray[index]) : ColorHEX('#ff0000'))));
  }

  updateValues(entries: Record<number, Value>) {
    this.entries = entries;
    if (Object.keys(entries).length !== this.bars.length) {
      return this.addValues(entries);
    }
    this.nextValues = Object.values(entries).map(e => e.value);
  }

  addValue(point: Point, channel = 0) {
    this.entries[channel] = {value: point.y / this.channelFactor[channel]};
    this.updateValues(this.entries);
  }

  private add(entry: Value, index?: number): AddResult {
    // Create rect dimensions.
    const rectDimensions = {
      x: this.x - this.options.figureThickness,
      y: 0,
      width: this.options.figureThickness,
      height: entry.value
    };
    // Add rect to the series.
    const rect = this.rectangles.add(rectDimensions);
    // Set individual color for the bar.

    rect.setFillStyle(new SolidFill().setColor(this.colorArray[index] ? ColorHEX(this.colorArray[index]) : ColorHEX('#ff0000')));

    // Set view manually.
    this.xAxis.setInterval(
      -(this.options.figureThickness + this.options.figureGap),
      this.x + this.options.figureGap
    );

    this.x += this.options.figureThickness + this.options.figureGap;
    // Return data-structure with both original 'entry' and the rectangle figure that represents it.
    return {
      entry,
      rect
    };
  }
}
