import { ChangeDetectionStrategy, Component, Input, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import { MinMax } from '@app/types';

@Component({
  selector: 'sba-electro-slider',
  templateUrl: './electro-slider.component.html',
  styleUrls: ['./electro-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElectroSliderComponent {

  @ViewChild('slider', { static: true })
  slider: ElementRef;

  @Input()
  set range(range) {
    this.rrange = range;
    this.gradient = this.calculateGradient();
  }

  rrange: MinMax = { min: 0, max: 100 };

  @Input() lvl = 0;

  gradient: string;
  constructor() { }

  getTransform() {
    if (!this.rrange) {
      return '';
    }
    if (this.slider) {
    const width = this.slider.nativeElement.clientWidth;

    return `translateX(${width * (this.lvl) / (this.rrange.max)}px)`;
    }

  }

  getBackgroundColor() {
    return this.lvl >= 11 ? '#fff6a2' : 'white';
  }

  calculateGradient() {
    let currentThresholdPercentage = 50;
    if (this.rrange) {
      currentThresholdPercentage = (10 / this.rrange.max) * 100;
    }
    return `linear-gradient(90deg, #66cccc, #66cccc ${currentThresholdPercentage - 1}%, #ffee00 ${currentThresholdPercentage + 1}%, #ffeb07 12%, #ffc248 35%, #d90677 70%, #74219c)`;
  }
}
