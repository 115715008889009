<div class="emg-page--content">
  <div class="scale">
    <sba-scale-selector [value]="max" (change)="changeScale($event)">
    </sba-scale-selector>
    <ng-content select="[rightTop]"></ng-content>
  </div>
  <div class="chart-slider-container">
    <div class="chart-content">
      <div class="chart-container">
        <div id="bar-chart-ems" #barChart></div>
      </div>
      <ng-content select="[badges]"></ng-content>
    </div>

    <div class="slider-container"
  
     *ngIf="showSlider">
      <mat-slider
      class="slider"
        vertical
        min="0"
        [max]="max"
        [step]="max / 50"
        [value]="threshold"
        (input)="handleThresholdChange($event)"
      ></mat-slider>
      <p class="mvc-text">{{ 'biofeedback.mvcSlider' | translate }}</p>
    </div>
  </div>
</div>
