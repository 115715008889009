<div class="outer-container {{ size }} {{avatar}} {{ role === '0' ? 'no-role' : '' }}">
  <img
    *ngIf = "!fallbackAvatarError; else connectionErrorIcon"
    class="avatar"
    src="{{ avatar | localFunction:getUrl }}"
    (error)="getOnErrorImage($event)"
  />
  <ng-template #connectionErrorIcon>
    <mat-icon class="no-connection-error" [matTooltip]="'common.avatarError' | translate">error_outline</mat-icon>
  </ng-template>
  <mat-icon class="feature-icon" *ngIf="role" [svgIcon]="this.role | localFunction:getFeatureIcon"></mat-icon>
</div>
