<div class="stim-all-search">
  <div class="content">
    <div *ngFor="let group of groupedPrograms | keyvalue | localFunction:sortByOrder">
      <div class="group-header">
        <h2>{{ "ems_program_group." + group.key | translate }}</h2>
        <p *ngIf="group.key | localFunction: hasDescription">
          {{ "ems_program_description." + group.key | translate }}
        </p>
      </div>
      <div class="stim-all-search--content">
        <sba-tile
          [data]="test"
          [disallowed]="test.allowed === false"
          *ngFor="let test of group.value"
          class="entry"
          (click)="handleClick(test)"
        >
          <div actions>
            <sba-game-copyright-action
              *ngIf="test.type === 'games'"
              [game]="test.id"
            ></sba-game-copyright-action>
          </div>
          <div stim class="details" *ngIf="test.type === 'games'">
            <div class="detail">
              <div class="label">{{ "games.channel" | translate }}</div>
              <div class="value">{{ test | localFunction:getChannelsAvailable }}</div>
            </div>
          </div>
          <div stim class="details" *ngIf="test.type === 'electrostim'">
            <div class="detail">
              <div class="label">{{ "electrostim.pulse" | translate }}</div>
              <div class="value">{{ test.steps | pulses }} {{ 'common.units.microseconds' | translate }}</div>
            </div>
            <div class="detail">
              <div class="label">{{ "electrostim.frequency" | translate }}</div>
              <div class="value">{{ test.steps | frequencies }} {{ 'common.units.hertz' | translate }}</div>
            </div>
            <div class="detail">
              <div class="label">{{ "electrostim.shape" | translate }}</div>
              <div class="value">
                {{
                  "electrostim.current." + test.steps.details.currentType
                    | translate
                }}
              </div>
            </div>
          </div>
        </sba-tile>
        <!-- <div class="entry" *ngIf="group.value.length % 2 === 1"></div> -->
      </div>
    </div>
  </div>
</div>
