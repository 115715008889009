import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { environment } from '@env/environment';
import { Gender } from './../../models/Gender.enum';
import { Role } from './../../models/Role.enum';

@Component({
  selector: 'sba-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {
  @Input() avatar: string;
  @Input() gender: Gender;
  @Input() size: 'small' | 'medium' | 'large' = 'small';
  @Input() role: Role | string = Role.PATIENT;

  private fallbackAvatarUsed = false;
  fallbackAvatarError = false;

  getOnErrorImage(event): void {
    if (!this.fallbackAvatarUsed) {
      event.target.src = `assets/svg/icon_avatar_${this.getGenderIcon(this.gender)}.svg`;
      this.fallbackAvatarUsed = true;
    } else if (!this.fallbackAvatarError) {
      this.fallbackAvatarError = true;
    }
  }

  getUrl(avatar: string): string {
    if (!avatar) {
      return;
    }
    if (avatar.startsWith('data:image')) {
      return avatar;
    }
    return environment.assetsUrl + '/' + avatar;
  }

  getGenderIcon(gender: Gender): string {
    switch (gender) {
      case Gender.FEMALE:
        return 'woman';
      case Gender.MALE:
        return 'man';
      default:
        return 'man';
    }
  }

  getFeatureIcon(role: Role): string {
    switch (Number(role)) {
      case Role.OBSERVER:
        return 'avatar_feature_observer';
      case Role.OWNER:
        return 'avatar_feature_owner';
      case Role.PATIENT:
        return 'avatar_feature_patient';
      case Role.RECEPTIONIST:
        return 'avatar_feature_reception';
      case Role.THERAPIST:
        return 'avatar_feature_specialist';
      default:
        return '';
    }
  }
}
